import axios from "axios";
import { configUrl } from "config.js";
import CryptoJS from 'crypto-js';

const key = 'pj23vs7nycq18uew';
let secretKey = CryptoJS.enc.Utf8.parse(key);

const encryptPassword = (password, secretKey) => {

    let encryptedBytes = CryptoJS.AES.encrypt(password, secretKey, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
    let encryptedString = encryptedBytes.toString();

    console.log(encryptedString, 'encryptedone');
    return encryptedString;
    // return CryptoJS.AES.encrypt(password, secretKey).toString();
}
const decryptPassword = (encryptedPassword, secretKey) => {

    let decryptedBytes = CryptoJS.AES.decrypt(encryptedPassword, secretKey, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
    let decryptedText = decryptedBytes.toString(CryptoJS.enc.Utf8);

    console.log(decryptedText, 'decryptedone');
    return decryptedText;
    // const bytes = CryptoJS.AES.decrypt(encryptedPassword, secretKey);
    // return bytes.toString(CryptoJS.enc.Utf8);
}
const getUserDetails = async (userId) => {
    let userID = userId ?? 0;
    let response = await axios.get(`${configUrl.AssessmentServer}/api/User/${userID}`);
    console.log(response?.data?.user, 'responseData')
    if (response?.data?.user != null) {
        response.data.user.passCode = decryptPassword(response?.data?.user?.passCode, secretKey)
    } else {
        console.log('adding user')
    }
    return response.data;
};
export default {
    getUserDetails: getUserDetails,
}