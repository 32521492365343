import { FormControlLabel, Radio, makeStyles } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import Snackbar from "@material-ui/core/Snackbar";
import { Check, FiberManualRecord, ModeCommentOutlined } from "@material-ui/icons";
import FormatAlignJustifyIcon from "@material-ui/icons/FormatAlignJustify";
import Help from "@material-ui/icons/Help";
import MuiAlert from "@material-ui/lab/Alert";
import { Checkbox, CircularProgress } from "@mui/material";
import radioStyles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle";
import Accordion from "components/Accordion/Accordion";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CustomAlert from "components/CustomAlert/CustomAlert";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput";
import CustomModal from "components/CustomModal/CustomModal";
import CustomMultipleSelect from "components/CustomSelect/CustomMultipleSelect";
import CustomSelect from "components/CustomSelect/CustomSelect";
import ImageUpload from "components/CustomUpload/ImageUpload";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { LoginContext } from "contexts/LoginContext";
import useGetCategoryService from "hooks/categoryHooks/useGetCategoryService";
import useGetQuestionByIdService from "hooks/questionHooks/useGetQuestionByIdService";
import useQuestionUpdate from "hooks/questionHooks/useQuestionUpdate";
import React, { useContext, useEffect, useState } from "react";
import DropdownTreeSelect from "react-dropdown-tree-select";
import getObjectiveService from "services/ConfigObjective/getObjectiveService";
import questionService from "services/QuestionService/questionService";
import getTargetAudience from "services/TargetAudience/getTargetAudience";
import QuestionPreview from "./QuestionPreview";
var styles = {
  ...dashboardStyle,
  ...loginPageStyle,
  ...radioStyles,
  cardTitle: {
    marginTop: "0",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles(styles);

const TreeDropDown = React.memo(
  ({ data, onChange, className, isDisable }) => {
    return (
      <>
        <DropdownTreeSelect
          disabled={isDisable}
          data={data}
          onChange={onChange}
          className={className}
          mode='radioSelect'
        />
      </>
    );
  },
  (prevProps, nextProps) => {
    return true;
  }
);

function QuestionForm(props) {
  //console.log(props, 'propsinQuestionForm')
  //const { data: questionDetails } = useGetQuestionById(props.id);
  const update = useQuestionUpdate();
  const [categories, setCategories] = React.useState(null);
  const [getObjectiveData, setObjectiveData] = React.useState([]);
  const [getTargetAudienceData, setTargetAudienceData] = React.useState([]);

  const [selectedEnabled, setSelectedEnabled] = useState(null);

  const [open, setOpen] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState(null);
  const [severity, setSeverity] = React.useState("success");

  const [selected, setSelected] = React.useState([]);
  const [showAlert, setShowAlert] = React.useState(null)
  const [flag, setFlag] = React.useState(false)
  const [questionsType, setQuestionsType] = React.useState(2)
  const [selectedTopics, setSelectedTopics] = React.useState([]);
  const [categoryTree, setCategoryTree] = React.useState(null);
  const [loaded, setIsLoading] = React.useState(false);
  const { user, providerId, userPrivileges } = useContext(LoginContext);
  const [showPreview, setShowPreview] = React.useState(false);
  const [handleClear, setHandleClear] = React.useState(false);
  const [model, setModel] = useState(null);
  let newModel =
  {
    $id: "1",
    questionId: 0,
    providerId: providerId,
    code: "",
    questionType: props.questionType ?? 2,
    contentType: 3,
    choicesAlignment: 1,
    objective: "",
    targetAudience: "",
    status: 1,
    isLocked: null,
    lockedBy: null,
    lockedDate: null,
    isActive: null,
    expiryDate: null,
    duration: null,
    difficultyLevel: 1,
    points: 0,
    owner: user,
    createdBy: user,
    createdDate: new Date(),
    updatedBy: user,
    updatedDate: new Date(),
    provider: null,
    poolQuestionMappings: {
      $id: "2",
      $values: []
    },
    questionChoices: {
      $id: "3",
      $values: [
        {
          $id: "4",
          questionId: 0,
          languageId: 1,
          isActive: true,
          choiceId01: null,
          choiceId02: null,
          choiceId03: null,
          choiceId04: null,
          choiceId05: null,
          choiceId06: null,
          choiceId01image: null,
          choiceId02image: null,
          choiceId03image: null,
          choiceId04image: null,
          choiceId05image: null,
          choiceId06image: null,
          choiceIdAnswers: null,
          choiceIdPoints: null,
          createdBy: user,
          createdDate: new Date(),
          updatedBy: user,
          updatedDate: new Date(),
          language: null,
          question: null
        }
      ]
    },
    questionLanguages: {
      $id: "5",
      $values: [
        {
          $id: "6",
          questionId: 0,
          languageId: 1,
          text: "",
          image: null,
          textHtml: null,
          solution: null,
          solutionText: null,
          paragraphText: null,
          paragraphImage: null,
          hint: null,
          passFeedback: null,
          failFeedback: null,
          masteryFeedback: null,
          isActive: null,
          status: 1,
          createdBy: user,
          createdDate: new Date(),
          updatedBy: user,
          updatedDate: new Date(),
          language: null,
          question: null
        }
      ]
    },
    topicQuestionMappings: {
      $id: "7",
      $values: [
        {
          $id: "8",
          topicId: 6,
          questionId: 0,
          isMandatory: null,
          createdBy: user,
          createdDate: new Date(),
          updatedBy: user,
          updatedDate: new Date(),
          question: null,
          topic: null
        }
      ]
    },
    topic: 0
  }

  const getObjectsndTargetAudience = async () => {

    try {
      let getOjbectives = await getObjectiveService.getObjective(true, false);
      setObjectiveData(getOjbectives['$values']);
      let getTargetAudiences = await getTargetAudience.getTargetAudience(true, false);
      setTargetAudienceData(getTargetAudiences['$values']);
    } catch (error) {
      console.error("Error fetching objectives and target audiences:", error);

    }
  }
  const getCategories = async () => {
    let getCategory = await useGetCategoryService.useGetCategoryService();
    console.log('categories', getCategory.length)
    setCategories(getCategory);
  }

  React.useEffect(() => {

    const fetchData = async () => {
      await getObjectsndTargetAudience();
      await getCategories();
      if (props.id == 0) {
        setModel(newModel)
        setIsLoading(true)
      } else {
        setIsLoading(false)
        useGetQuestionByIdService.useGetQuestionByIdService(props.id).then(resp => {
          console.log('got new user', resp)
          setModel(resp)
          setIsLoading(true)
        })
      }
    }
    // call the function
    fetchData()
      // make sure to catch any error
      .catch(console.error);
  }, [props]);

  React.useEffect(() => {
    //needs to be clear to avoid retaining of options
    setSelectedEnabled(null);
    setSelectedEnabled(model?.questionChoices["$values"][0]["choiceIdAnswers"]);

    if (model?.questionType == 1) {
      setSelected([]);
      if (model.questionChoices["$values"][0]["choiceIdAnswers"] == null) {
        setSelected([])
      } else {
        // setSelected(model && JSON.parse(model.questionChoices["$values"][0]["choiceIdAnswers"]))
        if (isJSON(model?.questionChoices["$values"][0]["choiceIdAnswers"])) {

          const filteredArray = model && JSON.parse(model?.questionChoices["$values"][0]["choiceIdAnswers"]).filter(item => {
            const pattern = /[a-zA-Z0-9,IVXivx]/g;
            return pattern.test(item);
          });
          console.log(filteredArray, 'filteredArray1')
          setSelected(filteredArray);
          // setSelected(model && JSON.parse(model?.questionChoices["$values"][0]["choiceIdAnswers"]))
        } else {
          setSelected(model?.questionChoices["$values"][0]["choiceIdAnswers"]);
        }
      }
    }
  }, [model]);

  useEffect(() => {
    if (model?.questionChoices) {

      if ((model.questionChoices["$values"][0]["choiceIdAnswers"] == null && model.status == 2) || (model.questionChoices["$values"][0]["choiceIdAnswers"] == "" && model.status == 2) || (model.questionChoices["$values"][0]["choiceIdAnswers"] == "[]" && model.status == 2)) {
        return (
          setShowAlert(
            <CustomAlert
              open={true}
              openFlag={() => setFlag(flag ? false : true)}
              severity="error"
              alertMessage="You have to set answers for question ready status."
            />
          )
        )
      }
    }
  }, [model?.status])

  const getTopicIds = (node, topics) => {
    const children = categories.filter((x) => x.parent == node.id);
    topics.push(node.topicId);
    if (children && children.length > 0) {
      children.forEach((y) => {
        getTopicIds(y, topics);
      });
    }
  };

  const setTopicIds = (root, tops) => {

    const children = categories.filter((x) => x.parent == root.id);
    if (tops.includes(root.topicId)) {
      root.checked = true;
    }
    else {
      root.checked = false;
    }

    if (children && children.length > 0) {
      children.forEach((y) => {
        setTopicIds(y, tops);
      });
    }
  };
  const createHierarchy = (root) => {
    if (!root) return;

    root.label = root.name;
    root.value = root.id;

    let children = [...categories].map(x => { return { ...x } }).filter(
      (x) => x.parent == root.id
    );

    if (children && children.length > 0) {
      root.disabled = true;
      root.children = [];

      children.forEach((x) => {
        x.value = x.id;
        x.label = x.name;
        root.children.push(x);
        createHierarchy(x);
      });
    } else {
      root.disabled = false;
    }
    return;
  };
  React.useEffect(() => {
    if (categories) {

      let root = [...categories].map(x => { return { ...x } }).find((x) => x.parent == null);

      if (model && model?.topicQuestionMappings) {
        let IdArray = [];
        for (let a = 0; a < model?.topicQuestionMappings["$values"].length; a++) {

          let modelTopicQuestionMapping = model.topicQuestionMappings["$values"][a].topicId > 0 ? model.topicQuestionMappings["$values"][a].topicId : null;
          IdArray.push(modelTopicQuestionMapping);
        }
        const tops = IdArray;
        setTopicIds(root, tops);
      }
      createHierarchy(root);
      setCategoryTree(root)
    }
  }, [categories, model]);

  const onChange = (currentNode, selectedNodes) => {
    let topicIds = [];
    selectedNodes.forEach((x) => {
      // topicIds.push(x.topicId);
      getTopicIds(x, topicIds);
    });
    setSelectedTopics(topicIds);

  };

  const handleQuestionChange = (event) => {
    setQuestionsType(event.target.value);

    // questionDetails.questionType = event.target.value;
    model.questionType = event.target.value
    model.questionChoices["$values"][0]["choiceIdAnswers"] = null
    if (event.target.value == 3) {
      model.questionChoices["$values"][0]["choiceId01"] = "True"
      model.questionChoices["$values"][0]["choiceId02"] = "False"
      model.questionChoices["$values"][0]["choiceId03"] = null
      model.questionChoices["$values"][0]["choiceId04"] = null
      model.questionChoices["$values"][0]["choiceId05"] = null
      model.questionChoices["$values"][0]["choiceId06"] = null
    }
    else if (event.target.value == 2 || event.target.value == 1) {
      model.questionChoices["$values"][0]["choiceId01"] = null
      model.questionChoices["$values"][0]["choiceId02"] = null
      model.questionChoices["$values"][0]["choiceId03"] = null
      model.questionChoices["$values"][0]["choiceId04"] = null
      model.questionChoices["$values"][0]["choiceId05"] = null
      model.questionChoices["$values"][0]["choiceId06"] = null
    }
  };
  const handleContentChange = (event) => {
    model.contentType = event.target.value;
    setModel({ ...model });
  }
  function isJSON(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  const handleCheck = (x) => {
    console.log('selected val', x)
    setSelectedEnabled(x);
    model.questionChoices["$values"][0]["choiceIdAnswers"] = x;
  };

  React.useEffect(() => {
    //needs to be clear to avoid retaining of options
    setSelectedEnabled(null);
    setSelectedEnabled(model?.questionChoices["$values"][0]["choiceIdAnswers"]);
  }, [ModeCommentOutlined]);

  React.useEffect(() => {
    //needs to be clear to avoid retaining of options
    if (model?.questionType == 1) {
      setSelected([]);
      if (model?.questionChoices["$values"][0]["choiceIdAnswers"] == null) {
        setSelected([])
      } else {
        if (isJSON(model?.questionChoices["$values"][0]["choiceIdAnswers"])) {

          const filteredArray = model && JSON.parse(model?.questionChoices["$values"][0]["choiceIdAnswers"]).filter(item => {
            // Define a regular expression pattern to match characters that are allowed
            const pattern = /[a-zA-Z0-9,IVXivx]/g;
            // Use test method to check if the item contains only allowed characters
            return pattern.test(item);
          });
          console.log(filteredArray, 'filteredArr2')
          setSelected(filteredArray);
          // setSelected(model && JSON.parse(model?.questionChoices["$values"][0]["choiceIdAnswers"]))
        } else {
          setSelected(model?.questionChoices["$values"][0]["choiceIdAnswers"]);
        }
      }
    }
  }, [model]);

  const handleCheckbox = (event) => {
    let selectedValues = [...selected];

    if (event.target.checked === true) {
      selectedValues.push(event.target.name);
    } else {
      selectedValues = selectedValues.filter(
        (x) => x !== event.target.name
      );
    }
    setSelected([...selectedValues]);

    model.questionChoices["$values"][0]["choiceIdAnswers"] = JSON.stringify(selectedValues);
  }

  const handleChange = (e) => {
    if (e.target.name == "isLocked" && e.target.value == true) {
      setModel({
        ...model,
        [e.target.name]: e.target.value,
        lockedBy: user,
        lockedDate: new Date(),
      });
      return;
    }
    const regex = /^[a-zA-Z0-9-_]*$/;

    if (e.target.name == 'code') {
      if (regex.test(e.target.value)) {
        setModel({ ...model, [e.target.name]: e.target.value });
      } else {
        showSnackBar("Spaces and special characters are not allowd in code.", "Error");
        return
      }
    }
    setModel({ ...model, [e.target.name]: e.target.value });
  };

  const handleChangeLangauage = (e) => {
    let language = model.questionLanguages["$values"][0];
    language[e.target.name] = e.target.value;
    setModel({ ...model });
  };

  const handleChangeParagraphText = (e) => {
    let language = model.questionLanguages["$values"][0];
    console.log("datasss", e.target.name, e.target.value);
    language[e.target.name] = e.target.value;
    setModel({ ...model });
  };

  const handleChangeChoices = (e) => {
    let Choices = model.questionChoices["$values"][0];
    Choices[e.target.name] = e.target.value === "" ? null : e.target.value;
    setModel({ ...model });
  };

  const getType = (type) => {
    switch (type) {
      case 1:
        return "Multiple Choice Question";
      case 2:
        return "Single Choice Question";
      case 3:
        return "True/False Question";
      case 4:
        return "Numerical Question";
      default:
        return;
      // return "UnKnown";
    }
  };
  // BSS121 want to revisit and correct.

  const updateQuestion = async () => {
    if (model.status == 2) {
      if ((model.questionChoices["$values"][0]["choiceIdAnswers"] == null || model.questionChoices["$values"][0]["choiceIdAnswers"] == "" || model.questionChoices["$values"][0]["choiceIdAnswers"] == "[]")) {
        showSnackBar("Answers is mandatory to set question status to ready state.", "Error");
      }
      else if (model.questionChoices["$values"][0]["choiceIdAnswers"] == null || model.questionChoices["$values"][0]["choiceIdAnswers"] == "" || model.questionChoices["$values"][0]["choiceIdAnswers"] == "[]") {
        showSnackBar("Answers are mandatory to set question status in ready state.", "Error");
      }
      else {
        if (model.questionLanguages["$values"][0]["text"] || (model.questionLanguages["$values"][0].image ? (model.questionLanguages["$values"][0].image == '/static/media/image_placeholder.61b9ec86.jpg') ? false : true : false)) {
          model.objective = model.objective == "[]" ? null : model.objective;
          model.targetAudience = model.targetAudience == "[]" ? null : model.targetAudience;
          await update.mutateAsync(model);
          //console.log("updateStatus", update);
          if (update.isError) {
            showSnackBar("Question not updated", "Error");
          } else {
            showSnackBar("Question updated successfully");
            setTimeout(() => {
              props.afterFinish()
            }, 2300)
          }
        }
        else {
          showSnackBar("Image or text should not empty to set question status in ready state", "Error");
          return
        }
      }
    } else {
      if (model.questionLanguages["$values"][0]["text"] || (model.questionLanguages["$values"][0].image ? (model.questionLanguages["$values"][0].image == '/static/media/image_placeholder.61b9ec86.jpg') ? false : true : false)) {
        console.log(model, 'model1235')
        model.objective = model.objective == "[]" ? null : model.objective;
        model.targetAudience = model.targetAudience == "[]" ? null : model.targetAudience;
        await update.mutateAsync(model);
        if (update.isError) {
          showSnackBar("Question not updated", "Error");
        } else {
          showSnackBar("Question updated successfully");
          setTimeout(() => {
            props.afterFinish()
          }, 2300)
        }
      }
      else {
        showSnackBar("Image or text should not empty to set suestion status in ready state", "Error");
        return
      }
    }
  };
  const [player, setPlayer] = React.useState(null);
  const showPlayer = async () => {
    setPlayer(
      <CustomModal
        modal={true}
        setModal={hidePlayer}
        content={<QuestionPreview model={model} isQuestionPreview={true} />}
        showOk={false}
        showCancel={false}
        onCancelClick={() => hidePlayer()}
        title={`Question Preview `}
        maxWidth="lg"
        fullWidth={true}
        hideTitle={false}
        showHeaderBackground={true}
      />
    );
  };

  const hidePlayer = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setPlayer(null);
  };

  const clearndBindFormData = () => {
    setModel(newModel);
  }
  console.log('model125', model?.code?.length)

  const onSaveQuestion = async (isTimeout) => {
    var imageObj = model.questionLanguages["$values"][0].image;
    var TextObj = model.questionLanguages["$values"][0]["text"];
    var AnswersObj = model.questionChoices["$values"][0]["choiceIdAnswers"];
    var questionChoices = model.questionChoices["$values"][0];
    console.log('model124', model)
    if (model.status == 2 && (model.contentType == null || model.code == null || model?.code?.length == 0 || selectedTopics.length <= 0 || AnswersObj == null || AnswersObj == "" || AnswersObj == "[]")) {
      showSnackBar("Code, Topics ,Answers and, Image or Text is mandatory", "Error");
    } else {
      if ((TextObj != null || imageObj != null) && imageObj != '/static/media/image_placeholder.61b9ec86.jpg' && model.contentType != null && model.code != null && model?.code?.length > 0 && selectedTopics.length > 0) {
        if (model.questionType == 3) {
          questionChoices.choiceId01 = questionChoices.choiceId01 == null ? true : questionChoices.choiceId01;
          questionChoices.choiceId02 = questionChoices.choiceId02 == null ? false : questionChoices.choiceId02;
        }
        model.isActive = model.status == 2 ? true : model.isActive;
        // model.status = model.status == null ? 1 : model.status;
        let topicQuesionMappingArray = [];
        let topicsId = "";
        for (let a = 0; a < selectedTopics.length; a++) {
          let modelTopicQuestionMapping = model.topicQuestionMappings["$values"][0]
          modelTopicQuestionMapping.topicId = selectedTopics[a];
          topicsId = selectedTopics[a];
          topicQuesionMappingArray.push(modelTopicQuestionMapping);
        }
        model.topicQuestionMappings["$values"] = topicQuesionMappingArray;
        model.objective = model.objective == "[]" ? null : model.objective;
        model.targetAudience = model.targetAudience == "[]" ? null : model.targetAudience;
        console.log('model123', model)
        // console.log('topic123', topicsId)
        let createQuestion = await questionService.createQuestion(model, topicsId);
        if (createQuestion.status == 200 || createQuestion.status == 201) {
          showSnackBar("Question created successfully");
          // TODO
          setQuestionsType(model.questionType)
          setHandleClear(handleClear == true ? false : true)
          newModel.code = model.code;
          newModel.objective = model.objective;
          newModel.questionLanguages["$values"][0].image = null;
          // newModel.questionLanguages["$values"][0].image = '/static/media/image_placeholder.61b9ec86.jpg'  // for clearing immage on add more button.
          // newModel.topicQuestionMappings["$values"] = model.topicQuestionMappings["$values"][0]
          newModel.targetAudience = model.targetAudience;
          newModel.questionType = model.questionType;
          newModel.contentType = model.contentType;
          newModel.status = model.status;
          newModel.difficultyLevel = model.difficultyLevel;
          if (model.questionType == 3) {
            newModel.questionChoices["$values"][0].choiceId01 = "True";
            newModel.questionChoices["$values"][0].choiceId02 = "False";
          }

          if (isTimeout == 'false') {
            console.log('not form closed')
          } else {
            setTimeout(() => {
              props.afterFinish()
            }, 2300)
          }

        } else if (createQuestion.status == 400) {
          showSnackBar(createQuestion?.messege ?? 'Error occured.', "Error");
        }
      } else {
        showSnackBar("Code, Topics and, Image or Text is mandatory", "Error");
      }
    }
    console.log(newModel, 'newModel123');
  }

  const createQuestion = async () => {
    onSaveQuestion('false');
    clearndBindFormData();
  }
  const classes = useStyles();
  const getExpression = (x) => {  // this is for mathml editor.
    model.questionLanguages["$values"][0]["text"] = x
  }
  const showSnackBar = (message, sev) => {
    setSeverity(sev ?? "success");
    setAlertMessage(message);
    setOpen(true);
  };

  const isPrivilege = (x) => {
    if (userPrivileges?.Privileges?.Question.indexOf(x) !== -1) {
      return true;
    }
    else {
      return false;
    }
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertMessage(null);
    setOpen(false);
  };

  return (
    <div>
      {
        !model ? <CircularProgress style={{ marginInline: '40%' }} /> :
          <div>
            {model && (
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="rose">
                    <Help />
                  </CardIcon>
                </CardHeader>
                <CardBody>
                  <GridContainer justifyContent="center">
                    {/* Image upload */}
                    <GridItem xs={12} sm={12} md={3}>
                      <h3>{getType(model.questionType)}</h3>
                      <GridItem xs={12} sm={12} md={10}>
                        <ImageUpload
                          source={model.questionLanguages["$values"][0].image}
                          label={'Question'}
                          handleClear={handleClear}
                          setImageValue={(e) => {
                            handleChangeLangauage({
                              target: {
                                name: "image",
                                value: e == '/static/media/image_placeholder.61b9ec86.jpg' ? null : e,
                              },
                            });
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={10} style={{ marginTop: '15%' }}>
                        <ImageUpload
                          source={model.questionLanguages["$values"][0].solution}
                          label={'Solution'}
                          handleClear={handleClear}
                          setImageValue={(e) => {
                            handleChangeLangauage({
                              target: {
                                name: "solution",
                                value: e == '/static/media/image_placeholder.61b9ec86.jpg' ? null : e,
                              },
                            });
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={10} style={{ marginTop: '15%' }}>
                        <ImageUpload
                          source={model.questionLanguages["$values"][0].paragraphImage}
                          label={'Paragraph'}
                          handleClear={handleClear}
                          setImageValue={(e) => {
                            handleChangeLangauage({
                              target: {
                                name: "ParagraphImage",
                                value: e == '/static/media/image_placeholder.61b9ec86.jpg' ? null : e,
                              },
                            });
                          }}
                        />
                      </GridItem>
                    </GridItem>
                    {/* Text based Inputs */}
                    <GridItem xs={12} sm={12} md={7}>

                      <Accordion
                        collapses={[
                          {
                            title: <h4 style={{ fontWeight: '400' }}>Question Configurations</h4>,
                            content:
                              <GridContainer>
                                <GridItem xs={12} sm={12} md={3}>
                                  <CustomSelect
                                    name="contentType"
                                    options={[
                                      {
                                        id: 1,
                                        name: "Text Based",
                                      },
                                      {
                                        id: 3,
                                        name: "Latex Based",
                                      },
                                      // {
                                      //   id: 4,
                                      //   name: "MathML Based",
                                      // },
                                    ]}
                                    setvalue={(v) =>
                                      handleContentChange({
                                        target: { value: v },
                                      })
                                    }
                                    disabled={!props.id == 0}
                                    value={model.contentType}
                                    label={<span>Content Type <small>(required)</small></span>}
                                  />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={3}>
                                  <CustomSelect
                                    name="questionType"
                                    options={[
                                      {
                                        id: 1,
                                        name: "MCQ",
                                      },
                                      {
                                        id: 2,
                                        name: "SCQ",
                                      },
                                      {
                                        id: 3,
                                        name: "True/False",
                                      },
                                      isPrivilege("NumericalQuestionType") &&
                                      {
                                        id: 4,
                                        name: "Numerical",
                                      },
                                    ].filter(Boolean)
                                    }
                                    setvalue={(v) =>
                                      handleQuestionChange({
                                        target: { value: v },
                                      })
                                    }
                                    disabled={!props.id == 0}
                                    value={model.questionType}
                                    label={<span>Question Type <small>(required)</small></span>}
                                  />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                  <h6 style={{ textTransform: "none" }}>Topics <small>(required)</small></h6>
                                  {categoryTree && (
                                    props.id != 0 ? <DropdownTreeSelect
                                      disabled={true}
                                      data={categoryTree}
                                      onChange={onChange}
                                      className={"mdl - demo"}
                                      mode='radioSelect'
                                    /> :
                                      <TreeDropDown
                                        isDisable={false}
                                        data={categoryTree}
                                        onChange={onChange}
                                        className={"mdl - demo"}
                                        mode='radioSelect'
                                      />
                                  )}
                                </GridItem>
                                <GridItem xs={12} sm={6} md={4}>
                                  <CustomMultipleSelect
                                    label="Objectives"
                                    selectLabel="Assp"
                                    options={getObjectiveData ? getObjectiveData : ''}
                                    setvalue={(v) => {
                                      handleChange({ target: { name: "objective", value: JSON.stringify(v) } });
                                    }}
                                    id="objective"
                                    name="objective"
                                    value={
                                      model &&
                                        model.objective
                                        ? isJSON(model.objective) == true
                                          ? JSON.parse(model.objective)
                                          : []
                                        : []
                                    }
                                  />
                                </GridItem>
                                <GridItem xs={12} sm={6} md={4}>
                                  <CustomMultipleSelect
                                    label="TargetAudience"
                                    selectLabel="Assp"
                                    options={getTargetAudienceData ? getTargetAudienceData : ''}
                                    setvalue={(v) => {
                                      //console.log('slected field', v)
                                      handleChange({ target: { name: "targetAudience", value: JSON.stringify(v) } });
                                    }}
                                    id="targetAudience"
                                    name="targetAudience"
                                    value={
                                      model &&
                                        model.targetAudience
                                        ? isJSON(model.targetAudience) == true
                                          ? JSON.parse(model.targetAudience)
                                          : []
                                        : []
                                    }
                                  />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={2}>
                                  <CustomSelect
                                    name="status"
                                    options={[
                                      {
                                        id: 1,
                                        name: "Draft",
                                      },
                                      {
                                        id: 2,
                                        name: "Ready",
                                      },
                                      {
                                        id: 3,
                                        name: "Publish",
                                      },
                                    ].filter(option => isPrivilege("PublishQuestion") || option.name !== "Publish")}
                                    setvalue={(v) =>
                                      handleChange({
                                        target: { value: v, name: "status" },
                                      })
                                    }
                                    value={model.status ? model.status : 1}
                                    label="Status"
                                  />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={2}>
                                  <CustomSelect
                                    name="difficultyLevel"
                                    options={[
                                      {
                                        id: 1,
                                        name: "Easy",
                                      },
                                      {
                                        id: 2,
                                        name: "Medium",
                                      },
                                      {
                                        id: 3,
                                        name: "Difficult",
                                      },
                                    ]}
                                    setvalue={(v) =>
                                      handleChange({
                                        target: { value: v, name: "difficultyLevel" },
                                      })
                                    }
                                    value={model.difficultyLevel}
                                    label="Difficulty Level"
                                  />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12}>
                                  <GridContainer>

                                    <GridItem xs={12} sm={12} md={1}>
                                      {/* <Button  size="sm" justIcon round color="primary" style={{ textTransform: 'none',marginTop:'25px',marginLeft:"-27px" }}
                                  onClick={()=>(setOpenEditor(openEditor?false:true))}>
                                    <strong>M</strong>
                              </Button>
                              <Button  size="sm" justIcon round color="primary" style={{ textTransform: 'none',marginTop:'25px',marginLeft:"5px" }}
                                  onClick={()=>(setOpenEditor(openEditor?false:true))}>
                                    <strong>L</strong>
                              </Button> */}
                                    </GridItem>
                                  </GridContainer>
                                </GridItem>
                                {/* Pass feedback Temporary Disabled */}

                                {/* <GridItem xs={12} sm={12} md={4}>
                                  <CustomInput
                                    labelText={<span>Pass Feedback</span>}
                                    id="passFeedback"
                                    formControlProps={{
                                      fullWidth: true,
                                    }}
                                    inputProps={{
                                      name: "passFeedback",
                                      onChange: handleChangeLangauage,
                                      value:
                                        model.questionLanguages &&
                                          model.questionLanguages["$values"][0]["passFeedback"]
                                          ? model.questionLanguages["$values"][0]["passFeedback"]
                                          : "",
                                    }}
                                  />
                                </GridItem> */}

                              </GridContainer>
                          },]} />
                      <GridContainer>

                        {/* will work on it later for auto generate Code control */}
                        <GridItem xs={12} sm={12} md={3}>
                          <CustomInput
                            labelText={<span>Code <small>(required)</small></span>}
                            id="code"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              name: "code",
                              onChange: handleChange,
                              value: model.code ?? "",
                              maxLength: 20,
                            }
                            }
                            disabled={!props.id == 0}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={9}>
                          {/* {
                               openEditor == false ?  */}
                          <CustomInput
                            labelText={<span>Text</span>}
                            id="text"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              name: "text",
                              onChange: handleChangeLangauage,
                              value: model.questionLanguages
                                ? model.questionLanguages["$values"][0]["text"]
                                : "",
                              endAdornment: (
                                <InputAdornment position="end" className={classes.inputAdornment}>
                                  <FormatAlignJustifyIcon
                                    className={classes.inputAdornmentIcon}
                                  />
                                </InputAdornment>
                              ),
                              rows: 3,
                              multiline: true,
                            }}
                          />
                          {/* :
                              <MathEditor getExpression={getExpression} model={model.questionLanguages["$values"][0]["text"]} />
                              } */}
                        </GridItem>
                        <GridItem xs={12} sm={12} md={9}>
                          <CustomInput
                            labelText={<span>Solution</span>}
                            id="solutionText"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              name: "solutionText",
                              onChange: handleChangeLangauage,
                              value: model.questionLanguages &&
                                model.questionLanguages["$values"][0]["solutionText"]
                                ? model.questionLanguages["$values"][0]["solutionText"]
                                : "",
                              endAdornment: (
                                <InputAdornment position="end" className={classes.inputAdornment}>
                                  <FormatAlignJustifyIcon
                                    className={classes.inputAdornmentIcon}
                                  />
                                </InputAdornment>
                              ),
                              rows: 3,
                              multiline: true,
                            }}
                          />
                        </GridItem>
                        {/* <GridItem xs={12} sm={12} md={5}>
                          <CustomInput
                            labelText={<span>Incorrect Feedback</span>}
                            id="failFeedback"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              name: "failFeedback",
                              onChange: handleChangeLangauage,
                              value:
                                model.questionLanguages &&
                                  model.questionLanguages["$values"][0]["failFeedback"]
                                  ? model.questionLanguages["$values"][0]["failFeedback"]
                                  : "",
                              endAdornment: (
                                <InputAdornment position="end" className={classes.inputAdornment}>
                                  <FormatAlignJustifyIcon
                                    className={classes.inputAdornmentIcon}
                                  />
                                </InputAdornment>
                              ),
                              rows: 3,
                              multiline: true,
                            }}
                          />
                        </GridItem> */}
                        <GridItem xs={12} sm={12} md={5}>
                          <CustomInput
                            labelText={<span>Paragraph Text</span>}
                            id="paragraphText"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              name: "paragraphText",
                              onChange: handleChangeParagraphText,
                              value:
                                model.questionLanguages &&
                                  model.questionLanguages["$values"][0]["paragraphText"]
                                  ? model.questionLanguages["$values"][0]["paragraphText"]
                                  : "",
                              endAdornment: (
                                <InputAdornment position="end" className={classes.inputAdornment}>
                                  <FormatAlignJustifyIcon
                                    className={classes.inputAdornmentIcon}
                                  />
                                </InputAdornment>
                              ),
                              rows: 3,
                              multiline: true,
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={2}></GridItem>
                        <GridItem xs={12} sm={12} md={5}>
                          <CustomInput
                            labelText={<span>Hint</span>}
                            id="hint"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              name: "hint",
                              onChange: handleChangeLangauage,
                              value:
                                model.questionLanguages &&
                                  model.questionLanguages["$values"][0]["hint"]
                                  ? model.questionLanguages["$values"][0]["hint"]
                                  : "",
                              endAdornment: (
                                <InputAdornment position="end" className={classes.inputAdornment}>
                                  <FormatAlignJustifyIcon
                                    className={classes.inputAdornmentIcon}
                                  />
                                </InputAdornment>
                              ),
                              rows: 3,
                              multiline: true,
                            }}
                          />
                        </GridItem>
                        {/* Temporary Hide */}
                        {/* 
                        <GridItem xs={12} sm={12} md={3}>
                          <CustomInput
                            labelText={<span>Points <small>(required)</small></span>}
                            id="points"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              name: "points",
                              onChange: handleChange,
                              value: model.points,
                              type: "number",
                            }}
                          />
                        </GridItem> */}
                      </GridContainer>
                      {
                        (props.id == 0 ? model.questionType == 1 : model.questionType == 1) ?
                          <>
                            <GridContainer style={{ justifyContent: 'end' }} spacing={2}>
                              <GridItem xs={11} sm={11} md={6}>
                                <div
                                  className={
                                    classes.checkboxAndRadio +
                                    " " +
                                    classes.checkboxAndRadioHorizontal
                                  }
                                >
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        tabIndex={-1}
                                        onClick={handleCheckbox}
                                        checked={
                                          (selected != null && selected.indexOf(model?.questionChoices["$values"][0]["choiceId01"]) !== -1
                                            ? true
                                            : false)
                                        }
                                        checkedIcon={
                                          <Check className={classes.checkedIcon} />
                                        }
                                        icon={<Check className={classes.uncheckedIcon} />}
                                        classes={{
                                          checked: classes.checked,
                                          root: classes.checkRoot,
                                        }}
                                        name={model.questionChoices["$values"][0]["choiceId01"]}
                                      />
                                    }
                                    classes={{ label: classes.label }}
                                  />
                                  <CustomInput
                                    labelText={<span>Choice 1</span>}
                                    id="choiceId01"
                                    formControlProps={{
                                      fullWidth: false,
                                    }}
                                    inputProps={{
                                      name: 'choiceId01',
                                      onChange: handleChangeChoices,
                                      value:
                                        model.questionChoices &&
                                          model.questionChoices["$values"][0]["choiceId01"]
                                          ? model.questionChoices["$values"][0]["choiceId01"]
                                          : "",
                                    }}
                                  />
                                </div>
                              </GridItem>
                              <GridItem xs={11} sm={11} md={6}>
                                <div
                                  className={
                                    classes.checkboxAndRadio +
                                    " " +
                                    classes.checkboxAndRadioHorizontal
                                  }
                                >
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        tabIndex={-1}
                                        onClick={handleCheckbox}
                                        checked={
                                          (selected != null && selected.indexOf(model?.questionChoices["$values"][0]["choiceId02"]) !== -1
                                            ? true
                                            : false)
                                        }
                                        checkedIcon={
                                          <Check className={classes.checkedIcon} />
                                        }
                                        icon={<Check className={classes.uncheckedIcon} />}
                                        classes={{
                                          checked: classes.checked,
                                          root: classes.checkRoot,
                                        }}
                                        name={model?.questionChoices["$values"][0]["choiceId02"]}
                                      />
                                    }
                                    classes={{ label: classes.label }}
                                  />
                                  <CustomInput
                                    labelText={<span>Choice 2</span>}
                                    id="choiceId02"
                                    formControlProps={{
                                      fullWidth: false,
                                    }}
                                    inputProps={{
                                      name: 'choiceId02',
                                      onChange: handleChangeChoices,
                                      value:
                                        model.questionChoices &&
                                          model.questionChoices["$values"][0]["choiceId02"]
                                          ? model.questionChoices["$values"][0]["choiceId02"]
                                          : "",
                                    }}
                                  />
                                </div>
                              </GridItem>
                              <GridItem xs={11} sm={11} md={6}>
                                <div
                                  className={
                                    classes.checkboxAndRadio +
                                    " " +
                                    classes.checkboxAndRadioHorizontal
                                  }
                                >
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        tabIndex={-1}
                                        onClick={handleCheckbox}
                                        checked={
                                          (selected != null && selected.indexOf(model?.questionChoices["$values"][0]["choiceId03"]) !== -1

                                            ? true
                                            : false)
                                        }
                                        checkedIcon={
                                          <Check className={classes.checkedIcon} />
                                        }
                                        icon={<Check className={classes.uncheckedIcon} />}
                                        classes={{
                                          checked: classes.checked,
                                          root: classes.checkRoot,
                                        }}
                                        name={model?.questionChoices["$values"][0]["choiceId03"]}
                                      />
                                    }
                                    classes={{ label: classes.label }}
                                  />
                                  <CustomInput
                                    labelText={<span>Choice 3</span>}
                                    id="choiceId03"
                                    formControlProps={{
                                      fullWidth: false,
                                    }}
                                    inputProps={{
                                      name: 'choiceId03',
                                      onChange: handleChangeChoices,
                                      value:
                                        model.questionChoices &&
                                          model.questionChoices["$values"][0]["choiceId03"]
                                          ? model.questionChoices["$values"][0]["choiceId03"]
                                          : "",
                                    }}
                                  />
                                </div>
                              </GridItem>
                              <GridItem xs={11} sm={11} md={6}>
                                <div
                                  className={
                                    classes.checkboxAndRadio +
                                    " " +
                                    classes.checkboxAndRadioHorizontal
                                  }
                                >
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        tabIndex={-1}
                                        onClick={handleCheckbox}
                                        checked={
                                          (selected != null && selected.indexOf(model?.questionChoices["$values"][0]["choiceId04"]) !== -1
                                            ? true
                                            : false)
                                        }
                                        checkedIcon={
                                          <Check className={classes.checkedIcon} />
                                        }
                                        icon={<Check className={classes.uncheckedIcon} />}
                                        classes={{
                                          checked: classes.checked,
                                          root: classes.checkRoot,
                                        }}
                                        name={model?.questionChoices["$values"][0]["choiceId04"]}
                                      />
                                    }
                                    classes={{ label: classes.label }}
                                  />
                                  <CustomInput
                                    labelText={<span>Choice 4</span>}
                                    id="choiceId04"
                                    formControlProps={{
                                      fullWidth: false,
                                    }}
                                    inputProps={{
                                      name: 'choiceId04',
                                      onChange: handleChangeChoices,
                                      value:
                                        model.questionChoices &&
                                          model.questionChoices["$values"][0]["choiceId04"]
                                          ? model.questionChoices["$values"][0]["choiceId04"]
                                          : "",
                                    }}
                                  />
                                </div>
                              </GridItem>
                              <GridItem xs={11} sm={11} md={6}>
                                <div
                                  className={
                                    classes.checkboxAndRadio +
                                    " " +
                                    classes.checkboxAndRadioHorizontal
                                  }
                                >
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        tabIndex={-1}
                                        onClick={handleCheckbox}
                                        checked={
                                          (selected != null && selected.indexOf(model?.questionChoices["$values"][0]["choiceId05"]) !== -1

                                            ? true
                                            : false)
                                        }
                                        checkedIcon={
                                          <Check className={classes.checkedIcon} />
                                        }
                                        icon={<Check className={classes.uncheckedIcon} />}
                                        classes={{
                                          checked: classes.checked,
                                          root: classes.checkRoot,
                                        }}
                                        name={model?.questionChoices["$values"][0]["choiceId05"]}
                                      />
                                    }
                                    classes={{ label: classes.label }}
                                  />
                                  <CustomInput
                                    labelText={<span>Choice 5</span>}
                                    id="choiceId05"
                                    formControlProps={{
                                      fullWidth: false,
                                    }}
                                    inputProps={{
                                      name: 'choiceId05',
                                      onChange: handleChangeChoices,
                                      value:
                                        model.questionChoices &&
                                          model.questionChoices["$values"][0]["choiceId05"]
                                          ? model.questionChoices["$values"][0]["choiceId05"]
                                          : "",
                                    }}
                                  />
                                </div>
                              </GridItem>
                              <GridItem xs={11} sm={11} md={6}>
                                <div
                                  className={
                                    classes.checkboxAndRadio +
                                    " " +
                                    classes.checkboxAndRadioHorizontal
                                  }
                                >
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        tabIndex={-1}
                                        onClick={handleCheckbox}
                                        checked={
                                          (selected != null && selected.indexOf(model?.questionChoices["$values"][0]["choiceId06"]) !== -1
                                            ? true
                                            : false)
                                        }
                                        checkedIcon={
                                          <Check className={classes.checkedIcon} />
                                        }
                                        icon={<Check className={classes.uncheckedIcon} />}
                                        classes={{
                                          checked: classes.checked,
                                          root: classes.checkRoot,
                                        }}
                                        name={model?.questionChoices["$values"][0]["choiceId06"]}
                                      />
                                    }
                                    classes={{ label: classes.label }}
                                  />
                                  <CustomInput
                                    labelText={<span>Choice 6</span>}
                                    id="choiceId06"
                                    formControlProps={{
                                      fullWidth: false,
                                    }}
                                    inputProps={{
                                      name: 'choiceId06',
                                      onChange: handleChangeChoices,
                                      value:
                                        model.questionChoices &&
                                          model.questionChoices["$values"][0]["choiceId06"]
                                          ? model.questionChoices["$values"][0]["choiceId06"]
                                          : "",
                                    }}
                                  />
                                </div>
                              </GridItem>
                            </GridContainer>
                          </>
                          : (props.id == 0 ? questionsType == 2 || questionsType == 3 : model.questionType == 2 || model.questionType == 3) ?
                            <>
                              <GridContainer style={{ justifyContent: 'end' }} spacing={2}>
                                <GridItem xs={11} sm={11} md={6}>
                                  <div
                                    style={{ color: 'black' }}
                                    className={
                                      classes.checkboxAndRadio +
                                      " " +
                                      classes.checkboxAndRadioHorizontal
                                    }
                                  >
                                    <FormControlLabel
                                      control={
                                        <Radio
                                          checked={model?.questionChoices["$values"][0]["choiceIdAnswers"] !== null && selectedEnabled !== null && selectedEnabled?.trim()?.toLowerCase()?.replace(/[(),]/g, '') === model?.questionChoices["$values"][0]["choiceId01"]?.trim()?.toLowerCase()?.replace(/[(),]/g, '')}
                                          onChange={() => handleCheck(model?.questionChoices["$values"][0]["choiceId01"])}
                                          value="a"
                                          name="radio button enabled1"
                                          aria-label="A"
                                          icon={
                                            <FiberManualRecord
                                              className={classes.radioUnchecked}
                                            />
                                          }
                                          checkedIcon={
                                            <FiberManualRecord
                                              className={classes.radioChecked}
                                            />
                                          }
                                          classes={{
                                            checked: classes.radio,
                                            root: classes.radioRoot,
                                          }}
                                          style={{ width: '70%' }}
                                        />
                                      }
                                      classes={{
                                        label: classes.label,
                                      }}
                                    />
                                    <CustomInput
                                      labelText={<span>Choice 1</span>}
                                      id="choiceId01"
                                      formControlProps={{
                                        fullWidth: false,
                                      }}
                                      inputProps={{
                                        name: 'choiceId01',
                                        onChange: handleChangeChoices,
                                        value:
                                          model.questionChoices &&
                                            model.questionChoices["$values"][0]["choiceId01"]
                                            ? model.questionChoices["$values"][0]["choiceId01"]
                                            : "",
                                      }}
                                      disabled={model.questionType == 3 ? true : false}
                                    />
                                  </div>
                                </GridItem>

                                <GridItem xs={11} sm={11} md={6}>
                                  <div
                                    style={{ color: 'black' }}
                                    className={
                                      classes.checkboxAndRadio +
                                      " " +
                                      classes.checkboxAndRadioHorizontal
                                    }
                                  >
                                    <FormControlLabel
                                      control={
                                        <Radio
                                          checked={model?.questionChoices["$values"][0]["choiceIdAnswers"] !== null && selectedEnabled !== null && selectedEnabled?.trim()?.toLowerCase()?.replace(/[(),]/g, '') === model?.questionChoices["$values"][0]["choiceId02"]?.trim()?.toLowerCase()?.replace(/[(),]/g, '')}
                                          onChange={() => handleCheck(model?.questionChoices["$values"][0]["choiceId02"])}
                                          value="b"
                                          name="radio button enabled2"
                                          aria-label="B"
                                          icon={
                                            <FiberManualRecord
                                              className={classes.radioUnchecked}
                                            />
                                          }
                                          checkedIcon={
                                            <FiberManualRecord
                                              className={classes.radioChecked}
                                            />
                                          }
                                          classes={{
                                            checked: classes.radio,
                                            root: classes.radioRoot,
                                          }}
                                        />
                                      }
                                      classes={{
                                        label: classes.label,
                                      }}
                                    />
                                    <CustomInput
                                      labelText={<span>Choice 2</span>}
                                      id="choiceId02"
                                      formControlProps={{
                                        fullWidth: false,
                                      }}
                                      inputProps={{
                                        name: 'choiceId02',
                                        onChange: handleChangeChoices,
                                        value:
                                          model.questionChoices &&
                                            model.questionChoices["$values"][0]["choiceId02"]
                                            ? model.questionChoices["$values"][0]["choiceId02"]
                                            : "",
                                      }}
                                      disabled={model.questionType == 3 ? true : false}
                                    />
                                  </div>
                                </GridItem>
                                {
                                  model.questionType == 2 && (
                                    <>
                                      <GridItem xs={11} sm={11} md={6}>
                                        <div
                                          style={{ color: 'black' }}
                                          className={
                                            classes.checkboxAndRadio +
                                            " " +
                                            classes.checkboxAndRadioHorizontal
                                          }
                                        >
                                          <FormControlLabel
                                            control={
                                              <Radio
                                                checked={model?.questionChoices["$values"][0]["choiceIdAnswers"] !== null && selectedEnabled !== null && selectedEnabled?.trim()?.toLowerCase()?.replace(/[(),]/g, '') === model?.questionChoices["$values"][0]["choiceId03"]?.trim()?.toLowerCase()?.replace(/[(),]/g, '')}
                                                onChange={() => handleCheck(model?.questionChoices["$values"][0]["choiceId03"])}
                                                value="c"
                                                name="radio button enabled3"
                                                aria-label="C"
                                                icon={
                                                  <FiberManualRecord
                                                    className={classes.radioUnchecked}
                                                  />
                                                }
                                                checkedIcon={
                                                  <FiberManualRecord
                                                    className={classes.radioChecked}
                                                  />
                                                }
                                                classes={{
                                                  checked: classes.radio,
                                                  root: classes.radioRoot,
                                                }}
                                              />
                                            }
                                            classes={{
                                              label: classes.label,
                                            }}
                                          />
                                          <CustomInput
                                            labelText={<span>Choice 3</span>}
                                            id="choiceId03"
                                            formControlProps={{
                                              fullWidth: false,
                                            }}
                                            inputProps={{
                                              name: 'choiceId03',
                                              onChange: handleChangeChoices,
                                              value:
                                                model.questionChoices &&
                                                  model.questionChoices["$values"][0]["choiceId03"]
                                                  ? model.questionChoices["$values"][0]["choiceId03"]
                                                  : "",
                                            }}
                                          />
                                        </div>
                                      </GridItem>

                                      <GridItem xs={11} sm={11} md={6}>
                                        <div
                                          style={{ color: 'black' }}
                                          className={
                                            classes.checkboxAndRadio +
                                            " " +
                                            classes.checkboxAndRadioHorizontal
                                          }
                                        >
                                          <FormControlLabel
                                            control={
                                              <Radio
                                                checked={model?.questionChoices["$values"][0]["choiceIdAnswers"] !== null && selectedEnabled !== null && selectedEnabled?.trim()?.toLowerCase()?.replace(/[(),]/g, '') === model?.questionChoices["$values"][0]["choiceId04"]?.trim()?.toLowerCase()?.replace(/[(),]/g, '')}
                                                onChange={() => handleCheck(model?.questionChoices["$values"][0]["choiceId04"])}
                                                value="d"
                                                name="radio button enabled4"
                                                aria-label="D"
                                                icon={
                                                  <FiberManualRecord
                                                    className={classes.radioUnchecked}
                                                  />
                                                }
                                                checkedIcon={
                                                  <FiberManualRecord
                                                    className={classes.radioChecked}
                                                  />
                                                }
                                                classes={{
                                                  checked: classes.radio,
                                                  root: classes.radioRoot,
                                                }}
                                              />
                                            }
                                            classes={{
                                              label: classes.label,
                                            }}
                                          />
                                          <CustomInput
                                            labelText={<span>Choice 4</span>}
                                            id="choiceId04"
                                            formControlProps={{
                                              fullWidth: false,
                                            }}
                                            inputProps={{
                                              name: 'choiceId04',
                                              onChange: handleChangeChoices,
                                              value:
                                                model.questionChoices &&
                                                  model.questionChoices["$values"][0]["choiceId04"]
                                                  ? model.questionChoices["$values"][0]["choiceId04"]
                                                  : "",
                                            }}
                                          />
                                        </div>
                                      </GridItem>
                                      <GridItem xs={11} sm={11} md={6}>
                                        <div
                                          style={{ color: 'black' }}
                                          className={
                                            classes.checkboxAndRadio +
                                            " " +
                                            classes.checkboxAndRadioHorizontal
                                          }
                                        >
                                          <FormControlLabel
                                            control={
                                              <Radio
                                                checked={model?.questionChoices["$values"][0]["choiceIdAnswers"] !== null && selectedEnabled !== null && selectedEnabled?.trim()?.toLowerCase()?.replace(/[(),]/g, '') === model?.questionChoices["$values"][0]["choiceId05"]?.trim()?.toLowerCase()?.replace(/[(),]/g, '')}
                                                onChange={() => handleCheck(model?.questionChoices["$values"][0]["choiceId05"])}
                                                value="c"
                                                name="radio button enabled3"
                                                aria-label="C"
                                                icon={
                                                  <FiberManualRecord
                                                    className={classes.radioUnchecked}
                                                  />
                                                }
                                                checkedIcon={
                                                  <FiberManualRecord
                                                    className={classes.radioChecked}
                                                  />
                                                }
                                                classes={{
                                                  checked: classes.radio,
                                                  root: classes.radioRoot,
                                                }}
                                              />
                                            }
                                            classes={{
                                              label: classes.label,
                                            }}
                                          />
                                          <CustomInput
                                            labelText={<span>Choice 5</span>}
                                            id="choiceId05"
                                            formControlProps={{
                                              fullWidth: false,
                                            }}
                                            inputProps={{
                                              name: 'choiceId05',
                                              onChange: handleChangeChoices,
                                              value:
                                                model.questionChoices &&
                                                  model.questionChoices["$values"][0]["choiceId05"]
                                                  ? model.questionChoices["$values"][0]["choiceId05"]
                                                  : "",
                                            }}
                                          />
                                        </div>
                                      </GridItem>

                                      <GridItem xs={11} sm={11} md={6}>
                                        <div
                                          style={{ color: 'black' }}
                                          className={
                                            classes.checkboxAndRadio +
                                            " " +
                                            classes.checkboxAndRadioHorizontal
                                          }
                                        >
                                          <FormControlLabel
                                            control={
                                              <Radio
                                                checked={model?.questionChoices["$values"][0]["choiceIdAnswers"] !== null && selectedEnabled !== null && selectedEnabled?.trim()?.toLowerCase()?.replace(/[(),]/g, '') === model?.questionChoices["$values"][0]["choiceId06"]?.trim()?.toLowerCase()?.replace(/[(),]/g, '')}
                                                onChange={() => handleCheck(model?.questionChoices["$values"][0]["choiceId06"])}
                                                value="d"
                                                name="radio button enabled4"
                                                aria-label="D"
                                                icon={
                                                  <FiberManualRecord
                                                    className={classes.radioUnchecked}
                                                  />
                                                }
                                                checkedIcon={
                                                  <FiberManualRecord
                                                    className={classes.radioChecked}
                                                  />
                                                }
                                                classes={{
                                                  checked: classes.radio,
                                                  root: classes.radioRoot,
                                                }}
                                              />
                                            }
                                            classes={{
                                              label: classes.label,
                                            }}
                                          />
                                          <CustomInput
                                            labelText={<span>Choice 6</span>}
                                            id="choiceId06"
                                            formControlProps={{
                                              fullWidth: false,
                                            }}
                                            inputProps={{
                                              name: 'choiceId06',
                                              onChange: handleChangeChoices,
                                              value:
                                                model.questionChoices &&
                                                  model.questionChoices["$values"][0]["choiceId06"]
                                                  ? model.questionChoices["$values"][0]["choiceId06"]
                                                  : "",
                                            }}
                                          />
                                        </div>
                                      </GridItem>
                                    </>
                                  )
                                }
                              </GridContainer>
                            </>
                            :
                            (props.id == 0 ? model.questionType == 4 : model.questionType == 4) ?
                              <GridItem xs={11} sm={11} md={11}>
                                <div
                                  className={
                                    classes.checkboxAndRadio +
                                    " " +
                                    classes.checkboxAndRadioHorizontal
                                  }
                                >
                                  {/* <FormControlLabel
                                    control={
                                      <Checkbox
                                        tabIndex={-1}
                                        onClick={handleCheckbox}
                                        checked={
                                          (selected != null && selected.indexOf(model?.questionChoices["$values"][0]["choiceId01"]) !== -1
                                            ? true
                                            : false)
                                        }
                                        checkedIcon={
                                          <Check className={classes.checkedIcon} />
                                        }
                                        icon={<Check className={classes.uncheckedIcon} />}
                                        classes={{
                                          checked: classes.checked,
                                          root: classes.checkRoot,
                                        }}
                                        name={model.questionChoices["$values"][0]["choiceId01"]}
                                      />
                                    }
                                    classes={{ label: classes.label }}
                                  /> */}
                                  <CustomInput
                                    labelText={<span>Answer</span>}
                                    id="choiceIdAnswers"
                                    formControlProps={{
                                      fullWidth: false,
                                    }}
                                    inputProps={{
                                      name: 'choiceIdAnswers',
                                      onChange: handleChangeChoices,
                                      type: "number",
                                      value:
                                        model.questionChoices &&
                                          model.questionChoices["$values"][0]["choiceIdAnswers"]
                                          ? model.questionChoices["$values"][0]["choiceIdAnswers"]
                                          : "",
                                    }}
                                  />
                                </div>
                              </GridItem>
                              :
                              ''
                      }
                    </GridItem>

                  </GridContainer>
                  {/* Submit/Preview Buttons */}
                  <GridItem xs={12} sm={12} md={12} style={{ textAlign: "right" }}>
                    <Button color="primary" style={{ textTransform: 'none' }}
                      disabled={update.isLoading || model.contentType == null} onClick={showPlayer}
                    >
                      {"Preview"}
                    </Button>
                    <Button color="primary" style={{ textTransform: 'none' }}
                      disabled={update.isLoading}
                      onClick={props.id == 0 ? onSaveQuestion : updateQuestion}>
                      {props.id == 0 ? 'Save' : update.isLoading ? "Updating" : "Update"}
                    </Button>
                    <Button color="primary" style={{ textTransform: 'none' }}
                      disabled={update.isLoading || props.id != 0} onClick={createQuestion}
                    >
                      {"Add more"}
                    </Button>
                  </GridItem>
                </CardBody>
              </Card>
            )}
            <Snackbar
              open={open}
              autoHideDuration={2000}
              onClose={handleClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
              <Alert onClose={handleClose} severity={severity}>
                {alertMessage}
              </Alert>
            </Snackbar>
            {showAlert}
            {player}
          </div>
      }
    </div>
  );
}
export default QuestionForm;
