import React from "react";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Wizard from "components/Wizard/Wizard.js";


import { TestContext } from "contexts/TestContext.js";
import TestConfiguration from "./TestWizardSteps/TestConfiguration.js";
import TestDetails from "./TestWizardSteps/TestDetails.js";
import TestPoolMapping from "./TestWizardSteps/TestPoolMapping.js";

import CustomActionAlert from "components/CustomAlert/CustomActionAlert.js";
import CustomAlert from "components/CustomAlert/CustomAlert.js";
import useCreatePool from "hooks/poolHooks/useCreatePool.js";
import useCreateTest from "hooks/testsHooks/useCreateTest.js";
import useUpdateTest from "hooks/testsHooks/useUpdateTest.js";
import { withRouter } from "react-router";
import activateService from "services/TestService/activateService.js";


function TestWizard(props) {

  const { mutate: createDefaultPool } = useCreatePool();
  let state = null;
  if (props.location && props.location.state) {

    state = props.location.state.state;
  }

  console.log("Test Object", state);
  const { data: response, mutate: createTest, isError, isSuccess } = useCreateTest();
  let id = response && response.data.testId
  const { mutate: updateTest } = useUpdateTest();

  const [testId, setTestId] = React.useState(state ? state.testId : null);
  const [testName, setTestName] = React.useState(state ? state?.text : null)
  const [details, setDetails] = React.useState({ ...state });
  const [languageId, setLanguageId] = React.useState(null);
  const [flag, setFlag] = React.useState(false)
  const [showAlert, setShowAlert] = React.useState(null)
  const [Loading, setLoading] = React.useState(false);
  console.log(testId, testName, "testdataaa");
  React.useEffect(() => {
    console.log(id);
    if (id) {
      setTestId(id);
      console.log("dataat", response?.data?.text)
      //setTestName(testName ?? response?.data?.text);
      createDefaultPool({ testId: id, testCode: details.testCode, poolName: details.text });
    }
  }, [id]);
  console.log(testId, testName, "testdataaa");
  const onNextClick = async (data) => {
    console.log(data);

    let mergedData = { ...details, ...data };

    console.log("mergedData", mergedData);

    if (data.type && data.type == "details" && data.textState !== "error") {
      if (!testId) {
        console.log("creating", data.textState);
        if (data?.text) {
          setTestName(data?.text);
        }
        createTestStructure(mergedData);
      } else {
        console.log("updating");
        console.log("updating123", data.textState);
        updateTestStructure(mergedData);
      }

      setDetails(mergedData);
    }
    console.log(data.pointsPerQuestionsState, data?.attemptsAllowedState, 'pointsperQuestion')
    if (data.type && data.type == "configuration" && data.pointsPerQuestionsState == "success" && data?.attemptsAllowedState == "success" && data?.masteryState == "success" && data?.questionsPerPageState == "success") {
      setDetails({ ...details, ...data });

      let mergedData = { ...details, ...data };

      if (!testId) return;

      await updateTestStructure(mergedData);
    }
  };

  const createTestStructure = async (data) => {
    createTest(data);
  };
  React.useEffect(() => {
    if (isError == true) {
      setShowAlert(
        <CustomActionAlert
          open={true}
          openFlag={() => setFlag(flag ? false : true)}
          onOk={"/admin/test"}
          severity="error"
          alertMessage={"Test code or name already exists."}
        />
      )
    }
  }, [isError]);

  const updateTestStructure = async (data) => {
    console.log("configdata", data)
    data.testId = testId;
    updateTest(data);
  };

  const activateTest = async () => {
    setLoading(true);
    // let resp = await axios.put(`${configUrl.AssessmentServer}/api/Tests/activate/${testId}`);
    let resp = await activateService.activateTestService(testId);
    console.log(resp, 'resp11')

    if (resp.status == 200) {
      setShowAlert(
        <CustomAlert
          open={true}
          openFlag={() => setFlag(flag ? false : true)}
          // onOk={"/admin/testhome"}
          severity="success"
          alertMessage={resp?.messege}
        />
      )
      setLoading(false);
      setTimeout(() => {
        props.history.push("/admin/testhome");
      }, 2000);
    } else {
      setShowAlert(
        <CustomAlert
          open={true}
          openFlag={() => setFlag(flag ? false : true)}
          // onOk={"/admin/test"}
          timeout={4000}
          severity="error"
          alertMessage={resp?.messege || "Error occured."}
        />
      )
      setLoading(false);
    }
    /* if (props.afterFinish) {
      props.afterFinish();
    } */
  };

  const updateTestAsDraft = async () => {
    setShowAlert(
      <CustomAlert
        open={true}
        openFlag={() => setFlag(flag ? false : true)}
        // onOk={"/admin/testhome"}
        severity="success"
        alertMessage="Test saved successfully."
      />
    )
    setTimeout(() => {
      props.history.push("/admin/testhome");
    }, 4000);
    /*  if (props.afterFinish) {
      props.afterFinish();
    } */
  };

  return (
    <TestContext.Provider value={{ testId }}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12}>

          <Wizard
            validate
            steps={[
              {
                stepName: "Information",
                stepComponent: TestDetails,
                stepId: "details",
                st: { ...state, testId },
              },
              {
                stepName: "Configuration",
                stepComponent: TestConfiguration,
                stepId: "configuration",
                st: { ...state, testId },
              },
              {
                stepName: "Questions",
                stepComponent: TestPoolMapping,
                stepId: "poolMapping",
                st: { ...state }
              },
            ]}
            hideTitle={props.hideTitle}
            title={testId ? id ? `New Test : ${testName}` : `Update Test : ${testName}` : "New Test"}
            subtitle=""
            finishButtonClick={details && details.status == 1 ? updateTestAsDraft : activateTest}
            onNextClick={onNextClick}
            // showDraftButton={state && state.isActive ? false : true}
            draftButtonClick={updateTestAsDraft}
            finishButtonText={Loading == true ? "Saving..." : "Save"}    //state && state.isActive ? "Save" : "Save as Live"
            isLoading={Loading}
            draftButtonText="Save as Draft"
            routeKey="testhome"

          />
        </GridItem>
        {showAlert}
      </GridContainer>

    </TestContext.Provider>
  );
}

export default withRouter(TestWizard);
