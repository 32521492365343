import { makeStyles, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import axios from "axios";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { configUrl } from "config";
import React, { useState } from "react";
import { withRouter } from "react-router";
import Button from '../../components/CustomButtons/Button';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
    },
    input: {
        display: "none",
    },
    uploadButton: {
        marginTop: theme.spacing(2),
    },
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function CreatePyqTests(props) {
    const classes = useStyles();
    const [selectedFile, setSelectedFile] = useState(null);

    const [loading, setLoading] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState(null);
    const [severity, setSeverity] = React.useState("success");

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };
    const showSnackBar = (message, sev) => {
        setSeverity(sev ?? "success");
        setAlertMessage(message);
        setOpen(true);
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        const formData = new FormData();
        formData.append('file', selectedFile);

        try {
            const response = await axios.post(`${configUrl.AssessmentServer}/api/TestsCreator/UploadExcelTests`, formData);

            if (response.status === 200) {
                showSnackBar('Test(s) created successfully.', "success");
                setTimeout(() => {
                    props.history.push("/admin/testhome");
                }, 2000);
            } else {
                showSnackBar('error occured.', "error");
            }
        } catch (error) {
            console.log("error");
            showSnackBar('error occured.', "error");
        } finally {
            setLoading(false);
        }
    };
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setAlertMessage(null);
        setOpen(false);
    };

    return (
        <div className={classes.root}>
            <GridContainer container justifyContent="center">
                <GridItem item xs={12} sm={12} md={12}>
                    <h3 style={{ textAlign: "center" }}>
                        Upload Excel File
                    </h3>
                    <form onSubmit={handleSubmit}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                            <input
                                accept=".xlsx, .xls"
                                className={classes.input}
                                id="file-upload"
                                type="file"
                                onChange={handleFileChange}
                                style={{ display: 'none' }}
                            />
                            <label htmlFor="file-upload">
                                <Button variant="contained" color="secondary" component="span">
                                    Choose File
                                </Button>
                            </label>
                            {selectedFile && (
                                <h6 style={{ margin: 0 }}>
                                    {selectedFile.name}
                                </h6>
                            )}
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                className={classes.uploadButton}
                                disabled={loading}
                            >
                                {loading ? 'Uploading' : "Upload"}
                            </Button>
                        </div>
                    </form>
                </GridItem>
                <Snackbar
                    open={open}
                    autoHideDuration={2000}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                >
                    <Alert onClose={handleClose} severity={severity}>
                        {alertMessage}
                    </Alert>
                </Snackbar>
            </GridContainer>
        </div>
    );
}


export default withRouter(CreatePyqTests);
