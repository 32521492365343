import axios from "axios";
import { configUrl } from "config.js";

const useGetCategoryService = async (isContentProvider) => {
    if (isContentProvider == undefined) {
        isContentProvider = false;
    }
    let response = await axios.get(`${configUrl.AssessmentServer}/api/Categories/getCategories/${isContentProvider}`);

    let categories = response.data["$values"].map((x) => {
        //console.log("x...",x)
        return {
            id: x.categoryId + "",
            name: x.categoryLanguages["$values"][0].text,
            parent: x.parentCategoryId,
            isCategory: true,
        };
    });

    let topiResponse = await axios.get(`${configUrl.AssessmentServer}/api/Topics/getTopics/${isContentProvider}`);

    let topics = topiResponse.data["$values"].map((x) => {
        //console.log("Topic..",x)
        return {
            id: "T" + x.topicId,
            name: x.topicLanguages["$values"][0].text,
            categoryId: x.categoryId,
            parent: x.parentTopicId ? "T" + x.parentTopicId : x.categoryId + "",
            isCategory: false,
            topicId: x.topicId,
        };
    });

    let questionCounts = await axios.get(
        `${configUrl.AssessmentServer}/api/Topics/questionCount`
    );
    console.log(questionCounts, 'questionCounts123')
    if (
        questionCounts &&
        questionCounts.data &&
        questionCounts.data["$values"]
    ) {
        questionCounts?.data["$values"]?.forEach((x) => {
            let t = topics.find((y) => y.topicId == x.id);
            console.log(t, 'this is t')
            if (t != undefined) {
                t.questionCount = x.count["$values"][0];
            }
        });
    }


    categories.push(...topics);

    return categories;
}

export default {
    useGetCategoryService: useGetCategoryService,
}
