import axios from "axios";
import { configUrl } from "config.js";
import { LoginContext } from "contexts/LoginContext";
import { useContext } from "react";
import { useMutation, useQueryClient } from "react-query";

export default function useCreateTest() {
  const { user, providerId } = useContext(LoginContext);

  const queryClient = useQueryClient()
  return useMutation(async (data) => {
    let test = {
      providerId: providerId,
      code: data.testCode,
      objective: data.objective != null && data.objective != "none" ? JSON.stringify(data.objective) : null,
      targetAudience: data.targetAudience != null && data.targetAudience != "none" ? JSON.stringify(data.targetAudience) : null,
      owner: user,
      createdBy: user,
      createdDate: new Date(),
      updatedBy: user,
      updatedDate: new Date(),
      isActive: false,
      status: 1,
    };

    let response = await axios.post(`${configUrl.AssessmentServer}/api/Tests`, test);

    let id = response.data.testId;

    let language = {
      testId: id,
      languageId: 1,
      text: data.text,
      image: "",
      textHtml: "",
      passFeedback: "",
      failFeedback: "",
      masteryFeedback: "",
      isActive: true,
      status: 1,
      createdBy: user,
      createdDate: new Date(),
      updatedBy: user,
      updatedDate: new Date(),
      welcomeMessage: data.welcomeMessage,
      concludeMessage: data.concludeMessage,
      description: data.description,
    };

    let langResponse = await axios.post(`${configUrl.AssessmentServer}/api/TestLanguages`, language);

    let languageId = langResponse.data.id;

    return response;

  },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("tests")
      }
    });
}
