import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import { CloudUploadSharp, HelpOutlineSharp } from '@material-ui/icons';
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import MuiAlert from "@material-ui/lab/Alert";
import styles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle";
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter';
import CardHeader from 'components/Card/CardHeader';
import CardIcon from 'components/Card/CardIcon';
import CustomAlert from "components/CustomAlert/CustomAlert";
import CustomInput from "components/CustomInput/CustomInput";
import CustomMultipleSelect from 'components/CustomSelect/CustomMultipleSelect';
import CustomSelect from 'components/CustomSelect/CustomSelect';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { re1 } from 'config';
import { LoginContext } from 'contexts/LoginContext';
import useQuestionBulkImport from 'hooks/questionBulkImportHooks/useQuestionBulkImport';
import React, { useContext } from 'react';
import SweetAlert from "react-bootstrap-sweetalert";
import getObjectiveService from 'services/ConfigObjective/getObjectiveService';
import QuestionBulkImportService from "services/QuestionsBulkImportServices/QuestionBulkImportService";
import getTargetAudience from 'services/TargetAudience/getTargetAudience';
import Button from '../../../components/CustomButtons/Button';

// const styles = {
//   infoText: {
//     fontWeight: "300",
//     margin: "10px 0 30px",
//     textAlign: "center",
//   },
//   inputAdornmentIcon: {
//     color: "#555",
//   },
//   inputAdornment: {
//     position: "relative",
//   },
// };
const useStyles = makeStyles(styles);
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function QuestionBulkImport(props) {
  console.log("in Constructor Start");
  const classes = useStyles();

  const { user, providerId } = useContext(LoginContext)

  let fileInput = React.createRef();

  const getObjectsndTargetAudience = async () => {
    try {
      let getOjbectives = await getObjectiveService.getObjective(true, false);
      setObjectiveData(getOjbectives['$values']);

      let getTargetAudiences = await getTargetAudience.getTargetAudience(true, false);
      setTargetAudienceData(getTargetAudiences['$values']);
    } catch (error) {
      console.log("error fetching objecitve and target", error);
    }
  }
  React.useEffect(() => {
    getObjectsndTargetAudience();
  }, []);

  const [getObjectiveData, setObjectiveData] = React.useState([]);
  const [getTargetAudienceData, setTargetAudienceData] = React.useState([]);

  const deleteFile = (index) => {
    console.log(index);
    let tempFile = fileIn[index];
    let l = fileIn.filter((el) => el !== tempFile);
    console.log(l);
    setFileIn(l);
  };

  const [objectiveState, setObjectiveState] = React.useState("");
  const [objective, setObjective] = React.useState([]);
  const [targetAudience, setTargetAudience] = React.useState([]);
  const [feedbackPass, setFeedbackPass] = React.useState('');
  const [feedbackFail, setFeedbackFail] = React.useState('');
  const [difficultyLevel, setDifficultyLevel] = React.useState(2);
  const [pointsPerQuestion, setPointsPerQuestion] = React.useState();
  const [numberOfChoices, setNumberOfChoices] = React.useState(4);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("error");
  const [error1, setError1] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [file, setFile] = React.useState([]);
  const [fileIn, setFileIn] = React.useState([]);
  const [filenames, setFileNames] = React.useState([]);
  const [formData, setFormDate] = React.useState(new FormData());
  const [fileNames, setSelectedFileNames] = React.useState(new FormData());
  const [flag, setFlag] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(null);
  const [alert, setAlert] = React.useState(null);

  const [validating, setValidating] = React.useState(false);

  const [choiceFormats, setchoiceFormats] = React.useState([
    { id: 0, name: "a), b), c), d)" },
    { id: 1, name: "(a), (b), (c), (d)" },
    { id: 2, name: "a., b., c., d." },
    { id: 3, name: "A), B), C), D)" },
    { id: 4, name: "(A), (B), (C), (D)" },
    { id: 5, name: "A., B., C., D." },
    { id: 6, name: "1), 2), 3), 4)" },
    { id: 7, name: "(1), (2), (3), (4)" },
    { id: 8, name: "1., 2., 3., 4." },
    { id: 9, name: "i), ii), iii), iv)" },
    { id: 10, name: "(i), (ii), (iii), (iv)" },
    { id: 11, name: "i., ii., iii., iv." }
  ]);
  const [choiceFormatId, setchoiceFormatId] = React.useState(0);

  React.useEffect(() => {
    if (!verifyLength(objective, 1)) {
      setObjectiveState("error");
    } else {
      setObjectiveState("success");
    }
  }, [objective]);

  const verifyLength = (value, length) => {
    if (!value) return false;
    if (value.length >= length) {
      return true;
    }
    return false;
  };
  const isValidated = () => {
    if (
      objectiveState === "success"
    ) {
      return true;
    } else {
      if (objectiveState !== "success") {
        setObjectiveState("error");
      }
    }
    return false;
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setError1(false);
  };
  const showErrorSnackBar = () => {
    setError1(true);
  };
  let filelengthValidate = fileIn.length + filenames.length;

  const handleImageChange = (e) => {
    console.log(e.target.files[0], "handleIMg");
    const file = e.target.files[0].name;
    const up = [];
    console.log("selected file", e.target.files[0])

    console.log(filelengthValidate.length);
    if (!re1.exec(e.target.files[0].name)) {
      setErrorMessage(
        "This File Extension Is Not Supported!. Suported extension is zip. "
      );
      showErrorSnackBar();
      return;
    }
    const maxSize = 10 * 1024 * 1024; // 10 MB in bytes
    if (e.target.files[0].size > maxSize) {
      setErrorMessage("Please upload file less than 10 MB. ");
      showErrorSnackBar();
      return;
    } else if (filelengthValidate >= 1) {
      setErrorMessage("Please do not upload more than 1 file.");
      showErrorSnackBar();
      return;
    } else if (
      fileIn.find((e) => e.name === file) ||
      filenames.find((e) => e === file)
    ) {
      setErrorMessage("Dublicate file is not Attached.");
      showErrorSnackBar();
      return;
    } else {
      setFileIn([...fileIn, e.target.files[0]]);
      console.log("selected file", fileIn);
    }
  };
  const handleClick = (e) => {
    e.preventDefault();
    fileInput.current.value = null;
    fileInput.current.click();

    // props.refresh()
  };
  const qusetionBulkUpload = useQuestionBulkImport();
  console.log('qusetionBulkUpload123', qusetionBulkUpload);

  const handleImportConfirmation = () => {
    if (numberOfChoices < 2 || numberOfChoices > 6) {
      setShowAlert(
        <CustomAlert
          open={true}
          openFlag={() => setFlag(flag ? false : true)}
          // afterfinish={() => props.closeForm()}
          severity="error"
          alertMessage={"Enter value greater than 2 and less than or equal to 6 for number of choices."}
        />
      )
      return;
    }
    if (fileIn.length === 0) {
      setShowAlert(
        <CustomAlert
          open={true}
          openFlag={() => setFlag(flag ? false : true)}
          // afterfinish={() => props.closeForm()}
          severity="warning"
          alertMessage={"Please select the file."}
        />
      )
      return;
    }
    setAlert(<SweetAlert
      style={{ display: "block", marginTop: "-100px" }}
      // title="Confirm Import"
      onConfirm={() => handleProceed()}
      showCancel={true}
      cancelBtnText={'Cancel'}
      confirmBtnText={'Proceed'}
      onCancel={() => setAlert(null)}
      confirmBtnCssClass={classes.button + " " + classes.success}
      cancelBtnCssClass={classes.button + " " + classes.warning}
    >
      <p ><strong >Please ensure your zip file meets the following prerequisites before import:</strong></p>
      <ul style={{ textAlign: 'left' }}>
        <li>The zip name should match the root category name.</li>
        <li>The zip contains required topic(folder) hierarchy.</li>
        <li>The questions folder like SCQ, MCQ, TF, Numerical should be part of the root level topic, while the
          <strong> Solutions </strong> folder should be inside the respective question type.</li>
        <li>All content is correctly formatted and named.</li>
        <li>The data provided is accurate and complete.</li>

      </ul>
      <p><strong>Failure to comply may result in unsuccessful import or data errors, and the data cannot be reverted.</strong></p>
      <p><strong>Do you want to proceed with importing the content?</strong></p>
    </SweetAlert>)
  }

  const handleProceed = () => {
    console.log("Proceeding with import...");
    setAlert(false);
    handleSubmit();
  };

  const handleSubmit = async () => {
    setValidating(true);


    if (filelengthValidate > 4) {
      errorText =
        errorText + "please do not select more than 4 file.";
    }
    if (file.length > 2) {
      errorText =
        errorText +
        "Please add minmum three attachment files...";
    }
    var tempFormData = new FormData();

    fileIn.forEach(async (element) => {
      tempFormData.append("file", element);

      console.log(element);

      // return;
    });
    console.log("questionObj3", Object.fromEntries(tempFormData))

    let validateZip = await QuestionBulkImportService.QuestionBulkImportService({ tempFormData })
    setValidating(false);

    console.log(validateZip, 'validatedZip');
    if (validateZip.status == 200 || validateZip.data == "Success") {
      let questionObj = {
        'difficultyLevel': difficultyLevel,
        'choiceFormatId': choiceFormatId == null ? 0 : choiceFormatId,
        'numberOfChoices': numberOfChoices,
        'points': pointsPerQuestion,
        'objective': objective.length == 0 ? null : JSON.stringify(objective),
        'targetAudience': targetAudience == 0 ? null : JSON.stringify(targetAudience),
        'passFeedback': feedbackPass,
        'failFeedback': feedbackFail,
        "createdBy": user,
        "createdDate": new Date(),
        "updatedBy": user,
        "updatedDate": new Date(),
        "providerId": providerId,
        "categoryId": props.categoryId
      };
      let questionObjString = JSON.stringify(questionObj);
      tempFormData.append("questionObj", questionObjString);
      setFormDate(tempFormData);

      qusetionBulkUpload.mutate({ tempFormData });
    }
    else if (validateZip.status == 400) {
      setShowAlert(
        <SweetAlert
          style={{ display: "block", marginTop: "-100px" }}
          title={''}
          onConfirm={() => setShowAlert(null)}
          onCancel={() => setShowAlert(null)}
          confirmBtnCssClass={classes.button + " " + classes.success}
        >
          <b style={{ marginBottom: '10px' }}> Zip is not validate!</b>  <br />
          {
            validateZip?.messege['$values']?.map((x, i) => (
              <>
                <p>{x.message}</p>
                <p>{x.content != null && x.content['$values']?.map((x, i) => (
                  `${x} , `
                ))}</p>
              </>
            ))
          }
          <br />
        </SweetAlert>
        // <CustomAlert
        //   open={true}
        //   openFlag={() => setFlag(flag ? false : true)}
        //   // afterfinish={() => props.closeForm()}
        //   severity="error"
        //   alertMessage={validateZip?.messege['$values'][0]?.message ?? "Zip is not validate!"}
        // />
      )
      console.log(validateZip.data, "need to verify zip")
    }

    // need to display message through status code from backend. //BSS121
    // if(qusetionBulkUpload.status == 'success'){
    // setShowAlert(
    //   <CustomActionAlert
    //     open={true}
    //     openFlag={() => setFlag(flag ? false : true)}
    //     afterfinish={() => props.closeForm()}
    //     severity="success"
    //     alertMessage={"Topics Created Successfully!"}
    //   />
    // )
    //  else {
    //   setErrorMessage("Topics Created Successfully!");
    //   showErrorSnackBar();
    //   setSeverity('success');
    // }
    // }
    // props.closeForm();

  }
  React.useEffect(() => {
    if (qusetionBulkUpload.isSuccess == true) {
      setShowAlert(
        <CustomAlert
          open={true}
          openFlag={() => setFlag(flag ? false : true)}
          // afterfinish={() => props.closeForm()}
          severity="warning"
          alertMessage={qusetionBulkUpload.data ?? "Questions imported successfully!"}
        />
      )
      setTimeout(() => {
        props.closeForm()
      }, 2000);
    }
    if (qusetionBulkUpload.isError == true) {
      setShowAlert(
        <CustomAlert
          open={true}
          openFlag={() => setFlag(flag ? false : true)}
          // afterfinish={() => props.closeForm()}
          severity="error"
          alertMessage={qusetionBulkUpload.error.response.data ?? "Error occured"}
        />
      )
    }
  }, [qusetionBulkUpload.data, qusetionBulkUpload.isSuccess, qusetionBulkUpload.isError])


  return (
    <>
      <Card>
        <CardHeader color="rose" icon>
          <CardIcon color="rose">
            <HelpOutlineSharp />
          </CardIcon>
        </CardHeader>
        {/* {
          qusetionBulkUpload.isLoading == true && (
            <>
              <CircularProgress style={{ marginInline: '45%', marginTop: '8%', textAlign: 'center', zIndex: '9', position: 'absolute' }} />
              <h4 style={{ color: 'black', marginInline: '35%', marginTop: '14%', zIndex: '9', position: 'absolute' }}> Please wait questions import in progress...</h4>
            </>
          )
        } */}
        {
          (

            <CardBody>
              <GridContainer justify="center">
                <GridItem xs={12} sm={6} md={3}>
                  <CustomMultipleSelect
                    label="Objective"
                    selectLabel="Assp"
                    options={getObjectiveData ? getObjectiveData : ''}
                    setvalue={(v) => {
                      console.log('slected field', v)
                      setObjective(v)
                    }}
                    id="course"
                    name="course"
                    disabled={qusetionBulkUpload.isLoading == true}
                    value={objective}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                  <CustomMultipleSelect
                    label=" Target Audience"
                    selectLabel="Assp"
                    options={getTargetAudienceData ? getTargetAudienceData : ''}
                    setvalue={(v) => {
                      console.log('slected field', v)
                      setTargetAudience(v)
                    }}
                    id="course"
                    name="course"
                    disabled={qusetionBulkUpload.isLoading == true}
                    value={targetAudience}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <CustomSelect
                    name="difficultyLevel"
                    options={[
                      {
                        id: 1,
                        name: "Easy",
                      },
                      {
                        id: 2,
                        name: "Medium",
                      },
                      {
                        id: 3,
                        name: "Difficult",
                      },
                    ]}
                    setvalue={(v) => setDifficultyLevel(v)}
                    value={difficultyLevel}
                    disabled={qusetionBulkUpload.isLoading == true}
                    label="Difficulty Level"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <CustomInput
                    labelText={<span>Number Of Choices </span>}
                    id="numberOfChoices"
                    disabled={qusetionBulkUpload.isLoading == true}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      maxLength: 6,
                      minLength: 2,
                      step: 1,
                      name: "numberOfChoices",
                      onChange: (event) => {
                        setNumberOfChoices(event.target.value);
                      },
                      value: numberOfChoices,
                      type: "number",

                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <CustomSelect
                    name="choiceFormatId"
                    label="Choice Format"
                    options={choiceFormats}
                    disabled={qusetionBulkUpload.isLoading == true}
                    setvalue={(v) => setchoiceFormatId(v)}
                    value={choiceFormatId}
                  />
                </GridItem>
                {/* Commented temporarily since is not being used anywhere 
            <GridItem xs={12} sm={12} md={2}>
              <CustomInput
                success={true}
                labelText={<span>Points</span>}
                id="pointsPerQuestion"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  type: "number",
                  onChange: (event) => {
                    setPointsPerQuestion(event.target.value);
                  },
                  value: pointsPerQuestion,
                }}
              />
            </GridItem> 
            <GridItem xs={12} sm={12} md={5}>
              <CustomInput
                success={true}
                labelText={<span>Feedback Pass</span>}
                id="feedbackPass"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  onChange: (event) => {
                    setFeedbackPass(event.target.value);
                  },
                  value: feedbackPass,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={5}>
              <CustomInput
                success={true}
                labelText={<span>Feedback Fail</span>}
                id="feedbackFail"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  onChange: (event) => {
                    setFeedbackFail(event.target.value);
                  },
                  value: feedbackFail,
                }}
              />
            </GridItem> */}

                <GridItem xs={12} sm={12} md={5}>
                  <Button color="rose" disabled={qusetionBulkUpload.isLoading == true} onClick={(e) => handleClick(e)} style={{ marginTop: 20, textTransform: 'none' }}>
                    <CloudUploadSharp />
                    Select File
                  </Button>
                  <input
                    type="file"
                    multiple={true}
                    onChange={handleImageChange}
                    ref={fileInput}
                    style={{ display: "none" }}
                  />
                  <GridItem xs={12}>
                    {fileIn.map((item, index) => {
                      console.log(item.name);
                      console.log(index);
                      return (
                        <GridContainer>
                          <GridItem>
                            <h6>{item.name} </h6>
                          </GridItem>
                          <GridItem>
                            {/* <span    
                                > */}
                            <HighlightOffIcon
                              onClick={() => {
                                if (qusetionBulkUpload.isLoading == true) {
                                  return
                                } else {
                                  deleteFile(index);
                                }
                              }}
                            />
                            {/* </HighlightOffIcon> */}
                            {/* </span> */}
                          </GridItem>
                        </GridContainer>
                      );
                    })}
                  </GridItem>
                  <GridItem xs={12}>
                    {filenames.map((item, index) => {
                      console.log(item);
                      console.log(index);
                      return (
                        <GridContainer>
                          <GridItem>
                            <h6>{item} </h6>
                          </GridItem>
                          <GridItem>
                            <HighlightOffIcon
                              onClick={() => {
                                formgefiledetete(index);
                              }}
                            />
                          </GridItem>
                        </GridContainer>
                      );
                    })}
                  </GridItem>
                </GridItem>
                <GridItem xs={12} sm={12} md={5}>
                  {/* <UserFileUpload onClick={()=>uploaddta()} label="Select File"/> */}
                  <Button
                    color="rose"
                    onClick={handleImportConfirmation}
                    style={{ marginTop: 20, marginLeft: 20, float: 'right', textTransform: 'none' }}
                    disabled={qusetionBulkUpload.isLoading == true || validating == true}
                  >
                    {
                      validating == true ? 'Validating...' :
                        qusetionBulkUpload.isLoading == true ? "Importing..." : 'Import'
                    }
                  </Button>
                </GridItem>
              </GridContainer>
            </CardBody>
          )
        }
        <CardFooter>
        </CardFooter>
        {showAlert}
        {alert}
        <Snackbar
          open={error1}
          autoHideDuration={2000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert onClose={handleClose} severity='error'>
            {errorMessage}
          </Alert>
        </Snackbar>
      </Card>
      <label style={{ marginLeft: "10%", color: "red" }}>Note: The fields selected above will be updated for all the questions in zip file.</label>
    </>
  )
}
