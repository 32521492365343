
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Wizard from "components/Wizard/Wizard.js";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import CustomAlert from "components/CustomAlert/CustomAlert.js";
import { LoginContext } from "contexts/LoginContext";
import useCreateProvider from "hooks/providerHooks/useCreateProvider";
import useUpdateProvider from "hooks/providerHooks/useUpdateProvider.js";
import useCreateUser from "hooks/userHooks/useCreateUser.js";
import useUpdatesUser from "hooks/userHooks/useUpdatesUser.js";
import { useContext, useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import ProviderDetails from "./ProviderWizardSteps/ProviderDetails.js";
import ProviderUserAdmin from "./ProviderWizardSteps/ProviderUserAdmin.js";
import Step3 from "./ProviderWizardSteps/Step3.js";

const useStyles = makeStyles(styles);
export default function ProviderWizard(props) {

  let state = null;
  const classes = useStyles();
  const createprovider = useCreateProvider();
  const updateProvider = useUpdateProvider();
  const createuser = useCreateUser()
  console.log(createuser, 'createuserResponce')
  const updateUser = useUpdatesUser()
  console.log(createprovider, 'createproviderResponce')

  let ProviderId = createprovider && createprovider?.data?.providerId;
  let ProviderKey = createprovider && createprovider?.data?.providerKey;

  const { user } = useContext(LoginContext);
  const [providerId, setProviderId] = useState(null);
  const [providerKey, setProviderKey] = useState(null);
  const [alert, setAlert] = useState(null);
  const [flag, setFlag] = useState(false);
  const [showAlert, setShowAlert] = useState(null);
  const [showExist, setShowExist] = useState(false);
  const [showExistNameMessage, setShowExistNameMessage] = useState();

  let CreateuserId = createuser && createuser?.data?.userId;
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    if (createprovider.isSuccess) {
      setShowAlert(
        <CustomAlert
          open={true}
          openFlag={() => setFlag(flag ? false : true)}
          severity="success"
          alertMessage="Provider successfully added!"
        />
      );
    }
  }, [createprovider.isSuccess]);

  useEffect(() => {
    console.log(showExist, showExistNameMessage, "messagecall");
    if (showExist) {
      setShowAlert(
        <CustomAlert
          open={true}
          openFlag={() => setFlag(flag ? false : true)}
          severity="error"
          alertMessage={`Provider ${showExistNameMessage ?? ""} already exist.`}
        />
      );
    }
    setShowExist(false);
  }, [showExist, showExistNameMessage]);

  useEffect(() => {
    if (updateProvider.isSuccess) {
      setShowAlert(
        <CustomAlert
          open={true}
          openFlag={() => setFlag(flag ? false : true)}
          severity="success"
          alertMessage="Provider successfully updated!"
        />
      );
    }
  }, [updateProvider.isSuccess]);

  useEffect(() => {
    if (createuser.isSuccess) {
      setAlert(
        <SweetAlert
          style={{ display: "block" }}
          title={''}
          onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)}
          confirmBtnCssClass={classes.button + " " + classes.success}
        >
          <b style={{ marginBottom: '10px', fontSize: "20px" }}> Provider added successfully! Please make sure to note down the admin credentials displayed below.</b>  <br />
          <b></b> <br />
          <p style={{ align: 'center', textAlign: 'left' }}><strong>Admin User :</strong> {createuser?.data?.email} </p>
          <p style={{ align: 'center', textAlign: 'left' }}><strong>Password   : </strong> {createuser?.data?.passCode} </p><br />


        </SweetAlert>
      )
    }
  }, [createuser.isSuccess]);

  useEffect(() => {
    if (updateUser.isSuccess) {
      setAlert(
        <SweetAlert
          style={{ display: "block", marginTop: "-100px" }}
          title={''}
          onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)}
          confirmBtnCssClass={classes.button + " " + classes.success}
        >
          <b style={{ marginBottom: '10px', fontSize: "20px" }}> Provider updated successfully! Please make sure to note down the admin credentials displayed below</b>  <br />
          <b></b> <br />
          <p style={{ align: 'center', textAlign: 'left' }}><strong>Admin User :</strong> {updateUser?.data?.email} <br /></p>
          <p style={{ align: 'center', textAlign: 'left' }}><strong>Password   :</strong> {updateUser?.data?.passCode} <br /></p>


        </SweetAlert>
      )
    }
  }, [updateUser.isSuccess]);

  useEffect(() => {
    console.log(ProviderId);
    if (ProviderId) {
      setProviderId(ProviderId);
    }
  }, [ProviderId]);

  useEffect(() => {
    console.log(CreateuserId, "createuserid");
    if (CreateuserId) {
      setUserId(CreateuserId);
    }
  }, [CreateuserId]);

  useEffect(() => {
    console.log(ProviderKey);
    if (ProviderKey) {
      setProviderKey(ProviderKey);
    }
  }, [ProviderKey]);

  console.log(state)
  console.log(createprovider)
  const [providerTypeId, setProviderTypeId] = useState();
  const onNextClick = (data) => {
    console.log(createuser);

    console.log(data, "here");
    if (data?.provider?.type == "details") {
      // TODO
      console.log(providerId, 'providerData')
      if (data.provider.isProviderExist == true) {
        setShowExist(true);
        setShowExistNameMessage("name");
      } else if (data.provider.isProviderExistEmail == true) {
        setShowExist(true);
        setShowExistNameMessage("email");
      } else if (data.provider.isProviderExistMobile == true) {
        setShowExist(true);
        setShowExistNameMessage("mobile");
      }
      if (data.provider.providerTypeId == 0) {
        setShowAlert(
          <CustomAlert
            open={true}
            openFlag={() => setFlag(flag ? false : true)}
            severity="error"
            alertMessage="Provider type is mandatory."
          />
        );
      }
      if (data.provider.packageId == 0) {
        setShowAlert(
          <CustomAlert
            open={true}
            openFlag={() => setFlag(flag ? false : true)}
            severity="error"
            alertMessage="Package type is mandatory."
          />
        );
      }
      if (data.provider.nameState === "success" &&
        data.provider.emailState === "success" &&
        data.provider.mobileState === "success" &&
        data.provider.addressState === "success" &&
        data.provider.providerTypeId !== 0 &&
        data.provider.packageId !== 0 &&
        data.provider.detailedNameState === "success" &&
        data.provider.isProviderExist === false &&
        data.provider.isProviderExistEmail === false &&
        data.provider.isProviderExistMobile === false) {
        if (!providerId) {
          console.log(data, 'providerData')
          setProviderTypeId(data.provider.providerTypeId);
          createprovider.mutate(data)
        } else {
          data.provider.providerId = providerId ?? createprovider?.data?.providerId
          data.provider.providerKey = providerKey;
          data.contentCuratorIds = data.contentsCurator;
          data.contentCuratorNames = null;
          data.providerUrl = "";
          delete data.contentsCurator;
          data.provider.createdBy = user ?? "system";
          data.provider.updatedBy = user ?? "system";
          updateProvider.mutate(data);
          console.log('update call', updateProvider)
        }
      }
    }
    if (data.type == "adminuserdetails") {
      console.log(userId, 'userIdData')
      if (
        // data.fullNameState === "success" &&
        data.firstNameState === "success" &&
        data.lastNameState === "success" &&
        data.emailState === "success" &&
        data.passCodeState === "success" &&
        data.confirmPasscode === data.passCode &&
        data.mobileState === "success" &&
        data.emailState === "success" &&
        data.addressState === "success") {
        if (!userId) {
          data.providerId = createprovider?.data?.providerId ?? providerId
          data.userTypeId = 2;
          console.log(data)
          createuser.mutate(data)
          console.log(createuser, 'create call user')
          // props.afterFinish();

        } else {
          data.providerId = createprovider?.data?.providerId ?? providerId
          data.userTypeId = 2;
          data.userId = userId;
          updateUser.mutate(data);
          console.log(updateUser, 'update call user')
        }
      }
      // props.afterFinish();
    }
    console.log(data, 'dataforTabs')
  }
  return (
    <GridContainer justify="center">
      <GridItem xs={12} sm={8} md={10}>
        <Wizard
          validate
          steps={[
            {
              stepName: "Provider Imformation ",
              stepComponent: ProviderDetails,
              stepId: "provider",
              st: { ...state, providerId }
            },
            {
              stepName: "Provider User Admin",
              stepComponent: ProviderUserAdmin,
              stepId: "useradmin",
              st: { ...state, providerId },
              providerId: [createprovider?.data?.providerId]
            },
            {
              stepName: "Address",
              stepComponent: Step3,
              stepId: "address",
              st: { ...state },
            },
          ]}
          title=""
          subtitle=""
          // finishButtonClick={() => props.afterFinish()}
          finishButtonClick={() => {
            props.afterFinish();
            props.setReloadGrid(true);
          }}
          onNextClick={onNextClick}
          showDraftButton={false}
          // draftButtonClick={updateTestAsDraft}
          finishButtonText="Save"
          draftButtonText="Save as Draft"
        />
      </GridItem>
      {alert}
      {showAlert}
    </GridContainer>
  );
}
