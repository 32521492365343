import React from "react";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Wizard from "components/Wizard/Wizard.js";



import CustomAlert from "components/CustomAlert/CustomAlert.js";
import useCreateCourse from "hooks/courseHooks/useCreateCourse.js";
import useUpdateCourse from "hooks/courseHooks/useUpdateCourse.js";
import { withRouter } from "react-router";
import CourseConfiguration from "./CourseWizardSteps/CourseConfiguration.js";
import CourseDetails from "./CourseWizardSteps/CourseDetails.js";
import CourseTestMapping from "./CourseWizardSteps/CourseTestMapping.js";

function CourseWizard(props) {
  let createCourse = useCreateCourse();
  let updateCourse = useUpdateCourse();

  console.log(createCourse, updateCourse, "datatch");

  let state = null;

  if (props.location && props.location.state) {
    state = props.location.state.state;
  }

  const [courseId, setCourseId] = React.useState(state ? state.courseId : null);
  const [courseName, setCourseName] = React.useState(state ? state?.text : null);
  const [details, setDetails] = React.useState({ ...state });
  const [flag, setFlag] = React.useState(false)
  const [showAlert, setShowAlert] = React.useState(null)
  const [Loading, setLoading] = React.useState(false);


  React.useEffect(() => {
    if (createCourse?.data) {
      setCourseId(createCourse?.data);
      setCourseName(createCourse?.variables?.text);
    } else if (updateCourse?.data) {
      setCourseId(updateCourse?.data);
    }
  }, [createCourse.data, updateCourse?.data]);



  const onNextClick = (data) => {

    let mergedData = { ...details, ...data };

    console.log("mergedDataC", mergedData)

    if (data.type && data.type == "details" && data.textState !== "error") {
      if (!courseId) {
        // if( mergedData.selectedCategoriesState == "success"){
        createCourseStructure(mergedData);
        // }
      } else {
        // if( mergedData.selectedCategoriesState == "success"){
        data.courseId = courseId;
        updateCourse.mutate(mergedData);
        // }
      }

      setDetails(mergedData);
    }

    if (data.type && data.type == "configuration") {
      setDetails({ ...details, ...data });

      let mergedData = { ...details, ...data };

      if (!courseId) return;
      mergedData.courseId = courseId;

      updateCourse.mutate(mergedData);
    }
  };

  const createCourseStructure = async (data) => {
    createCourse.mutate(data);
  };

  const activateTest = async (data) => {
    setLoading(true);
    setDetails({ ...details, ...data });

    let mergedData = { ...details, ...data.configuration, ...data.test };

    if (!courseId) return;

    mergedData.courseId = courseId;
    if (!mergedData.courseCode) {
      mergedData.courseCode = mergedData.code;
    }

    await updateCourse.mutateAsync(mergedData);

    console.log("resp", updateCourse)
    // BS121 Need to review for status
    if (updateCourse.isSuccess || updateCourse.status == "idle") {
      setShowAlert(
        <CustomAlert
          open={true}
          openFlag={() => setFlag(flag ? false : true)}
          // onOk={"/admin/testSeries"}
          severity="success"
          alertMessage="Test series details saved successfully."
        />
      )
      setLoading(false);
      setTimeout(() => {
        props.history.push("/admin/testSeries");
      }, 3000);

    } else if (updateCourse.isError) {
      setShowAlert(
        <CustomAlert
          open={true}
          openFlag={() => setFlag(flag ? false : true)}
          // onOk={"/admin/course"}
          severity="error"
          alertMessage="Test Series Details Not Saved."
        />
      )
      setLoading(false)
    }

    // props.history.push("/admin/testSeries");

    /* if (props.afterFinish) {
      props.afterFinish();
    } */
  };

  const updateTestAsDraft = async () => {
    setShowAlert(
      <CustomAlert
        open={true}
        openFlag={() => setFlag(flag ? false : true)}
        // onOk={"/admin/testSeries"}
        severity="success"
        alertMessage="Test series details saved successfully."
      />
    )
    setTimeout(() => {
      props.history.push("/admin/testSeries");
    }, 2000);

    /*  if (props.afterFinish) {
      props.afterFinish();
    } */
  };

  return (
    <GridContainer justify="center">
      <GridItem xs={12} sm={12} md={12}>
        <Wizard
          validate
          steps={[
            {
              stepName: "Information",
              stepComponent: CourseDetails,
              stepId: "details",
              st: { ...state, courseId },
            },
            {
              stepName: "Test",
              stepComponent: CourseTestMapping,
              stepId: "test",
              st: { courseId: courseId },
            },
            {
              stepName: "Configuration",
              stepComponent: CourseConfiguration,
              stepId: "configuration",
              st: { ...state },
            },
          ]}
          hideTitle={props.hideTitle}
          title={courseId ? createCourse?.data ? `New Test Series: ${courseName}` : `Update Test Series: ${courseName}` : "New Test Series"}
          subtitle=""
          finishButtonClick={activateTest}
          onNextClick={onNextClick}
          showDraftButton={false}
          draftButtonClick={updateTestAsDraft}
          finishButtonText={Loading == true ? "Saving..." : "Save"}
          isLoading={Loading}
          draftButtonText="Save as Draft"
          routeKey="testSeries"
        />
      </GridItem>
      {showAlert}

    </GridContainer>
  );
}

export default withRouter(CourseWizard);
