import { makeStyles } from "@material-ui/core/styles";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TreeItem from "@material-ui/lab/TreeItem";
import TreeView from "@material-ui/lab/TreeView";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import React, { useContext } from "react";

import PublishIcon from "@material-ui/icons/Publish";

import CategoryIcon from "@material-ui/icons/Category";
import SweetAlert from "react-bootstrap-sweetalert";

import { DeleteForever, WarningOutlined } from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import SpellcheckIcon from '@material-ui/icons/Spellcheck';
import CustomModel from "NewComponents/CustomModal/CustomModal";
import TestPlayer from "NewComponents/TestPlayer";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle";
import styles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import CustomAlert from "components/CustomAlert/CustomAlert";
import CustomModal from "components/CustomModal/CustomModal";
import FileUpload from "components/CustomUpload/FileUpload";
import { LoginContext } from "contexts/LoginContext";
import addTopicService from "services/QuestionService/addTopicService";
import removeTopicService from "services/QuestionService/removeTopicService";
import QuestionBulkUpdate from "views/Components/QuestionBulkUpdate/QuestionBulkUpdate";

const useStylesAlert = makeStyles(styles);

const useStyles = makeStyles({
  ...sweetAlertStyle,
  root: {
    height: 400,
    flexGrow: 1,
    maxWidth: 800,
  },
});

export default function CustomTree(props) {
  console.log(props)
  const { rawData } = props;
  let currentLevel = 1;
  const classes = useStyles();
  const classesAlert = useStylesAlert();
  const { userPrivileges, user, providerId, licensePackageObject, providerType } = useContext(LoginContext)
  const [alert, setAlert] = React.useState(null);
  const [topicAlert, setTopicAlert] = React.useState(null);

  const [questionImportModal, setQuestionImportModal] = React.useState(null);
  const [questionUpdateModal, setQuestionUpdateModal] = React.useState(null);
  const [qAnswersForm, setQAnswersForm] = React.useState(true);
  const [flag, setFlag] = React.useState(false)
  const [categoryTree, setCategoryTree] = React.useState([])
  const [modal, setModal] = React.useState(true);
  const [updateModal, setUpdateModal] = React.useState(true);
  const [isDeleting, setisDeleting] = React.useState(false);
  React.useEffect(() => {
    console.log("questionUpdateModal", questionUpdateModal);
  }, [questionUpdateModal]);
  const isPrivilege = (x) => {
    if (userPrivileges?.Privileges?.CategoryTopic.indexOf(x) !== -1) {
      return true;
    }
    else {
      return false;
    }
  }
  var root;
  // let root1 = rawData ? rawData.find((x) => x.parent == null) : null;
  // console.log(root, 'rootfindof')
  // BSS121
  // let root = rawData ? [...rawData].filter((x) => x.parent == null && x.isCategory == true) : null;
  // console.log(root1, 'rootfilter')

  // console.log(root);


  const createHierarchy = (root, currentLevel) => {

    if (!root) return;

    root.label = root.name;
    root.value = root.id;
    // let children = rawData.filter((x) => x.parent == root.id);
    console.log(root, 'this is Root1')
    let children = [...rawData].filter((x) => x.parent == root.id);
    console.log(children, 'same as 2')
    if (children && children.length > 0) {






      root.children = [];

      children.forEach((x) => {
        console.log(x, 'child2')
        x.value = x.id;
        x.label = x.name;
        x.currentLevel = currentLevel;
        root.children.push(x);
        // root.currentLevel = currentLevel;
        createHierarchy(x, currentLevel + 1);
      });
    }

    return;
  };

  const canAddTopic = (nodes) => {
    const isParent = rawData.some((x) => x.parent == nodes?.id);

    if (nodes?.questionCount && nodes?.questionCount > 0) return;

    if (isParent) {
      const children = rawData.filter((x) => x.parent == nodes?.id);

      if (children && children.some((x) => x.isCategory == true)) {
        return false;
      }

      return true;
    }

    return true;
  };

  const canUploadQuestions = (nodes) => {
    if (nodes?.isCategory == true) return false;
    if (nodes?.questionCount && nodes?.questionCount > 0) return false;

    const isParent = rawData.some((x) => x.parent == nodes?.id);

    if (isParent) {
      return false;
    }

    return true;
  };

  const canAddCategory = (nodes) => {
    if (nodes?.id.includes("T")) return false;

    const isParent = rawData.some((x) => x.parent == nodes?.id);

    if (isParent) {
      const children = rawData.filter((x) => x.parent == nodes?.id);

      if (children && children.some((x) => x.isCategory == false)) {
        return false;
      }

      return true;
    }

    return true;
  };
  const handleDelteTopic = async (topicId) => {
    // setAlert(null)
    setAlert(
      <SweetAlert
        style={{ display: "block", marginTop: "-100px" }}
        title={''}
        onConfirm={() => console.log('loading')} // console.log(nodes?.topicId, 'clickedNodes')}
        showCancel={true}
        cancelBtnText={'CANCEL'}
        confirmBtnText={'LOADING...'}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={classes.button}
        cancelBtnCssClass={classes.button + " " + classes.warning}
      >
        <div style={{ display: 'inline-flex' }}>
          <WarningOutlined style={{ marginTop: '10px', marginRight: '5px', color: 'brown' }} />
          <h4> Loading...</h4>
        </div>
      </SweetAlert>
    )
    let removeResp = await removeTopicService.removeTopic(topicId);
    console.log(removeResp, 'removeResp');
    if (removeResp?.status == 200 || removeResp?.status == 201 || removeResp?.status == 204) {
      props.refresh();
      setAlert(
        <CustomAlert
          open={true}
          openFlag={() => setFlag(flag ? false : true)}
          severity="success"
          alertMessage={removeResp?.data ?? "Topic removed successfully."}
        />
      )
      setTimeout(() => {
        setisDeleting(false);
        setAlert(null)
      }, 3000);
    } else if (removeResp?.status == 404) {
      setAlert(
        <CustomAlert
          open={true}
          openFlag={() => setFlag(flag ? false : true)}
          severity="error"
          alertMessage={removeResp?.messege ?? "Error occured"}
        />
      )
    } else {
      setAlert(
        <CustomAlert
          open={true}
          openFlag={() => setFlag(flag ? false : true)}
          severity="error"
          alertMessage={removeResp?.messege ?? "Error occured"}
        />
      )
    }
  }
  React.useEffect(() => {
    if (rawData) {
      console.log("Dropcategories", rawData);
      root = [...rawData].filter((x) => x.parent == null && x.isCategory == true);
      console.log("Droproot", root);
      createHierarchy(root, currentLevel);
      setCategoryTree(root);
      // renderTree(root);

      // console.log("root", root);
    }
  }, [rawData]);
  // createHierarchy(root, currentLevel);

  const renderTree = (nodes) => {
    console.log('nodes:' + nodes)
    console.log('nodesproviderId:' + nodes.providerId)
    return (

      <TreeItem
        key={nodes?.id}
        nodeId={nodes?.id}
        label={
          <GridContainer style={{ margin: 2, borderBottom: "1px black solid" }}>
            <GridItem xs={6} sm={6} md={7}>
              <h4>

                {nodes?.name}{" "}
                <small>
                  {nodes?.isCategory == false && (!nodes?.children || nodes?.children.length == 0)
                    ? `(Questions - ${nodes?.questionCount})`
                    : null}
                </small>
              </h4>
            </GridItem>

            <GridItem xs={6} sm={6} md={5} style={{ textAlign: "right" }}>
              {
                isPrivilege("CreateCategory") == true ?
                  canAddCategory(nodes) ? (
                    <Button
                      size="sm"
                      justIcon
                      round
                      color="primary"
                      disabled={nodes?.providerId != providerId}
                      onClick={(e) => {
                        e.preventDefault();
                        //props.addCategory("", nodes.id);
                        createCategoryAlert(nodes);
                      }}
                      style={{ marginRight: 5 }}  //canAddTopic(nodes) ? null :
                    >
                      C
                    </Button>
                  ) : null
                  : ''
              }
              {
                isPrivilege("CreateTopic") == true ?
                  canAddTopic(nodes) ? (
                    <Button
                      size="sm"
                      justIcon
                      round
                      color="primary"
                      disabled={nodes?.providerId != providerId}
                      onClick={(e) => {
                        e.preventDefault();
                        //props.addCategory("", nodes.id);
                        createTopicAlert(nodes);
                      }}
                      style={{ marginRight: 5 }}  //canUploadQuestions(nodes) ? null :
                    >
                      T
                    </Button>
                  ) : null
                  : ''
              }

              {console.log(nodes, 'nodes1')}

              {
                isPrivilege("ImportExcel") == true ?
                  nodes?.isCategory == false && !nodes?.children ?
                    (
                      <Button
                        size="sm"
                        justIcon
                        round
                        color="behance"
                        disabled={nodes?.providerId != providerId}
                        onClick={(e) => {
                          e.preventDefault();
                          //props.addCategory("", nodes.id);
                          createQuestionImportModal(nodes);
                        }}
                        style={{ marginRight: 5 }}
                      >
                        <PublishIcon />
                      </Button>
                    ) : null
                  : ''
              }
              {

                nodes?.questionCount && nodes?.questionCount > 0 ? (
                  <>
                    {
                      isPrivilege("PreviewQuestion") == true ?
                        <Button
                          size="sm"
                          justIcon
                          round
                          color="reddit"
                          //disabled={nodes?.providerId != providerId}
                          onClick={() => {
                            setAnswersForm(null, "Set Answers", nodes);
                          }}
                          style={{ marginRight: 5 }}
                        >
                          <SpellcheckIcon />
                        </Button>
                        : ''
                    }
                    {
                      isPrivilege("EditTopic") == true ?
                        <Button
                          size="sm"
                          justIcon
                          round
                          color="reddit"
                          disabled={nodes?.providerId != providerId}
                          onClick={(e) => {
                            e.preventDefault();
                            //props.addCategory("", nodes.id);
                            createQuestionUpdateModal(nodes);
                          }}
                          style={{ marginRight: 5 }}
                        >
                          <EditIcon />
                        </Button>
                        : ''}
                  </>
                ) : null
              }
              {
                isPrivilege("DeleteTopic") == true ?
                  nodes?.isCategory == false && !nodes?.children ? (
                    <Button
                      size="sm"
                      justIcon
                      round
                      color="primary"
                      disabled={nodes?.providerId != providerId}
                      onClick={(e) => {
                        e.preventDefault();
                        setAlert(
                          <SweetAlert
                            style={{ display: "block", marginTop: "-100px" }}
                            title={''}
                            onConfirm={() => handleDelteTopic(nodes.topicId)} // console.log(nodes?.topicId, 'clickedNodes')}
                            showCancel={true}
                            cancelBtnText={'CANCEL'}
                            confirmBtnText={'OK'}
                            onCancel={() => setAlert(null)}
                            confirmBtnCssClass={classes.button + " " + classes.success}
                            cancelBtnCssClass={classes.button + " " + classes.warning}
                          >
                            <div style={{ display: 'inline-flex' }}>
                              <WarningOutlined style={{ marginTop: '10px', marginRight: '5px', color: 'brown' }} />
                              <h4> Are you sure you want to delete topic?</h4>
                            </div>
                          </SweetAlert>
                        );
                        //props.addCategory("", nodes.id);
                        // createTopicAlert(nodes);
                      }}
                      style={{ marginRight: 5 }}
                    >
                      <DeleteForever />
                    </Button>
                  ) : null
                  : ''
              }
            </GridItem>
          </GridContainer>
        }
      >
        {Array.isArray(nodes?.children)

          ? nodes?.children?.map((node) => renderTree(node))
          : null}
      </TreeItem>
    );
  };

  const handleCategoryAlertConfirm = (e, nodes) => {
    props.addCategory({ name: e, parentId: nodes ? nodes?.id : null });
    hideAlert();
  };

  const handleTopicAlertConfirm = async (e, nodes) => {
    console.log(e);

    let rawNode = rawData.find((x) => x.id == nodes?.id);

    // props.addTopic({
    //   name: e,
    //   parentId: nodes.id,
    //   categoryId: rawNode.categoryId ?? rawNode.id,
    // });
    console.log(nodes?.id,
      rawNode.categoryId ?? rawNode.id, 'posting it')
    let createtopic = await addTopicService.createTopicService(e, nodes?.id, rawNode.categoryId ?? rawNode.id, user, providerId);
    if (createtopic?.status == 400) {
      setAlert(
        <CustomAlert
          open={true}
          openFlag={() => setFlag(flag ? false : true)}
          severity="error"
          alertMessage={createtopic?.messege ?? "Error occured"}
        />
      )
    } else {
      props.refresh()
      setAlert(
        <CustomAlert
          open={true}
          openFlag={() => setFlag(flag ? false : true)}
          severity="success"
          alertMessage={"Topic created successfully"}
        />
      )

    }
    console.log(createtopic, 'createTopic123')
    // createTopic(e, nodes.id, rawNode.categoryId ?? rawNode.id)
    hideTopicAlert();
  };

  const createCategoryAlert = (nodes) => {
    let desc = nodes ? (
      <>
        <h6>Create Category under Parent Category</h6>
        <h5>{nodes?.name}</h5>
      </>
    ) : (
      <>
        <h6>Create Root Category</h6>
      </>
    );
    setAlert(
      <SweetAlert
        input
        showCancel
        style={{ display: "block", marginTop: "-100px" }}
        title={
          <InfoArea
            title="Create Category"
            description={desc}
            icon={CategoryIcon}
            iconColor="rose"
          />
        }
        onConfirm={(e) => handleCategoryAlertConfirm(e, nodes)}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classesAlert.button + " " + classesAlert.info}
        cancelBtnCssClass={classesAlert.button + " " + classesAlert.warning}
      />
    );
  };

  // const calculateTreeDepth = (node) => {
  //   if (!node || !node.children || node.children.length === 0) {
  //     return 1;
  //   }

  //   const childDepths = node.children.map(calculateTreeDepth);
  //   return 1 + Math.max(...childDepths);
  // };
  const createTopicAlert = (nodes) => {
    //rawData.length
    console.log("packagedata", licensePackageObject, props?.rawData);
    let topicsCount = props?.rawData?.filter(item => item.isCategory === false)?.length;
    // let count = categoriesCount ? (props?.rawData?.length - categoriesCount) : 0;
    console.log(nodes?.currentLevel, "packagedatacount", nodes);
    // if ((props?.rawData?.length + 1) > licensePackageObject?.MaxTopics) {
    // if (1000 > licensePackageObject?.MaxTopics) {
    if ((nodes?.currentLevel + 1) > licensePackageObject?.TopicHierarchy) {
      setAlert(
        <CustomAlert
          open={true}
          openFlag={() => setFlag(flag ? false : true)}
          severity="warning"
          alertMessage={`You cannot create more than ${licensePackageObject?.TopicHierarchy} level hierarchy.`}
        />
      );
      return;
    }
    if ((topicsCount + 1) > licensePackageObject?.MaxTopics) {
      setAlert(
        <CustomAlert
          open={true}
          openFlag={() => setFlag(flag ? false : true)}
          severity="warning"
          alertMessage={`Please upgrade to higher package to create more than ${licensePackageObject?.MaxTopics} topics.`}
        />
      );
      return;
    }
    let desc = (
      <>
        <h6>
          Create Topic under Parent{" "}
          {nodes?.id.includes("T") ? "Topic" : "Category"}
        </h6>
        <h5>{nodes?.name}</h5>
      </>
    );

    setTopicAlert(
      <SweetAlert
        input
        showCancel
        style={{ display: "block", marginTop: "-100px" }}
        title={
          <InfoArea
            title="Create Topic"
            description={desc}
            icon={CategoryIcon}
            iconColor="info"
          />
        }
        onConfirm={(e) => handleTopicAlertConfirm(e, nodes)}
        onCancel={() => hideTopicAlert()}
        confirmBtnCssClass={classesAlert.button + " " + classesAlert.info}
        cancelBtnCssClass={classesAlert.button + " " + classesAlert.warning}
      />
    );
  };

  const createQuestionImportModal = (nodes) => {
    let title = `Upload question file under Topic "${nodes?.name}"`;

    setModal(true);
    setQuestionImportModal(
      <CustomModal
        modal={modal}
        padding1={true}
        setModal={() => hideImportQuestionModal()}
        content={
          <FileUpload
            topicId={nodes?.id.replace("T", "")}
            callback={() => {
              hideImportQuestionModal();
              props.refresh();
            }}
          />
        }
        cancelButtonMessage="Cancel"
        okButtonMessage="Ok"
        showOk={false}
        showCancel={false}
        onCancelClick={() => hideImportQuestionModal()}
        title={title}
      />
    );
  };
  const setAnswersForm = (topicId, title, nodes) => {
    console.log(nodes, 'nodes123')
    setQAnswersForm(
      <CustomModel
        modal={true}
        setModal={() => hideQSetAnsForm()}
        content={
          <div>
            {
              <TestPlayer
                afterfinish={() => hideQSetAnsForm()}
                topicId={nodes?.topicId}
                providerIdHeirarchy={nodes?.providerId}
                providerType={providerType}
                providerId={providerId}
              />
            }
          </div>
        }
        cancelButtonMessage="cancel"
        okButtonMessage="Create"
        showOk={false}
        onCancelClick={() => hideQSetAnsForm()}
        title={title}
        maxWidth="lg"
        fullWidth={true}
      />
    );
  };
  const createQuestionUpdateModal = (nodes) => {
    let title = `Update question file under topic "${nodes?.name}"`;

    setUpdateModal(true);
    setQuestionUpdateModal(
      <CustomModal
        modal={updateModal}
        setModal={() => hideUpdateQuestionModal()}
        content={
          <QuestionBulkUpdate hideHeader={true} topicId={nodes?.topicId} />
        }
        cancelButtonMessage="Cancel"
        okButtonMessage="Ok"
        showOk={false}
        showCancel={false}
        onCancelClick={() => hideUpdateQuestionModal()}
        title={title}
        maxWidth="lg"
        fullWidth={true}
      />
    );
  };
  const hideAlert = () => {
    setAlert(null);
  };

  const hideTopicAlert = () => {
    setTopicAlert(null);
  };

  const hideImportQuestionModal = () => {
    setQuestionImportModal(null);
  };

  const hideUpdateQuestionModal = () => {
    console.log("yo");
    setQuestionUpdateModal(null);
  };
  const hideQSetAnsForm = () => {
    setQAnswersForm(false);
  };

  function isCategoryPresent(data, providerId) {
    for (let i = 0; i < data.length; i++) {
      if (data[i].providerId === providerId) {
        return true; // Node with providerId found
      }
      // Check recursively in children if present
      if (data[i].children) {
        if (isCategoryPresent(data[i].children, providerId)) {
          return true; // Node with providerId found in children
        }
      }
    }
    return false; // Node with providerId not found
  }

  return (
    <>
      {alert}
      {topicAlert}
      {questionImportModal}
      {questionUpdateModal}
      {qAnswersForm}
      {console.log((providerType == 1 || providerType == 2) && isCategoryPresent(categoryTree, providerId) != true, 'propsrowData', providerType, isCategoryPresent(categoryTree))}
      {isCategoryPresent(categoryTree, providerId) == true ? props.onCheckCategory(true) : null}
      {
        (providerType == 1 || providerType == 2) && isCategoryPresent(categoryTree, providerId) != true ? (
          <div>
            <h4>Create your first Category</h4>
            <Button color="rose" style={{ textTransform: 'none' }} onClick={() => createCategoryAlert()}>
              Create Category
            </Button>
          </div>
        ) : null}
      {categoryTree ? (
        categoryTree.length ? (
          <>
            <TreeView
              className={classes.root}
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpanded={["root"]}
              defaultExpandIcon={<ChevronRightIcon />}
              sx={{
                height: 264,
                flexGrow: 1,
                maxWidth: 800,
                overflowY: "false",
              }}
              disableSelection={true}
            >
              {console.log(root, 'root125', categoryTree)}
              {
                Array.isArray(categoryTree)

                  ? categoryTree?.map((node) => renderTree(node))
                  : null
              }
            </TreeView>
          </>
        ) : null
      ) : null}

    </>
  );
}
