import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TreeItem from "@material-ui/lab/TreeItem";
import TreeView from "@material-ui/lab/TreeView";
import * as React from 'react';
import styles from '../../Styles/DetailingTable.module.css';
// import Button from '../Components/CustomButtons/Button'
import Button from "components/CustomButtons/Button";


function createData(topicName, yourScore, avgScore, topScore,) {
  return {
    topicName,
    yourScore,
    avgScore,
    topScore,
  };
}

const config = (open) => ({
  from: { height: 0, opacity: 0, transform: "translate3d(20px,0,0)" },
  to: {
    height: open ? "auto" : 0,
    opacity: open ? 1 : 0,
    transform: open ? "translate3d(0px,0,0)" : "translate3d(20px,0,0)",
  },
});

export default function AnalyticsTable({ analyticsData }) {

  const [open, setOpen] = React.useState(false);


  const renderTree = (nodes) => {
    console.log("renderTree1", nodes)
    console.log("renderTree/Name", nodes.Name)

    function FormatDuration(durationInMillis) {
      // Convert milliseconds to seconds
      let seconds = Math.floor(durationInMillis / 1000);

      // Calculate minutes and remaining seconds
      let minutes = Math.floor(seconds / 60);
      seconds %= 60;

      // Format the time
      let formattedTime = `${minutes}:${seconds}`;

      return formattedTime;
    }
    return (
      <TreeItem
        key={nodes.Guid}
        nodeId={nodes.Guid}
        label={
          <div>
            <div xs={6} sm={6} md={4}>
              <p style={{ float: 'left', marginTop: '19px', fontSize: '14px' }}>

                {nodes.Name}{" "}

              </p>
            </div>
            <div xs={6} sm={6} md={8} style={{ float: 'right', marginRight: '9px' }}>

              <Button
                size="sm"
                justIcon
                round
                style={{ backgroundColor: '#00BCD4', float: 'left', marginInline: '16px', height: '44px', width: '47px', fontSize: '14px' }}
              >
                {
                  nodes.Label == 'DURATION' ?
                    FormatDuration(nodes.Low) : nodes.Low
                }
                {
                  nodes.Label == 'ATTEMPTRATE' ? '%' : nodes.Label == 'ACCURACYRATE' ? '%' : ''
                }
              </Button>
              <Button
                size="sm"
                justIcon
                round
                style={{ float: 'initial', marginInline: '16px', backgroundColor: '#F05B4F', height: '44px', width: '47px', fontSize: '14px' }}
              >
                {
                  nodes.Label == 'DURATION' ?
                    FormatDuration(nodes.Average) : nodes.Average
                }
                {
                  nodes.Label == 'ATTEMPTRATE' ? '%' : nodes.Label == 'ACCURACYRATE' ? '%' : ''
                }
              </Button>
              <Button
                size="sm"
                justIcon
                round
                style={{ backgroundColor: '#F4C63D', float: 'right', marginInline: '16px', height: '44px', width: '47px', fontSize: '14px' }}
              >
                {
                  nodes.Label == 'DURATION' ?
                    FormatDuration(nodes.Top) :
                    nodes.Top
                }
                {
                  nodes.Label == 'ATTEMPTRATE' ? '%' : nodes.Label == 'ACCURACYRATE' ? '%' : ''
                }
              </Button>
            </div>
          </div>
        }
      >
        {Array.isArray(nodes.SubObjectList) && nodes.SubObjectList.length > 0
          ? nodes.SubObjectList.map((node) => renderTree(node))
          : null}
      </TreeItem>
    );
  };

  return (
    <div style={{ padding: '5px' }}>
      <div>
        <div xs={6} sm={2} md={2}>
          <p className={styles.tableHeading} />
        </div>
        <div xs={6} sm={6} md={10} style={{ float: 'right' }}>
          <p className={styles.tableHeading} >Low </p>
          <p className={styles.tableHeading} >Average </p>
          <p style={{ paddingRight: '5%' }} className={styles.tableHeading} >Top </p>
        </div>
      </div>

      <TreeView
        // className={classes.root}
        defaultCollapseIcon={<ExpandMoreIcon />}
        // defaultExpanded={["root"]}
        defaultExpandIcon={<ChevronRightIcon />}
        sx={{
          height: 264,
          flexGrow: 1,
          maxWidth: 1000,
          overflowY: "false",
        }}
        disableSelection={true}
      >

        {
          analyticsData ?

            analyticsData.map((row, i) => (
              renderTree(analyticsData[i])
            )) : ''
        }

      </TreeView>
    </div>
  );
}