import React, { useContext } from "react";

// @material-ui/core components
import Icon from "@material-ui/core/Icon";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles } from "@material-ui/core/styles";

import { withRouter } from "react-router";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import { LoginContext } from "contexts/LoginContext";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

import CircularProgress from "@material-ui/core/CircularProgress";
import EmailIcon from "@material-ui/icons/Email";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import axios from "axios";
import CustomAlert from "components/CustomAlert/CustomAlert";
import DropDownSelect from "components/DropDownSelect/DropDownSelect";
import useCreateUser from "hooks/userHooks/useCreateUser";
import useGetUserByUserName from "hooks/userHooks/useGetUserByUserName";
import ForgotPassService from "services/ForgotPassService";

const useStyles = makeStyles(styles);

function Login(props) {
  const [loginUsername, setLoginUsername] = React.useState("");
  const [forgotEmail, setEmailForForgot] = React.useState("");
  const [showAlert, setShowAlert] = React.useState(false);
  const [showAlert1, setShowAlert1] = React.useState(false)
  const [severty, setSeverty] = React.useState('success');
  const [alertMessage, setAlertMessage] = React.useState('')
  const [intialLoad, setInitialLoad] = React.useState(true);
  const [Loading, setLoading] = React.useState(false);

  const [signIn, setSignIn] = React.useState(1);
  const [checkMandatory, setCheckMandatory] = React.useState(false);

  const {
    data: loginDetails,
    isLoading,
    mutate: getUserDetails,
    error: userFetchError,
  } = useGetUserByUserName();

  const createUser = useCreateUser();

  const flipSignIn = (val) => {
    setSignIn(val);
  };

  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  const { setUser, setUserId, setUserPrivileges, setProviderId, setProviderType, setUserType, setContentProviderIds, setLicensePackageObject } = useContext(LoginContext);

  const [loginPassword, setLoginPassword] = React.useState("");
  const [retry, setRetry] = React.useState(true);
  const [userExists, setUserExists] = React.useState(false);

  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");

  const [mobile, setMobile] = React.useState(null);
  const [email, setEmail] = React.useState("");

  const [passwordSame, setPasswordSame] = React.useState(true);

  const [rePassWord, setRePassWord] = React.useState("");

  const [topics, setTopics] = React.useState(null);

  const checkIfUserNameExists = (val) => {
    if (!val.target.value) {
      return;
    }

    getUserDetails(val.target.value);
  };

  React.useEffect(() => {
    let id = setTimeout(function () {
      setCardAnimation("");
    }, 700);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });
  const classes = useStyles();

  const handleInputChange = (e) => {
    setLoginUsername(e.target.value);
  };
  const handleEmailInputChange = (e) => {
    setEmailForForgot(e.target.value)
  }
  const handlePasswordChange = (e) => {
    setLoginPassword(e.target.value);
  };

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
  };

  const handleMobileChange = (e) => {
    setMobile(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleRePasswordChange = (e) => {
    setRePassWord(e.target.value);
    if (loginPassword != e.target.value) {
      setPasswordSame(false);
    } else {
      setPasswordSame(true);
    }
  };

  React.useEffect(() => {
    setInitialLoad(false);
  }, []);
  // const findPrivilege = async (userId) => {
  //   const Privilege = await getPrivileges.getPrivileges(userId)
  //   setUserPrivileges(Privilege);
  // }

  React.useEffect(async () => {

    if (!loginDetails?.userDetails) {
      return;
    }

    if (signIn == 2) {
      if (loginDetails?.userDetails && loginDetails?.userDetails.userName) {
        setUserExists(true);
      } else {
        setUserExists(false);
      }

      return;
    }
    console.log(loginDetails, 'userDetails123');

    if (
      loginDetails?.userDetails &&
      loginDetails?.userDetails.userName.toLowerCase() == loginUsername.toLowerCase() &&
      loginDetails?.userDetails.passCode == loginPassword
    ) {
      //
      //findPrivilege(loginDetails?.userDetails?.userId);
      let privileges = loginDetails?.privilegeObject ? JSON.parse(loginDetails?.privilegeObject) : "";
      let licensePackage = loginDetails?.licenseObject ? JSON.parse(loginDetails?.licenseObject) : "";
      setLicensePackageObject(licensePackage);
      setUserPrivileges(privileges?.Value);
      setUser(loginUsername);
      setUserId(loginDetails?.userDetails?.userId);
      setProviderId(loginDetails?.userDetails.providerId);
      setProviderType(loginDetails?.userDetails.providerTypeId);
      setContentProviderIds(loginDetails?.userDetails.contentProviderIds["$values"]);
      setUserType(loginDetails?.userDetails.userTypeId);

      axios.defaults.headers.common = {
        providerId: loginDetails?.userDetails.providerId,
        contentProviderIds: loginDetails?.userDetails.contentProviderIds["$values"]
      };

      props.history.push("/admin");
    } else {
      setRetry(false);
      setShowAlert(true);
    }
  }, [loginDetails]);

  React.useEffect(() => {
    if (userFetchError && signIn == 1) {
      console.log("in here");
      setShowAlert(true);
    }
    if (userFetchError && signIn == 2) {
      setUserExists(false);
    }
  }, [userFetchError]);

  const handleSubmit = async () => {
    if (!loginUsername) {
      return;
    }

    getUserDetails(loginUsername);
    //setRetry(true);
  };
  const handleResetPass = async () => {
    if (!forgotEmail) {
      return;
    }
    // axios.defaults.headers.common = {
    //   providerId: 1000,
    // };
    try {
      setLoading(true);
      let emailResp = await ForgotPassService.EmailForForgotPass(forgotEmail)
      setLoading(false);
      console.log(emailResp, 'emailResp')
      if (emailResp.status == 200) {
        setAlertMessage('Email send successfully!')
        setSeverty('success');
        setShowAlert1(true);

        setTimeout(() => {
          setShowAlert1(false);
          flipSignIn(1);
        }, 3000);
      } else {
        setAlertMessage(emailResp.messege)
        setSeverty('error');
        setShowAlert1(true);
        setTimeout(() => {
          setShowAlert1(false);
        }, 3000);
      }
    } catch (error) {
      console.log(error, 'this is error')
      setAlertMessage('Error occured')
      setSeverty('error');
      setShowAlert1(true);
      setLoading(false);
    } finally {

      setLoading(false);
    }
    // getUserDetails(loginUsername);
    //setRetry(true);
  };
  const handleCreateUser = () => {
    if (
      !firstName ||
      !loginUsername ||
      !lastName ||
      !mobile ||
      !email ||
      !loginPassword ||
      !rePassWord
    ) {
      setCheckMandatory(true);
      return;
    }

    if (passwordSame == false) {
      return;
    }

    setUser(loginUsername);
    setProviderId(1000);
    setUserType(2);

    axios.defaults.headers.common = {
      providerId: 1000,
    };

    createUser.mutate({
      userName: loginUsername,
      firstName: firstName,
      lastName: lastName,
      middleName: " ",
      mobile: mobile,
      email: email,
      passCode: loginPassword,
      address: " ",
      isActive: true,
      userTypeId: 2,
      topics: topics.join(",")
    });
  };

  React.useEffect(() => {
    if (createUser.isSuccess == true) {
      props.history.push("/admin");
    }
  }, [createUser.isSuccess]);

  React.useEffect(() => {
    if (createUser.error == true) {
      setShowAlert(true);
    }
  }, [createUser.error]);

  const [passwordType, setPasswordType] = React.useState("password");
  // const [passwordCo, setPasswordType] = React.useState("password");
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text")
      return;
    }
    else {
      setPasswordType("password")
      return;
    }
  }
  return (
    <div className={classes.container}>
      <GridContainer justifyContent="center">
        <GridItem xs={12} sm={6} md={4}>
          {signIn == 1 ? (
            <form onSubmit={handleSubmit} autoComplete="off">
              <Card login className={classes[cardAnimaton]}>
                <CardHeader
                  className={`${classes.cardHeader} ${classes.textCenter}`}
                  color="rose"
                >
                  <h4 className={classes.cardTitle}>Log in</h4>
                </CardHeader>
                <CardBody>
                  <CustomInput
                    labelText="UserName"
                    id="userName"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Face className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                      ),
                      autoComplete: "off",
                      onChange: handleInputChange,
                    }}
                  />

                  <CustomInput
                    labelText="Password"
                    id="password"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon className={classes.inputAdornmentIcon}
                            onClick={togglePassword}>
                            {passwordType === "password" ? <VisibilityOff /> : <Visibility />}
                          </Icon>
                        </InputAdornment>
                      ),
                      type: passwordType,
                      autoComplete: "off",
                      onChange: handlePasswordChange,
                    }}
                  />
                </CardBody>
                <CardFooter className={classes.justifyContentCenter}>
                  {isLoading == true ? (
                    <CircularProgress
                      color="secondary"
                      style={{ size: "1.5rem" }}
                    />
                  ) : (
                    <Button
                      color="rose"
                      simple
                      size="lg"
                      block
                      onClick={handleSubmit}
                      style={{ textTransform: 'none' }}
                    >
                      Log in
                    </Button>
                  )}
                </CardFooter>
                <CardFooter
                  className={classes.justifyContentCenter}
                  style={{ textTransform: "none" }}
                >
                  <a href="#" onClick={() => flipSignIn(3)}> Forgot Password ?</a>
                </CardFooter>
                <CardFooter
                  className={classes.justifyContentCenter}
                  style={{ textTransform: "none" }}
                >
                  {/* Don't have an account &nbsp;{" "}
                  <a href="#" onClick={() => flipSignIn(2)}>
                    {" "}
                    Sign Up
                  </a> */}
                </CardFooter>
              </Card>
            </form>
          ) : signIn == 2 ? (
            <form onSubmit={handleSubmit} autoComplete="off">
              <Card
                login
                className={classes[cardAnimaton]}
                style={{ width: 500 }}
              >
                <CardHeader
                  className={`${classes.cardHeader} ${classes.textCenter}`}
                  color="rose"
                >
                  <h4 className={classes.cardTitle}>Register</h4>
                </CardHeader>
                <CardBody>
                  <CustomInput
                    labelText="User Name"
                    id="userName"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    required={true}
                    error={userExists || (checkMandatory && !loginUsername)}
                    helperText={userExists == true ? "User Name Exists" : ""}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Face className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                      ),
                      autoComplete: "off",
                      onChange: handleInputChange,
                      onBlur: (v) => checkIfUserNameExists(v),
                    }}
                  />

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="First Name"
                        id="userName"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        required={true}
                        error={checkMandatory && !firstName}
                        inputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Face className={classes.inputAdornmentIcon} />
                            </InputAdornment>
                          ),
                          autoComplete: "off",
                          onChange: handleFirstNameChange,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      {" "}
                      <CustomInput
                        labelText="Last Name"
                        id="userName"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        required={true}
                        error={checkMandatory && !lastName}
                        inputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Face className={classes.inputAdornmentIcon} />
                            </InputAdornment>
                          ),
                          autoComplete: "off",
                          onChange: handleLastNameChange,
                        }}
                      />
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      {" "}
                      <CustomInput
                        labelText="Mobile"
                        id="userName"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        required={true}
                        error={checkMandatory && !mobile}
                        inputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <PhoneAndroidIcon
                                className={classes.inputAdornmentIcon}
                              />
                            </InputAdornment>
                          ),
                          autoComplete: "off",
                          onChange: handleMobileChange,
                          type: "number"
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      {" "}
                      <CustomInput
                        labelText="Email"
                        id="userName"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        required={true}
                        error={checkMandatory && !email}
                        inputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <EmailIcon
                                className={classes.inputAdornmentIcon}
                              />
                            </InputAdornment>
                          ),
                          autoComplete: "off",
                          onChange: handleEmailChange,

                        }}
                      />
                    </GridItem>
                  </GridContainer>

                  <CustomInput
                    labelText="Password"
                    id="password"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    required={true}
                    error={checkMandatory && !loginPassword}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon className={classes.inputAdornmentIcon}>
                            lock_outline
                          </Icon>
                        </InputAdornment>
                      ),
                      type: "password",
                      autoComplete: "off",
                      onChange: handlePasswordChange,
                    }}
                  />

                  <CustomInput
                    labelText="Re-Enter Password"
                    id="password"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    helperText="Password does not match"
                    required={true}
                    error={
                      passwordSame == false || (checkMandatory && !rePassWord)
                    }
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon className={classes.inputAdornmentIcon}>
                            lock_outline
                          </Icon>
                        </InputAdornment>
                      ),
                      type: "password",
                      autoComplete: "off",
                      onChange: handleRePasswordChange,
                    }}
                  />

                  <DropDownSelect setTopics={setTopics} showCategoriesOnly={true} />
                </CardBody>
                <CardFooter className={classes.justifyContentCenter}>
                  {createUser.isLoading == true ? (
                    <CircularProgress
                      color="secondary"
                      style={{ size: "1.5rem" }}
                    />
                  ) : (
                    <Button
                      color="rose"
                      simple
                      size="lg"
                      block
                      onClick={handleCreateUser}
                      style={{ textTransform: 'none' }}
                    >
                      Create User
                    </Button>
                  )}
                </CardFooter>
                <CardFooter
                  className={classes.justifyContentCenter}
                  style={{ textTransform: "none" }}
                >
                  Already have an account &nbsp;{" "}
                  <a href="#" onClick={() => flipSignIn(1)}>
                    {" "}
                    Sign In
                  </a>
                </CardFooter>
              </Card>
            </form>
          ) : (
            <form onSubmit={handleResetPass} autoComplete="off">
              <Card login className={classes[cardAnimaton]}>
                <CardHeader
                  className={`${classes.cardHeader} ${classes.textCenter}`}
                  color="rose"
                >
                  <h4 className={classes.cardTitle}>Reset Password</h4>
                </CardHeader>
                <CardBody>
                  <CustomInput
                    labelText="Enter Email"
                    id="email"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Face className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                      ),
                      autoComplete: "off",
                      onChange: handleEmailInputChange,
                    }}
                  />

                  {/* <CustomInput
                    labelText="Password"
                    id="password"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon className={classes.inputAdornmentIcon}
                            onClick={togglePassword}>
                            {passwordType === "password" ? <VisibilityOff /> : <Visibility />}
                          </Icon>
                        </InputAdornment>
                      ),
                      type: passwordType,
                      autoComplete: "off",
                      onChange: handlePasswordChange,
                    }}
                  /> */}
                </CardBody>
                <CardFooter className={classes.justifyContentCenter}>
                  {Loading == true ? (
                    <CircularProgress
                      color="secondary"
                      style={{ size: "1.5rem" }}
                    />
                  ) : (
                    <Button
                      color="rose"
                      simple
                      size="lg"
                      block
                      onClick={handleResetPass}
                      style={{ textTransform: 'none' }}
                    >
                      Submit
                    </Button>
                  )}
                </CardFooter>
                <CardFooter
                  className={classes.justifyContentCenter}
                  style={{ textTransform: "none" }}
                >
                  Already have account &nbsp;{" "} <a href="#" onClick={() => flipSignIn(1)}> Sign In</a>
                </CardFooter>
                <CardFooter
                  className={classes.justifyContentCenter}
                  style={{ textTransform: "none" }}
                >
                  {/* Don't have an account &nbsp;{" "}
                  <a href="#" onClick={() => flipSignIn(2)}>
                    {" "}
                    Sign Up
                  </a> */}
                </CardFooter>
              </Card>
            </form>
          )}
          <CustomAlert
            open={showAlert1}
            severity={severty}
            timeout={3000}
            alertMessage={alertMessage}
            handleClose={() => setShowAlert(false)}
          />
          <CustomAlert
            open={showAlert}
            severity="error"
            alertMessage={
              createUser.error == true
                ? "Unable to create user"
                : "Invalid Credentials"
            }
            handleClose={() => setShowAlert(false)}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default withRouter(Login);
