import axios from "axios";
import { configUrl } from "config.js";
import { useQuery } from "react-query";

export default function useGetPoolsByTest(testId) {
  return useQuery(["pools", testId], async () => {
    let response = await axios.get(
      `${configUrl.AssessmentServer}/api/TestPoolMappings/${testId}`
    );

    console.log("poolsacallfrom hook", response.data);
    let poolData = response.data["$values"];

    let poolDetails = [];

    for (let i = 0; i < poolData.length; i++) {
      let poolResponse = await axios.get(
        `${configUrl.AssessmentServer}/api/Pools/${poolData[i].poolId}`
      );

      let language = { ...poolResponse.data.poolLanguages["$values"][0] };
      let p = { ...poolResponse.data };

      p.poolLanguages = [language];
      p.sequenceId = poolData[i]?.sequenceId;
      p.showQuestions = false;
      p.assignedQuestionCount = poolData[i].assignedQuestionCount;
      poolDetails.push(p);
    }
    return [...poolDetails.sort((a, b) => a.sequenceId - b.sequenceId)];
  },
    //BSS121 - will correct it for multiple calls.
    // {
    //   staleTime: 1000 * 60 * 5,
    // }
  );
}
