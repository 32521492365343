import axios from "axios";
import { configUrl } from "config.js";
import { useMutation } from "react-query";

export default function useDownloadOmr() {

    return useMutation(async ({ formData, testId }) => {
        try {

            let response = await axios.post(
                `${configUrl.AssessmentServer}/api/OmrSheet/createOmrSheet/${testId}`,
                formData, {
                responseType: 'blob'
            }
            );

            let DownloadOmr = response;

            if (!DownloadOmr) {
                throw new Error('Not Found');
            }
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'OmrSheet.pdf');
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);


            return response.data;
        } catch (error) {
            console.error("Error occured", error);
            throw error;
        }
    }
    );
}
