import React, { useContext } from "react";
// material-ui
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";
import Check from "@material-ui/icons/Check";
import radioStyles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch";
import dashboardStyle from "assets/newAssets/dashboardStyle";
import loginPageStyle from "assets/newAssets/loginPageStyle";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Hint from "components/Hint/Hint";
import { TestContext } from "contexts/TestContext";
import 'katex/dist/katex.min.css';
import ReactHtmlParser from "react-html-parser";
import Latex from 'react-latex-next';
{/* latex CSS */ }

var styles = {
  ...dashboardStyle,
  ...loginPageStyle,
  ...radioStyles,
  cardTitle: {
    marginTop: "0",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);

export default function MultipleChoiceQuestion({ qNoes, question, contentType, updateQuestion, preview, checkForDisabled }) {
  const { test, setSelect, select } = useContext(TestContext);
  console.log("MultipleChoiceQuestion", question);


  console.log("options", question.options)
  const [selected, setSelected] = React.useState([]);

  // let choices = question.answer.split(",");
  function isJSON(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
  const handleCheck = (event) => {
    console.log("selectedVal", event)
    let selectedValues = [...selected];
    if (event.target.checked === true) {
      selectedValues.push(event.target.name);
    } else {
      console.log(selectedValues, 'filteringVals')
      selectedValues = selectedValues.filter(
        (x) => x !== event.target.name
      );
    }
    setSelected([...selectedValues]);
    console.log("selectedVal123", selectedValues)
    question.questionChoices.choiceIdAnswers = JSON.stringify(selectedValues);

    question.isCorrect = false;
  };

  function isJSON(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  // const choiceIdAnswers = question?.questionChoices?.choiceIdAnswers && JSON.parse(question?.questionChoices?.choiceIdAnswers)?.filter(item => {
  //   const pattern = /[a-zA-Z0-9,IVXivx]/g;

  //   console.log(item, 'checkingPattern1')
  //   let ansArray = [];
  //   ansArray.push(item?.trim()?.toLowerCase()?.replace(/[(),]/g, ''))

  //   console.log(ansArray, 'checkingPattern')
  //   return ansArray;
  // });
  console.log(choiceIdAnswers, 'filteredVals123')
  let choiceIdAnswers = question?.questionChoices?.choiceIdAnswers && JSON.parse(question?.questionChoices?.choiceIdAnswers).map((element) => {
    return element?.trim()?.toLowerCase()?.replace(/[(),]/g, '');
  });

  console.log(choiceIdAnswers, 'filteredVals123')
  const classes = useStyles();
  return (
    <div style={{ width: "100%" }}>
      <Box display="flex" p={2}>
        <Box p={2} sx={{ width: "100%" }}>
          <Box display="flex" p={1} style={{ padding: 0 }}>
            <Box p={1} flexGrow={1} style={{ padding: 0, float: 'left', color: 'black', fontSize: '22px' }}>
              <div style={{ float: 'left', marginRight: '5px', marginTop: '7px' }}> Q{qNoes}. </div>
              {
                question && question.text && question.text.length > 0 ?
                  (contentType === 1 ?
                    <div >{question?.text}</div>
                    : contentType === 3 ?
                      <Latex >{question?.text}</Latex>
                      : contentType === 4 ?
                        <div>{question.text && ReactHtmlParser(question.text)}</div>
                        :
                        <div style={{ marginBottom: '10px' }}>{question.text}</div>
                  ) : null
              }
              {question.image &&
                <Box p={2}>
                  {question.image && <img src={question.image} alt="..." />}
                </Box>
              }
            </Box>
            <Box p={1}>
              <Hint message={question.hint} ContentType={contentType} questionId={question.id} />
            </Box>
          </Box>

          <Box>
            <GridContainer alignItems="center" spacing={1}>
              {
                question?.questionChoices?.choiceId01 != null &&
                <GridItem xs={11} sm={11} md={6}>
                  <div
                    className={
                      classes.checkboxAndRadio +
                      " " +
                      classes.checkboxAndRadioHorizontal
                    }
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={handleCheck}
                          checked={
                            choiceIdAnswers &&
                              choiceIdAnswers?.indexOf(question?.questionChoices?.choiceId01?.trim()?.toLowerCase()?.replace(/[(),]/g, '')) !== -1
                              ? true
                              : false
                          }
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot,
                          }}
                          name={question.questionChoices.choiceId01}
                          disabled={checkForDisabled}
                        // disabled={question.isSubmitted}
                        />
                      }
                      classes={{ label: classes.label }}
                      label={
                        contentType == 1 ?
                          question?.questionChoices?.choiceId01 : contentType == 3 ?
                            <Latex style={{ color: 'black' }}>{question?.questionChoices?.choiceId01}</Latex> :
                            contentType == 4 ?
                              ReactHtmlParser(question?.questionChoices?.choiceId01) :
                              question?.questionChoices?.choiceId01
                      }
                    />
                  </div>
                </GridItem>
                // </GridContainer>
              }
              {
                question?.questionChoices?.choiceId02 != null &&
                // <GridContainer alignItems="center" spacing={1}>
                <GridItem xs={11} sm={11} md={6}>
                  <div
                    className={
                      classes.checkboxAndRadio +
                      " " +
                      classes.checkboxAndRadioHorizontal
                    }
                  >

                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={handleCheck}
                          checked={
                            choiceIdAnswers &&
                              choiceIdAnswers?.indexOf(question?.questionChoices?.choiceId02?.trim()?.toLowerCase()?.replace(/[(),]/g, '')) !== -1
                              ? true
                              : false
                          }
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot,
                          }}
                          name={question.questionChoices.choiceId02}
                          disabled={checkForDisabled}
                        // disabled={question.isSubmitted}
                        />
                      }
                      classes={{ label: classes.label }}
                      label={
                        contentType == 1 ?
                          question?.questionChoices?.choiceId02 : contentType == 3 ?
                            <Latex style={{ color: 'black' }}>{question?.questionChoices?.choiceId02}</Latex> :
                            contentType == 4 ?
                              ReactHtmlParser(question?.questionChoices?.choiceId02) :
                              question?.questionChoices?.choiceId02
                      }
                    />
                  </div>
                </GridItem>
                // </GridContainer>
              }
              {
                question?.questionChoices?.choiceId03 != null &&
                // <GridContainer alignItems="center" spacing={1}>
                <GridItem xs={11} sm={11} md={6}>
                  <div
                    className={
                      classes.checkboxAndRadio +
                      " " +
                      classes.checkboxAndRadioHorizontal
                    }
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={handleCheck}
                          checked={
                            choiceIdAnswers &&
                              choiceIdAnswers?.indexOf(question?.questionChoices?.choiceId03?.trim()?.toLowerCase()?.replace(/[(),]/g, '')) !== -1
                              ? true
                              : false
                          }
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot,
                          }}
                          name={question.questionChoices.choiceId03}
                          disabled={checkForDisabled}
                        // disabled={question.isSubmitted}
                        />
                      }
                      classes={{ label: classes.label }}
                      label={
                        contentType == 1 ?
                          question?.questionChoices?.choiceId03 : contentType == 3 ?
                            <Latex style={{ color: 'black' }}>{question?.questionChoices?.choiceId03}</Latex> :
                            contentType == 4 ?
                              ReactHtmlParser(question?.questionChoices?.choiceId03) :
                              question?.questionChoices?.choiceId03
                      }
                    />
                  </div>
                </GridItem>
                // </GridContainer>
              }
              {
                question?.questionChoices?.choiceId04 != null &&
                // <GridContainer alignItems="center" spacing={1}>
                <GridItem xs={11} sm={11} md={6}>
                  <div
                    className={
                      classes.checkboxAndRadio +
                      " " +
                      classes.checkboxAndRadioHorizontal
                    }
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={handleCheck}
                          checked={
                            choiceIdAnswers &&
                              choiceIdAnswers?.indexOf(question?.questionChoices?.choiceId04?.trim()?.toLowerCase()?.replace(/[(),]/g, '')) !== -1
                              ? true
                              : false
                          }
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot,
                          }}
                          name={question.questionChoices.choiceId04}
                          disabled={checkForDisabled}
                        // disabled={question.isSubmitted}
                        />
                      }
                      classes={{ label: classes.label }}
                      label={
                        contentType == 1 ?
                          question?.questionChoices?.choiceId04 : contentType == 3 ?
                            <Latex style={{ color: 'black' }}>{question?.questionChoices?.choiceId04}</Latex> :
                            contentType == 4 ?
                              ReactHtmlParser(question?.questionChoices?.choiceId04) :
                              question?.questionChoices?.choiceId04
                      }
                    />
                  </div>
                </GridItem>
                // </GridContainer>
              }
              {
                question?.questionChoices?.choiceId05 != null &&
                // <GridContainer alignItems="center" spacing={1}>
                <GridItem xs={11} sm={11} md={6}>
                  <div
                    className={
                      classes.checkboxAndRadio +
                      " " +
                      classes.checkboxAndRadioHorizontal
                    }
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={handleCheck}
                          checked={
                            choiceIdAnswers &&
                              choiceIdAnswers?.indexOf(question?.questionChoices?.choiceId05?.trim()?.toLowerCase()?.replace(/[(),]/g, '')) !== -1
                              ? true
                              : false
                          }
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot,
                          }}
                          name={question.questionChoices.choiceId05}
                          disabled={checkForDisabled}
                        // disabled={question.isSubmitted}
                        />
                      }
                      classes={{ label: classes.label }}
                      label={
                        contentType == 1 ?
                          question?.questionChoices?.choiceId05 : contentType == 3 ?
                            <Latex style={{ color: 'black' }}>{question?.questionChoices?.choiceId05}</Latex> :
                            contentType == 4 ?
                              ReactHtmlParser(question?.questionChoices?.choiceId05) :
                              question?.questionChoices?.choiceId05
                      }
                    />
                  </div>
                </GridItem>
                // </GridContainer>
              }
              {
                question?.questionChoices?.choiceId06 != null &&
                // <GridContainer alignItems="center" spacing={1}>
                <GridItem xs={11} sm={11} md={6}>
                  <div
                    className={
                      classes.checkboxAndRadio +
                      " " +
                      classes.checkboxAndRadioHorizontal
                    }
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={handleCheck}
                          checked={
                            choiceIdAnswers &&
                              choiceIdAnswers?.indexOf(question?.questionChoices?.choiceId06?.trim()?.toLowerCase()?.replace(/[(),]/g, '')) !== -1
                              ? true
                              : false
                          }
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot,
                          }}
                          name={question.questionChoices.choiceId06}
                          disabled={checkForDisabled}
                        // disabled={question.isSubmitted}
                        />
                      }
                      classes={{ label: classes.label }}
                      label={
                        contentType == 1 ?
                          question?.questionChoices?.choiceId06 : contentType == 3 ?
                            <Latex style={{ color: 'black' }}>{question?.questionChoices?.choiceId06}</Latex> :
                            contentType == 4 ?
                              ReactHtmlParser(question?.questionChoices?.choiceId06) :
                              question?.questionChoices?.choiceId06
                      }
                    />
                  </div>
                </GridItem>
              }

            </GridContainer>
          </Box>
        </Box>
      </Box>
    </div>
  );
}