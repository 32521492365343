import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import { IconButton } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import CustomInput from "components/CustomInput/CustomInput.js";
const styles = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  inputAdornmentIcon: {
    color: "#555",
  },
  choiche: {
    textAlign: "center",
    cursor: "pointer",
    marginTop: "20px",
  },
  ...customSelectStyle,
  ...customCheckboxRadioSwitch,
};

const useStyles = makeStyles(styles);

const ProviderUserAdmin = React.forwardRef((props, ref) => {
  console.log(props)
  const classes = useStyles();
  const [simpleSelect, setsimpleSelect] = React.useState("");
  const [design, setdesign] = React.useState(false);
  const [code, setcode] = React.useState(false);
  const [develop, setdevelop] = React.useState(false);


  // const [fullName, setfullName] = React.useState(firstName + lastName);
  const [firstName, setfirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [fullNameState, setfullNameState] = React.useState("");
  const [firstNameState, setFirstNameState] = React.useState("");
  const [lastNameState, setLastNameState] = React.useState("");
  const [passCode, setpassCode] = React.useState("");
  const [passCodeState, setpassCodeState] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const [confirmPasscode, setConformPasscode] = React.useState("");
  const [confirmPasscodeState, setConformPasscodeState] = React.useState("");
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [address, setaddress] = React.useState("");
  const [addressState, setaddressState] = React.useState("");

  const [mobile, setmobile] = React.useState("");
  const [mobileState, setmobileState] = React.useState("");

  const [email, setemail] = React.useState("");
  const [emailState, setemailState] = React.useState("");
  //const [validateState, setValidateState] = React.useState(false);
  React.useImperativeHandle(ref, () => ({
    isValidated: () => {
      return isValidated();
    },
    sendState: () => {
      return sendState();
    },
    state: {
      fullName: firstName + lastName,
      // fullNameState,
      firstName,
      lastName,
      firstNameState,
      lastNameState,
      passCode,
      passCodeState,
      confirmPasscode,
      confirmPasscodeState,
      mobile,
      mobileState,
      email,
      emailState,
      address,
      addressState,
      providerId: "",
      providerTypeId: props?.allStates?.provider?.provider?.providerTypeId,
      createdDate: new Date(),
      updatedDate: new Date(),
      type: "adminuserdetails",
      // validate: validateState,
    },
  }));
  const sendState = () => {
    return {
      fullName: firstName + lastName,
      // fullNameState,
      firstName,
      lastName,
      firstNameState,
      lastNameState,
      passCode,
      passCodeState,
      confirmPasscode,
      confirmPasscodeState,
      mobile,
      mobileState,
      email,
      emailState,
      address,
      addressState,
      providerId: props.st.providerId,
      providerTypeId: props?.allStates?.provider?.provider?.providerTypeId,
      createdDate: new Date(),
      updatedDate: new Date(),
      type: "adminuserdetails",
      // validate: validateState,
    };
  };
  // const isValidated = () => {
  //   return true;
  // };
  const verifyEmail = (value) => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };
  // function that verifies if a string has a given length or not
  const verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };

  const toggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const verifyPassword = (password) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z0-9]).{8,}$/;
    return regex.test(password);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword((prevShowConfirmPassword) => !prevShowConfirmPassword);
  };

  const verifyConfirmedPassword = (password, confirmPassword) => {
    return password === confirmPassword;
  };

  const verifyMobileLength = (value, length) => /^\d+$/.test(value) && value.length == length;

  const isValidated = () => {
    if (
      // fullNameState === "success" &&
      firstNameState == "success" &&
      lastNameState == "success" &&
      emailState === "success" &&
      passCodeState === "success" &&
      confirmPasscode === passCode &&
      mobileState === "success" &&
      addressState === "success"
    ) {
      return true;
    } else {
      // if (fullNameState !== "success") {
      //   setfullNameState("error");
      // }
      if (firstNameState !== "success") {
        setFirstNameState("error");
      }
      if (lastNameState !== "success") {
        setLastNameState("error");
      }
      if (passCodeState !== "success") {
        setpassCodeState("error");
      }
      if (emailState !== "success") {
        setemailState("error");
      }
      if (addressState !== "success") {
        setaddressState("error");
      }

      if (mobileState !== "success") {
        setmobileState("error");
      }

    }
    return false;
  };
  return (
    <div>
      {/* <h4 className={classes.infoText}>What are you doing? (checkboxes)</h4> */}
      <GridContainer >
        {/* <GridItem xs={12} sm={12} md={12} lg={10}>
          <GridContainer>
            <GridItem xs={12} sm={4}>
              <div className={classes.choiche}>
                <Checkbox
                  tabIndex={-1}
                  onClick={() => setdesign(!design)}
                  checkedIcon={
                    <i
                      className={
                        "fas fa-pencil-alt " + classes.iconCheckboxIcon
                      }
                    />
                  }
                  icon={
                    <i
                      className={
                        "fas fa-pencil-alt " + classes.iconCheckboxIcon
                      }
                    />
                  }
                  classes={{
                    checked: classes.iconCheckboxChecked,
                    root: classes.iconCheckbox,
                  }}
                />
                <h6>Design</h6>
              </div>
            </GridItem>
            <GridItem xs={12} sm={4}>
              <div className={classes.choiche}>
                <Checkbox
                  tabIndex={-1}
                  onClick={() => setcode(!code)}
                  checkedIcon={
                    <i
                      className={"fas fa-terminal " + classes.iconCheckboxIcon}
                    />
                  }
                  icon={
                    <i
                      className={"fas fa-terminal " + classes.iconCheckboxIcon}
                    />
                  }
                  classes={{
                    checked: classes.iconCheckboxChecked,
                    root: classes.iconCheckbox,
                  }}
                />
                <h6>Code</h6>
              </div>
            </GridItem>
            <GridItem xs={12} sm={4}>
              <div className={classes.choiche}>
                <Checkbox
                  tabIndex={-1}
                  onClick={() => setdevelop(!develop)}
                  checkedIcon={
                    <i
                      className={"fas fa-laptop " + classes.iconCheckboxIcon}
                    />
                  }
                  icon={
                    <i
                      className={"fas fa-laptop " + classes.iconCheckboxIcon}
                    />
                  }
                  classes={{
                    checked: classes.iconCheckboxChecked,
                    root: classes.iconCheckbox,
                  }}
                />
                <h6>Develop</h6>
              </div>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel
                  htmlFor="simple-select-step-2"
                  className={classes.selectLabel}
                >
                  Choose City
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  classes={{
                    select: classes.select,
                  }}
                  value={simpleSelect}
                  onChange={(value) => {
                    setsimpleSelect(value.target.value);
                  }}
                  inputProps={{
                    name: "simpleSelect",
                    id: "simple-select-step-2",
                  }}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    Choose City
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value="2"
                  >
                    Paris
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value="3"
                  >
                    Bucharest
                  </MenuItem>
                </Select>
              </FormControl>
            </GridItem>
          </GridContainer>
        </GridItem> */}
        {/* <GridItem xs={12} sm={6} md={3}>
          <CustomInput
            success={fullNameState === "success"}
            error={fullNameState === "error"}
            labelText={
              <span>
                FullName <small>(required)</small>
              </span>
            }
            id="name"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              onChange: (event) => {
                if (!verifyLength(event.target.value, 3)) {
                  setfullNameState("error");
                } else {
                  setfullNameState("success");
                }
                setfullName(event.target.value);
              },
              endAdornment: (
                <InputAdornment position="end" className={classes.inputAdornment}>
                  <Face className={classes.inputAdornmentIcon} />
                </InputAdornment>
              ),
            }}
          />
        </GridItem> */}
        <GridItem xs={12} sm={6} md={3}>
          <CustomInput
            success={firstNameState === "success"}
            error={firstNameState === "error"}
            labelText={
              <span>
                FirstName <small>(required)</small>
              </span>
            }
            id="firstName"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              onChange: (event) => {
                if (!verifyLength(event.target.value, 3)) {
                  setFirstNameState("error");
                } else {
                  setFirstNameState("success");
                }
                setfirstName(event.target.value);
              },
              endAdornment: (
                <InputAdornment position="end" className={classes.inputAdornment}>
                  {/* <Face className={classes.inputAdornmentIcon} /> */}
                </InputAdornment>
              ),
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <CustomInput
            success={lastNameState === "success"}
            error={lastNameState === "error"}
            labelText={
              <span>
                LastName <small>(required)</small>
              </span>
            }
            id="lastName"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              onChange: (event) => {
                if (!verifyLength(event.target.value, 3)) {
                  setLastNameState("error");
                } else {
                  setLastNameState("success");
                }
                setLastName(event.target.value);
              },
              endAdornment: (
                <InputAdornment position="end" className={classes.inputAdornment}>
                  {/* <Face className={classes.inputAdornmentIcon} /> */}
                </InputAdornment>
              ),
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <CustomInput
            success={mobileState === "success"}
            error={mobileState === "error"}
            labelText={
              <span>
                Mobile <small>(required)</small>
              </span>
            }
            id="mobile"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              onChange: (event) => {
                if (!verifyMobileLength(event.target.value, 10)) {
                  setmobileState("error");
                } else {
                  setmobileState("success");
                }
                setmobile(event.target.value);
              },
              endAdornment: (
                <InputAdornment position="end" className={classes.inputAdornment}>
                  {/* <RecordVoiceOver className={classes.inputAdornmentIcon} /> */}
                </InputAdornment>
              ),
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <CustomInput
            success={emailState === "success"}
            error={emailState === "error"}
            labelText={
              <span>
                Email <small>(required)</small>
              </span>
            }
            id="email"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              onChange: (event) => {
                if (!verifyEmail(event.target.value)) {
                  setemailState("error");
                } else {
                  setemailState("success");
                }
                setemail(event.target.value);
              },
              endAdornment: (
                <InputAdornment position="end" className={classes.inputAdornment}>
                  {/* <Email className={classes.inputAdornmentIcon} /> */}
                </InputAdornment>
              ),
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={6} md={4}>
          <CustomInput
            success={passCodeState === "success"}
            error={passCodeState === "error"}
            labelText={
              <span>
                Password <small>(required)</small>
              </span>
            }
            id="passCode"
            formControlProps={{
              fullWidth: true,
            }}
            helperText={passCodeState ? "Password should contain special charachters uppercase,lowercase characters and number" : ''}
            inputProps={{
              type: showPassword ? 'text' : 'password',
              onChange: (event) => {
                if (!verifyPassword(event.target.value)) {
                  setpassCodeState("error");
                } else {
                  setpassCodeState("success");
                }
                setpassCode(event.target.value);
              },
              endAdornment: (
                <InputAdornment position="end" className={classes.inputAdornment}>
                  <IconButton onClick={toggleShowPassword}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={6} md={4}>
          <CustomInput
            success={passCode.trim().length > 0 ?? passCode == confirmPasscode}
            error={passCode != confirmPasscode}
            labelText={
              <span>
                Confirm password <small>(required)</small>
              </span>
            }
            id="confirmPassCode"
            formControlProps={{
              fullWidth: true,
            }}
            helperText={confirmPasscode != passCode ? "The confirmed password does not match the original." : ''}
            inputProps={{
              type: showConfirmPassword ? 'text' : 'password',
              onChange: (event) => {
                if (!verifyConfirmedPassword(event.target.value, passCode)) {
                  setConformPasscodeState("error");
                } else {
                  setConformPasscodeState("success");
                }
                setConformPasscode(event.target.value);
              },
              endAdornment: (
                <InputAdornment position="end" className={classes.inputAdornment}>
                  <IconButton onClick={toggleShowConfirmPassword}>
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={6} md={4}>
          <CustomInput
            success={addressState === "success"}
            error={addressState === "error"}
            labelText={
              <span>
                Address<small>(required)</small>
              </span>
            }
            id="address"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              onChange: (event) => {
                if (!verifyLength(event.target.value, 3)) {
                  setaddressState("error");
                } else {
                  setaddressState("success");
                }
                setaddress(event.target.value);
              },
              endAdornment: (
                <InputAdornment position="end" className={classes.inputAdornment}>
                  {/* <RecordVoiceOver className={classes.inputAdornmentIcon} /> */}
                </InputAdornment>
              ),
            }}
          />
        </GridItem>



      </GridContainer>
    </div>
  );
});

ProviderUserAdmin.displayName = "Step2";

export default ProviderUserAdmin;
