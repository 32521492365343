import { useState } from "react";
// material-ui
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import authPageStyles from "assets/jss/material-dashboard-pro-react/layouts/authStyle.js";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle";



import { CircularProgress } from "@mui/material";
import QuestionWrapper from "components/Questions/QuestionWrapper";
import { TestContext } from "contexts/TestContext";


var styles = {
  ...dashboardStyle,
  ...loginPageStyle,
  cardTitle: {
    marginTop: "0",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);

const authUseStyles = makeStyles(authPageStyles);

export default function TestPlayer(props) {
  if (!props.tests) return null;
  let tId = props.testId;

  const classes = useStyles();

  let testData = props.tests;
  let testAttemptDetailsData = props.testAttemptDetails;
  console.log('testAttemptDetailsData', testData)
  const [testAttemptDetails, setTestAttemptDetails] = useState(testAttemptDetailsData);
  const [test, setTest] = useState(testData);

  const [testAnswers, setTestAnswers] = useState([]);

  const [testId, setTestId] = useState(props.testId);
  const [userId, setUserId] = useState(props.userId);
  const [loadingTest, setLoadingTest] = useState(false);

  // useEffect(async()=> {
  //   if(props.testId){
  //     setLoadingTest(true);    
  //     let response = await testPreviewService.getPreviewTests(testId);
  //     setTest(response.data)
  //     setLoadingTest(false);
  //   }
  // }, [props.testId]);

  return (
    <div className={classes.wrapper}>
      <div
        style={{
          // background: "#9c27b0",
          //padding: "120px 0",
          position: "relative",
          minHeight: "70vh",
          display: "flex!important",
          margin: "0",
          border: "0",
          color: "white",
          alignItems: "center",
          backgroundSize: "cover",
          backgroundPosition: "center center",
          height: "100%",
        }}
      >
        {
          loadingTest == true &&
          <>
            <CircularProgress style={{ marginInline: '45%' }} />
            <h6 style={{ marginInline: '45%' }}>Loading...</h6>
          </>
        }
        {test && (
          <TestContext.Provider
            value={{ test, setTest, testAnswers, setTestAnswers, testAttemptDetails, setTestAttemptDetails, testId, userId }}
          >
            <GridContainer justifyContent="center" style={{ width: "calc(100% + 15px)" }}>
              <GridItem xs={12} sm={12} md={12} style={{ marginLeft: 10 }}>
                <QuestionWrapper onClose={props.onClose} testName={props.testName} isShowPublish={props.isShowPublish} testStatus={props.testStatus} attempt={props.attempt} preview={props.preview} isCourse={props.isCourse} totalScore={test.totalScore} />
              </GridItem>
            </GridContainer>
          </TestContext.Provider>
        )}
      </div>
    </div>
  );
}
