
import { TestContext } from "contexts/TestContext";
import { useContext } from "react";
import MultipleChoiceQuestion from "./MultipleChoiceQuestion";
import NumericalChoiceQuestion from "./NumericalChoiceQuestion";
import SingleChoiceQuestion from "./SingleChoiceQuestion";
import TrueFalseChoiceQuestion from "./TrueFalseChoiceQuestion";

const Question = ({ key, qNoes, question, updateQuestion, preview, checkForDisabled }) => {
  const { questionIndex, setQuestionIndex } = useContext(TestContext);
  console.log(question, "checking questions")
  const getQuestionComponent = () => {
    switch (question?.questionType) {
      case 1:
        return (
          <MultipleChoiceQuestion
            qNoes={qNoes}
            question={question}
            contentType={question?.contentType}
            updateQuestion={updateQuestion}
            preview={preview}
            checkForDisabled={checkForDisabled}
          />
        );
      case 2:
        return (
          <SingleChoiceQuestion
            qNoes={qNoes}
            question={question}
            contentType={question?.contentType}
            updateQuestion={updateQuestion}
            preview={preview}
            checkForDisabled={checkForDisabled}
          />
        );
      case 3:
        return (
          <TrueFalseChoiceQuestion
            qNoes={qNoes}
            question={question}
            contentType={question?.contentType}
            updateQuestion={updateQuestion}
            preview={preview}
            checkForDisabled={checkForDisabled}
          />
        );
      case 4:
        return (
          <NumericalChoiceQuestion
            qNoes={qNoes}
            question={question}
            contentType={question?.contentType}
            updateQuestion={updateQuestion}
            preview={preview}
            checkForDisabled={checkForDisabled}
          />
        );
      default:
        return null;
    }
  };
  return <>{getQuestionComponent()}</>;
};

export default Question;
