// import { Today } from "@material-ui/icons";
// import Card from "components/Card/Card";
// import Accordion from "components/Accordion/Accordion";
// import CardBody from "components/Card/CardBody";
// import CardHeader from "components/Card/CardHeader";
// import CardIcon from "components/Card/CardIcon";
// import CustomInput from "components/CustomInput/CustomInput";
// import Checkbox from '@material-ui/core/Checkbox';
// import FormGroup from '@material-ui/core/FormGroup';
// import { InputLabel } from '@material-ui/core';
// // import FormControlLabel from '@material-ui/core/FormControlLabel';
// import CustomSelect from "components/CustomSelect/CustomSelect";
// import GridContainer from "components/Grid/GridContainer";
// import GridItem from "components/Grid/GridItem";
// import React from "react";
// import Button from "components/CustomButtons/Button.js";
// import DropdownTreeSelect from "react-dropdown-tree-select";
// import Switch from "@material-ui/core/Switch";
// import { FormControlLabel } from "@material-ui/core";
// import CustomAlert from "components/CustomAlert/CustomAlert";
// import "assets/css/tree-dropdown-main.css";
// import "assets/css/tree-dropdown.css";
// import IntrestListing from "views/Components/IntrestListing";
// import SystemConfigurations from "views/Components/SystemConfigurations";
// import SchoolIcon from "@material-ui/icons/School";
// import PersonAddIcon from "@material-ui/icons/PersonAdd";


// function ProviderListing(props) {


//     return (
//         <div>
//             <Card>
//       <CardHeader color="rose" icon>
//         <CardIcon color="rose">
//           <SchoolIcon />
//         </CardIcon>

//       </CardHeader>
//       <CardBody>
//       <h2>Provider</h2>
//     {/* <Accordion 
//           collapses={[
//             {
//               title: <h3>Interest</h3>,
//               content: 

//                 <IntrestListing></IntrestListing>
//             },
//             {
//               title: <h3>Class</h3>,
//               content:               
//                 <SystemConfigurations configurationType="Class"></SystemConfigurations>
//             },
//             {
//               title: <h3>Education Board</h3>,
//               content:               
//                 <SystemConfigurations configurationType="EduBoard"></SystemConfigurations>
//             },
//             {
//               title: <h3>State</h3>,
//               content:               
//                 <SystemConfigurations configurationType="State"></SystemConfigurations>
//             },
//             {
//               title: <h3>City</h3>,
//               content:               
//                 <SystemConfigurations configurationType="City"></SystemConfigurations>
//             },
//             {
//               title: <h3>Education</h3>,
//               content:               
//                 <SystemConfigurations configurationType="Education"></SystemConfigurations>
//             },

//             ]} /> */}
// </CardBody>
// </Card>

//         </div>
//     );
//   }

//   export default ProviderListing;

import React, { useContext, useRef, useState } from "react";

import Switch from "@material-ui/core/Switch";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";

import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import Button from "components/CustomButtons/Button.js";
import CustomDataGrid from "components/CustomDataGrid.js/CustomDataGrid";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import EditProvider from "views/Components/EditProvider";
// import useGetAllProvider from "hooks/interestsHooks/useGetAllInterests";
import CustomAlert from "components/CustomAlert/CustomAlert";
import useCreateInterest from "hooks/interestsHooks/useCreateInterest";
import useDeleteInterest from "hooks/interestsHooks/useDeleteInterest";
import useIsInterestExists from "hooks/interestsHooks/useIsInterestExists";
import useUpdateInterest from "hooks/interestsHooks/useUpdateInterest";

import PersonAddIcon from "@material-ui/icons/PersonAdd";
import SchoolIcon from "@material-ui/icons/School";
import CustomModal from "components/CustomModal/CustomModal";
// import { render } from 'react-dom';
// import { WithContext as ReactTags } from 'react-tag-input';
import { Edit } from "@material-ui/icons";
import LinkIcon from '@material-ui/icons/Link';
import { Tooltip } from "@mui/material";
import { LoginContext } from "contexts/LoginContext";
import 'react-tagsinput/react-tagsinput.css';
import ActiveInactiveProvider from "services/ProviderServices/ActiveInactiveProvider";
import getallProviderService from "services/ProviderServices/getallProviderService";
import ProviderWizard from "views/Forms/ProviderWizard";

const useStyles = makeStyles(styles);

export default function ProviderListing(props) {
  const classes = useStyles();

  const { userPrivileges, providerId } = useContext(LoginContext);
  // const {
  //   data: providers,
  //   error: interestsFetchError,
  //   isLoading: interestsFetchLoading,
  // } = useGetAllProvider();

  // console.log(providers)
  const createInterst = useCreateInterest();
  const updateInterest = useUpdateInterest();
  const deleteInterest = useDeleteInterest();
  const isinterexist = useIsInterestExists();
  const [columns, setColumns] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [interestsEditForm, setInterestsEditForm] = React.useState(null);
  const [deleteModal, setDeleteModal] = React.useState(null);
  const [showModal, setShowModal] = React.useState(false);
  const [providerIdEdit, setProviderIdEdit] = React.useState(null); 1
  const [reloadGrid, setReloadGrid] = React.useState(false);
  const [interest, setInterest] = React.useState({
    interestId: 0,
    providerId: 0,
    interests: "",
    description: "",
    parentInterestId: 0,
    createdBy: "",
    createdDate: new Date(),
    updatedBy: "",
    updatedDate: new Date()
  });
  const [error, setError] = useState(false)
  const [message, setMessages] = useState('')
  const [showAlert, setShowAlert] = useState(null);
  const [flag, setFlag] = useState(false)
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 10
  })
  console.log(interest)

  // const [tags, setTags] = React.useState([]);

  // const handleChange = value => {
  //   setTags(value);
  // }
  const ref = useRef(null);

  //const [copyText, setCopyText] = useState('');
  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
  };



  const fetchData = async (pageNumber, pageSize) => {
    setPageState(old => ({ ...old, isLoading: true }))
    let providerTypeId = 0;
    let getProviders = await getallProviderService.getAllProviders(pageNumber, pageSize, providerTypeId);


    console.log(getProviders, 'getObjective1234')
    let filteredProviders = getProviders["$values"]
      .map((x, i) => {
        return { ...x, id: i, ind: i + 1 };
      })
    console.log(filteredProviders, 'getObjective1234')
    setPageState(old => ({ ...old, isLoading: false, data: filteredProviders, total: filteredProviders?.length > 0 ? filteredProviders[0].totalCount : 0 }))
  }
  React.useEffect(() => {
    if (pageState.page > 0) {
      fetchData(pageState.page, pageState.pageSize);
    }
    console.log(reloadGrid, "reloadgrid");
  }, [pageState.page, pageState.pageSize, reloadGrid])

  // TODO:  Error (reloadGrid)getting undefined after change
  //ST
  console.log(reloadGrid, "R1");
  const isPrivilege = (x) => {
    if (userPrivileges?.Privileges?.Provider.indexOf(x) !== -1) {
      return true;
    }
    else {
      return false;
    }
  }
  const handleChange = (event) => {
    setInterest({ ...interest, [event.target.name]: event.target.value })
    console.log({ ...interest, [event.target.name]: event.target.value });
  }
  const OnSubmit = async () => {
    console.log(interest)
    const found = pageState.data.find(element => element.interests === interest.interests);
    console.log("found..", found)
    let errorText = ''
    if (interest.interests === '') {
      errorText = errorText + "Please enter value"
    }
    if (found) {
      errorText = errorText + "Interest is exist"
    }
    if (errorText != "") {
      alert(errorText);
      // setMessages(errorText)
      // showError()
      return;
    }
    else {
      createInterst.mutate({ ...interest, description: interest.interests });
    }

  }
  const showError = () => {
    setError(true)
  }
  const showDeleteConfirmation = (id) => {
    setDeleteModal(
      <CustomModal
        modal={true}
        setModal={() => hideDeleteCustomModal()}
        content={<h3>Are you sure you want to delete the provider?</h3>}
        cancelButtonMessage="Cancel"
        okButtonMessage={deleteInterest.isLoading ? "Deleting" : "Yes"}
        showOk={true}
        onCancelClick={() => hideDeleteCustomModal()}
        title="Delete Interest"
        maxWidth="sm"
        onOkClick={async () => {
          await deleteInterest.mutateAsync(id);
          hideDeleteCustomModal();
        }}
        showCancel={true}
        padding1={true}
      />
    );
  };

  const hideDeleteCustomModal = () => {
    setDeleteModal(null);
  };

  const onDelete = async (e, id) => {
    showDeleteConfirmation(id);
  };

  const onUpdate = async (providerId, status) => {
    console.log(providerId, status, 'active Providerid')
    let updateProviderActivation = await ActiveInactiveProvider.ActiveInactiveProvider(providerId, status);
    console.log(updateProviderActivation, 'updateProviderresp')
    if (updateProviderActivation.status == 200 || updateProviderActivation.status == 201) {
      setShowAlert(
        <CustomAlert
          open={true}
          openFlag={() => setFlag(flag ? false : true)}
          // afterfinish={props.afterfinish}
          severity="success"
          alertMessage={updateProviderActivation.data}
        />
      )
      fetchData(pageState.page, pageState.pageSize);
    } else {
      setShowAlert(
        <CustomAlert
          open={true}
          openFlag={() => setFlag(flag ? false : true)}
          // afterfinish={props.afterfinish}
          severity="error"
          alertMessage={updateProviderActivation.messege}
        />
      )
      fetchData(pageState.page, pageState.pageSize);

    }
    // updateInterest.mutate(values);
  };

  const onInfo = (e, id) => {
    alert("info");
  };

  const handleRowSelection = (e) => {
    setSelection(e);
  };

  const onCellEditCommit = (p) => { };

  const onCellBlur = (e, p) => { };

  const handleEdit = (p, e) => { };

  React.useEffect(() => {
    let cols = [
      // {
      //   field: "Active/InactiveProvider",
      //   headerName: "#",
      //   editable: false,
      //   width: 80,
      //   filterable: false,
      //   hide: isPrivilege("Active/InactiveProvider") == true ? false : true,
      //   sortable: false,
      //   renderCell: (cellValues) => {
      //     return (
      //       // fillButtons
      //       <>
      //         <Switch
      //           value={cellValues.row.isActive}
      //           onChange={(e) => {
      //             let val = { target: { name: 'isActive', value: !cellValues.row.isActive } };
      //             //handleChange(val);
      //             cellValues.row.isActive = !cellValues.row.isActive
      //             onUpdate(cellValues)
      //           }}
      //           // console.log(cellValues.row.isActive)
      //           checked={cellValues.row.isActive}
      //         />
      //       </>
      //     );
      //   },
      // },
      // {
      //   field: "ind",
      //   headerName: "#",
      //   editable: false,
      //   width: 50,
      //   filterable: false,
      //   hideSortIcons: true,
      //   align: 'center',
      //   headerAlign: 'center',
      //   sortable: false,
      //   disableColumnMenu: true,
      // },
      {
        field: "name",
        headerName: "Provider Name",
        editable: false,
        width: 200,
        renderCell: (cellValues) => {
          console.log(cellValues, 'cells')
          return (
            <Tooltip classes={{ tooltip: classes.tooltip }} title={cellValues.row.name}>
              <span>
                {cellValues.row.name}
              </span>
            </Tooltip>
          )
        }
      },
      {
        field: "detailedName",
        headerName: "Details",
        editable: false,
        hide: true,
        width: 200,
      },
      {
        field: "website",
        headerName: "Website",
        editable: false,
        width: 200,
      }, {
        field: "providerTypeId",
        headerName: "Provider Type",
        editable: false,
        width: 200,
        renderCell: (cellValues) => {
          let typeLabel = "";
          switch (cellValues.row.providerTypeId) {
            case 1:
              typeLabel = "All";
              break;
            case 2:
              typeLabel = "Content Curator";
              break;
            case 3:
              typeLabel = "Subscriber";
              break;
            case 4:
              typeLabel = "Simplified Test";
              break;
            default:
              typeLabel = "Unknown";
              break;
          }
          return typeLabel;
        },
      },
      {
        field: "address",
        headerName: "Address",
        editable: false,
        width: 200,
      },
      {
        field: "mobile",
        headerName: "Mobile",
        editable: false,
        width: 130,
      },
      {
        field: "email",
        headerName: "Email",
        editable: false,
        width: 200,
        renderCell: (cellValues) => {
          console.log(cellValues, 'cells')
          return (
            <Tooltip classes={{ tooltip: classes.tooltip }} title={cellValues.row.email}>
              <span>
                {cellValues.row.email}
              </span>
            </Tooltip>
          )
        }
      },
      // {
      //   field: "providerKey",
      //   headerName: "ProviderKey",
      //   editable: false,
      //   width: 150,
      // },
      {
        field: "providerUrl",
        headerName: "Provider Url",
        editable: false,
        width: 200,
        renderCell: (cellValues) => {
          return (
            cellValues.row.providerTypeId === 2 ? '' : cellValues.row.providerUrl

          )
        },
      },
      {
        field: "actions",
        headerName: "Actions",
        editable: false,
        width: 150,
        filterable: false,
        // hide: isPrivilege("Active/InactiveProvider") == true ? false : true,
        sortable: false,
        renderCell: (cellValues) => {
          return (
            // fillButtons
            <>
              {
                isPrivilege("Active/InactiveProvider") == true ?
                  <Tooltip title="Active/Deactive">
                    <Switch
                      value={cellValues.row.isActive}
                      onChange={(e) => {
                        let val = { target: { name: 'isActive', value: !cellValues.row.isActive } };
                        //handleChange(val);
                        cellValues.row.isActive = !cellValues.row.isActive
                        onUpdate(cellValues.row.providerId, cellValues.row.isActive)
                      }}
                      // console.log(cellValues.row.isActive)
                      checked={cellValues.row.isActive}
                    />
                  </Tooltip>
                  : ""
              }

              <Tooltip title="Copy Url">
                <Button
                  size="sm"
                  justIcon
                  round
                  // color="reddit"
                  onClick={(e) => {
                    //e.preventDefault();
                    // showTaskDetailsMapModal(cellValues.row);
                    handleCopy(cellValues.row.providerUrl); // Copy text to clipboard
                  }}
                  style={{ size: "1px", backgroundColor: "#DD4B39" }}
                  disabled={cellValues.row.providerTypeId === 2 ? true : false}
                >
                  <LinkIcon />
                </Button>
              </Tooltip>
              <Tooltip title="Edit">
                <Button
                  size="sm"
                  justIcon
                  round
                  onClick={() => {
                    setShowModal(true);
                    setProviderIdEdit(cellValues.row.providerId);
                    console.log("providerIdedit SET", providerIdEdit);
                  }}
                  style={{ size: "1px", backgroundColor: "#DD4B39" }}
                >
                  <Edit />
                </Button>
              </Tooltip>
            </>
          );
        },
      },
    ];

    setColumns(cols);
  }, []);

  console.log(pageState.data, 'pageStateDate')
  // React.useEffect(() => {
  //   if (providers) {
  //     setRows(
  //       providers["$values"]
  //         .map((x, i) => {
  //           return { ...x, id: i, ind: i + 1 };
  //         })
  //     );
  //   }
  // }, [providers]);

  const handleGridReload = () => {
    setReloadGrid(true);
  }

  const createInterestsEditForm = (row, title) => {
    setInterestsEditForm(
      <CustomModal
        modal={true}
        setModal={() => hideInterestsEditForm()}
        content={
          <ProviderWizard
            setReloadGrid={(state) => setReloadGrid(state)}
            icon={PersonAddIcon}
            user={row}
            afterFinish={() => hideInterestsEditForm()}
            userType={2}
          />
        }
        cancelButtonMessage="cancel"
        okButtonMessage="Create"
        showOk={false}
        onCancelClick={() => hideInterestsEditForm()}
        title={title}
        maxWidth="lg"
        fullWidth={true}
      />
    );
  };


  const handleCloseEditProvider = () => {
    setShowModal(false);
  }
  const hideInterestsEditForm = () => {
    setInterestsEditForm(null);
  };

  const getRowClassName = (row) => {
    return null;
  };

  return (
    <>
      <div>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <SchoolIcon />
            </CardIcon>
          </CardHeader>
          <CardBody>
            {/* <h2>Provider</h2> */}
            <GridContainer >
              {/* <GridItem >
            <TextField
              // {...params}
              variant="outlined"
              // label="filterSelectedOptions"
              // placeholder="Favorit
              type="text"
              name="interests"
              value={interest.interests}
              onChange={handleChange}
            />
          </GridItem> */}
              {/* provider */}
              <GridItem xs={12} sm={12} md={12} style={{ textAlign: "right" }}>
                {
                  isPrivilege("AddProvider") == true ?
                    <Button
                      style={{ textTransform: "none" }}
                      color="primary"
                      onClick={createInterestsEditForm}
                    >
                      + Add
                    </Button> : ''}
              </GridItem>

            </GridContainer>
            <br /><br />
            <GridContainer spacing={1}>
              <div style={{ height: 500, width: "100%" }}>
                <div style={{ display: "flex", height: "100%" }}>
                  {pageState.data ? (
                    <div ref={ref} style={{ flexGrow: 1 }}>
                      {
                        isPrivilege("ViewProvider") == true ?
                          <CustomDataGrid
                            rows={pageState.data}
                            rowCount={pageState.total}
                            loading={pageState.isLoading}
                            rowsPerPageOptions={[10, 30, 50, 70, 100]}
                            page={pageState.page - 1}
                            pageSize={pageState.pageSize}
                            paginationMode="server"
                            onPageChange={(newPage) => setPageState(old => ({ ...old, page: newPage + 1 }))}
                            onPageSizeChange={(newPageSize) => setPageState(old => ({ ...old, pageSize: newPageSize }))}
                            columns={columns}
                            onEditCellPropsChange={handleEdit}
                            onCellEditCommit={onCellEditCommit}
                            onSelectionModelChange={handleRowSelection}
                            getRowClassName={getRowClassName}

                          // autoPageSize={true}
                          // onCellBlur={onCellBlur}
                          />
                          : ""
                      }
                    </div>
                  ) : (
                    <div style={{ flexGrow: 1 }}>
                      <CircularProgress /> Loading Providers...
                    </div>
                  )}
                </div>
              </div>
              {console.log("providerIdeditRoot", providerIdEdit)}
              <CustomModal
                modal={showModal}
                setModal={() => handleCloseEditProvider()}
                padding1={true}
                content={<EditProvider
                  afterFinish={() => {
                    handleCloseEditProvider();
                    // refetch();
                  }}
                  providerIdEdit={providerIdEdit}
                />}
                showOk={false}
                showCancel={false}
                title={"Edit provider"}
                maxWidth="xl"
                onCancelClick={() => handleCloseEditProvider()}
              >

              </CustomModal>
              {updateInterest.isError == true && (
                <CustomAlert
                  open={true}
                  severity="error"
                  alertMessage="Error while updating provider"
                />
              )}
              {console.log("provider update: ", updateInterest.isSuccess)}
              {updateInterest.isSuccess == true && (
                <CustomAlert
                  open={true}
                  severity="success"
                  alertMessage="Provider Updated successfully"
                />
              )}
              {deleteInterest.isSuccess == true && (
                <CustomAlert
                  open={true}
                  severity="success"
                  alertMessage="Provider Deleted successfully"
                />
              )}
              {
                <CustomAlert
                  open={error}
                  severity="error"
                  alertMessage={message}
                />
              }
              {showAlert}
              {interestsEditForm}
              {deleteModal}
            </GridContainer>
          </CardBody>
        </Card>
      </div>
    </>
  );
}


