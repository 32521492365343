import { Box } from '@mui/material';
import 'katex/dist/katex.min.css';
import ReactHtmlParser from "react-html-parser";
import Latex from 'react-latex-next';

{/* latex CSS */ }
export default function Solution({ solutionText, solution, contentType, updateQuestion, preview }) {
  return (
    <div>
      <Box p={2} style={{ color: 'black', padding: '36px' }} >
        <h3>Solution: </h3>
        <Box p={1} style={{ color: 'black' }}>
          {
            contentType === 1 ?
              /* Text Based */
              <p>{solutionText && solutionText}</p>
              :
              contentType === 3 ?
                /* latex Based */
                (solutionText == null ? '' :
                  <Latex>{solutionText}</Latex>)
                :
                contentType === 4 ?
                  /* for mathMl need to modify with html parser */
                  (solutionText == null ? '' :
                    <div style={{ color: 'black' }}>{ReactHtmlParser(solutionText)}</div>)
                  :
                  <p>{solutionText && solutionText}</p>
          }
        </Box>
        <Box p={1}>
          {solution && <img src={solution} alt="..." />}
        </Box>
      </Box>
    </div>
  )
}
