import { Tooltip, makeStyles } from "@material-ui/core";
import CustomAlert from "components/CustomAlert/CustomAlert";
import CustomDataGrid from "components/CustomDataGrid.js/CustomDataGrid";
import { useEffect, useState } from "react";
import taskDetailsService from "services/TaskService/taskDetailsService";
const useStyles = makeStyles((theme) => ({
    tooltip: {
        fontSize: "1em",
    },
}));
function TaskDetails(props) {
    const classes = useStyles();

    const { taskId, afterFinish } = props;
    const [state, setState] = useState([]
    );
    const [flag, setFlag] = useState(false);
    const [showAlert, setShowAlert] = useState(null);

    // const [loading, setLoading] = useState(false);

    const [pageState, setPageState] = useState({
        isLoading: false,
        data: [],
        total: 0,
        page: 1,
        pageSize: 10
    })
    // Function to add 'id' property and ensure uniqueness
    const addIdAndEnsureUnique = (array) => {
        const idCountMap = new Map();

        // Iterate over each object in the array
        array.forEach(obj => {
            const id = obj.Id;
            const count = idCountMap.get(id) || 0;
            idCountMap.set(id, count + 1);

            // Create unique id by appending count if id is not unique
            obj.id = count === 0 ? id : `${id}_${count}`;

            // Remove the original 'Id' property
            delete obj.Id;
        });

        return array;
    };
    const fetchTaskDetails = async (taskId) => {
        setPageState(old => ({ ...old, isLoading: true }))

        let getOjbectives = await taskDetailsService.taskDetailsService(taskId);
        console.log(Array.isArray(getOjbectives), typeof getOjbectives === 'object', 'nnmm', getOjbectives, 'data1')

        if (getOjbectives && getOjbectives?.status == 404) {
            console.log('is here')
            setPageState(old => ({ ...old, isLoading: false, data: [], total: 0 }))
        } else {
            if (Array.isArray(getOjbectives)) {
                // for getting array of objects.
                let updatedData = addIdAndEnsureUnique(getOjbectives)
                console.log(updatedData, 'updatedData')
                setPageState(old => ({ ...old, isLoading: false, data: updatedData, total: getOjbectives?.length > 0 ? getOjbectives?.length : 0 }))
            } else if (typeof getOjbectives === 'object') {
                // for getting single obj. only, I'm Converting object to array of objects
                const outputArray = [getOjbectives]
                // and Modifying each object in the array to add Id as a property if getting single obj
                outputArray.forEach((obj, index) => {
                    obj.id = obj.Id || index + 1; // If Id is 0, assign index + 1
                });
                setPageState(old => ({ ...old, isLoading: false, data: outputArray, total: getOjbectives?.length > 0 ? getOjbectives?.length : 0 }))
            }

        }

        console.log(getOjbectives, 'getObjective1234')
    }
    useEffect(() => {
        fetchTaskDetails(taskId)
    }, [])
    const handleCopy = (text) => {
        navigator.clipboard.writeText(text);
        setShowAlert(
            <CustomAlert
                open={true}
                openFlag={() => setFlag(flag ? false : true)}
                severity="success"
                alertMessage={"Text copied into clipboard."}
            />
        )
    };
    const [columns, setColumns] = useState([]);
    useEffect(() => {
        let cols = [
            {
                field: "Status",
                headerName: "Status",
                editable: false,
                width: 100,
                filterable: false,
                hideSortIcons: true,
                align: 'center',
                headerAlign: 'center',
                sortable: false,
                disableColumnMenu: true,
                renderCell: (cellValues) => {
                    console.log(cellValues, 'cells')
                    return (
                        // cellValues.row.status == 0 ?
                        //     (<span style={{ color: '#F7CA26', fontWeight: '600' }}>Draft</span>)
                        // :
                        cellValues.row.Status == 0 ?
                            (<span style={{ color: '#F7CA26', fontWeight: '600' }}>InProcess</span>) /// This change is temporary, need to revert after bacend changes

                            : cellValues.row.Status == 2 ?
                                (<span style={{ color: '#53AD57', fontWeight: '600' }}>Success</span>)
                                :
                                cellValues.row.Status == 1 ?
                                    (<span style={{ color: 'red', fontWeight: '600' }}>Failed</span>)
                                    :
                                    "-"
                    )
                }
            },
            {
                field: "id",
                headerName: "Id",
                editable: false,
                hide: true,
                width: 100,
            },
            {
                field: "SubTask",
                headerName: "Sub Task",
                editable: false,
                filterable: false,
                hideSortIcons: true,
                // align: 'center',
                // headerAlign: 'center',
                sortable: false,
                disableColumnMenu: true,
                width: 350,
                renderCell: (cellValues) => {
                    console.log(cellValues, 'cells')
                    return (
                        <Tooltip classes={{ tooltip: classes.tooltip }} title={cellValues.row.SubTask ?? 'none'}>
                            <span style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', lineHeight: '1.5' }}>
                                {cellValues.row.SubTask ?
                                    cellValues.row.SubTask?.substr(0, 90) : '-'
                                }

                            </span>
                        </Tooltip>
                    )
                }
            },
            {
                field: "StartDateTime",
                headerName: "Start Date",
                editable: false,
                filterable: false,
                hideSortIcons: true,
                // align: 'center',
                // headerAlign: 'center',
                sortable: false,
                disableColumnMenu: true,
                width: 200,
                renderCell: (cellValues) => {
                    if (cellValues.row.StartDateTime) {
                        console.log(typeof (cellValues.row.StartDateTime), 'dates', cellValues.row.startDate)

                        // const utcDateTime = new Date(cellValues.row.StartDateTime);
                        // const istOffset = 5.5 * 60 * 60 * 1000; // IST is UTC+5.5 hours
                        // const istDateTime = new Date(utcDateTime.getTime() + istOffset);

                        // console.log('originalCellrows', cellValues.row)
                        // console.log(istDateTime, 'convertedDate', 'original', cellValues.row.StartDateTime)
                        // return istDateTime.toLocaleString();

                        const dateTime = new Date(cellValues.row.StartDateTime);
                        // Get individual date and time components
                        const day = dateTime.getDate();
                        const month = dateTime.toLocaleString('default', { month: 'short' });
                        const year = dateTime.getFullYear();
                        let hours = dateTime.getHours();
                        const minutes = String(dateTime.getMinutes()).padStart(2, '0');
                        const seconds = String(dateTime.getSeconds()).padStart(2, '0');
                        const ampm = hours >= 12 ? 'PM' : 'AM';
                        hours = hours % 12;
                        hours = String(hours ? hours : 12).padStart(2, '0');

                        return `${day}-${month}-${year} ${hours}:${minutes}:${seconds} ${ampm}`;
                    } else {
                        return "-"
                    }
                    // return cellValues.row.startDate
                    //     ? cellValues.row.startDate.slice(0, 19).replace("T", " ")
                    //     : <i> none </i>;
                },
            },
            {
                field: "EndDateTime",
                headerName: "End Date",
                editable: false,
                filterable: false,
                hideSortIcons: true,
                // align: 'center',
                // headerAlign: 'center',
                sortable: false,
                disableColumnMenu: true,
                width: 200,
                renderCell: (cellValues) => {
                    if (cellValues.row.EndDateTime) {
                        const dateTime = new Date(cellValues.row.EndDateTime);
                        // Get individual date and time components
                        const day = dateTime.getDate();
                        const month = dateTime.toLocaleString('default', { month: 'short' });
                        const year = dateTime.getFullYear();
                        let hours = dateTime.getHours();
                        const minutes = String(dateTime.getMinutes()).padStart(2, '0');
                        const seconds = String(dateTime.getSeconds()).padStart(2, '0');
                        const ampm = hours >= 12 ? 'PM' : 'AM';
                        hours = hours % 12;
                        hours = String(hours ? hours : 12).padStart(2, '0');

                        return `${day}-${month}-${year} ${hours}:${minutes}:${seconds} ${ampm}`;
                    } else {
                        return "-"
                    }
                },
            },
            {
                field: "Remark",
                headerName: "Remarks",
                editable: false,
                filterable: false,
                hideSortIcons: true,
                // align: 'center',
                // headerAlign: 'center',
                sortable: false,
                disableColumnMenu: true,
                width: 400,
                renderCell: (cellValues) => {
                    console.log(cellValues, 'cells')
                    return (
                        <Tooltip classes={{ tooltip: classes.tooltip }} title={cellValues.row.Remark ?? 'none'}>
                            <span onClick={() => handleCopy(cellValues?.row?.Remark)} style={{ textAlign: 'left', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                                {cellValues.row.Remark ?
                                    //cellValues.row.Remark?.substr(0, 110) 
                                    cellValues.row.Remark : '-'
                                }
                            </span>
                        </Tooltip>
                    )
                }
            },
        ];
        setColumns(cols);
    }, []);
    const handleEdit = (p, e) => { };

    const onCellEditCommit = (p) => {
        let row = questionData.questions.find((x) => x.id == p.id);
        if (row[p.field] == p.value) {
            return;
        }
    };
    const getRowClassName = (row) => {
        if (row.isLocked == true) {
            return "gray";
        }

        return null;
    };
    return (
        <div style={{ marginTop: '-2%', overflowX: 'auto' }} >
            <div style={{ height: 520, marginInline: '0%', width: '1250px' }}>
                <div style={{ display: "flex", height: "100%", marginTop: '5px' }}>
                    <div style={{ flexGrow: 1 }}>
                        {pageState.isLoading == false && pageState?.data?.length != 0 ?
                            <CustomDataGrid
                                rows={pageState.data}
                                rowCount={pageState.total}
                                loading={pageState.isLoading == true}
                                // rowsPerPageOptions={[10, 30, 50, 70, 100]}
                                // page={pageState.page - 1}
                                pageSize={7}
                                // paginationMode="server"
                                // onPageChange={(newPage) => setPageState(old => ({ ...old, page: newPage + 1 }))}
                                // onPageSizeChange={(newPageSize) => setPageState(old => ({ ...old, pageSize: newPageSize }))}
                                columns={columns}
                                onEditCellPropsChange={handleEdit}
                                onCellEditCommit={onCellEditCommit}
                                getRowClassName={getRowClassName}
                            />
                            :
                            <CustomDataGrid
                                rows={[]}
                                loading={pageState.isLoading == true}
                                columns={columns}
                                // checkboxSelection
                                getRowClassName={getRowClassName}
                            />
                            // <h4 style={{ textAlign: 'center' }}>Task(s) not available</h4>
                        }
                    </div>
                </div>
            </div>

            {/* <GridItem sm={12} md={12} lg={12}>
                <h4>sample text2</h4>
            </GridItem> */}
            {showAlert}
        </div >

    );
}

export default TaskDetails;
