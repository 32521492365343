import React, { useContext } from "react";
// material-ui
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons

// core components
import Box from "@material-ui/core/Box";

import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle";

import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";

import FormControlLabel from "@material-ui/core/FormControlLabel";
// @material-ui/icons
// core components
import Divider from "@material-ui/core/Divider";
import radioStyles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";

import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Hint from "components/Hint/Hint";
import Paragraph from "components/Paragraph/Paragraph";
import { TestContext } from "contexts/TestContext";
import 'katex/dist/katex.min.css';
import ReactHtmlParser from "react-html-parser";
import Latex from 'react-latex-next';
{/* latex CSS */ }

var styles = {
  ...dashboardStyle,
  ...loginPageStyle,
  ...radioStyles,
  cardTitle: {
    marginTop: "0",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);

export default function MultipleChoiceQuestion({ question, contentType, updateQuestion, preview, qnoes }) {
  const { test } = useContext(TestContext);


  console.log("options", question)
  const [selected, setSelected] = React.useState([]);
  console.log("qAnswer", typeof (question.answer))
  let choices;

  if (isJSON(question.answer)) {
    //BSS121 need to take care for below regx scenario
    // choices = JSON.parse(question.answer)
    choices = question.answer && JSON.parse(question.answer).map((element) => {
      return element?.trim()?.toLowerCase()?.replace(/[(),]/g, '');
    });
    console.log(choices, 'parsed');

  } else {
    let Qanswers = question?.answer?.trim()?.toLowerCase()?.replace(/[(),]/g, '')
    console.log(Qanswers, 'trimmed')
    choices = Qanswers.split(",");
    console.log(choices, 'splitted')
  }
  console.log(choices, 'parsed1');

  function isJSON(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  const handleCheck = (event) => {
    let currentlySelected = [...selected];

    if (event.target.checked === true) {
      currentlySelected.push(event.target.name);
    } else {
      currentlySelected = currentlySelected.filter(
        (x) => x !== event.target.name
      );
    }
    setSelected([...currentlySelected]);

    question.response = currentlySelected;

    question.isCorrect = false;
    console.log(currentlySelected, 'mcqSelected')
    console.log(choices, 'Choices123')
    function isempty(x) {
      if (x !== "")
        return true;
    }
    let choicesLength = choices.filter(isempty)
    console.log(choicesLength, 'choicesLength1234')

    if (choices && currentlySelected.length === choicesLength.length) {
      question.isCorrect = true;
      for (let s of currentlySelected) {
        if (!choicesLength.includes(s.trim().toLowerCase().replace(/[(),]/g, ''))) {
          question.isCorrect = false;
          break;
        }
      }
      console.log("true");
    } else {
      return
    }
  };

  React.useEffect(() => {
    setSelected([]);
    if (question.response) {
      setSelected(question.response);
    }
  }, [question]);

  const classes = useStyles();
  return (
    <div style={{ width: "100%" }}>
      <Box display="flex" p={2}>
        <Box p={2} sx={{ width: "100%" }}>
          <Box display="flex" p={1} style={{ padding: 0 }}>
            <Box p={1} style={{ width: '8%', padding: 0, color: 'black', fontSize: '20px' }}>
              <div style={{ float: 'left', marginRight: '5px', fontSize: '21px' }}> Q {qnoes}. </div>
            </Box>
            <Box p={1} flexGrow={1} style={{ padding: 0, color: 'black', fontSize: '20px' }}>
              {
                question && question.text && question.text.length > 0 ? (contentType == 1 ?
                  <div style={{ marginBottom: '10px' }}>{question.text}</div>
                  : contentType == 3 ?
                    <Latex style={{ color: 'black' }}>{question?.text}</Latex>
                    :
                    contentType == 4 ?
                      <div>{question.text && ReactHtmlParser(question.text)}</div>
                      : <div style={{ marginBottom: '10px' }}>{question.text}</div>
                )
                  : null
              }
              {/* <Box p={2} > */}
              <Box p={1}>
                {question.image && <img src={question.image} alt="..." />}
              </Box>
              {/* </Box> */}
            </Box>
            <Box p={1}>
              {(question?.paragraphImage != null || question?.paragraphText != null)

                ? (
                  <Paragraph messageImage={question?.paragraphImage} messageText={question?.paragraphText} questionId={question.id} />
                ) : null}
              {test && test.showHint == 2 ? null : (
                <Hint message={question.hint} ContentType={contentType} questionId={question.id} />
              )}
            </Box>
          </Box>
          {/* have added below 1 line for horizontal choices */}
          <GridContainer alignItems="center" spacing={1}>
            {
              question.options["$values"]
                .filter((x) => x != null && x != "")
                .map((x, i) => (
                  //have added below 1 line for horizontal choices
                  <GridItem xs={11} sm={11} md={6}>
                    <GridContainer alignItems="center" key={i} spacing={1}>
                      <GridItem xs={10} sm={10} md={10}>
                        <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          }
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                tabIndex={-1}
                                onClick={handleCheck}
                                checked={
                                  question.response &&
                                    question.response.indexOf(x) !== -1
                                    ? true
                                    : false
                                }
                                checkedIcon={
                                  <Check className={classes.checkedIcon} />
                                }
                                icon={<Check className={classes.uncheckedIcon} />}
                                classes={{
                                  checked: classes.checked,
                                  root: classes.checkRoot,
                                }}
                                name={x}
                                disabled={question.isSubmitted}
                              />
                            }
                            classes={{ label: classes.label }}
                            label={
                              contentType == 1 ?
                                x : contentType == 3 ?
                                  <Latex style={{ color: 'black' }}>{x}</Latex> :
                                  contentType == 4 ?
                                    ReactHtmlParser(x) :
                                    x
                            }
                          />
                        </div>
                      </GridItem>
                      {(question.isSubmitted == true || preview == true) ? (
                        <GridItem xs={1} sm={1} md={1}>
                          {choices && choices.indexOf(x.trim().toLowerCase().replace(/[(),]/g, '')) !== -1 ? (
                            <CheckIcon style={{ color: "green" }} />
                          ) : (
                            question.isCorrect == false && !preview && <CloseIcon style={{ color: "red" }} />
                          )}
                        </GridItem>
                      ) : null}
                      <GridItem xs={11} sm={11} md={11}>
                        <Divider />
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                ))
            }

          </GridContainer>

        </Box>
      </Box>
    </div>
  );
}
