import { InputAdornment, Snackbar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import MuiAlert from "@material-ui/lab/Alert";
import axios from "axios";
import Button from "components/CustomButtons/Button";
import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { configUrl } from "config";
import { LoginContext } from "contexts/LoginContext";
import { useContext, useEffect, useState } from "react";
import getPoolsByTestId from "services/SimplifiedTestService/getPoolsByTestId";
import isCodeandTextExist from "services/TestService/isCodeandTextExist";
import quickTestService from "services/TestService/quickTestService";

const styles = {
    infoText: {
        fontWeight: "300",
        margin: "10px 0 30px",
        textAlign: "center",
    },
    inputAdornmentIcon: {
        color: "#555",
    },
    inputAdornmentDisabled: {
        color: "#b2b8be !important",
    },
    inputAdornment: {
        position: "relative",
    },
};
const useStyles = makeStyles(styles);

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export default function TestProperties(props) {
    const { user, providerId } = useContext(LoginContext);
    const classes = useStyles();
    // const { data: poolsRaw, isLoading, refetch } = useGetPoolsByTest(props?.testData?.testId);
    // console.log(poolsRaw, 'poolsCount')
    const [testId, setTestId] = useState(props?.testData?.testId ?? 0);
    const [text, settext] = useState(props?.testData?.text ?? "");
    const [textState, settextState] = useState("");
    const [passingScore, setPassingScore] = useState(props?.testData?.passingScore ?? 0);
    const [passingScoreState, setPassingScoreState] = useState("");
    const [duration, setDuration] = useState(props?.testData?.duration ?? 180);
    const [durationState, setDurationState] = useState("");
    const [pointsPerQuestion, setPointsPerQuestion] = useState(props?.testData?.pointsPerQuestion ?? 0);
    const [pointsPerQuestionsState, setPointsPerQuestionState] = useState("");
    const [negativePointsPerQuestion, setNegativePointsPerQuestion] = useState(props?.testData?.negativePointsPerQuestion ?? 0);
    const [negativePointsPerQuestionsState, setNegativePointsPerQuestionState] = useState("");
    const [saving, setIsSaving] = useState(false);
    const [open, setOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);
    const [severity, setSeverity] = useState("success");

    const [testObject, setTestObject] = useState(
        {
            testId: testId,
            testName: "",
            duration: 0,
            passingScore: 0,
            pointsPerQuestion: 0,
            negativePointsPerQuestion: -1,
            passFeedback: "Passed",
            failFeedback: "Failed",
            masteryFeedback: "Great",
            welcomeMessage: "Welcome",
            concludeMessage: "Thanks",
            CreatedBy: user,
            UpdatedBy: user
        }
    )
    const verifyLength = (value, length) => {
        if (!value) return false;
        if (value.length >= length) {
            return true;
        }
        return false;
    };
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setAlertMessage(null);
        setOpen(false);
    };
    const showSnackBar = (message, sev) => {
        setSeverity(sev ?? "success");
        setAlertMessage(message);
        setOpen(true);
    };
    const isValidate = () => {
        console.log(text, '', text?.length, 'textlength')
        if (text?.length < 5) {
            settextState('error');
        } else {
            settextState('success');
        }
        if (passingScore <= 0) {
            setPassingScoreState('error');
        } else {
            setPassingScoreState('success');
        }
        if (duration <= 0) {
            setDurationState('error');
        } else {
            setDurationState('success');
        }
        if (pointsPerQuestion <= 0) {
            setPointsPerQuestionState('error');
        } else {
            setPointsPerQuestionState('success');
        }
        if (negativePointsPerQuestion > 0) {
            setNegativePointsPerQuestionState('error');
        } else {
            setNegativePointsPerQuestionState('success');
        }
        return true
    }
    useEffect(() => {
        isValidate();
    }, [])

    const createPool = async (testId) => {
        let obj = {}
        obj.ProviderId = providerId,
            // obj.PoolId = model.poolId,
            obj.Code = "",
            obj.PoolName = text,
            obj.QuestionCount = 5,
            obj.PointsPerQuestion = 0,
            obj.NegativePointsPerQuestion = 0,
            obj.PassingScore = 0,
            obj.IsMandatoryToPass = false,
            obj.CreatedBy = user,
            obj.UpdatedBy = user

        let response = await axios.put(
            `${configUrl.AssessmentServer}/api/SimplifiedTest/createPool/${testId}`,
            obj
        );
        setIsSaving(false);

        console.log(response, 'poolupdated resp data');
        if (response.status == 200 || response.status == 201) {
            showSnackBar('Details saved successfully');
            setTimeout(() => {
                props.setTab(1)
            }, 2000);
        } else {
            showSnackBar('Default pool not created');
            setTimeout(() => {
                props.setTab(1)
            }, 2000);
        }
    };

    const handleSaveTestProperties = async () => {
        console.log(textState, 'textState', passingScoreState, 'passingScoreState', durationState, 'durationState', pointsPerQuestionsState, 'pointsPerQuestionsState', negativePointsPerQuestionsState, 'negativePointsPerQuestionsState')
        console.log(textState == 'success', 'textState', passingScoreState == 'success', 'passingScoreState', durationState == 'success', 'durationState', pointsPerQuestionsState == 'success', 'pointsPerQuestionsState', negativePointsPerQuestionsState == 'success', 'negativePointsPerQuestionsState')
        if (textState == 'success' && passingScoreState == 'success' && durationState == 'success' && pointsPerQuestionsState == 'success' && negativePointsPerQuestionsState == 'success') {
            console.log(typeof (duration), typeof (passingScore), typeof (pointsPerQuestion), typeof (negativePointsPerQuestion), 'typeof')
            console.log(typeof (duration), typeof (passingScore), typeof (pointsPerQuestion), typeof (negativePointsPerQuestion), 'typeof')
            testObject.testName = text;
            testObject.duration = parseInt(duration);
            testObject.passingScore = parseInt(passingScore);
            testObject.pointsPerQuestion = parseInt(pointsPerQuestion);
            testObject.negativePointsPerQuestion = parseFloat(negativePointsPerQuestion);
            console.log(typeof (testObject.duration), typeof (testObject.passingScore), typeof (testObject.pointsPerQuestion), typeof (testObject.negativePointsPerQuestion), 'typeof')
            console.log(testObject, 'testObject');
            setIsSaving(true);
            console.log(testId, 'testIds')
            let isTesNameExist = await isCodeandTextExist.dupulicateTextCheck(testId, testObject.testName);
            if (testId == 0 && isTesNameExist == true) {
                showSnackBar('Test name already exist.', 'error');
                setIsSaving(false);
                return;
            } else {
                console.log('name is unique.')
            }
            let response = await quickTestService.getQuickTestData(testObject);
            // setIsSaving(false);

            testObject.testId = response?.data?.testId;
            setTestId(response?.data?.testId);
            console.log(response, 'resp1234')
            props.testId(response?.data?.testId, text);

            if (response.status == 200) {
                let a = await getPoolsByTestId.getPoolsByTestId(response?.data?.testId);
                console.log(a, 'getting poolInfo')
                if (a.length == 0) {
                    createPool(response?.data?.testId);
                } else {
                    showSnackBar('Details saved successfully');
                    setTimeout(() => {
                        props.setTab(1)
                    }, 2000);
                    setIsSaving(false);
                }
                // showSnackBar('Details saved successfully');

                // props.setTab(1)

            }
            console.log(response, 'response')
            console.log(response?.data, 'responseOfData')
        }

    }
    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                    success={textState === "success"}
                    error={textState === "error"}
                    labelText={
                        <span>
                            Test Name <small>(required)</small>
                        </span>
                    }
                    labelProps={{
                        shrink: text,
                    }}
                    id="testText"
                    formControlProps={{
                        fullWidth: true,
                    }}
                    inputProps={{
                        onChange: (event) => {
                            // if (!verifyLength(event.target.value, 3)) {
                            if (event.target.value.length > 5) {
                                settextState("success");
                            } else {
                                settextState("error");
                            }
                            settext(event.target.value);
                        },
                        value: text,
                        endAdornment: (
                            <InputAdornment position="end" className={classes.inputAdornment}>
                                <PermIdentityIcon className={classes.inputAdornmentIcon} />
                            </InputAdornment>
                        ),
                    }}
                    disabled={saving ? true : false}
                />
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
                <CustomInput
                    success={passingScoreState === 'success'}
                    error={passingScoreState === 'error'}
                    labelText={<span>Passing Score (Cut-off)</span>}
                    id="passingScore"
                    formControlProps={{
                        fullWidth: true,
                    }}
                    inputProps={{
                        type: "number",
                        onChange: (event) => {
                            if (event.target.value > 0) {
                                setPassingScore(event.target.value);
                                setPassingScoreState('success');
                            } else if (event.target.value < 0) {
                                setPassingScoreState('error');
                                setPassingScore('');
                            }
                            else {
                                setPassingScore('');
                                setPassingScoreState('error');
                            }
                        },
                        value: passingScore,
                    }}
                    disabled={saving ? true : false}
                />
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
                <CustomInput
                    success={durationState === "success"}
                    error={durationState === "error"}
                    labelText={<span>Duration <small> Range 1-360 minutes (required)</small></span>}
                    id="duration"
                    formControlProps={{
                        fullWidth: true,
                    }}
                    inputProps={{
                        type: "number",
                        onChange: (event) => {
                            if (event.target.value > 0 && event.target.value <= 360) {
                                setDuration(event.target.value);
                                setDurationState('success');
                            } else if (event.target.value < 0 && event.target.value > 360) {
                                setDurationState('error');
                                setDuration('');
                            }
                            else {
                                setDuration('');
                                setDurationState('error');
                            }
                        },
                        value: duration,
                    }}
                    disabled={saving ? true : false}
                />
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
                <CustomInput
                    success={pointsPerQuestionsState === "success"}
                    error={pointsPerQuestionsState === "error"}
                    labelText={<span>Points per Question <small>(required)</small></span>}
                    id="pointsPerQuestion"
                    formControlProps={{
                        fullWidth: true,
                    }}
                    inputProps={{
                        type: "number",
                        onChange: (event) => {
                            if (event.target.value > 0) {
                                setPointsPerQuestion(event.target.value);
                                setPointsPerQuestionState('success');
                            } else {
                                setPointsPerQuestionState('error');
                                setPointsPerQuestion('');
                            }
                            // setPointsPerQuestion(event.target.value);
                        },
                        value: pointsPerQuestion,
                    }}
                    disabled={saving ? true : false}
                />
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
                <CustomInput
                    success={negativePointsPerQuestionsState === "success"}
                    error={negativePointsPerQuestionsState === "error"}
                    labelText={<span>Negative Points per Question</span>}
                    id="negativePointsPerQuestion"
                    formControlProps={{
                        fullWidth: true,
                    }}
                    inputProps={{
                        type: "number",
                        onChange: (event) => {

                            if (event.target.value <= 0) {
                                setNegativePointsPerQuestion(event.target.value);
                                setNegativePointsPerQuestionState('success');
                            } else {
                                setNegativePointsPerQuestionState('error');
                                setNegativePointsPerQuestion('');
                            }
                            //setNegativePointsPerQuestion(event.target.value);
                        },
                        value: negativePointsPerQuestion,
                    }}
                    disabled={saving ? true : false}
                />
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>

            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
                <Button round small color="primary" disabled={saving ? true : false} onClick={() => handleSaveTestProperties()} style={{ textTransform: 'none', float: 'right' }}>
                    {
                        saving ? 'Saving...' :
                            'Save & Next'
                    }
                </Button>
            </GridItem>
            <Snackbar
                open={open}
                autoHideDuration={2000}
                onClose={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
                <Alert onClose={handleClose} severity={severity}>
                    {alertMessage}
                </Alert>
            </Snackbar>
        </GridContainer>
    )
}
