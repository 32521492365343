import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import React, { useContext } from "react";

import EditIcon from "@material-ui/icons/Edit";
import Button from "components/CustomButtons/Button.js";

import CustomDataGrid from "components/CustomDataGrid.js/CustomDataGrid";
import CustomModal from "components/CustomModal/CustomModal";


import {
  CircularProgress,
  FormControlLabel,
  Popover,
  Switch
} from "@material-ui/core";
import CopyIcon from "@material-ui/icons/FileCopy";
import InfoIcon from "@material-ui/icons/Info";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import ScheduleIcon from "@material-ui/icons/Schedule";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import useTestLockUnLock from "hooks/testsHooks/useTestLockUnLock";
import TestCopyComponent from "./TestCopyComponent";
import TestDetailsComponent from "./TestDetailsComponent";
import TestScheduleComponent from "./TestScheduleComponent";
import TestUserMappingGrid from "./TestUserMappingGrid";


import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import { ClearAllOutlined, CloseOutlined, ListAltOutlined, Publish, SearchOutlined, TimelineOutlined, WarningOutlined } from "@material-ui/icons";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import MuiAlert from "@material-ui/lab/Alert";
import { ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import CustomInput from "components/CustomInput/CustomInput";
import CustomSelect from "components/CustomSelect/CustomSelect";
import PreviewComponent from "components/PreviewComponent/PreviewComponent";
import { LoginContext } from "contexts/LoginContext";
import useGetAllObjective from "hooks/configurationsHooks/useGetAllObjective";
import useGetAllConfigurations from "hooks/configurationsHooks/useGetAllTargetAudience";
import useDeleteTest from "hooks/testsHooks/useDeleteTest";
import useTestBulkUpdate from "hooks/testsHooks/useTestBulkUpdate";
import useUserDataCleanup from "hooks/testsHooks/useUserDataCleanup";
import SweetAlert from "react-bootstrap-sweetalert";
import { withRouter } from "react-router-dom";
import generateTestService from "services/TestService/generateTestService";
import getAllTests from "services/TestService/getAllTests";
import testScheduleDetailsService from "services/TestService/testScheduleDetailsService";
import testService from "services/TestService/testService";
import styles from '../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js';
import Analytics from "./Analytics";
import OMRSheetsUpload from "./OMRSheetsUpload";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
// const useStyles = makeStyles(styles);
// function CustomToolbar() {
//   return (
//     <GridToolbarContainer>
//       <GridToolbarColumnsButton />
//       <GridToolbarFilterButton/>
//       <GridToolbarExport />
//     </GridToolbarContainer>
//   );
// }

const useStyles = makeStyles((theme) => ({
  ...styles,
  tooltip: {
    fontSize: "1em",
  },
}));

function TestHomeComponent(props) {
  const classes = useStyles();
  var enableSearch = false;
  let Status;
  let difficultyLevel;
  let showHint;
  let showBookmark;
  let navigationAllowed;
  let SearchedObjective;
  let SearchedTargetAudience;

  let configurationType = 'Objective';
  let configurationTypeAudience = 'TargetAudience';
  let isActive = true;
  let courseId = null;
  if (props.location && props.location.state) {

    courseId = props.location.state.state;
  }
  console.log(courseId, 'stateinTestHome')
  const { data: objectiveResponse } = useGetAllObjective(configurationType, isActive, true);
  const { data: targetAudienceResponse } = useGetAllConfigurations(configurationTypeAudience, isActive, true)
  const update = useTestBulkUpdate();

  const { user, userPrivileges, providerId, licensePackageObject } = useContext(LoginContext);
  const deleteTest = useDeleteTest();
  const cleanupTestUsers = useUserDataCleanup();


  const lockUnlock = useTestLockUnLock();
  const [objective, setObjectiveOptions] = React.useState([""]);
  const [targetAudience, setTargetAudience] = React.useState([""]);
  const [testEditModal, setTestEditModal] = React.useState(null);
  const [testSchedule, setTestSchedule] = React.useState(null);
  const [testCopy, setTestCopy] = React.useState(null);
  //detailsstates
  const [testDetails, setTestDetails] = React.useState(null);

  const [analyticsModal, setAnalyticsModel] = React.useState(null);

  const [testUserMap, setTestUserMap] = React.useState(null);
  const [quickTestModal, setQuickTestModal] = React.useState(null);
  const [popOver, setPopOver] = React.useState(null);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [columns, setColumns] = React.useState([]);
  const [select, setSelection] = React.useState([]);

  const [bulkOption, setBulkOption] = React.useState({});
  const [bulkOptions, setBulkOptions] = React.useState([]);

  const [bulkValue, setBulkValue] = React.useState("");
  const [alert, setAlert] = React.useState(null);
  const [contentFilter, setContentFilter] = React.useState('0')

  const [open, setOpen] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState(null);
  const [severity, setSeverity] = React.useState("success");
  const [reloadGrid, setReloadGrid] = React.useState(false);
  const [showPoolError, setShowPoolError] = React.useState('');
  const [pageState, setPageState] = React.useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 10
  })

  const fetchData = async (pageNumber, pageSize, Status, difficultyLevel, showHint, showBookmark, navigationAllowed, SearchedObjective, SearchedTargetAudience, contentFilter) => {
    setPageState(old => ({ ...old, isLoading: true }))
    let getOjbectives = await getAllTests.getAllTests(pageNumber, pageSize, Status, difficultyLevel, showHint, showBookmark, navigationAllowed, SearchedObjective, SearchedTargetAudience, courseId, contentFilter, 0);
    console.log(getOjbectives, 'getObjective1234')
    setPageState(old => ({ ...old, isLoading: false, data: getOjbectives, total: getOjbectives?.length > 0 ? getOjbectives[0].totalCount : 0 }))
  }

  const reloadGridFunction = () => {
    console.log("reloadinfunction", reloadGrid);
    filterValues(pageState.page, pageState.pageSize, contentFilter);
  };

  const showPoolErrors = (response) => {
    // setShowPoolError(response)
    console.log(response, 'resp123');
    if (response == undefined) {
      showSnackBar("Question assigned to pool and topics are less than question count provided", 'warning')
    } else {
      showSnackBar(`${response}`, 'error');
    }
  }

  const filterValues = (pageNumber, pageSize, contentFilter) => {
    if (enableSearch == false) {
      if (bulkOption.name == 'Difficulty Level') {
        difficultyLevel = bulkValue;
      }
      if (bulkOption.name == 'Status') {
        Status = bulkValue;
      }
      if (bulkOption.name == 'Objective') {
        if (bulkValue == JSON.stringify("none")) {
          SearchedObjective = JSON.parse(bulkValue);
        } else {
          SearchedObjective = bulkValue;
        }

      }
      if (bulkOption.name == 'Target Audience') {
        if (bulkValue == JSON.stringify("none")) {
          SearchedTargetAudience = JSON.parse(bulkValue);
        } else {
          SearchedTargetAudience = bulkValue;
        }
      }
      if (bulkOption.name == 'Navigation Allowed') {
        navigationAllowed = bulkValue;
      }
      if (bulkOption.name == 'Show Hint') {
        showHint = bulkValue;
      }
      if (bulkOption.name == 'Show Bookmark') {
        showBookmark = bulkValue;
      }
    }
    else {
      Status = 0;
      difficultyLevel = 0;
      showHint = 0;
      showBookmark = 0;
      navigationAllowed = 0;
      SearchedObjective = null;
      SearchedTargetAudience = null;
    }
    fetchData(pageNumber, pageSize, Status, difficultyLevel, showHint, showBookmark, navigationAllowed, SearchedObjective, SearchedTargetAudience, contentFilter);
  }
  React.useEffect(() => {
    if (pageState.page > 0) {
      filterValues(pageState.page, pageState.pageSize, contentFilter);
    }
  }, [pageState.page, pageState.pageSize, deleteTest.isSuccess, cleanupTestUsers.isSuccess, reloadGrid])

  console.log(pageState.data, 'orgTest124')
  const handleSearch = () => {
    enableSearch = false
    if (pageState.page > 1) {
      setPageState({ pageSize: 10, page: 1 })
    } else {
      filterValues(1, pageState.pageSize, contentFilter);
    }
  }
  const handleClear = () => {
    // setBulkOption({});
    handleBulkOptionFieldChange(0);
    // setBulkValue(null);  //
    enableSearch = true;
    if (pageState.page > 1) {
      setPageState({ pageSize: 10, page: 1 })
    } else {
      filterValues(1, 10, contentFilter);
    }
  }

  React.useEffect(() => {
    if (objectiveResponse && objectiveResponse.length > 0) {
      setObjectiveOptions(
        objectiveResponse.map((x) => {
          return {
            id: x.id,
            name: x.valueString,
          };
        })
      );
    }
  }, [objectiveResponse]);

  const removeTest = (id) => {
    deleteTest.mutate(id);

  }

  const cleanupUsers = (id) => {
    cleanupTestUsers.mutate(id)
  }
  React.useEffect(() => {
    if (targetAudienceResponse && targetAudienceResponse.length > 0) {
      setTargetAudience(
        targetAudienceResponse.map((x) => {
          return {
            id: x.id,
            name: x.valueString,
          };
        })
      );
    }
  }, [targetAudienceResponse]);

  const handleInputChange = (e) => {
    setBulkValue(e.target.value);
  };

  const getCustomInput = (type) => {
    return (
      <CustomInput
        formControlProps={{
          fullWidth: true,
        }}
        inputProps={{
          fullWidth: true,
          onBlur: handleInputChange,
          type: type,
          autoComplete: "off",
        }}
      />
    );
  };

  const getCustomSelect = (options) => {
    if (options.length <= 0) return;
    return (
      <CustomSelect
        options={options.map((x) => ({ id: x.id, name: x.name }))}
        setvalue={(v) => {
          handleInputChange({ target: { value: v } });
        }}
      />
    );
  };

  const getCustomSelectForDropdown = (options, showNotConf) => {
    if (options.length <= 0) return;
    return (
      <CustomSelect
        options={options.map((x) => ({ id: x.id, name: x.name }))}
        setvalue={(v) => handleInputChange({ target: { value: JSON.stringify(v) } })}
        showNotConf={showNotConf === true ? true : false}
      />
    );
  };

  const isPrivilege = (x) => {
    if (userPrivileges?.Privileges?.Test.indexOf(x) !== -1) {
      return true;
    }
    else {
      return false;
    }
  }

  const getCustomSwitch = (label) => {
    return (
      <FormControlLabel
        control={
          <Switch
            name="switch"
            //value={false}
            onChange={(e) => {
              handleInputChange({
                target: { value: e.target.checked ? 1 : 0 },
              });
            }}
            checked={false}
          />
        }
        label={<small>{label}</small>}
      />
    );
  };

  const updateTests = (selected, tests, option, value) => {

    // let candidates = tests.filter((x) => selected.includes(x.testId));

    // candidates.forEach((x) => {
    //   x.code = x.testCode;
    //   x[option["key"]] = value;
    //   x["updatedBy"] = user;
    //   x["updatedDate"] = new Date();

    //   if (option["key"] == "isLocked") {
    //     x["lockedBy"] = user;
    //     x["lockedDate"] = new Date();
    //   }
    //   if (option["key"] == "showHint") {
    //     x[option["key"]] = 1;
    //   }
    // });
    // console.log(candidates, 'modifiedcand')
    if (value == JSON.stringify("none")) {
      value = JSON.parse(value);
    }
    update.mutate({ selected, option, value });
    //await getQ();
  };

  React.useEffect(() => {

    let cols = [
      {
        field: "testId",
        headerName: "Id",
        editable: false,
        hide: true,
        width: 100,
      },
      {
        field: "testCode",
        headerName: "Code",
        editable: false,
        width: 180,
      },
      {
        field: "text",
        headerName: "Name",
        editable: false,
        width: 200,
        renderCell: (cellValues) => {
          console.log(cellValues, 'cells')
          return (
            <Tooltip classes={{ tooltip: classes.tooltip }} title={cellValues.row.text ?? 'none'}>
              <span>
                {cellValues.row.text ?
                  cellValues.row.text?.substr(0, 22) : 'none'
                }
              </span>
            </Tooltip>
          )
        }
      },
      {
        field: "providerName",
        headerName: "Provider Name",
        editable: false,
        width: 200,
        resizable: true,
      },
      {
        field: "status",
        headerName: "Status",
        editable: false,
        width: 130,
        renderCell: (cellValues) => {
          return (
            <span>
              {cellValues.row.status == 1
                ? "Draft"
                : cellValues.row.status == 2
                  ? "Ready"
                  : cellValues.row.status == 3
                    ? "Published"
                    : cellValues.row.status == 4
                      ? "Scheduled"
                      : cellValues.row.status == 5
                        ? "In Progress"
                        : cellValues.row.status == 6
                          ? "Completed"
                          : "Terminated"
              }
            </span>
          )
        }
      },
      {
        field: "isActive",
        headerName: "Is Live",
        editable: false,
        hide: true,
        width: 150,
      },
      {
        field: "difficultyLevel",
        headerName: "Difficulty Level",
        editable: false,
        width: 180,
        renderCell: (cellValues) => {
          if (!cellValues.row.difficultyLevel) {
            return "Medium";
          }
          return cellValues.row.difficultyLevel == "1"
            ? "Easy"
            : cellValues.row.difficultyLevel == "2"
              ? "Medium"
              : "Difficult";
        },
      },

      {
        field: "expiryDate",
        headerName: "Expiry Date",
        editable: false,
        hide: true,
        width: 200,
      },
      {
        field: "objectiveText",
        headerName: "Objective",
        editable: false,
        width: 150,
      },
      {
        field: "targetAudienceText",
        headerName: "Target Audience",
        editable: false,
        width: 180,
      },
      {
        field: "scheduleDate",
        headerName: "Scheduled Date",
        editable: false,
        width: 200,
        renderCell: (cellValues) => {
          if (cellValues.row.scheduleDate) {
            console.log(typeof (cellValues.row.scheduleDate), 'dates', cellValues.row.scheduleDate)

            function getDiff() {
              const currentDate = new Date(); // Current date in local timezone
              const currentUtcDate = new Date(currentDate.toUTCString());

              const timezoneOffsetLocal = currentDate.getTimezoneOffset(); // Offset in minutes
              const timezoneOffsetUTC = currentUtcDate.getTimezoneOffset(); // Offset in minutes

              const offsetHoursLocal = Math.abs(timezoneOffsetLocal / 60);
              const offsetMinutesLocal = Math.abs(timezoneOffsetLocal % 60);

              const offsetHoursUTC = Math.abs(timezoneOffsetUTC / 60);
              const offsetMinutesUTC = Math.abs(timezoneOffsetUTC % 60);

              console.log(`UTC offset: ${offsetHoursUTC} hours ${offsetMinutesUTC} minutes`);
              console.log(`Local timezone offset: ${offsetHoursLocal} hours ${offsetMinutesLocal} minutes`);
              return offsetHoursLocal;
            }
            // converting into local time start
            function formatDateToCustomString(date) {
              const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
              const day = String(date.getDate()).padStart(2, "0");
              const month = months[date.getMonth()];
              const year = date.getFullYear();
              // let hours = date.getHours();
              let hours = String(date.getHours()).padStart(2, "0");
              const minutes = String(date.getMinutes()).padStart(2, "0");
              const seconds = String(date.getSeconds()).padStart(2, "0");
              const meridiem = hours >= 12 ? "PM" : "AM";
              hours %= 12;
              hours = hours || 12; // Handle midnight (0 hours)

              return `${day}-${month}-${year} ${hours}:${minutes}:${seconds} ${meridiem}`;
            }
            const utcDateTime = new Date(cellValues.row.scheduleDate);
            let diff = getDiff();
            console.log(diff, 'inDiff')
            const istOffset = diff * 60 * 60 * 1000; // IST is UTC+5.5 hours
            const istDateTime = new Date(utcDateTime.getTime() + istOffset);
            console.log('originalCellrows', cellValues.row)
            console.log(istDateTime, 'convertedDate', 'original', cellValues.row.scheduleDate)
            const formattedDateTime = formatDateToCustomString(istDateTime);
            return formattedDateTime;

            // 2.
            // getDiff();
            // const dateTime = new Date(cellValues.row.scheduleDate);

            // const utcDateTimeString = '2024-07-16T12:34:56Z'; // Example UTC datetime string
            // const utcDate = new Date(dateTime);

            // const options = {
            //   day: '2-digit',
            //   month: 'short',
            //   year: 'numeric',
            //   hour: '2-digit',
            //   minute: '2-digit',
            //   second: '2-digit',
            //   hour12: true // Use 12-hour format (AM/PM)
            // };

            // const localDateTimeString = utcDate.toLocaleString(undefined, options);
            // return localDateTimeString;
            // console.log(localDateTimeString, 'localTimeString')

            // 3.
            // // Get individual date and time components
            // const day = dateTime.getDate();
            // const month = dateTime.toLocaleString('default', { month: 'short' });
            // const year = dateTime.getFullYear();
            // let hours = dateTime.getHours();
            // const minutes = String(dateTime.getMinutes()).padStart(2, '0');
            // const seconds = String(dateTime.getSeconds()).padStart(2, '0');
            // const ampm = hours >= 12 ? 'PM' : 'AM';
            // hours = hours % 12;
            // hours = String(hours ? hours : 12).padStart(2, '0');

            // return `${day}-${month}-${year} ${hours}:${minutes}:${seconds} ${ampm}`;
          } else {
            return "none"
          }
          // return cellValues.row.scheduleDate
          //   ? cellValues.row.scheduleDate.slice(0, 19).replace("T", " ")
          //   : <i> none </i>;
        },
      },
      {
        field: "welcomeMessage",
        hide: true,
      },
      {
        field: "concludeMessage",
        hide: true,
      },
      {
        field: "description",
        hide: true,
      },
      {
        field: "attemptsAllowed",
        hide: true,
      },

      {
        field: "action",
        headerName: "Actions",
        editable: false,
        width: 350,
        filterable: false,
        sortable: false,

        renderCell: (cellValues) => (
          <>
            {
              isPrivilege("UpdateTest") == true ?
                <Tooltip title="Edit">
                  <Button
                    size="sm"
                    justIcon
                    round
                    // color="reddit"
                    onClick={(e) => {
                      e.preventDefault();

                      showTestEditModal(cellValues.row);
                    }}
                    style={{ size: "1px", backgroundColor: "#DD4B39" }}
                    disabled={cellValues.row.providerId == providerId ? cellValues.row.status != 3 ? cellValues.row.scheduleStatus != 5 ? false : true : true : true}
                  // disabled={cellValues.row.providerId == providerId ? cellValues.row.isLocked == true ? cellValues.row.lockedBy == user ? false : true : cellValues.row.status >= 5 ? true : false : true}
                  >
                    <EditIcon />
                  </Button>
                </Tooltip> : ''
            }
            {
              // TODO
              isPrivilege("PreviewTest") == true ?
                <PreviewComponent reloadGridFunction={reloadGridFunction} showPoolError={showPoolErrors} reloadGridState={reloadGrid} isShowPublish={isPrivilege("PublishTest") == true && cellValues.row.providerId == providerId ? true : false} testStatus={cellValues?.row?.status} testName={cellValues?.row?.text} testId={cellValues.row.testId} multiple={true} /> : ''
            }


            { //starting............
              isPrivilege("PreviewTest") == true ?
                <Tooltip title="Test Details">
                  <Button
                    size="sm"
                    justIcon
                    round
                    // color="twitter"
                    onClick={(e) => {
                      e.preventDefault();

                      showTestDetailsModal(cellValues.row);
                      // handleTestDetails();

                    }}
                    style={{ size: "1px", backgroundColor: "#DD4B39" }}
                  // disabled={cellValues.row.isLocked == true ? true : !cellValues.row.isActive}
                  >
                    <InfoIcon />
                  </Button>
                </Tooltip> : ''
            }
            {
              isPrivilege("AssignStudents") == true ?
                <Tooltip title="Assign To Student">
                  <Button
                    size="sm"
                    justIcon
                    round
                    // color="google"
                    onClick={(e) => {
                      e.preventDefault();
                      showTestUserMapModal(cellValues);
                    }}
                    style={{ size: "1px", backgroundColor: "#DD4B39" }}
                  >
                    <PersonAddIcon />
                  </Button>
                </Tooltip> : ''
            }

            {
              isPrivilege("AssignTest") == true ?
                <Tooltip title="Schedule">
                  <Button
                    size="sm"
                    justIcon
                    round
                    // color="twitter"
                    onClick={(e) => {
                      e.preventDefault();
                      showTestScheduleModal(cellValues.row);
                    }}
                    style={cellValues.row.scheduleStatus == 4 ? { size: "1px", backgroundColor: "#53AD57" } : cellValues.row.scheduleStatus == 5 ? { size: "1px", backgroundColor: "#FD940C" } : { size: "1px", backgroundColor: "#dd4b39" }}
                    disabled={(cellValues.row.isLocked == true ? true : !cellValues.row.isActive) || cellValues.row.status == 1}
                  >
                    <ScheduleIcon />
                  </Button>
                </Tooltip> : ''
            }
            {
              isPrivilege("AssignTestForContentCurator") == true ?
                <Tooltip title="Schedule">
                  <Button
                    size="sm"
                    justIcon
                    round
                    // color="twitter"
                    onClick={(e) => {
                      e.preventDefault();
                      setAlert(
                        <SweetAlert
                          style={{ display: "block", marginTop: "-100px" }}
                          title={''}
                          onConfirm={() => assignTest(cellValues.row)}
                          showCancel={true}
                          cancelBtnText={'CANCEL'}
                          confirmBtnText={'OK'}
                          onCancel={() => setAlert(null)}
                          confirmBtnCssClass={classes.button + " " + classes.success}
                          cancelBtnCssClass={classes.button + " " + classes.warning}
                        >
                          <div style={{ display: 'inline-flex' }}>
                            <WarningOutlined style={{ marginTop: '10px', marginRight: '5px', color: 'brown' }} />
                            <h4> Are you sure you want to schedule test?</h4>
                          </div>
                        </SweetAlert>
                      );
                    }}
                    // style={cellValues.row.scheduleDate != null ? { size: "1px", backgroundColor: "#53AD57" } : { size: "1px", backgroundColor: "#DD4B39" }}
                    style={cellValues.row.scheduleStatus == 4 ? { size: "1px", backgroundColor: "#53AD57" } : cellValues.row.scheduleStatus == 5 ? { size: "1px", backgroundColor: "#FD940C" } : { size: "1px", backgroundColor: "#dd4b39" }}
                    disabled={(cellValues.row.isLocked == true ? true : !cellValues.row.isActive) || cellValues.row.status == 1}
                  >
                    <ScheduleIcon />
                  </Button>
                </Tooltip> : ''
            }
            {
              isPrivilege("CopyTest") == true ?
                <Tooltip title="Copy Test">
                  <Button
                    size="sm"
                    justIcon
                    round
                    // color="google"
                    onClick={(e) => {
                      e.preventDefault();
                      ((cellValues?.row?.totalCount + 1) > licensePackageObject?.Tests) ?
                        showSnackBar(`Your current package allows up to ${licensePackageObject?.Tests} tests.Please upgrade to higher package to create additional tests.`, 'warning') :
                        showTestCopyModal(cellValues.row);
                    }}
                    style={{ size: "1px", backgroundColor: "#DD4B39" }}
                    disabled={cellValues.row.providerId == providerId ? cellValues.row.isLocked == true ? true : false : true}
                  >
                    <CopyIcon />
                  </Button>
                </Tooltip> : ''
            }

            {/* {!cellValues.row.isLocked ? (
            <Tooltip title="Lock">
              <Button
                size="sm"
                justIcon
                round
                color="dodgerblue"
                onClick={(e) => {
                  e.preventDefault();
                  lockUnlock.mutate({ id: cellValues.row.testId, lock: true });
                }}
                disabled = {cellValues.row.status >= 3 ? true : false}
              >
                <Lock />
              </Button>
            </Tooltip>
            ) : (
            <Tooltip title="Unlock">
              <Button
                size="sm"
                justIcon
                round
                color="white"
                onClick={(e) => {
                  e.preventDefault();
                  lockUnlock.mutate({ id: cellValues.row.testId, lock: false });
                }}
                disabled = {cellValues.row.isLocked == true ? cellValues.row.lockedBy == user ? false : true : false}
              >
                <LockOpenIcon />
              </Button>
            </Tooltip>
            )} */}
            {
              isPrivilege("DeleteTest") == true ?
                <Tooltip title="Delete">
                  <Button
                    size="sm"
                    justIcon
                    round
                    color="reddit"
                    onClick={(e) => {
                      e.preventDefault();
                      setAlert(
                        <SweetAlert
                          style={{ display: "block", marginTop: "-100px" }}
                          title={''}
                          onConfirm={() => removeTest(cellValues.row.testId)}
                          showCancel={true}
                          cancelBtnText={'CANCEL'}
                          confirmBtnText={'OK'}
                          onCancel={() => setAlert(null)}
                          confirmBtnCssClass={classes.button + " " + classes.success}
                          cancelBtnCssClass={classes.button + " " + classes.warning}
                        >
                          <div style={{ display: 'inline-flex' }}>
                            <WarningOutlined style={{ marginTop: '10px', marginRight: '5px', color: 'brown' }} />
                            <h4> Are you sure you want to delete this test?</h4>
                          </div>
                        </SweetAlert>
                      );
                    }}
                    disabled={cellValues.row.providerId == providerId ? cellValues.row.isLocked == true ? cellValues.row.lockedBy == user ? false : true : cellValues.row.scheduleStatus >= 3 ? true : false : true}
                  >
                    <DeleteForeverIcon />
                  </Button>
                </Tooltip> : ''
            }
            <Tooltip title="Upload answers">
              <Button
                size="sm"
                justIcon
                round
                color="behance"
                onClick={(e) => {
                  e.preventDefault();
                  showOMRSheetUploaddModal(cellValues.row);
                }}
              >
                <Publish size="1em" />
              </Button>
            </Tooltip>
            {
              isPrivilege("TestAnalytics") == true ?
                <Tooltip title="Analytics">
                  <Button
                    size="sm"
                    justIcon
                    round
                    color="reddit"
                    onClick={() => showAnalytics(cellValues.row.testId, cellValues.row)}
                    disabled={cellValues.row.scheduleStatus != 5}
                  >
                    <TimelineOutlined />
                  </Button>
                </Tooltip> : ''
            }
            {
              isPrivilege("CleanData") == true ?
                <Tooltip title="Clean Data">
                  <Button
                    size="sm"
                    justIcon
                    round
                    color="reddit"
                    onClick={(e) => {
                      e.preventDefault();
                      setAlert(
                        <SweetAlert
                          style={{ display: "block", marginTop: "-100px" }}
                          title={''}
                          onConfirm={() => cleanupUsers(cellValues.row.testId)}
                          showCancel={true}
                          cancelBtnText={'CANCEL'}
                          confirmBtnText={'OK'}
                          onCancel={() => setAlert(null)}
                          confirmBtnCssClass={classes.button + " " + classes.success}
                          cancelBtnCssClass={classes.button + " " + classes.warning}
                        >
                          <div style={{ display: 'inline-flex' }}>
                            <WarningOutlined style={{ marginTop: '10px', marginRight: '5px', color: 'brown' }} />
                            <h4> Are you sure you want to cleanup the test?</h4>
                          </div>
                        </SweetAlert>
                      );
                    }}
                    disabled={cellValues.row.scheduleStatus != 5}
                  >
                    <ClearAllOutlined />
                  </Button>
                </Tooltip> : ''
            }
          </>
        ),
      },
    ];

    setColumns(cols);

    setBulkOptions([
      // {
      //   id: 1,
      //   name: "Is Locked",
      //   inputType: "bool",
      //   key: "isLocked",
      //   updateMethod: updateTests,
      //   component: getCustomSelect([
      //     { id: true, name: "true" },
      //     { id: false, name: "false" },
      //   ]),
      // },
      // {
      //   id: 2,
      //   name: "Expiry Date",
      //   inputType: "date",
      //   key: "expiryDate",
      //   updateMethod: updateTests,
      //   component: getCustomInput("date"),
      // },
      {
        id: 0,
        name: "Select",
        inputType: "text",
        key: "select",
        // updateMethod: updateTests,
        component: getCustomSelect([
          {
            id: 0,
            name: "Select",
          },
        ]),
      },
      {
        id: 3,
        name: "Status",
        inputType: "text",
        key: "status",
        updateMethod: updateTests,
        component: getCustomSelect([
          {
            id: 1,
            name: "Draft",
          },
          {
            id: 2,
            name: "Ready",
          },
          {
            id: 3,
            name: "Published",
          },
          {
            id: 4,
            name: "Scheduled",
          },
          {
            id: 5,
            name: "In Progress"
          }
        ]),
      },
      {
        id: 4,
        name: "Difficulty Level",
        inputType: "test",
        key: "difficultyLevel",
        updateMethod: updateTests,
        component: getCustomSelect([
          {
            id: 1,
            name: "Easy",
          },
          {
            id: 2,
            name: "Medium",
          },
          {
            id: 3,
            name: "Difficult",
          },
        ]),
      },
      {
        id: 5,
        name: "Show Hint",
        inputType: "bool",
        key: "showHint",
        updateMethod: updateTests,
        component: getCustomSelect([
          { id: 2, name: "True" },
          { id: 1, name: "False" },
        ]),
      },
      {
        id: 6,
        name: "Show Bookmark",
        inputType: "bool",
        key: "showBookmark",
        updateMethod: updateTests,
        component: getCustomSelect([
          { id: 2, name: "True" },
          { id: 1, name: "False" },
        ]),
      },
      // {
      //   id: 6,
      //   name: "Owner",
      //   inputType: "text",
      //   key: "owner",
      //   updateMethod: updateTests,
      //   component: getCustomInput("text"),
      // },
      {
        id: 7,
        name: "Navigation Allowed",
        inputType: "bool",
        key: "navigationAllowed",
        updateMethod: updateTests,
        component: getCustomSelect([
          { id: 2, name: "True" },
          { id: 1, name: "False" },
        ]),
      },
      {
        id: 8,
        name: "Objective",
        inputType: "text",
        key: "objective",
        updateMethod: updateTests,
        component: getCustomSelectForDropdown(objective, true),
      },

      {
        id: 9,
        name: "Target Audience",
        inputType: "bool",
        key: "targetAudience",
        updateMethod: updateTests,
        component: getCustomSelectForDropdown(targetAudience, true)
      }
    ]);
  }, [objective, targetAudience]);

  const showPopover = (cellValues) => {
    setPopOver(
      <Popover
        style={{ width: 600 }}
        id="popover"
        anchorPosition={{ left: -900 }}
        open={true}
        anchorEl={anchorEl}
        onClose={hidePopOver}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <TestUserMappingGrid state={cellValues.row} />
      </Popover>
    );
  };

  const hidePopOver = () => {
    setPopOver(null);
  };

  const showTestUserMapModal = (x) => {
    setTestUserMap(
      <CustomModal
        modal={true}
        setModal={() => hideTestUserMapModal()}
        content={
          <TestUserMappingGrid
            state={x?.row}
            afterFinish={() => {
              hideTestUserMapModal();
            }}
          />
        }
        showOk={false}
        showCancel={false}
        onCancelClick={() => hideTestUserMapModal()}
        title={`Test User Mapping: ${x.row.text}`}
        maxWidth="lg"
        fullWidth={true}
      />
    );
  };
  const hideTestUserMapModal = () => {
    setTestUserMap(null);
    // enableSearch = true;
    // if (pageState.page > 1) {
    //   setPageState({ pageSize: 10, page: 1 });
    //   setSelection([]);
    // } else {
    //   filterValues(1, 10, contentFilter);
    //   setSelection([]);
    // }
  };

  const hideQuickTestModal = () => {
    setQuickTestModal(null);
  }

  const showTestScheduleModal = async (test) => {
    console.log(test?.testId, 'testId');
    let testData = await testScheduleDetailsService.getTesScheduletData(test.testId);
    console.log(testData, 'testDataResponce')
    setTestSchedule(
      <CustomModal
        modal={true}
        setModal={() => hideTestScheduleModal()}
        padding1={true}
        content={
          <TestScheduleComponent
            state={test}
            afterFinish={() => {
              hideTestScheduleModal();
              // refetch();
            }}
            scheduleDates={testData}
          />
        }
        showOk={false}
        showCancel={false}
        onCancelClick={() => hideTestScheduleModal()}
        title={`${test.text}`}          //Test Schedule/Assign: 
        maxWidth="sm"
      />
    );
  };
  const assignTest = async (test) => {
    // setIsAssigning(true);
    const startDateTime = null //new Date(scheduleStartDateValue);
    const endDateTime = null //new Date(scheduleEndDateValue);
    const assignedDateTime = new Date();

    let response = await generateTestService.generateTests(test?.testId, startDateTime, endDateTime, assignedDateTime);
    // setIsAssigning(false);
    if (response?.status == 200 || response?.status == 204) {
      setAlert(null);
      showSnackBar("Test assigned successfully")
      // setTimeout(() => {
      //   setAlert(null);
      // }, 2000);
    } else if (response?.status == 400 || response?.status == 404) {
      showSnackBar("Error occured", 'error');
    }
  }

  const hideTestScheduleModal = () => {
    setTestSchedule(null);
    enableSearch = true;
    if (pageState.page > 1) {
      setPageState({ pageSize: 10, page: 1 });
      setSelection([]);
    } else {
      filterValues(1, 10, contentFilter);
      setSelection([]);
    }
  };

  const showTestDetailsModal = (test) => {
    setTestDetails(
      <CustomModal
        modal={true}
        setModal={() => hideTestDetailsModal()}
        padding1={true}
        content={
          <TestDetailsComponent
            state={test.testId}
            afterFinish={() => {
              hideTestDetailsModal();
              // refetch();
            }}
          />
        }
        showOk={false}
        showCancel={false}
        onCancelClick={() => hideTestDetailsModal()}
        title={`Information`}
        maxWidth="lg"
        showHeaderBackground={true}
      />
    );
  };
  const hideTestDetailsModal = () => {
    setTestDetails(null);

  };
  const showOMRSheetUploaddModal = (test) => {
    setTestDetails(
      <CustomModal
        modal={true}
        setModal={() => hideTestDetailsModal()}
        padding1={true}
        content={
          <OMRSheetsUpload
            testId={test.testId}
            afterFinish={() => {
              hideTestDetailsModal();
            }}
          />
        }
        showOk={false}
        showCancel={false}
        onCancelClick={() => hideTestDetailsModal()}
        title={`Upload answer script`}
        maxWidth="lg"
        showHeaderBackground={true}
      />
    );
  }

  const showTestCopyModal = (test) => {
    console.log("copydata", pageState);
    setTestCopy(
      <CustomModal
        modal={true}
        setModal={() => hideTestCopyModal()}
        padding1={true}
        content={
          <TestCopyComponent
            state={test}
            afterFinish={() => {
              hideTestCopyModal();
              // refetch();
            }}
          />
        }
        showOk={false}
        showCancel={false}
        onCancelClick={() => hideTestCopyModal()}
        title={`Test Copy`}
        maxWidth="sm"
      />
    );
  };

  const hideTestCopyModal = () => {
    setTestCopy(null);
    enableSearch = true;
    if (pageState.page > 1) {
      setPageState({ pageSize: 10, page: 1 });
      setSelection([]);
    } else {
      filterValues(1, 10, contentFilter);
      setSelection([]);
    }
  };

  const showAnalytics = async (lOid, testName) => {
    console.log(testName, "testName")
    setAnalyticsModel(
      <CustomModal
        modal={true}
        setModal={hideAnalyticsModel}
        content={
          <Analytics
            lOid={lOid}
            studentId={12}
            totalTestScore={testName?.totalScore}
          />
        }
        showOk={false}
        showCancel={false}
        onCancelClick={hideAnalyticsModel}
        title={`Analytics - ${testName.text}`}
        maxWidth="lg"
        fullWidth={true}
        fullScreen={true}
        showHeaderBackground={true}
      />
    )
  }
  const hideAnalyticsModel = (event, reason) => {
    if (reason) console.log("reason", reason);
    setAnalyticsModel(null);
  };

  const showTestEditModal = async (test) => {

    let testData = await testService.getTestData(test.testId);
    console.log(testData, 'testData123');
    if (testData?.testType == 1) { //will replace it with testData?.testType
      props.history.push("/admin/quickTest", { state: testData });
    } else {
      props.history.push("/admin/test", { state: testData });
    }

    /*    setTestEditModal(
      <CustomModal
        modal={true}
        setModal={() => hideTestEditModal()}
        content={
          <TestWizard
            state={test}
            hideTitle={true}
            afterFinish={() => {
              hideTestEditModal();
              refetch();
            }}
          />
        }
        showOk={false}
        showCancel={false}
        onCancelClick={() => hideTestEditModal()}
        title="Edit Test"
        maxWidth="lg"
        fullWidth={true}
      />
    ); */
  };

  const hideTestEditModal = () => {
    setTestEditModal(null);
  };

  const showQuestion = (test) => {
    let tempTests = tests.map((x) => {
      return { ...x, showQuestions: false };
    });

    let p = tempTests.find((x) => x.testId == test.testId);
    p.showQuestions = true;
    setTests([...tempTests]);
  };

  const hideQuestion = (test) => {
    let p = tests.find((x) => x.testId == test.testId);
    p.showQuestions = false;
    setTests([...tests]);
  };

  const handleEdit = (p, e) => { };

  const onCellEditCommit = (p) => {
    let row = questionData.questions.find((x) => x.id == p.id);
    if (row[p.field] == p.value) {
      return;
    }
  };

  const handleRowSelection = (e) => {
    setSelection(e);
  };

  const getRowClassName = (row) => {
    if (row.isLocked == true) {
      return "gray";
    }

    return null;
  };

  const handleBulkOptionFieldChange = (v) => {
    // setBulkValue("");
    console.log(v, 'gettingvals', bulkOptions.find((x) => x.id == v))
    setBulkOption(bulkOptions.find((x) => x.id == v));
  };

  const handleUpload = async () => {

    //console.log(isEqual(bulkValue, "none"), 'bulkvals1234', typeof (bulkValue), "1st", typeof ("none"))
    if (!bulkOption.updateMethod) {
      showSnackBar("Please select field to update", "warning");
      return;
    }
    if (bulkValue == JSON.stringify("none")) {

      showSnackBar("Cannot update not configured.", "warning");
      return
    }
    if (bulkOption.name == "Status" && bulkValue == 3) {
      showSnackBar("Cannot update status as Published ", "error");
      return
    }
    if (bulkOption.name == "Status" && bulkValue == 4) {
      showSnackBar("Cannot update status as Scheduled ", "error");
      return
    }
    if (bulkOption.name == "Status" && bulkValue == 5) {
      showSnackBar("Cannot update status as In-Progress ", "error");
      return
    }
    console.log(bulkValue, 'bulkvals123');
    if ((bulkOption.name == 'Objective' || bulkOption.name == 'Target Audience') && bulkValue == JSON.stringify("none")) {
      showSnackBar("Cannot update not configured ", "error");
      return
    }
    if (bulkValue == "") {
      showSnackBar("Please select values to update", "warning");
      return
    }
    if (select.length <= 0) {
      showSnackBar("Please select tests to update", "warning");
      return
    }
    console.log(pageState.data, 'pageState123');
    // console.log(orgTests, 'pageState124');
    // pageState.data
    await bulkOption.updateMethod(select, pageState.data, bulkOption, bulkValue);

  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertMessage(null);
    setOpen(false);
  };

  const showSnackBar = (message, sev) => {
    setSeverity(sev ?? "success");
    setAlertMessage(message);
    setOpen(true);
  };

  React.useEffect(() => {
    console.log(deleteTest, 'successf')
    if (deleteTest.isSuccess == true) {
      showSnackBar("Test deleted successfully");
      setAlert(null);
    }
  }, [deleteTest.isSuccess]);

  React.useEffect(() => {
    console.log(deleteTest, 'successf')
    if (deleteTest.isError == true) {
      showSnackBar(deleteTest?.error?.response?.data, 'error');
      setAlert(null);
    }
  }, [deleteTest.isError]);

  React.useEffect(() => {
    console.log(cleanupTestUsers, 'successf')
    if (cleanupTestUsers.isSuccess == true) {
      showSnackBar("Test cleanup successfully");
      setAlert(null);
    }
  }, [cleanupTestUsers.isSuccess]);

  React.useEffect(() => {
    console.log(cleanupTestUsers, 'successf')
    if (cleanupTestUsers.isError == true) {
      showSnackBar(cleanupTestUsers?.error?.response?.data, 'error');
      setAlert(null);
    }
  }, [cleanupTestUsers.isError]);

  React.useEffect(() => {
    if (update.isSuccess == true) {
      console.log(update, "update223");
      if (update.data.totalCount == undefined) {
        setAlert(
          <SweetAlert
            style={{ display: "block", marginTop: "-100px" }}
            title={''}
            onConfirm={() => setAlert(null)}
            onCancel={() => setAlert(null)}
            confirmBtnCssClass={classes.button + " " + classes.success}
          >

            <b style={{ marginBottom: '10px' }}> Test(s) updated successfully</b>  <br />
            <br />
            <small><span style={{ float: 'left', fontWeight: 500 }}>Note:</span> <ul style={{ textAlign: 'left' }}>
              <li>If the Test(s) is not updated, it means that the 'Ready' or 'Publish' test criteria have not been met.</li>
              <li>You are not allowed to change the status of Published Test'(s).</li>
            </ul>

            </small>
          </SweetAlert>
        )
        // showSnackBar("Tests updated successfully");
      } else {
        setAlert(
          <SweetAlert
            style={{ display: "block", marginTop: "-100px" }}
            title={''}
            onConfirm={() => setAlert(null)}
            onCancel={() => setAlert(null)}
            confirmBtnCssClass={classes.button + " " + classes.success}
          >

            <b style={{ marginBottom: '10px' }}> Test(s) updated successfully</b>  <br />
            <br />
            <div style={{ paddingInline: '30%' }}>

              <ul style={{ listStyleType: 'none', padding: 0, textAlign: 'left' }}>
                <li>Total tests: {update.data.totalCount}       <br /></li>
                <li>Ready tests: {update.data.readyCount} <br /></li>
                <li>Draft tests: {update.data.draftCount}     <br /></li>
              </ul>
            </div>
            <br />
            <small><span style={{ float: 'left', fontWeight: 500 }}>Note:</span> <ul style={{ textAlign: 'left' }}>
              <li>If the Test status is not set to 'Ready', it means that the 'Ready' or 'Publish' test criteria have not been met.</li>
              <li>You are not allowed to change the status of Published Test'(s).</li>
            </ul>

            </small>

            {/* <b style={{ marginBottom: '10px' }}> Test(s) updated successfully</b>  <br />
            <b></b> <br />
            Total tests: {update.data.totalCount} <br />
            Ready tests: {update.data.readyCount} <br />
            Draft tests: {update.data.draftCount} <br />
            <br />
            <small>Note: If the test status is not set to 'Ready', it means that the 'Ready' test criteria have not been met.</small>
             */}
          </SweetAlert>
        )
        // showSnackBar("Total tests: " + update.data.totalCount + ", Ready tests: " + update.data.readyCount + ", Draft tests: " + update.data.draftCount);
      }

      enableSearch = true;
      if (pageState.page > 1) {
        setPageState({ pageSize: 10, page: 1 });
        setSelection([]);
      } else {
        filterValues(pageState.page, pageState.pageSize, contentFilter);
        setSelection([]);
      }
    }
  }, [update.isSuccess]);
  const handleAlignment = (event, content) => {
    console.log(content, 'contentFilterVals2')
    setContentFilter(content)
    filterValues(pageState.page, pageState.pageSize, content)
  };
  return (
    <Card>
      {alert}
      <CardHeader color="rose" icon>
        <CardIcon color="rose">
          <ListAltOutlined />
        </CardIcon>
      </CardHeader>
      <CardBody>
        {/* <h1>Tests</h1> */}

        <GridContainer>
          <GridItem xs={12} sm={12} md={2}>
            <CustomSelect
              label="Select field"
              options={bulkOptions}
              setvalue={handleBulkOptionFieldChange}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={2}>
            {bulkOption && bulkOption.component ? (
              bulkOption.component
            ) : (
              <CustomInput
                id="filename"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  fullWidth: true,
                  onChange: handleInputChange,
                  type: "text",
                  value: bulkValue,
                  autoComplete: "off",
                }}
              />
            )}
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <Button
              color="primary"
              onClick={handleClear}
              style={{ marginTop: 20, marginRight: 14, padding: 12, textTransform: "none" }}
            // disabled={pageState.isLoading == true}
            >
              <CloseOutlined style={{ marginRight: '0px' }} />
            </Button>
            <Button
              color="primary"
              onClick={handleSearch}
              style={{ marginTop: 20, marginRight: 14, padding: 12, textTransform: "none" }}
              disabled={pageState.isLoading == true}
            >
              <SearchOutlined style={{ marginRight: '0px' }} />
            </Button>
            {
              isPrivilege("UpdateTest") == true ?
                <Button
                  color="primary"
                  onClick={handleUpload}
                  style={{ marginTop: 20, textTransform: "none" }}
                  disabled={update.isLoading == true}
                >
                  {update.isLoading == true ? "Updating..." : "Update"}
                </Button> : ''
            }
            {deleteTest.isLoading && <CircularProgress size="2rem" />}
          </GridItem>
          <GridItem xs={12} sm={12} md={5} style={{ textAlign: "right" }}>
            {
              courseId !== null && (
                <Button
                  color="primary"
                  onClick={() => { props.history.push("/admin/testSeries") }}
                  style={{ marginTop: 4, textTransform: "none", marginRight: 14 }}
                >
                  Back
                </Button>
              )
            }
            <ToggleButtonGroup
              value={contentFilter}
              color="secondary"
              size="small"
              exclusive
              onChange={handleAlignment}
              aria-label="content"
              style={{ marginRight: 10, marginTop: 20 }}
            >
              <ToggleButton value={'0'} aria-label="All content">
                All
              </ToggleButton>
              <ToggleButton value={'1'} aria-label="Owned content">
                Owned
              </ToggleButton>
              <ToggleButton value={'2'} aria-label="Subscribed content">
                Subscribed
              </ToggleButton>
            </ToggleButtonGroup>
            {console.log("licensePackagetest", pageState?.total, licensePackageObject?.Tests)}
            {
              isPrivilege("CreateTest") == true ?
                <Button
                  color="primary"
                  onClick={() => {
                    ((pageState?.total + 1) > licensePackageObject?.Tests) ?
                      // 2500 > licensePackageObject?.Tests ?
                      showSnackBar(`Your current package allows up to ${licensePackageObject?.Tests} tests.Please upgrade to higher package to create additional tests.`, 'warning')
                      : props.history.push("/admin/test")
                  }}
                  disabled={pageState.isLoading}
                  style={{ marginTop: 4, textTransform: "none" }}
                >
                  + New
                </Button> : ''}
          </GridItem>
        </GridContainer>

        {pageState.isLoading == false && pageState.data ? (
          <div >
            <div style={{ height: 650, width: "100%" }}>
              {
                isPrivilege("ViewTest") == true ?
                  <CustomDataGrid
                    rows={pageState.data}
                    rowCount={pageState.total}
                    loading={pageState.isLoading}
                    rowsPerPageOptions={[10, 30, 50, 70, 100]}
                    page={pageState.page - 1}
                    pageSize={pageState.pageSize}
                    paginationMode="server"
                    onPageChange={(newPage) => setPageState(old => ({ ...old, page: newPage + 1 }))}
                    onPageSizeChange={(newPageSize) => setPageState(old => ({ ...old, pageSize: newPageSize }))}
                    columns={columns}
                    onEditCellPropsChange={handleEdit}
                    onCellEditCommit={onCellEditCommit}
                    onSelectionModelChange={handleRowSelection}
                    // isRowSelectable={(params: GridRowParams) => !(params.row.isLocked == true ? params.row.lockedBy == user ? false : true : params.row.status >= 3 ? true : false)}
                    isRowSelectable={(params: GridRowParams) => (params.row.status >= 3 || params.row.scheduleStatus == 5) ? false : true && params.row.providerId == providerId ? true : false}
                    checkboxSelection
                    getRowClassName={getRowClassName}
                  /> : ''
              }
            </div>
            <Snackbar
              open={open}
              autoHideDuration={2000}
              onClose={handleClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
              <Alert onClose={handleClose} severity={severity}>
                {alertMessage}
              </Alert>
            </Snackbar>
          </div>
        ) : (<div style={{ height: 645, width: "100%" }}>
          <CustomDataGrid
            rows={[]}
            loading={pageState.isLoading}
            columns={columns}
            checkboxSelection
            getRowClassName={getRowClassName}
          />
          {/* <CircularProgress style={{marginInline:'45%'}}/>
          <h6 style={{marginInline:'45%'}}>Loading...</h6> */}
        </div>
        )
        }
      </CardBody>
      {testEditModal}
      {testSchedule}
      {testCopy}
      {testUserMap}
      {quickTestModal}
      {popOver}
      {analyticsModal}
      {testDetails}
    </Card >
  );
}

export default withRouter(TestHomeComponent);
