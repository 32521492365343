import Button from "components/CustomButtons/Button";
import CustomInput from "components/CustomInput/CustomInput";
import CustomSelect from "components/CustomSelect/CustomSelect";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import useDownloadOmr from "hooks/testsHooks/useDownloadOmr";
import { useState } from "react";

export default function OmrDownload({ testData, testName, testId }) {
    const DownloadOmr = useDownloadOmr();

    const [studentIdDigits, setStudentIdDigits] = useState(2);
    const [choiceFormats] = useState([
        { id: 1, name: "a, b, c, d, e, f" },
        { id: 2, name: "A, B, C, D, E, F" },
        { id: 3, name: "1, 2, 3, 4, 5, 6" },
        { id: 4, name: "i, ii, iii, iv, v, vi" }
    ]);
    const poolsData = testData?.pools?.$values || [];

    const initialNumberOfChoicesArray = poolsData.map(pool => 4);
    const initialChoiceFormatIdArray = poolsData.map(pool => 3);

    const [choiceFormatIdArray, setChoiceFormatIdArray] = useState(initialChoiceFormatIdArray);


    const handleChoiceFormatIdChange = (index, value) => {
        const newArray = [...choiceFormatIdArray];
        newArray[index] = value;
        setChoiceFormatIdArray(newArray);
    };

    const [numberOfChoicesArray, setNumberOfChoicesArray] = useState(initialNumberOfChoicesArray);

    const handleNumberOfChoicesChange = (index, value) => {
        const newArray = [...numberOfChoicesArray];
        newArray[index] = parseInt(value);
        setNumberOfChoicesArray(newArray);
    };

    const generateFormData = async () => {
        const formData = {
            formName: testName,
            testIdDigits: 0,
            studentIdDigits: parseInt(studentIdDigits),
            sections: poolsData.map((pool, index) => ({
                sectionName: pool.text,
                questionCount: pool.questionCount,
                numberOfChoices: numberOfChoicesArray[index],
                choiceType: choiceFormatIdArray[index]
            }))
        };
        console.log(formData, 'formdatas')
        DownloadOmr.mutate({ formData, testId })
    };



    return (
        <GridContainer>
            <GridItem sm={10} md={6} lg={5} style={{ marginLeft: '7%' }}>
                {/* <h4 style={{ color: 'black', marginLeft: '10%' }}>{testName}</h4> */}
                <CustomInput
                    labelText={<span>Test Name </span>}
                    formControlProps={{ fullWidth: true }}
                    disabled={true}
                    inputProps={{ value: testName, type: "text" }}
                />
            </GridItem>
            <GridItem sm={10} md={2} lg={2} style={{ marginLeft: '2%' }}>
                {/* <h4 style={{ color: 'black', marginLeft: '10%' }}>{testName}</h4> */}
                <CustomInput
                    labelText={<span>StudentId Digits </span>}
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                        value: studentIdDigits,
                        type: "number",
                        onChange: (event) => setStudentIdDigits(event.target.value)
                    }}
                />
            </GridItem>
            <GridItem sm={10} md={10} lg={10} style={{ marginLeft: '7%' }}>
                <h6 style={{ color: 'black' }}>Sections: </h6>
            </GridItem>
            {
                poolsData.map((pool, index) => (
                    <GridContainer key={index} style={{ justifyContent: 'center' }}>
                        <GridItem sm={12} md={3} lg={3}>
                            <CustomInput
                                labelText={<span>Section Name </span>}
                                formControlProps={{ fullWidth: true }}
                                disabled={true}
                                inputProps={{ value: pool.text, type: "text" }}
                            />
                        </GridItem>
                        <GridItem sm={12} md={2} lg={2}>
                            <CustomInput
                                labelText={<span>Question count </span>}
                                formControlProps={{ fullWidth: true }}
                                disabled={true}
                                inputProps={{ value: pool.questionCount, type: "number" }}
                            />
                        </GridItem>
                        <GridItem sm={12} md={2} lg={2}>
                            <CustomInput
                                labelText={<span>Number Of Choices </span>}
                                formControlProps={{ fullWidth: true }}
                                inputProps={{
                                    maxLength: 6,
                                    minLength: 2,
                                    step: 1,
                                    value: numberOfChoicesArray[index],
                                    type: "number",
                                    onChange: (event) => handleNumberOfChoicesChange(index, event.target.value)
                                }}
                            />
                        </GridItem>
                        <GridItem sm={12} md={3} lg={3}>
                            <CustomSelect
                                name="choiceFormatId"
                                label="Choice Format"
                                options={choiceFormats}
                                value={choiceFormatIdArray[index]}
                                setvalue={(v) => handleChoiceFormatIdChange(index, v)}
                            />
                        </GridItem>
                    </GridContainer>
                ))
            }
            {/* Optional: Button to log the generated form data */}
            <GridItem sm={12}>
                {/* <button onClick={() => console.log(generateFormData())}>
                    Generate Form Data
                </button> */}
                <Button round color="primary" style={{ margin: '20px 0% 0% 73%', position: 'absolute' }} disabled={false} onClick={() => generateFormData()}>
                    Download OMR
                </Button>
            </GridItem>
        </GridContainer>
    );
}
