import axios from "axios";
import { configUrl } from "config";
import { LoginContext } from "contexts/LoginContext";
import { useContext } from "react";
import { useQuery } from "react-query";

export default function useGetAllProvider() {

  const { config, providerId } = useContext(LoginContext);

  return useQuery(
    "Provider",
    async () => {
      let response = await axios.get(`${config.api}/api/Provider`);
      console.log(response)
      return response.data;
    },
    {
      staleTime: 1000 * 60 * 5,
    }
  );
}

export async function getProviderById(id) {
  try {
    console.log("  provider id is" + id);
    let response = await axios.get(
      // `${configUrl.AssessmentServer}/api/Provider/${key}`
      `${configUrl.AssessmentServer}/api/Provider/${id}`
    );
    console.log(
      "Provider data " +
      response.data
    );


    return response.data;
  } catch (error) {
    console.error("error in fetching details.");
  }
}

export async function providerNameExists(name, providerId) {
  console.log(providerId, "kkkkkooooooo");
  try {
    console.log("  provider name is" + name);
    let response = await axios.get(
      `${configUrl.AssessmentServer}/api/Provider/isProviderNameExists/${name}/${providerId}`
    );
    return response.data

  } catch (error) {
    console.error("error in fetching details.");
  }
}

export async function providerEmailExists(email, providerId) {
  try {
    console.log("  provider  email is" + email);
    let response = await axios.get(
      `${configUrl.AssessmentServer}/api/Provider/isProviderEmailExists/${email}/${providerId}`
    );
    return response.data

  } catch (error) {
    console.error("error in fetching details.");
  }
}

export async function providerMobileExists(mobile, providerId) {
  try {
    console.log("  provider mobile is" + mobile);
    let response = await axios.get(
      `${configUrl.AssessmentServer}/api/Provider/isProviderMobileExists/${mobile}/${providerId}`
    );
    return response.data

  } catch (error) {
    console.error("error in fetching details.");
  }
}
