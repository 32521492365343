import axios from "axios";
import { configUrl } from "config.js";
import { LoginContext } from "contexts/LoginContext";
import { useContext } from "react";
import { useMutation, useQueryClient } from "react-query";

export default function useUpdateTest() {
  const { user, providerId } = useContext(LoginContext);

  const queryClient = useQueryClient();
  return useMutation(
    async (data) => {
      console.log("useUpdateTest", data)
      let test = {
        testId: data.testId,
        providerId: providerId,
        code: data.testCode,
        objective: data.objective === "none" || data.objective === null || data.objective === "" ? null : JSON.stringify(data.objective),
        targetAudience: data.targetAudience === "none" || data.targetAudience === null || data.targetAudience === "" ? null : JSON.stringify(data.targetAudience),
        owner: user,
        createdBy: user,
        createdDate: new Date(),
        updatedBy: user,
        updatedDate: new Date(),
        isActive: data.isActive,
        status: data.status == null ? 1 : data.status,
        languageId: 1,
        difficultyLevel: data.difficultyLevel,
        expiryDate: data.expiryDate,
        masteryScore: data.masteryScore == '' ? undefined : data.masteryScore,
        navigationAllowed: typeof (data.navigationAllowed) === 'boolean' ? data.navigationAllowed == true ? 2 : 1 : data.navigationAllowed,
        passingScore: data.passingScore == '' ? undefined : data.passingScore,
        totalScore: data.totalScore,
        questionsPerPage: data.questionsPerPage,
        pointsPerQuestion: data.pointsPerQuestion,
        negativePointsPerQuestion: data.negativePointsPerQuestion ?? 0,
        duration: data.duration,
        showHint: typeof (data.showHint) === 'boolean' ? data.showHint == true ? 2 : 1 : data.showHint,
        showBookmark: typeof (data.showBookmark) === 'boolean' ? data.showBookmark == true ? 2 : 1 : data.showBookmark,
        showResult: typeof (data.showResult) === 'boolean' ? data.showResult == true ? 2 : 1 : data.showResult,
        showTimer: typeof (data.showTimer) === 'boolean' ? data.showTimer == true ? 2 : 1 : data.showTimer,
        isLocked: data.isLocked,
        LockedBy: data.isLocked == true ? user : null,
        attemptsAllowed: data.attemptsAllowed,
        testDate: data.testDate
      };

      console.log("testupdate", test)

      await axios.put(
        `${configUrl.AssessmentServer}/api/Tests/${data.testId}`,
        test
      );

      let language = {
        testId: data.testId,
        languageId: 1,
        text: data.text,
        image: "",
        textHtml: "",
        passFeedback: data.feedbackPass,
        failFeedback: data.feedbackFail,
        masteryFeedback: data.feedbackMastery,
        isActive: true,
        status: 1,
        createdBy: user,
        createdDate: new Date(),
        updatedBy: user,
        updatedDate: new Date(),
        welcomeMessage: data.welcomeMessage,
        concludeMessage: data.concludeMessage,
        description: data.description,
      };

      await axios.put(
        `${configUrl.AssessmentServer}/api/TestLanguages/${data.testId}`,
        language
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("tests");
      },
    }
  );
}
