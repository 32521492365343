import { Divider } from "@material-ui/core";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
// import '../../Styles/PdfGenerater.module.css';

import { jsPDF } from "jspdf";
import { useEffect, useState } from "react";
import testPreviewService from "services/Preview/testPreviewService";
import OmrDownload from "./OmrDownload";
export default function PrintPreviewComponent({ pageSize, testId, testName }) {
    const [previewTest, setPreviewTest] = useState([])
    const [questions, setQuestions] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [showAnswerSheet, setShowAnswerSheet] = useState(false);
    const getSync = async () => {
        try {
            setIsLoading(true);
            let response = await testPreviewService.getPreviewTests(testId, 1, pageSize);
            console.log(response?.data, 'Allquestions');
            setPreviewTest(response?.data);

            console.log(response?.data, 'previewTest')
            let rtest = response?.data?.pools["$values"].map((x, i) => {
                x.questions["$values"].forEach((y) => {
                    y.poolText = x.text;
                    y.poolId = x.id;
                    y.isMandatoryToPass = x.isMandatoryToPass;
                    y.poolScore = x.poolScore;
                    y.response = null;
                    y.isCorrect = false;
                });

                return x.questions["$values"];
            });

            rtest = rtest.flat();

            const qPerPage = response?.data?.questionsPerPage;
            // console.log("ExpiryDate",test.ExpiryDate);
            let c = 0;
            let currentPool = "";
            let index = 0;

            let mdata = rtest.reduce((acc, cur) => {
                if (cur.id === 0) {
                    return acc;
                }
                if (currentPool == "") {
                    currentPool = cur.poolText;
                }

                if (c == qPerPage || currentPool != cur.poolText) {
                    c = 0;
                    currentPool = cur.poolText;
                    index++;
                }

                if (!acc[index]) {
                    acc[index] = [];
                }
                console.log("acbjaka", acc);
                acc[index].push(cur);
                c++;

                return acc;
            }, []);

            setQuestions(mdata);

            console.log("mdata", mdata);
            setIsLoading(false);

        } catch (error) {
            console.error('Error:', error);
            setIsLoading(false);
        } finally {
            console.log('error occured')
            setIsLoading(false);
        }
        // setIsLoading(false);
    }

    useEffect(() => {
        console.log('calling')
        getSync();
    }, [])
    console.log(previewTest, 'pres')

    console.log(testId, 'testId11', pageSize)

    const jspdf = new jsPDF('p', 'pt', 'letter')
    const handleSubmit = () => {
        // Prepare HTML content for the PDF
        const contentElement = document.getElementById('print-content');
        console.log(contentElement, 'formData')
        const data = {
            callback: function (jspdf) {
                jspdf.save('demo.pdf')
            },
            margin: [25, 10, 25, 10],
            // padding: [25, 10, 25, 10],
            autoPaging: 'text'
        }

        jspdf.html(contentElement, data)
    }
    function convertMinutesToHMS(mins) {
        // Convert minutes to hours and minutes
        const hours = Math.floor(mins / 60);
        const minutes = mins % 60;

        // Format hours, minutes, and seconds to hh:mm:ss
        const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:00`;

        return formattedTime;
    }

    return (
        <>
            {
                showAnswerSheet == true ?
                    <OmrDownload
                        testData={previewTest}
                        testName={testName}
                        testId={testId}
                    />
                    :
                    (

                        isLoading ? <h3 style={{ color: 'black' }} > Loading Preview...</h3 > :
                            <div id="print-content" style={{ border: '1px solid black', padding: '2%', width: '70%' }}>
                                <div style={{ textAlign: 'center', color: 'black' }}>
                                    {/* <h3>{`Sample Question Paper`}</h3> */}
                                    <h3 style={{ fontWeight: 'bold' }}>{`${testName} `}</h3>
                                    {/* <h4>{`CLASS XII (2023-24)`}</h4> */}
                                </div>
                                <div style={{ color: 'black' }}>
                                    <p style={{ float: 'left', fontWeight: 'bold' }}>{`Time ${convertMinutesToHMS(previewTest?.duration)} Hours`}</p>
                                    <p style={{ float: 'right', fontWeight: 'bold' }}>{`Total. Marks ${previewTest?.totalScore}`}</p>
                                </div>
                                <div style={{ marginTop: '10%' }}>
                                    <Divider />
                                </div>
                                <div style={{ marginTop: '5%' }}>
                                    {
                                        questions.map((x, i) => (
                                            <ul style={{ listStyleType: 'none', color: 'black', marginTop: '5%' }}>
                                                {
                                                    x[0].image != null ?
                                                        <li>{`Q ${i + 1}. `}<img src={x[0].image} alt="React Image" style={{ width: '-webkit-fill-available' }} /></li>
                                                        : null
                                                }
                                                {
                                                    x[0].text != null ?
                                                        <li style={{ fontWeight: '500' }} >{`Q ${i + 1}. ${x[0].text}`}</li>
                                                        : null
                                                }
                                                {x[0].image == null ?
                                                    <GridContainer style={{ padding: '10px 50px', width: 'calc(100% + 16px)' }}>
                                                        {
                                                            x[0]?.options["$values"].map((x, i) => (
                                                                x != null ?
                                                                    <GridItem xs={5} sm={5} md={3} lg={3}>
                                                                        <p style={{ fontWeight: '500' }}>{x}</p>
                                                                    </GridItem> : ''

                                                            ))
                                                        }
                                                    </GridContainer> : null

                                                }
                                            </ul>
                                            // console.log(x[0], 'questions123')
                                        ))
                                    }
                                </div>
                            </div>

                    )

            }
            {
                showAnswerSheet == true ?
                    null :
                    <Button round color="primary" style={{ margin: '20px 0% 0% 75%', position: 'absolute' }} disabled={isLoading} onClick={(e) => handleSubmit(e)}>
                        Download
                    </Button>
            }
            <Button round color="primary" style={{ margin: '20px 0% 0% 62%', position: 'absolute' }} disabled={isLoading} onClick={(e) => setShowAnswerSheet(showAnswerSheet == true ? false : true)}>
                {
                    showAnswerSheet == true ?
                        "Back" :
                        "Create OMR"
                }
            </Button>
        </>
    )
}
