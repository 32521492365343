import React from "react";
// material-ui
import { makeStyles } from "@material-ui/core/styles";
// core components
import Box from "@material-ui/core/Box";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
// @material-ui/icons
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
// core components
import radioStyles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch";
import dashboardStyle from "assets/newAssets/dashboardStyle";
import loginPageStyle from "assets/newAssets/loginPageStyle";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Hint from "components/Hint/Hint";
import 'katex/dist/katex.min.css';
import ReactHtmlParser from "react-html-parser";
import Latex from 'react-latex-next';
{/* latex CSS */ }

var styles = {
  ...dashboardStyle,
  ...loginPageStyle,
  ...radioStyles,
  cardTitle: {
    marginTop: "0",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);

export default function SingleChoiceQuestion({ qNoes, question, contentType, updateQuestion, preview, checkForDisabled }) {
  console.log("SingleChoiceQuestion", question);


  const [selectedEnabled, setSelectedEnabled] = React.useState(null);

  const handleCheck = (x) => {
    setSelectedEnabled(x);

    question.questionChoices.choiceIdAnswers = x;
    // question.isCorrect = x == question.answer;
  };

  React.useEffect(() => {
    //needs to be clear to avoid retaining of options
    setSelectedEnabled(null);
    setSelectedEnabled(question.questionChoices.choiceIdAnswers);
  }, [question]);

  const classes = useStyles();

  return (
    <div style={{ width: "100%" }}>
      <Box display="flex" p={2}>
        <Box p={2} sx={{ width: "100%" }}>
          <Box display="flex" p={1} style={{ padding: 0 }}>
            <Box p={1} flexGrow={1} style={{ padding: 0, float: 'left', color: 'black', fontSize: '22px' }}>
              <div style={{ float: 'left', marginRight: '5px', marginTop: '7px' }}> Q{qNoes}. </div>
              {
                question && question.text && question.text.length > 0 ?
                  (contentType === 1 ?
                    <div >{question?.text}</div>
                    : contentType === 3 && question?.text != null ?
                      <Latex >{question?.text}</Latex>
                      : contentType === 4 && question?.text != null ?
                        <div>{question.text && ReactHtmlParser(question.text)}</div>
                        :
                        <div style={{ marginBottom: '10px' }}>{question.text}</div>
                  ) : null
              }
              {question.image && question?.image != null &&
                <Box p={2}>
                  {question.image && <img src={question.image} alt="..." />}
                </Box>
              }
            </Box>
            <Box p={1}>
              <Hint message={question.hint} ContentType={contentType} questionId={question.id} />
            </Box>
          </Box>
          <Box>
            <GridContainer alignItems="center" spacing={1}>
              {
                question?.questionChoices?.choiceId01 != null &&
                <GridItem xs={11} sm={11} md={6}>
                  <div
                    style={{ color: 'black' }}
                    className={
                      classes.checkboxAndRadio +
                      " " +
                      classes.checkboxAndRadioHorizontal
                    }
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          checked={selectedEnabled?.trim()?.toLowerCase()?.replace(/[(),]/g, '') === question.questionChoices.choiceId01?.trim()?.toLowerCase()?.replace(/[(),]/g, '')}
                          onChange={() => handleCheck(question.questionChoices.choiceId01)}
                          value="a"
                          name="radio button enabled"
                          aria-label="A"
                          icon={
                            <FiberManualRecord
                              className={classes.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classes.radioChecked}
                            />
                          }
                          classes={{
                            checked: classes.radio,
                            root: classes.radioRoot,
                          }}
                          disabled={checkForDisabled}
                        // disabled={question.isSubmitted == true}
                        />
                      }
                      classes={{
                        label: classes.label,
                      }}
                      label={
                        contentType == 1 ?
                          question?.questionChoices?.choiceId01 :
                          contentType == 3 ?
                            <Latex style={{ color: 'black' }}>{question?.questionChoices?.choiceId01}</Latex> :
                            contentType == 4 ?
                              ReactHtmlParser(question?.questionChoices?.choiceId01) :
                              question?.questionChoices?.choiceId01
                      }
                    />
                  </div>
                </GridItem>
              }
              {
                question?.questionChoices?.choiceId02 != null &&
                // <GridContainer alignItems="center" spacing={1}>
                <GridItem xs={11} sm={11} md={6}>
                  <div
                    style={{ color: 'black' }}
                    className={
                      classes.checkboxAndRadio +
                      " " +
                      classes.checkboxAndRadioHorizontal
                    }
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          checked={selectedEnabled?.trim()?.toLowerCase()?.replace(/[(),]/g, '') === question.questionChoices.choiceId02?.trim()?.toLowerCase()?.replace(/[(),]/g, '')}
                          onChange={() => handleCheck(question.questionChoices.choiceId02)}
                          value="a"
                          name="radio button enabled"
                          aria-label="A"
                          icon={
                            <FiberManualRecord
                              className={classes.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classes.radioChecked}
                            />
                          }
                          classes={{
                            checked: classes.radio,
                            root: classes.radioRoot,
                          }}
                          disabled={checkForDisabled}
                        // disabled={question.isSubmitted == true}
                        />
                      }
                      classes={{
                        label: classes.label,
                      }}
                      label={
                        contentType == 1 ?
                          question?.questionChoices?.choiceId02 :
                          contentType == 3 && question?.questionChoices?.choiceId02 != null ?
                            <Latex style={{ color: 'black' }}>{question?.questionChoices?.choiceId02}</Latex> :
                            contentType == 4 ?
                              ReactHtmlParser(question?.questionChoices?.choiceId02) :
                              question?.questionChoices?.choiceId02
                      }
                    />
                  </div>
                </GridItem>
                // </GridContainer>
              }
              {
                question?.questionChoices?.choiceId03 != null &&
                // <GridContainer alignItems="center" spacing={1}>
                <GridItem xs={11} sm={11} md={6}>
                  <div
                    style={{ color: 'black' }}
                    className={
                      classes.checkboxAndRadio +
                      " " +
                      classes.checkboxAndRadioHorizontal
                    }
                  >

                    <FormControlLabel
                      control={
                        <Radio
                          checked={selectedEnabled?.trim()?.toLowerCase()?.replace(/[(),]/g, '') === question.questionChoices.choiceId03?.trim()?.toLowerCase()?.replace(/[(),]/g, '')}
                          onChange={() => handleCheck(question.questionChoices.choiceId03)}
                          value="a"
                          name="radio button enabled"
                          aria-label="A"
                          icon={
                            <FiberManualRecord
                              className={classes.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classes.radioChecked}
                            />
                          }
                          classes={{
                            checked: classes.radio,
                            root: classes.radioRoot,
                          }}
                          disabled={checkForDisabled}
                        // disabled={question.isSubmitted == true}
                        />
                      }
                      classes={{
                        label: classes.label,
                      }}
                      label={
                        contentType == 1 ?
                          question?.questionChoices?.choiceId03 :
                          contentType == 3 && question?.questionChoices?.choiceId03 != null ?
                            <Latex style={{ color: 'black' }}>{question?.questionChoices?.choiceId03}</Latex> :
                            contentType == 4 ?
                              ReactHtmlParser(question?.questionChoices?.choiceId03) :
                              question?.questionChoices?.choiceId03
                      }
                    />
                  </div>
                </GridItem>
                // </GridContainer>
              }
              {
                question?.questionChoices?.choiceId04 != null &&
                // <GridContainer alignItems="center" spacing={1}>
                <GridItem xs={11} sm={11} md={6}>
                  <div
                    style={{ color: 'black' }}
                    className={
                      classes.checkboxAndRadio +
                      " " +
                      classes.checkboxAndRadioHorizontal
                    }
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          checked={selectedEnabled?.trim()?.toLowerCase()?.replace(/[(),]/g, '') === question.questionChoices.choiceId04?.trim()?.toLowerCase()?.replace(/[(),]/g, '')}
                          onChange={() => handleCheck(question.questionChoices.choiceId04)}
                          value="a"
                          name="radio button enabled"
                          aria-label="A"
                          icon={
                            <FiberManualRecord
                              className={classes.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classes.radioChecked}
                            />
                          }
                          classes={{
                            checked: classes.radio,
                            root: classes.radioRoot,
                          }}
                          disabled={checkForDisabled}
                        // disabled={question.isSubmitted == true}
                        />
                      }
                      classes={{
                        label: classes.label,
                      }}
                      label={
                        contentType == 1 ?
                          question?.questionChoices?.choiceId04 :
                          contentType == 3 && question?.questionChoices?.choiceId04 != null ?
                            <Latex style={{ color: 'black' }}>{question?.questionChoices?.choiceId04}</Latex> :
                            contentType == 4 ?
                              ReactHtmlParser(question?.questionChoices?.choiceId04) :
                              question?.questionChoices?.choiceId04
                      }
                    />
                  </div>
                </GridItem>
                // </GridContainer>
              }
              {
                question?.questionChoices?.choiceId05 != null &&
                // <GridContainer alignItems="center" spacing={1}>
                <GridItem xs={11} sm={11} md={6}>
                  <div
                    style={{ color: 'black' }}
                    className={
                      classes.checkboxAndRadio +
                      " " +
                      classes.checkboxAndRadioHorizontal
                    }
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          checked={selectedEnabled?.trim()?.toLowerCase()?.replace(/[(),]/g, '') === question.questionChoices.choiceId05?.trim()?.toLowerCase()?.replace(/[(),]/g, '')}
                          onChange={() => handleCheck(question.questionChoices.choiceId05)}
                          value="a"
                          name="radio button enabled"
                          aria-label="A"
                          icon={
                            <FiberManualRecord
                              className={classes.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classes.radioChecked}
                            />
                          }
                          classes={{
                            checked: classes.radio,
                            root: classes.radioRoot,
                          }}
                          disabled={checkForDisabled}
                        // disabled={question.isSubmitted == true}
                        />
                      }
                      classes={{
                        label: classes.label,
                      }}
                      label={
                        contentType == 1 ?
                          question?.questionChoices?.choiceId05 :
                          contentType == 3 && question?.questionChoices?.choiceId05 != null ?
                            <Latex style={{ color: 'black' }}>{question?.questionChoices?.choiceId05}</Latex> :
                            contentType == 4 ?
                              ReactHtmlParser(question?.questionChoices?.choiceId05) :
                              question?.questionChoices?.choiceId05
                      }
                    />
                  </div>
                </GridItem>
                // </GridContainer>
              }
              {
                question?.questionChoices?.choiceId06 != null &&
                // <GridContainer alignItems="center" spacing={1}>
                <GridItem xs={11} sm={11} md={6}>
                  <div
                    style={{ color: 'black' }}
                    className={
                      classes.checkboxAndRadio +
                      " " +
                      classes.checkboxAndRadioHorizontal
                    }
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          checked={selectedEnabled?.trim()?.toLowerCase()?.replace(/[(),]/g, '') === question.questionChoices.choiceId06?.trim()?.toLowerCase()?.replace(/[(),]/g, '')}
                          onChange={() => handleCheck(question.questionChoices.choiceId06)}
                          value="a"
                          name="radio button enabled"
                          aria-label="A"
                          icon={
                            <FiberManualRecord
                              className={classes.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classes.radioChecked}
                            />
                          }
                          classes={{
                            checked: classes.radio,
                            root: classes.radioRoot,
                          }}
                          disabled={checkForDisabled}
                        // disabled={question.isSubmitted == true}
                        />
                      }
                      classes={{
                        label: classes.label,
                      }}
                      label={
                        contentType == 1 ?
                          question?.questionChoices?.choiceId06 :
                          contentType == 3 && question?.questionChoices?.choiceId06 != null ?
                            <Latex style={{ color: 'black' }}>{question?.questionChoices?.choiceId06}</Latex> :
                            contentType == 4 ?
                              ReactHtmlParser(question?.questionChoices?.choiceId06) :
                              question?.questionChoices?.choiceId06
                      }
                    />
                  </div>
                </GridItem>
              }
            </GridContainer>
          </Box>
        </Box>
      </Box>
    </div>
  );
}
