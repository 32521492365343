/*eslint-disable*/
import { useEffect, useState } from "react";

// react plugin for creating charts
import "chartist-plugin-axistitle";
import ChartistGraph from "react-chartist";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Timeline from "@material-ui/icons/Timeline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";



import styles from "assets/jss/material-dashboard-pro-react/views/chartsStyle.js";
import chartistPluginAxistitle from "chartist-plugin-axistitle";
import UseGetGraph from "hooks/analyticHooks/UseGetGraphAnalytics";

const useStyles = makeStyles(styles);

export default function AnalyticsCharts({ lOid }) {
  const [graphAnalyticsData, setGraphAnalyticsData] = useState();
  const classes = useStyles();

  const getGraphData = async () => {
    const testAnalytics = await UseGetGraph(lOid);
    console.log("chartResp", testAnalytics)
    setGraphAnalyticsData(testAnalytics);
  }
  useEffect(() => {
    getGraphData();
  }, [])
  console.log("chartResp", graphAnalyticsData)


  const [simpleChartData, setSimpleChartData] = useState(
    {
      labels: [],
      series: []
    }
    // {
    //   labels: ["-2 - 0", "1 - 2", "3 - 4", "5 - 6", "7 - 8"], // Ranges of marks
    //   series: [
    //     [2, 0, 1, 3, 2]  // Student counts
    //   ]
    // }
  );
  useEffect(() => {

    let seriesArray = [];
    let labelsArray = [];
    graphAnalyticsData && graphAnalyticsData['$values']?.map((x, i) => {
      seriesArray.push(Math.round(x.studentCount));
      labelsArray.push(x.range);
    })
    setSimpleChartData(
      {
        series: [seriesArray],
        labels: labelsArray,
      }

    )
  }, [graphAnalyticsData]);

  const ranges = simpleChartData.labels.map(label => {
    const [min, max] = label.split(' - ').map(Number);
    return { min, max };
  });


  const minX = Math.min(...ranges.map(range => range.min));
  const maxX = Math.max(...ranges.map(range => range.max));

  // const seriesData = Array.isArray(simpleChartData.series[0]) ? simpleChartData.series[0] : [];
  // const minY = seriesData.length > 0 ? Math.min(...seriesData) : 0;
  // const maxY = seriesData.length > 0 ? Math.max(...seriesData) : 0;
  const options = {
    axisX: {
      low: minX,
      high: maxX,
      onlyInteger: true,
    },
    axisY: {
      onlyInteger: true,
      low: 0,

    },
    plugins: [
      chartistPluginAxistitle({
        axisX: {
          axisTitle: "Score",
          axisClass: "ct-axis-title",
          offset: {
            x: 0,
            y: 30,
          },
          textAnchor: "middle",
        },
        axisY: {
          axisTitle: "Students",
          axisClass: "ct-axis-title",
          offset: {
            x: 0,
            y: -15,
          },
          flipTitle: false,
        },
      }),
    ],
  };
  console.log(simpleChartData, 'simpleChartData')

  // const simpleLineChartData = {
  //   labels: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
  //   series: [
  //     [12, 9, 7, 8, 5],
  //     [2, 1, 3.5, 7, 3],
  //     [1, 3, 4, 5, 6]
  //   ]
  // }

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <CardIcon color="primary">
            <Timeline style={{ color: 'white' }} />
          </CardIcon>
          <h4 className={classes.cardIconTitle}>
            Test Analytics <small>- (Topic Level)</small>
          </h4>
          <CardBody>
            <ChartistGraph
              data={simpleChartData && simpleChartData.labels.length > 0 && simpleChartData.series.length > 0 && simpleChartData}
              type="Line"
              options={options}
            // options={series}
            // listener={colouredLinesChart.animation}
            />
          </CardBody>
        </GridItem>
      </GridContainer>
    </div>
  );
}
