import { CircularProgress } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { ImportContactsSharp } from "@material-ui/icons";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import axios from "axios";
import CustomAlert from "components/CustomAlert/CustomAlert";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Solution from "components/Solution/Solution";
import { configUrl } from "config";
import useUpdateQuestionChoices from "hooks/questionBulkImportHooks/useUpdateQuestionChoices";
import { useEffect, useState } from "react";
import Button from "../components/CustomButtons/Button";
import Question from "./Question";

const QuestionWrapper = (props) => {
  // const { setAnswerModalTitle, answerModalTitle } = useContext(LoginContext);
  const [qanswers, setQanswers] = useState([]);

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [flag, setFlag] = useState(false)
  const [showAlert, setShowAlert] = useState(null)
  const [showSolution, setShowSolution] = useState(false);
  const [isDisableEdit, setIsDisableEdit] = useState(props?.providerIdHeirarchy ? (props.providerType === 1 && props.providerIdHeirarchy !== props.providerId) : false);

  console.log(isDisableEdit, "check54")
  const getSync = () => {
    // var topicId = 0;
    axios.get(`${configUrl.AssessmentServer}/api/Questions/getQuestionAnswers/${pageNumber}/${pageSize}/${props.topicId}/${isDisableEdit}`).then((response) => {
      console.log(response?.data, 'Allquestions')
      setQanswers(response?.data)
    });
  }
  useEffect(() => {
    getSync();
  }, [pageNumber]);

  // if (qanswers.length == 0) {
  //   getSync();
  // }


  const updateQuestionChoices = useUpdateQuestionChoices();
  console.log(updateQuestionChoices, 'updateQuestionChoices')
  const setNextQuestion = () => {
    // setAnswerModalTitle(qanswers['$values'][currentQuestionIndex]?.topicName)
    let questionIndex = currentQuestionIndex + 1
    if (questionIndex + 1 == 11) {
      if (props.providerType === 1 && props.providerIdHeirarchy !== props.providerId) {
        setQanswers([]);
        setPageNumber(pageNumber + 1);
        setCurrentQuestionIndex(0);
      } else {
        updateQuestionChoices.mutate({ qanswers });
        setQanswers([]);
        setPageNumber(pageNumber + 1);
        setCurrentQuestionIndex(0);
      }
    } else {
      setCurrentQuestionIndex(questionIndex)
    }
    setShowSolution(false);
  };

  const setPreviousQuestion = () => {
    // setAnswerModalTitle(qanswers['$values'][currentQuestionIndex]?.topicName)
    let questionIndex = currentQuestionIndex - 1
    if (questionIndex == -1) {
      if (!(props.providerType === 1 && props.providerIdHeirarchy !== props.providerId)) {
        updateQuestionChoices.mutate({ qanswers });
      }
      if (pageNumber > 1) {
        setQanswers([]);
        setPageNumber(pageNumber - 1);
        setCurrentQuestionIndex(9);
      }
    } else {
      setCurrentQuestionIndex(questionIndex);
    }
    setShowSolution(false);
  };

  const handleSubmit = () => {
    updateQuestionChoices.mutate({ qanswers });
    if (updateQuestionChoices.isSuccess || updateQuestionChoices.isIdle) {
      setShowAlert(
        <CustomAlert
          open={true}
          openFlag={() => setFlag(flag ? false : true)}
          afterfinish={props.afterfinish}
          severity="success"
          alertMessage="Answers set successfully."
        />
      )
      setTimeout(() => {
        props.afterfinish()
      }, 2000);
    } else {
      setShowAlert(
        <CustomAlert
          open={true}
          openFlag={() => setFlag(flag ? false : true)}
          // afterfinish={props.afterfinish}
          severity="error"
          alertMessage="Answers not saved."
        />
      )
    }
    // props.afterfinish();
  };
  console.log(qanswers, 'updateQuestionChoices')
  var totalQuestions = qanswers && qanswers['$values'] == undefined ? '' : qanswers['$values']?.length > 0 && qanswers['$values'][0]?.totalQuestions
  return (

    <GridContainer>
      <GridItem xs={12} sm={12} md={12} lg={12} style={{ borderBottom: '1px solid #EA729D' }}>
        <h4 style={{ marginLeft: '1%', color: '#E63573', fontWeight: '400' }}>Topic - {qanswers?.length != 0 && qanswers['$values']?.length > 0 ? qanswers['$values'][currentQuestionIndex]?.topicName?.length == 0 ? <i style={{ color: 'gray' }}>none</i> : qanswers['$values'][currentQuestionIndex]?.topicName : null} (  Q Code - {qanswers?.length != 0 && qanswers['$values']?.length > 0 ? qanswers['$values'][currentQuestionIndex]?.code?.length == 0 ? <i style={{ color: 'gray' }}>none</i> : qanswers['$values'][currentQuestionIndex]?.code : null})</h4>
      </GridItem>
      {
        qanswers.length == 0 ?
          <GridItem xs={12} sm={12} md={12} lg={12} style={{ height: '35rem', overflow: 'auto', textAlign: 'center' }}>
            <CircularProgress style={{ marginInline: '45%', marginTop: '20%' }} />
            <h4 style={{ color: 'black', textAlign: 'center' }}> Loading...</h4>
          </GridItem>
          :
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <GridItem xs={12} sm={12} md={12} lg={12} style={{ height: '35rem', overflow: 'auto' }}>
              {qanswers['$values'] &&
                qanswers['$values'].length > 0 &&

                <div >
                  {updateQuestionChoices.isLoading ?
                    <>
                      <CircularProgress style={{ marginInline: '45%', marginTop: '21%', textAlign: 'center' }} />
                      <h4 style={{ color: 'black', textAlign: 'center' }}> Your changes are being saved</h4>
                    </> :
                    <Question
                      qNoes={(pageNumber - 1) * pageSize + currentQuestionIndex + 1}
                      key={currentQuestionIndex}
                      question={qanswers['$values'][currentQuestionIndex]}
                      checkForDisabled={props.providerType === 1 && props.providerIdHeirarchy !== props.providerId}
                    />}
                  {
                    showSolution ?
                      <Solution
                        solutionText={qanswers['$values'][currentQuestionIndex]?.solutionText}
                        solution={qanswers['$values'][currentQuestionIndex]?.solution}
                        contentType={qanswers['$values'][currentQuestionIndex]?.contentType}
                        updateQuestion=""
                        preview={true}
                      />
                      : null
                  }
                </div>
              }
            </GridItem>
            {/*Navigation Buttons */}
            {
              qanswers.length != 0 && qanswers['$values'].length > 0 &&
              <GridItem xs={12} sm={12} md={12} lg={12} style={{ height: '10' }}>
                {

                  <Button color="primary" style={{ marginInline: '3%', float: 'left', marginTop: '20px' }} onClick={() => setShowSolution(showSolution ? false : true)} disabled={updateQuestionChoices.isLoading || (qanswers['$values'][currentQuestionIndex]?.solution || qanswers['$values'][currentQuestionIndex]?.solutionText ? false : true)}>
                    <ImportContactsSharp style={{ marginRight: '5px' }} />
                    {
                      showSolution ? ' Hide Solution ' : 'Show Solution'
                    }
                  </Button>
                }
                {/* textAlign: 'center', justifyContent: 'center' */}
                <Box display="flex" p={1} style={{ marginLeft: '38%' }}>
                  <Box p={1} style={{ textAlign: 'center' }} justifyContent={"center"}>
                    <Button
                      justIcon
                      round
                      color="primary"
                      onClick={setPreviousQuestion}
                      disabled={currentQuestionIndex == 0 && pageNumber == 1 || updateQuestionChoices.isLoading}
                    >
                      <ArrowBackIcon />
                    </Button>

                    <span style={{ marginInline: '18px', color: 'black', fontWeight: '700', fontFamily: 'Roboto, Helvetica, Arial, sans-serif', fontSize: '12px' }}>
                      Questions {(pageNumber - 1) * pageSize + currentQuestionIndex + 1} of {qanswers['$values'] == undefined ? '' : qanswers['$values'][currentQuestionIndex]?.totalQuestions}
                    </span>

                    {console.log("check", props.providerType, props.providerIdHeirarchy, props.providerId)}
                    {(pageNumber - 1) * pageSize + currentQuestionIndex + 1 >= totalQuestions ?? 0 ? (
                      <Button color="primary" round onClick={handleSubmit} disabled={props.providerType === 1 && props.providerIdHeirarchy !== props.providerId}>
                        submit
                      </Button>
                    ) :
                      <Button
                        justIcon
                        round
                        color="primary"
                        onClick={setNextQuestion}
                        disabled={(pageNumber - 1) * pageSize + currentQuestionIndex + 1 >= totalQuestions || updateQuestionChoices.isLoading}
                      >
                        <ArrowForwardIcon />
                      </Button>
                    }
                  </Box>
                </Box>
              </GridItem>
            }

          </GridItem>
      }
      {showAlert}
    </GridContainer>

  );
};

export default QuestionWrapper;
