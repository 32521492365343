import axios from "axios";
import { configUrl } from "config.js";
import { useMutation, useQueryClient } from "react-query";

export default function useDecreasePoolSequence() {
    let queryClient = useQueryClient();
    return useMutation(async (props) => {
        await axios.put(
            `${configUrl.AssessmentServer}/api/TestPoolMappings/decreaseSequence/${props.testId}/${props.poolId}`
        );
    },
        {
            onSuccess: () => {
                queryClient.invalidateQueries("questionsByPoolId");
            },
        }
    );
}
