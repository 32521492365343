import React from "react";
// used for making the prop types of this component
import { makeStyles } from "@material-ui/core/styles";

import * as XLSX from "xlsx";

// core components
import Button from "components/CustomButtons/Button.js";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import { FileCopy } from "@material-ui/icons";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import styles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import { LoginContext } from "contexts/LoginContext";
import CryptoJS from 'crypto-js';
import useBulkUploadUsers from "hooks/userHooks/useBulkUploadUsers";
import SweetAlert from "react-bootstrap-sweetalert";

// import { config } from ;
const useStyles = makeStyles(styles);

export default function UserFileUpload(props) {
  console.log(props, 'props in userfileUpload')
  const { mutate: bulkUpload, isSuccess, isLoading, isError, error } = useBulkUploadUsers();

  const [file, setFile] = React.useState(null);
  const [fileName, setFileName] = React.useState("");
  const classes = useStyles();
  console.log("classes", classes)
  const [alert, setAlert] = React.useState(null);
  const [payload, setPayload] = React.useState(null);
  const [errorLength, setErrrLength] = React.useState(null)
  console.log(payload)
  const [defaultEvents, setDefaultEvents] = React.useState();
  const [showAlert, setShowAlert] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  let { user, providerId } = React.useContext(LoginContext);

  let fileInput = React.createRef();
  const key = 'pj23vs7nycq18uew';
  let secretKey = CryptoJS.enc.Utf8.parse(key);

  const encryptPassword = (password, secretKey) => {

    let encryptedBytes = CryptoJS.AES.encrypt(password, secretKey, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
    let encryptedString = encryptedBytes.toString();

    console.log(encryptedString, 'encryptedone');
    return encryptedString;
    // return CryptoJS.AES.encrypt(password, secretKey).toString();
  }
  const decryptPassword = (encryptedPassword, secretKey) => {

    let decryptedBytes = CryptoJS.AES.decrypt(encryptedPassword, secretKey, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
    let decryptedText = decryptedBytes.toString(CryptoJS.enc.Utf8);

    console.log(decryptedText, 'decryptedone');
    return decryptedText;
    // const bytes = CryptoJS.AES.decrypt(encryptedPassword, secretKey);
    // return bytes.toString(CryptoJS.enc.Utf8);
  }
  const map = {
    // UserName: "userName",
    FullName: "fullName",
    DOB: "dob",
    Email: "email",
    Pincode: "pinCode",
    // FirstName: "firstName",
    // LastName: "lastName",
    Passcode: "passCode",
    // Email: "email",
    // Nativelanguage:"nativeLanguage",
    // Eduboard:"edubord",
    Mobile: "mobile",
    Gender: "gender",
    // Active: "active",
    // Address: "address",
    // state:props.state
  };
  console.log(map)
  const setSelectedEvents = (e) => {
    setDefaultEvents(e);
  };

  const getMappedName = (d) => {
    if (d in map) {
      console.log(d)
      return map[d];
    }

    return null;
  };

  const validatePayload = (payload) => {
    console.log(payload, 'recv.payload')
    let filteredData = [];
    let errors = [];
    if (payload) {
      payload.forEach((x, i) => {

        if (x?.userTypeId == 4) {
          // if (!x.questionChoices || x.questionChoices.length == 0) {
          if (!x.fullName || x?.fullName.length == 0) {
            errors.push(<div style={{ textAlign: 'left', marginLeft: '10%' }}>Missing <strong>name</strong> for {i + 1} no. student</div>);
            // return;
          }
          if (!x.dob || x?.dob.length == 0) {
            errors.push(<div style={{ textAlign: 'left', marginLeft: '10%' }}>Missing <strong>DOB</strong> for {x.fullName}</div>);
            // return;
          }
          if (!x.email || x?.email.length == 0) {
            errors.push(<div style={{ textAlign: 'left', marginLeft: '10%' }}>Missing <strong>email</strong> for {x.fullName}</div>);
            // return;
          }
          if (!x.mobile || x?.mobile.length == 0) {
            errors.push(<div style={{ textAlign: 'left', marginLeft: '10%' }}>Missing <strong>mobile</strong> for {x.fullName}</div>);
            // return;
          }
          if (!x.gender || x?.gender == 0 || x.gender == null) {
            errors.push(<div style={{ textAlign: 'left', marginLeft: '10%' }}>Missing <strong>gender</strong> for {x.fullName}</div>);
            // return;
          }
          if (!x.passCode || x?.passCode.length == 0) {
            errors.push(<div style={{ textAlign: 'left', marginLeft: '10%' }}>Missing <strong>Passcode</strong> for {x.fullName}</div>);
          }
          if (x?.fullName?.length > 0 && x?.dob?.length > 0 && x?.email?.length > 0 && x?.mobile?.length > 0 && (x.gender && x?.gender > 0 && x.gender != null) && x?.passCode?.length > 0) {
            filteredData.push(x);
          }
        }
      });
      console.log(filteredData, 'filData')
      console.log(errors, 'errorsData')
      setErrrLength(errors.length)
      let comObj = {
        filteredData, errors
      }
      console.log(comObj, 'comObj')
      return comObj;
      // return errors;
    }
  };

  const onImportOtherStudents = (validateResult) => {
    let use = { studentInfo: validateResult?.filteredData, testId: props.testId ?? 0, classId: props.class, eduBoardId: props.eduBoard, nativeLanguageId: props.language, interestId: 0, CourseIds: props.course }
    console.log("use...", use)

    bulkUpload(use);
    return
  }

  const handleChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    console.log(file)
    reader.onload = (e) => {
      const btsr = e.target.result;
      const wb = XLSX.read(btsr, { type: "binary" });
      const wbname = wb.SheetNames[0];

      console.log(wb.SheetNames);

      let mappedData = [];

      wb.SheetNames.forEach((x) => {
        let data = createPayload(x, wb);
        mappedData = mappedData.concat(data);
      });

      console.log("mappedData..", mappedData);
      props.func(mappedData)
      let validateResult = validatePayload(mappedData);
      console.log(validateResult?.errors, 'is error occured');

      // TODO
      if (validateResult?.errors.length > 0) {
        // setErrorMessage(`${errors}. Fix file and try again `);
        // setShowAlert(true);
        var errMessages = validateResult.errors.map(error => {
          return error.props.children.map(child =>
            typeof child === 'string' ? child : child.props.children
          ).join('');
        }).join('\n');
        setAlert(
          <SweetAlert
            style={{ display: "block", marginTop: "-100px" }}
            title="Upload Failed"
            onConfirm={() => onImportOtherStudents(validateResult)}
            onCancel={() => setAlert(null)}
            confirmBtnCssClass={classes.button + " " + classes.danger}
          >
            <div style={{ marginTop: '77px', marginLeft: '55%', alignContent: 'end', position: 'absolute' }}>
              <FileCopy
                onClick={() => {
                  navigator.clipboard.writeText(`${errMessages}\n`);
                  alert('Copied to clipboard!');
                }}
                fontSize='small'
                titleAccess="Copy All"
                style={{ cursor: 'pointer', color: "#757575", position: "absolute", marginTop: '1px', marginLeft: '10px' }}
                onMouseEnter={(e) => e.target.style.color = "#424242"}
                onMouseLeave={(e) => e.target.style.color = "#757575"}
              />
            </div>

            {validateResult?.errors?.slice(0, 4)}
            {validateResult?.errors?.length > 4 ? `...and ${validateResult?.errors?.length - 4} more` : ''}

            <div style={{ marginTop: "10%", marginLeft: '10%', textAlign: 'left', fontWeight: '500' }}>Note: Click ‘OK’ to import the rest of the students.</div>
          </SweetAlert>
        );
        // return;
      } else {
        let use = { studentInfo: validateResult?.filteredData, testId: props.testId ?? 0, classId: props.class, eduBoardId: props.eduBoard, nativeLanguageId: props.language, interestId: 0, CourseIds: props.course }
        console.log("use...", use)

        bulkUpload(use);
      }

    };

    reader.onloadend = () => {
      setFile(file);
      setFileName(file.name);
    };

    reader.readAsBinaryString(e.target.files[0]);

    e.target.value = ""
  };

  const hideAlert = async () => {
    setAlert(null);
    props && props?.afterFinish ?
      await props?.afterFinish() : null
  };

  const createPayload = (sheetName, wb) => {
    const ws = wb.Sheets[sheetName];

    let data = XLSX.utils.sheet_to_json(ws, {
      header: 1,
      raw: false,
      blankrows: false,
      defval: "",
    });

    let header = data[0];

    let filteredData = data.filter((_, i) => i > 0);
    console.log("filteredData", filteredData);
    let mappedData = [];
    let mapped = {};
    function formatDate(d) {

      const date = new Date(d);
      const futureDate = date.getDate();
      date.setDate(futureDate);
      const defaultValue = date.toLocaleDateString('en-CA');
      return defaultValue;

    }

    //questionChoices: new Array(6)
    let choiceCount = 0;

    for (let i = 0; i < filteredData.length; i++) {
      if (filteredData[i][11] == "TRUE") {
        filteredData[i][11] = Boolean(1);
      } else {
        filteredData[i][11] = Boolean(0);
      }

      // if (filteredData[i][0] && !filteredData[i][1]) {
      //   continue;
      // }

      if (filteredData[i][0]) {
        mapped = {};
        //questionChoices: new Array(6)
        choiceCount = 0;
      }
      console.log(filteredData, 'filteredData')
      filteredData[i].map((x, j) => {
        if (x) {
          let k = getMappedName(header[j]);

          if (k != "dob") {
            if (k) mapped[k] = x;
          }
          else {
            x = formatDate(x);
            if (k) mapped[k] = x;
          }
        }
      });
      // TODO
      if (filteredData[i]) { //filteredData[i][0]
        console.log(mapped, 'mappeddata')
        mappedData.push({
          ...mapped,
          state: props.state,
          city: props.city,
          isActive: true,
          userName: mapped.email,
          passCode: props.passCode?.length > 0 ? encryptPassword(props.passCode, secretKey) : encryptPassword(mapped.passCode, secretKey),
          dob: mapped.dob ?? "",
          fullName: mapped.fullName ?? "",



          gender: mapped.gender == "Male" ? 1 : mapped.gender == "Female" ? 2 : mapped.gender?.length > 0 ? 3 : '', // == 'Male' ? 1 ? mapped.gender === "Female" : 2 : 3,
          createdBy: user,
          createdDate: new Date(),
          userTypeId: props.userTypeId,
          providerId: providerId,
          testId: props.testId ?? 0,
          updatedBy: user,
          updatedDate: new Date()
        });
      }
    }

    console.log("mappedData", mappedData);
    return mappedData;
  };

  const successAlert = () => {
    setAlert(
      <SweetAlert
        style={{ display: "block", marginTop: "-100px" }}
        title="Upload Success"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.success}
      >
        File has been uploaded.
      </SweetAlert>
    );
  };

  const errorAlert = (errorResponse) => {
    console.log(errorResponse, 'errorResponse1')
    const errorMessages = errorResponse?.$values?.map((err, index) => {
      const itemsToShow = err.content.$values?.slice(0, 4);
      const remainingItems = err.content.$values?.length - itemsToShow?.length;
      const allItems = err.content.$values?.join(", ");

      return (
        <div key={index} style={{ marginBottom: "10px" }}>
          <strong>{err.message}</strong>
          <FileCopy
            onClick={() => copyToClipboard(allItems)}
            fontSize='small'
            titleAccess="Copy All"
            style={{ cursor: 'pointer', color: "#757575", position: "absolute", marginTop: '1px', marginLeft: '10px' }}
            onMouseEnter={(e) => e.target.style.color = "#424242"}
            onMouseLeave={(e) => e.target.style.color = "#757575"}
          />
          <ul>
            {itemsToShow.map((contentItem, i) => (
              <li key={i} style={{ listStyleType: "none" }}>{contentItem}</li>
            ))}
            {remainingItems > 0 && (
              <li style={{ listStyleType: "none" }}>...and {remainingItems} more</li>
            )}
          </ul>

        </div>
      );
    });
    setAlert(
      <SweetAlert
        style={{ display: "block", marginTop: "-100px" }}
        title="Upload Failed"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.danger}
      >
        {/* Failed to upload. Please check file for duplicate username or Mobile number */}
        {errorMessages || "Failed to upload. Please check file for duplicate username or Mobile number."}
      </SweetAlert>
    );
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      console.log("Copied to clipboard");
    }, (err) => {
      console.error("Failed to copy text: ", err);
    });
  };

  // eslint-disable-next-line
  const handleSubmit = (e) => {
    e.preventDefault();
    // file is the file/image uploaded
    // in this function you can save the image (file) on form submit
    // you have to call it yourself
  };
  const handleClick = () => {
    fileInput.current.click();
  };

  const handleUpload = async () => {
    console.log("handleUpload started");
    // let response = await taskService.createTask(1000,fileName,"BULK UPLOAD","QUESTION IMPORT")
    //console.log("response",response)

    if (payload) {
      console.log("posting payload", payload);
      bulkUpload(payload);
    }

    //await taskService.updateTask(response)
  };

  React.useEffect(() => {
    if (errorLength == 0 && isSuccess == true) {
      successAlert();
      setFile(null);
    }
  }, [isSuccess]);

  React.useEffect(() => {
    if (isError == true) {
      console.log();
      console.log("rsvm", error?.response?.data);
      errorAlert(error?.response?.data);
      setFile(null);
    }
  }, [isError]);

  return (
    <div>
      {alert}
      <input
        type="file"
        onChange={handleChange}
        ref={fileInput}
        accept=".xls,.xlsx"
        style={{ display: "none" }}
      />

      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'right' }}>
            <Button
              color="rose"
              onClick={() => handleClick()}
              style={{ marginTop: 20, textTransform: "none" }}
              disabled={isLoading == true}
            >
              <CloudUploadIcon />
              {isLoading == true
                ? "Uploading..."
                : props.label || "Import"}
            </Button>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}

