import CircularProgress from '@material-ui/core/CircularProgress';
import { CloudUploadSharp } from "@material-ui/icons";
import CustomModal from "NewComponents/CustomModal/CustomModal";
import TestPlayer from "NewComponents/TestPlayer";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button";
import CustomTree from "components/CustomTree/CustomTree";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { LoginContext } from "contexts/LoginContext";
import useAddCategory from 'hooks/categoryHooks/useAddCategory';
import useGetCategories from "hooks/categoryHooks/useGetCategories";
import useAddTopic from "hooks/topicHooks/useAddTopic";
import { useContext, useState } from "react";
import QuestionBulkImport from "./QuestionBulkImport/QuestionBulkImport";

export default function CategoryTreeView() {
  const { userPrivileges, setUserPrivileges, providerId, providerType } = useContext(LoginContext);
  const { data: categories, isLoading, refetch } = useGetCategories(true);
  // const { data: questionData,isLoading:questionDataLoading } = useGetQuestions();
  // console.log("questionsDetails",questionData)
  const [userEditForm, setUserEditForm] = useState(true);
  const [qAnswersForm, setQAnswersForm] = useState(true);
  const [flag, setFlag] = useState(false)
  const [showAlert, setShowAlert] = useState(null);
  const [isCategoryPresent, setIsCategoryPresent] = useState(false);


  console.log("categories", categories)
  const matchedCategory = categories ? categories.find(category => category?.providerId === providerId) : [];
  console.log("cId", matchedCategory);
  console.log("Privilege", userPrivileges)
  const { mutate: addCategory, isLoading: categoryUpdating } = useAddCategory();
  const { mutate: addTopic, isLoading: topicUpdating } = useAddTopic();

  // React.useEffect(() => {
  //   console.log(answerModalTitle, "answerModalTitle")
  // }, [answerModalTitle]);

  // useEffect(() => {
  //   if (qusetionBulkUpload.isError == true) {

  // setShowAlert(
  //   <CustomAlert
  //     open={true}
  //     openFlag={() => setFlag(flag ? false : true)}
  //     severity="error"
  //     alertMessage={"Error occured"}
  //   />
  // )
  //   }
  // }, [qusetionBulkUpload.isError])
  const handleCategoryCheck = (result) => {
    console.log("categoryPresent", result);
    setIsCategoryPresent(result);
  };

  const createUserEditForm = (userId, title) => {

    setUserEditForm(
      <CustomModal
        modal={true}
        setModal={() => hideUserEditForm()}
        content={
          <QuestionBulkImport
            closeForm={() => hideUserEditForm()}
            categoryId={categories && matchedCategory?.id}
          />
        }
        cancelButtonMessage="cancel"
        okButtonMessage="Create"
        showOk={false}
        onCancelClick={() => hideUserEditForm()}
        title={title}
        maxWidth="lg"
        fullWidth={true}
      />
    );
  };
  const setAnswersForm = (userId, title) => {

    setQAnswersForm(
      <CustomModal
        modal={true}
        setModal={() => hideQSetAnsForm()}
        content={
          <div>
            {
              //     questionDataLoading?
              //     <>
              //     <CircularProgress style={{marginInline:'45%'}}/>
              // <h6 style={{marginInline:'45%'}}>Loading...</h6>
              //     </>

              //      :
              <TestPlayer
                // questions={questionData == undefined?'':questionData}
                afterfinish={() => hideQSetAnsForm()}
                topicId={0}
              //providerIdHeirarchy={}
              // providerType={providerType}
              // providerId={providerId}
              />
            }
          </div>
        }
        cancelButtonMessage="cancel"
        okButtonMessage="Create"
        showOk={false}
        onCancelClick={() => hideQSetAnsForm()}
        title={title}
        maxWidth="lg"
        fullWidth={true}
      />
    );
  };
  const hideUserEditForm = () => {
    setUserEditForm(false);
  };
  const hideQSetAnsForm = () => {
    setQAnswersForm(false);
  };

  const isPrivilege = (x) => {
    if (userPrivileges?.Privileges?.CategoryTopic.indexOf(x) !== -1) {
      return true;
    }
    else {
      return false;
    }
  }

  return (
    <Card style={{ height: 1500, overflowY: 'scroll' }}>
      <CardBody>
        <h1>{(categoryUpdating || topicUpdating || isLoading) && <CircularProgress color="primary" style={{ marginInline: '45%' }} />}</h1>
        {/* <h1>Import {(categoryUpdating || topicUpdating || isLoading) && <CircularProgress color="primary" size="1.5rem"/>}</h1> */}

        {/* <Divider /> */}
        {isLoading && <h6 style={{ marginInline: '45%' }}>Loading...</h6>}

        {!isLoading && (
          <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
              <CustomTree
                rawData={categories && categories.length > 0 ? categories : null}
                addCategory={addCategory}
                addTopic={addTopic}
                refresh={refetch}
                onCheckCategory={handleCategoryCheck}

              />
            </GridItem>
            <GridItem xs={12} sm={6} md={4}>
              {
                isPrivilege("BulkImport") == true ?
                  < Button
                    color="rose"
                    onClick={() => {
                      createUserEditForm(null, "Import Questions");
                      //getQ();
                    }}
                    style={{ marginTop: 10, marginLeft: 20, marginBottom: 22, textTransform: "none" }}
                    disabled={categories && categories.length == 0}
                  >
                    <CloudUploadSharp /> Bulk Import
                  </Button>
                  : ''
              }
              {
                isPrivilege("SetAnswer") == true && isCategoryPresent == true ?
                  <Button
                    color="rose"
                    onClick={() => {
                      setAnswersForm(null, "Set Answers");
                    }}
                    style={{ marginTop: 10, marginLeft: 20, marginBottom: 22, textTransform: "none" }}
                    disabled={categories && categories.length == 0}
                  >
                    Set Answers
                  </Button> : ''
              }
            </GridItem>

          </GridContainer>
        )}
      </CardBody>
      {showAlert}
      {userEditForm}
      {qAnswersForm}
    </Card >
  );
}
