import { CircularProgress, makeStyles } from "@material-ui/core";
import { WarningOutlined } from "@material-ui/icons";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { Tooltip } from "@mui/material";
import styles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import CustomAlert from "components/CustomAlert/CustomAlert";
import Button from "components/CustomButtons/Button";
import CustomDataGrid from "components/CustomDataGrid.js/CustomDataGrid";
import CustomModal from "components/CustomModal/CustomModal";
import { LoginContext } from "contexts/LoginContext";
import useGetAssignedTestsofCourses from "hooks/courseHooks/useGetAssignedTestsofCourses";
import useGetTestsForCourse from "hooks/courseTestMappingHooks/useGetTestsForCourse";
import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import deleteTestMappedToCourse from "services/TestSeriesService/deleteTestMappedToCourse";
import TestMappingGrid from "views/Components/TestMappingGrid";



const useStyles = makeStyles(styles);

const CourseTestMapping = React.forwardRef((props, ref) => {
  const classes = useStyles();

  const [courseId, setCourseCode] = React.useState(props.st.courseId);
  console.log(courseId, props, 'stateProps')
  const [alert, setAlert] = React.useState(null);

  // const {
  //   data: testDetails,
  //   isLoading,
  //   refetch: getAssignedTests,
  // } = useGetAssignedTestsForCourse(props.st.courseId, props?.allStates?.details?.objective, props?.allStates?.details?.targetAudience);
  const {
    data: testDetails,
    isLoading,
    refetch: getAssignedTests,
  } = useGetAssignedTestsofCourses(props.st.courseId);
  console.log(testDetails, 'testDetailsinMappedTests')
  const { data: mappedTests, isError, error, refetch } = useGetTestsForCourse(
    props.st.courseId
  );
  const { licensePackageObject } = React.useContext(LoginContext);
  const [cols, setCols] = React.useState([]);
  const [selected, setSelection] = React.useState([]);

  const [retryCount, useSetRetryCount] = React.useState(0);

  const [selectionModel, setSelectionModel] = React.useState([]);

  const [flag, setFlag] = React.useState(false)
  const [showAlert, setShowAlert] = React.useState(null)
  const [testImportModal, setTestImportModal] = React.useState(null);
  const [removingTests, setRemovingTests] = React.useState(false);

  const removeTest = async (cid, id) => {
    setRemovingTests(true)
    let deleteResponse = await deleteTestMappedToCourse.deleteTestMappedToCourse(cid, id);
    setAlert(null)
    setRemovingTests(false);
    console.log(deleteResponse, 'deleteResponse123')
    getAssignedTests();
    console.log('delete test id is ', id)
  }
  React.useEffect(() => {
    if (
      testDetails &&
      props.allStates &&
      props.allStates.configuration &&
      props.allStates.configuration.isDynamic == true
    ) {
      console.log("im in here");
      console.log("testDetails", testDetails);
      setSelection(testDetails.map((x) => x.testId));
      return;
    }

    if (mappedTests) {
      setSelection(mappedTests.map((x) => x.testId));
    }
  }, [mappedTests, testDetails]);

  React.useEffect(() => {
    if (isError == true) {
      console.log(error);
    }
  }, [isError]);

  React.useEffect(() => {
    setCourseCode(props.st.courseId)
    console.log("yo", props);
    getAssignedTests();
    refetch();
  }, [props, ref]);

  React.useEffect(() => {
    let columns = [
      {
        field: "testId",
        headerName: "Id",
        editable: false,
        width: 100,
        hide: true,
      },
      {
        field: "code",
        headerName: "Code",
        editable: false,
        width: 180,
      },
      {
        field: "name",
        headerName: "Test",
        editable: false,
        width: 300,
      },
      {
        field: "difficultyLevel",
        headerName: "Difficulty Level",
        editable: false,
        width: 180,
        renderCell: (cellValues) => {
          if (!cellValues.row.difficultyLevel) {
            return "Medium";
          }
          return cellValues.row.difficultyLevel == "1"
            ? "Easy"
            : cellValues.row.difficultyLevel == "2"
              ? "Medium"
              : "Difficult";
        },
      },
      {
        field: "providerName",
        headerName: "Provider Name",
        editable: false,
        width: 180,
      },
      {
        field: "objective",
        headerName: "Objective",
        editable: false,
        width: 200,
        // renderCell: (cellValues) => {
        //   console.log("courseTestCellVals",cellValues)
        //   return (
        //     <span>{cellValues.row.objective}</span>
        //   );
        // },
      },
      {
        field: "targetAudience",
        headerName: "Target Audience",
        editable: false,
        width: 200,
      },
      {
        field: "actions",
        headerName: "Actions",
        editable: false,
        width: 200,
        filterable: false,
        sortable: false,
        renderCell: (cellValues) => (
          <>
            {
              <Tooltip title="Delete">
                <Button
                  size="sm"
                  justIcon
                  round
                  color="reddit"
                  onClick={(e) => {
                    e.preventDefault();
                    setAlert(
                      <SweetAlert
                        style={{ display: "block", marginTop: "-100px" }}
                        title={''}
                        onConfirm={() => removeTest(cellValues.row.courseId, cellValues.row.testId)}
                        showCancel={true}
                        cancelBtnText={'CANCEL'}
                        confirmBtnText={'OK'}
                        disabled={removingTests}
                        onCancel={() => setAlert(null)}
                        confirmBtnCssClass={classes.button + " " + classes.success}
                        cancelBtnCssClass={classes.button + " " + classes.warning}
                      >
                        <div style={{ display: 'inline-flex' }}>
                          <WarningOutlined style={{ marginTop: '10px', marginRight: '5px', color: 'brown' }} />
                          <h4> Are you sure you want to delete the test from course ?</h4>
                        </div>
                      </SweetAlert>
                    );
                  }}
                // disabled={cellValues.row.providerId == providerId ? cellValues.row.isLocked == true ? cellValues.row.lockedBy == user ? false : true : cellValues.row.status >= 3 ? true : false : true}
                >
                  <DeleteForeverIcon />
                </Button>
              </Tooltip>
            }
          </>
        ),
      },
    ];

    setCols(columns);
  }, []);

  React.useImperativeHandle(ref, () => ({
    isValidated: () => {
      return isValidated();
    },
    sendState: () => {
      return sendState();
    },
    state: {
      selected,
      type: "test",
    },
  }));
  const sendState = () => {
    return {
      selected,
      type: "test",
    };
  };

  const isValidated = () => {
    if (testDetails && testDetails?.length > 0) {
      return true;
    }
    else if (testDetails.length <= 0) {
      console.log('not selected')
      setShowAlert(
        <CustomAlert
          open={true}
          openFlag={() => setFlag(flag ? false : true)}
          severity="error"
          alertMessage={"Please select test(s)"}
        />
      )
      return false
    }
  };

  const handleEdit = (p, e) => { };

  const onCellEditCommit = (p) => {
    let row = questionData.questions.find((x) => x.id == p.id);
    if (row[p.field] == p.value) {
      return;
    }
  };

  const handleRowSelection = (e) => {
    setSelection(e);
  };

  const getRowClassName = (row) => {
    if (row.isLocked == true) {
      return "gray";
    }

    return null;
  };
  const createImportTestModal = () => {
    let title = "Add Tests";
    console.log("testpackageDetails", testDetails, licensePackageObject?.TestSeriesTests);
    if (testDetails) {
      if ((testDetails?.length + 1) > licensePackageObject?.TestSeriesTests) {
        setShowAlert(
          <CustomAlert
            open={true}
            openFlag={() => setFlag(flag ? false : true)}
            severity="warning"
            alertMessage={`Please upgrade to higher package to add more than ${licensePackageObject?.TestSeriesTests} tests.`}
          />
        )
        return;
      }
    }
    setTestImportModal(
      <CustomModal
        modal={true}
        setModal={() => hideImportTestModal()}
        content={
          <TestMappingGrid testObjs={props?.allStates} onFinish={hideImportTestModal} courseId={props.st.courseId} objective={props?.allStates?.details?.objective} targetAudience={props?.allStates?.details?.targetAudience} testCount={testDetails?.length} />
        }
        cancelButtonMessage="cancel"
        okButtonMessage="Create"
        showOk={false}
        onCancelClick={() => hideImportTestModal()}
        title={title}
        maxWidth="lg"
        fullWidth={true}
      />
    );
  };
  const hideImportTestModal = () => {
    setTestImportModal(null);
    getAssignedTests();

  };
  return (
    <div>
      {showAlert}
      {alert}
      {testImportModal}
      <div xs={12} sm={12} md={12} style={{ textAlign: "right" }}>
        <Button color="primary" onClick={createImportTestModal}>
          Add Tests
        </Button>
      </div>
      {isLoading == false && testDetails ? (
        <div>
          <div style={{ height: 500, width: "100%" }}>
            <CustomDataGrid
              loading={isLoading}
              columns={cols}
              rows={testDetails}
              autoPageSize={true}
              onEditCellPropsChange={handleEdit}
              onCellEditCommit={onCellEditCommit}
              onSelectionModelChange={handleRowSelection}
              // checkboxSelection
              getRowClassName={getRowClassName}
            // selectionModel={selected}
            />
          </div>
        </div>
      ) :
        isLoading == false ? <h5 style={{ textAlign: 'center' }}>Tests not assigned</h5> :
          (
            <CircularProgress style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', textAlign: 'center' }} />
          )}
    </div>
  );
});

CourseTestMapping.displayName = "Step3";

export default CourseTestMapping;
