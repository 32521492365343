import { Tooltip, makeStyles } from "@material-ui/core";
import { ListAlt } from "@material-ui/icons";
import CheckIcon from '@material-ui/icons/Check';
import DescriptionIcon from '@material-ui/icons/Description';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import ScheduleIcon from '@material-ui/icons/Schedule';
import axios from "axios";
import { configUrl } from "config.js";
import React, { useContext, useEffect, useState } from "react";
// import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import GetAppIcon from '@material-ui/icons/GetApp';
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import Button from "components/CustomButtons/Button";
import CustomDataGrid from "components/CustomDataGrid.js/CustomDataGrid";
import CustomModal from "components/CustomModal/CustomModal";
import GridContainer from "components/Grid/GridContainer.js";
import { LoginContext } from "contexts/LoginContext";
import taskService from "services/taskService";
import TaskDetails from "./TaskDetails";
const useStyles = makeStyles((theme) => ({
  tooltip: {
    fontSize: "1em",
  },
}));
export default function TaskManager(props) {
  // const { isLoading, data: tasks } = useGetAllTasks();
  // console.log(tasks, 'tasks resp')
  const classes = useStyles();
  const [pageState, setPageState] = React.useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 10
  })
  const [columns, setColumns] = React.useState([]);
  const [taskDetailsMap, setTaskDetailsMap] = React.useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { userPrivileges } = useContext(LoginContext);

  const fetchTaskData = async (pageNumber, pageSize) => {
    setPageState(old => ({ ...old, isLoading: true }))
    let getAllTasks = await taskService.getAllTasks(pageNumber, pageSize);
    console.log(getAllTasks, 'getObjective1234')
    setPageState(old => ({ ...old, isLoading: false, data: getAllTasks, total: getAllTasks?.length > 0 ? getAllTasks[0].totalCount : 0 }))
  }
  useEffect(() => {
    fetchTaskData(pageState.page, pageState.pageSize)
  }, [pageState.page])
  const isPrivilege = (x) => {
    if (userPrivileges?.Privileges?.TaskManager.indexOf(x) !== -1) {
      return true;
    }
    else {
      return false;
    }
  }
  const showTaskDetailsMapModal = (x) => {
    console.log(x, 'clickrowval')
    setTaskDetailsMap(
      <CustomModal
        modal={true}
        setModal={() => hideTaskDetailsModal()}
        padding1={true}
        content={
          <TaskDetails
            // state={JSON.parse(x.outputDetails)}
            taskId={x.taskId}
            afterFinish={() => {
              hideTaskDetailsModal();
            }}
          />
        }
        showOk={false}
        showCancel={false}
        onCancelClick={() => hideTaskDetailsModal()}
        title={`${x?.taskDetails}`}
        maxWidth="xl"
        showHeaderBackground={true}

      />
    );
  };

  const hideTaskDetailsModal = () => {
    setTaskDetailsMap(null);
  };

  useEffect(() => {
    let cols = [
      {
        field: "taskStatus",
        headerName: "Status",
        editable: false,
        width: 100,
        filterable: false,
        hideSortIcons: true,
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        disableColumnMenu: true,
        renderCell: (cellValues) => {
          return (
            cellValues.row.taskStatus == 0 ?
              (
                <Tooltip classes={{ tooltip: classes.tooltip }} title={"Draft"}>
                  <ScheduleIcon style={{ color: "#F7CA26" }} />
                </Tooltip>
              ) : cellValues.row.taskStatus == 1 ?
                (
                  <Tooltip classes={{ tooltip: classes.tooltip }} title={"Started"}>
                    <CheckIcon style={{ color: "#FD940C" }} />
                  </Tooltip>
                ) : cellValues.row.taskStatus == 2 ?
                  (
                    <Tooltip classes={{ tooltip: classes.tooltip }} title={"Success"}>
                      <DoneAllIcon style={{ color: "#53AD57" }} />
                    </Tooltip>
                  ) :
                  cellValues.row.taskStatus == 3 ?
                    (
                      <Tooltip classes={{ tooltip: classes.tooltip }} title={"Failed"}>
                        <ErrorOutlineIcon style={{ color: "red" }} />
                      </Tooltip>
                    )
                    :
                    null
          )
        }
      },
      {
        field: "taskId",
        headerName: "Id",
        editable: false,
        hide: true,
        width: 100,
      },
      {
        field: "taskType",
        headerName: "Task Type",
        editable: false,
        width: 250,
      },
      {
        field: "priority",
        headerName: "Priority",
        editable: false,
        filterable: false,
        hideSortIcons: true,
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        disableColumnMenu: true,
        hide: true,
        width: 100,
      },
      {
        field: "taskArguments",
        headerName: "File Name",
        editable: false,
        width: 300,
        hide: true,
      },
      {
        field: "taskDetails",
        headerName: "Task Details",
        editable: false,
        width: 250,
        renderCell: (cellValues) => {
          console.log(cellValues, 'cells')
          return (
            <Tooltip classes={{ tooltip: classes.tooltip }} title={cellValues.row.taskDetails ?? 'none'}>
              <span>
                {cellValues.row.taskDetails ?
                  cellValues.row.taskDetails?.substr(0, 30) : 'none'
                }
              </span>
            </Tooltip>
          )
        }
      },
      {
        field: "scheduleDate",
        headerName: "Schedule Date",
        editable: false,
        width: 200,
        renderCell: (cellValues) => {
          if (cellValues.row.scheduleDate) {
            console.log(typeof (cellValues.row.scheduleDate), 'dates', cellValues.row.scheduleDate)
            // const utcDateTime = new Date(cellValues.row.scheduleDate);
            // const istOffset = 5.5 * 60 * 60 * 1000; // IST is UTC+5.5 hours
            // const istDateTime = new Date(utcDateTime.getTime() + istOffset);

            // console.log('originalCellrows', cellValues.row)
            // console.log(istDateTime, 'convertedDate', 'original', cellValues.row.scheduleDate)
            // return istDateTime.toLocaleString();
            const date = new Date(cellValues.row.scheduleDate);
            const localDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60 * 1000));
            const dateTime = new Date(localDate);
            // Get individual date and time components
            const day = dateTime.getDate();
            const month = dateTime.toLocaleString('default', { month: 'short' });
            const year = dateTime.getFullYear();
            let hours = dateTime.getHours();
            const minutes = String(dateTime.getMinutes()).padStart(2, '0');
            const seconds = String(dateTime.getSeconds()).padStart(2, '0');
            const ampm = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12;
            hours = String(hours ? hours : 12).padStart(2, '0');

            return `${day}-${month}-${year} ${hours}:${minutes}:${seconds} ${ampm}`;
          } else {
            return "none"
          }
          // return cellValues.row.scheduleDate
          //   ? cellValues.row.scheduleDate.slice(0, 19).replace("T", " ")
          //   : <i> - </i>;

        },
      },
      {
        field: "startDate",
        headerName: "Start Date",
        editable: false,
        width: 200,
        renderCell: (cellValues) => {
          if (cellValues.row.startDate) {
            console.log(typeof (cellValues.row.startDate), 'dates', cellValues.row.startDate)

            // const utcDateTime = new Date(cellValues.row.startDate);
            // const istOffset = 5.5 * 60 * 60 * 1000; // IST is UTC+5.5 hours
            // const istDateTime = new Date(utcDateTime.getTime() + istOffset);

            // console.log('originalCellrows', cellValues.row)
            // console.log(istDateTime, 'convertedDate', 'original', cellValues.row.startDate)
            // return istDateTime.toLocaleString();
            const date = new Date(cellValues.row.startDate);
            const localDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60 * 1000));
            const dateTime = new Date(localDate);
            // Get individual date and time components
            const day = dateTime.getDate();
            const month = dateTime.toLocaleString('default', { month: 'short' });
            const year = dateTime.getFullYear();
            let hours = dateTime.getHours();
            const minutes = String(dateTime.getMinutes()).padStart(2, '0');
            const seconds = String(dateTime.getSeconds()).padStart(2, '0');
            const ampm = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12;
            hours = String(hours ? hours : 12).padStart(2, '0');

            return `${day}-${month}-${year} ${hours}:${minutes}:${seconds} ${ampm}`;
          } else {
            return "none"
          }
          // return cellValues.row.startDate
          //   ? cellValues.row.startDate.slice(0, 19).replace("T", " ")
          //   : <i> none </i>;
        },
      },
      {
        field: "endDate",
        headerName: "End Date",
        editable: false,
        width: 200,
        renderCell: (cellValues) => {
          if (cellValues.row.endDate) {
            console.log(typeof (cellValues.row.endDate), 'dates', cellValues.row.endDate)
            // const utcDateTime = new Date(cellValues.row.endDate);
            // const istOffset = 5.5 * 60 * 60 * 1000; // IST is UTC+5.5 hours
            // const istDateTime = new Date(utcDateTime.getTime() + istOffset);

            // console.log('originalCellrows', cellValues.row)
            // console.log(istDateTime, 'convertedDate', 'original', cellValues.row.endDate)
            // return istDateTime.toLocaleString();
            const date = new Date(cellValues.row.endDate);
            const localDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60 * 1000));
            const dateTime = new Date(localDate);
            // Get individual date and time components
            const day = dateTime.getDate();
            const month = dateTime.toLocaleString('default', { month: 'short' });
            const year = dateTime.getFullYear();
            let hours = dateTime.getHours();
            const minutes = String(dateTime.getMinutes()).padStart(2, '0');
            const seconds = String(dateTime.getSeconds()).padStart(2, '0');
            const ampm = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12;
            hours = String(hours ? hours : 12).padStart(2, '0');

            return `${day}-${month}-${year} ${hours}:${minutes}:${seconds} ${ampm}`;
          } else {
            return "none"
          }
          // return cellValues.row.endDate
          //   ? cellValues.row.endDate.slice(0, 19).replace("T", " ")
          //   : <i> none </i>;
        },
      },
      {
        field: "action",
        headerName: "Actions",
        editable: false,
        width: 150,
        filterable: false,
        sortable: false,

        renderCell: (cellValues) => (
          <>
            {
              isPrivilege("ViewTask") == true ?
                <Tooltip classes={{ tooltip: classes.tooltip }} title="Details">
                  <Button
                    size="sm"
                    justIcon
                    round
                    // color="reddit"
                    onClick={(e) => {
                      e.preventDefault();
                      showTaskDetailsMapModal(cellValues.row);
                    }}
                    style={{ size: "1px", backgroundColor: "#DD4B39" }}
                  // disabled={cellValues.row.providerId == providerId ? cellValues.row.isLocked == true ? cellValues.row.lockedBy == user ? false : true : cellValues.row.status >= 3 ? true : false : true}
                  >
                    <DescriptionIcon />
                  </Button>
                </Tooltip> : ''
            }
          </>
        )
      }
    ];
    setColumns(cols);
  }, []);

  const handleEdit = (p, e) => { };

  const currentDate = new Date();
  const formattedDate = currentDate.toISOString().slice(0, 19).replace(/[-T:]/g, '');

  const handleDownloadLogs = async () => {
    setIsLoading(true);
    axios.get(`${configUrl.AssessmentServer}/api/TaskManagers/downloadLogs`, {
      responseType: 'blob'
    })
      .then((obj) => {
        const url = URL.createObjectURL(obj.data);
        const a = document.createElement('a');
        a.href = url;
        a.download = `Logs_${formattedDate}.zip`;
        document.body.appendChild(a);
        a.click();
        a.remove();
        URL.revokeObjectURL(url);
        setIsLoading(false);
      })

  };


  const onCellEditCommit = (p) => {
    let row = questionData.questions.find((x) => x.id == p.id);
    if (row[p.field] == p.value) {
      return;
    }
  };
  const getRowClassName = (row) => {
    if (row.isLocked == true) {
      return "gray";
    }

    return null;
  };

  return (
    <Card>
      <CardHeader color="rose" icon>
        <CardIcon color="rose">
          <ListAlt />
        </CardIcon>
      </CardHeader>
      <CardBody>
        {/* <h1>Tasks</h1> */}
        {/* {pageState.isLoading == true ? (<>
          <CircularProgress style={{ marginInline: '45%' }} />
          <h6 style={{ marginInline: '45%' }}>Loading...</h6>
        </>
        ) : ( */}

        <GridContainer style={{ marginBottom: 21 }} justifyContent="flex-end" alignItems="flex-end" spacing={2} >

          { // Button for Downloading Logs
            <Button
              color="primary"
              onClick={handleDownloadLogs}
              style={{ marginTop: 20, marginRight: 10, textTransform: "none" }}
              disabled={isLoading}
            >

              {isLoading ? 'Downloading...' : <><GetAppIcon /> <span> Logs</span> </>}
            </Button>
          }

        </GridContainer>

        <div style={{ height: 660, width: "100%" }}>
          <div style={{ display: "flex", height: "100%" }}>
            <div style={{ flexGrow: 1 }}>
              {pageState.isLoading == false && pageState.data ?
                <CustomDataGrid
                  rows={pageState.data}
                  rowCount={pageState.total}
                  loading={pageState.isLoading}
                  rowsPerPageOptions={[10, 30, 50, 70, 100]}
                  page={pageState.page - 1}
                  pageSize={pageState.pageSize}
                  paginationMode="server"
                  onPageChange={(newPage) => setPageState(old => ({ ...old, page: newPage + 1 }))}
                  onPageSizeChange={(newPageSize) => setPageState(old => ({ ...old, pageSize: newPageSize }))}
                  columns={columns}
                  onEditCellPropsChange={handleEdit}
                  onCellEditCommit={onCellEditCommit}
                  getRowClassName={getRowClassName}
                />
                :
                <CustomDataGrid
                  rows={[]}
                  loading={pageState.isLoading}
                  columns={columns}
                  // checkboxSelection
                  getRowClassName={getRowClassName}
                />
                // <h4 style={{ textAlign: 'center' }}>Task(s) not available</h4>
              }
            </div>
          </div>
        </div>

      </CardBody>
      {taskDetailsMap}
    </Card>
  );
}
