import axios from "axios";
import { configUrl } from "config.js";
import { LoginContext } from "contexts/LoginContext";
import { useContext } from "react";
import { useMutation } from "react-query";

export default function uploadOmrzip() {
    const { user, providerId } = useContext(LoginContext);

    return useMutation(async ({ tempFormData, testId }) => {
        console.log("uploadOmrzip file", Object.fromEntries(tempFormData))
        try {
            let response = await axios.post(
                `${configUrl.AssessmentServer}/api/OmrSheet/readOmrSheet/${testId}`,
                tempFormData, { headers: { 'Content-Type': 'multipart/form-data' } }

            );

            let uploadDetails = response.data;

            if (!uploadDetails) {
                throw new Error('Not Found');
            }

            console.log("uploadOmrzip", response);
            return response.data;
        } catch (error) {
            console.error("Error uploading OMR zip file:", error);
            throw error;
        }
    }
    );
}
