import { Checkbox, FormControlLabel, InputAdornment, Radio, makeStyles } from "@material-ui/core";
import { Check, FiberManualRecord, Today } from "@material-ui/icons";
import FormatAlignJustifyIcon from "@material-ui/icons/FormatAlignJustify";
import radioStyles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import CustomAlert from "components/CustomAlert/CustomAlert";
// import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
// import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle";
import Button from "components/CustomButtons/Button";
import CustomInput from "components/CustomInput/CustomInput";
import ImageUpload from "components/CustomUpload/ImageUpload";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { LoginContext } from "contexts/LoginContext";
import useQuickTestMapCreatedQuestion from "hooks/poolQuestionHooks/useQuickTestMapCreatedQuestion";
import useQuestionUpdate from "hooks/questionHooks/useQuestionUpdate";
import { useContext, useEffect, useState } from "react";
import questionService from "services/QuestionService/questionService";

var styles = {
    // ...dashboardStyle,
    // ...loginPageStyle,
    ...radioStyles,
    // cardTitle: {
    //     marginTop: "0",
    //     minHeight: "auto",
    //     fontWeight: "300",
    //     fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    //     marginBottom: "3px",
    //     textDecoration: "none",
    // },
};
const useStyles = makeStyles(styles);

export default function QuickTestQuestionForm(props) {
    console.log(props, 'props')
    const classes = useStyles();
    const mapQuestion = useQuickTestMapCreatedQuestion();
    const update = useQuestionUpdate();
    const { user, providerId } = useContext(LoginContext);
    const [selectedEnabled, setSelectedEnabled] = useState(null);
    const [selected, setSelected] = useState([]);
    const [handleClear, setHandleClear] = useState(false);
    const [showAlert, setShowAlert] = useState(null);
    const [flag, setFlag] = useState(false);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        //needs to be clear to avoid retaining of options
        setSelectedEnabled(null);
        setSelectedEnabled(model?.questionChoices["$values"][0]["choiceIdAnswers"]);

        if (model?.questionType == 1) {
            setSelected([]);
            if (model.questionChoices["$values"][0]["choiceIdAnswers"] == null) {
                setSelected([])
            } else {
                // setSelected(model && JSON.parse(model.questionChoices["$values"][0]["choiceIdAnswers"]))
                if (isJSON(model?.questionChoices["$values"][0]["choiceIdAnswers"])) {

                    const filteredArray = model && JSON.parse(model?.questionChoices["$values"][0]["choiceIdAnswers"]).filter(item => {
                        const pattern = /[a-zA-Z0-9,IVXivx]/g;
                        return pattern.test(item);
                    });
                    console.log(filteredArray, 'filteredArray1')
                    setSelected(filteredArray);
                    // setSelected(model && JSON.parse(model?.questionChoices["$values"][0]["choiceIdAnswers"]))
                } else {
                    setSelected(model?.questionChoices["$values"][0]["choiceIdAnswers"]);
                }
            }
        }
    }, [model])

    const [model, setModel] = useState(
        props.state ??
        {
            $id: "1",
            questionId: 0,
            providerId: providerId,
            code: "",
            questionType: props.questionType ?? 2,
            contentType: 3,
            choicesAlignment: 1,
            objective: "",
            targetAudience: "",
            status: 2,
            isLocked: null,
            lockedBy: null,
            lockedDate: null,
            isActive: null,
            expiryDate: null,
            duration: null,
            difficultyLevel: 1,
            points: 0,
            owner: user,
            createdBy: user,
            createdDate: new Date(),
            updatedBy: user,
            updatedDate: new Date(),
            provider: null,
            poolQuestionMappings: {
                $id: "2",
                $values: []
            },
            questionChoices: {
                $id: "3",
                $values: [
                    {
                        $id: "4",
                        questionId: 0,
                        languageId: 1,
                        isActive: true,
                        choiceId01: props.questionType == 3 ? "True" : null,
                        choiceId02: props.questionType == 3 ? "False" : null,
                        choiceId03: null,
                        choiceId04: null,
                        choiceId05: null,
                        choiceId06: null,
                        choiceId01image: null,
                        choiceId02image: null,
                        choiceId03image: null,
                        choiceId04image: null,
                        choiceId05image: null,
                        choiceId06image: null,
                        choiceIdAnswers: null,
                        choiceIdPoints: null,
                        createdBy: user,
                        createdDate: new Date(),
                        updatedBy: user,
                        updatedDate: new Date(),
                        language: null,
                        question: null
                    }
                ]
            },
            questionLanguages: {
                $id: "5",
                $values: [
                    {
                        $id: "6",
                        questionId: 0,
                        languageId: 1,
                        text: null,
                        image: null,
                        textHtml: null,
                        solution: null,
                        solutionText: null,
                        hint: null,
                        passFeedback: null,
                        failFeedback: null,
                        masteryFeedback: null,
                        isActive: null,
                        status: 1,
                        createdBy: user,
                        createdDate: new Date(),
                        updatedBy: user,
                        updatedDate: new Date(),
                        language: null,
                        question: null
                    }
                ]
            },
            topicQuestionMappings: {
                $id: "7",
                $values: [
                    {
                        $id: "8",
                        topicId: 6,
                        questionId: 0,
                        isMandatory: null,
                        createdBy: user,
                        createdDate: new Date(),
                        updatedBy: user,
                        updatedDate: new Date(),
                        question: null,
                        topic: null
                    }
                ]
            },
            topic: 0
        }
    );

    function isJSON(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    const handleChangeLangauage = (e) => {
        let language = model.questionLanguages["$values"][0];
        language[e.target.name] = e.target.value;
        setModel({ ...model });
    };

    const handleCheckbox = (event) => {
        let selectedValues = [...selected];

        if (event.target.checked === true) {
            selectedValues.push(event.target.name);
        } else {
            selectedValues = selectedValues.filter(
                (x) => x !== event.target.name
            );
        }
        setSelected([...selectedValues]);

        model.questionChoices["$values"][0]["choiceIdAnswers"] = JSON.stringify(selectedValues);
    }

    const handleCheck = (x) => {
        console.log('selected val', x)
        setSelectedEnabled(x);
        model.questionChoices["$values"][0]["choiceIdAnswers"] = x;
    };

    const handleChangeChoices = (e) => {
        let Choices = model.questionChoices["$values"][0];
        Choices[e.target.name] = e.target.value === "" ? null : e.target.value;
        setModel({ ...model });
    };

    function generateRandomCode(name) {
        const balajiString = 'Balaji';
        let randomCode = name ?? "QuestionCode";

        // Generate random digits and concatenate with the balajiString until the length is 20
        while (randomCode.length < 20) {
            randomCode += Math.random().toString(36).substr(2);
        }

        // Trim the code to the desired length (20 digits)
        randomCode = randomCode.substr(0, 20);

        return randomCode;
    }


    const onSaveQuestion = async () => {
        var TextObj = model.questionLanguages["$values"][0]["text"];
        var imageObj = model.questionLanguages["$values"][0].image;
        var questionChoices = model.questionChoices["$values"][0];
        console.log(questionChoices?.choiceId01, 'choice')
        if ((TextObj == null || TextObj?.length == 0) && (imageObj == null || imageObj == '/static/media/image_placeholder.61b9ec86.jpg')) {
            setShowAlert(
                <CustomAlert
                    open={true}
                    openFlag={() => setFlag(flag ? false : true)}
                    severity="error"
                    alertMessage="Please enter question text or select question image. "
                />
            )
            return
        }
        if (model.questionType != 4 && (questionChoices?.choiceId01?.length == 0 || questionChoices?.choiceId01 == null) && (questionChoices?.choiceId02?.length == 0 || questionChoices?.choiceId02 == null) && (questionChoices?.choiceId03?.length == 0 || questionChoices?.choiceId03 == null) && (questionChoices?.choiceId04?.length == 0 || questionChoices?.choiceId04 == null) && (questionChoices?.choiceId05?.length == 0 || questionChoices?.choiceId05 == null) && (questionChoices?.choiceId06?.length == 0 || questionChoices?.choiceId06 == null)) {
            setShowAlert(
                <CustomAlert
                    open={true}
                    openFlag={() => setFlag(flag ? false : true)}
                    severity="error"
                    alertMessage="Please set choices."
                />
            )
            return
        }
        if (questionChoices?.choiceIdAnswers?.length == 0 || questionChoices?.choiceIdAnswers == null || questionChoices?.choiceIdAnswers == "[]") {
            setShowAlert(
                <CustomAlert
                    open={true}
                    openFlag={() => setFlag(flag ? false : true)}
                    severity="error"
                    alertMessage="Please select answer(s)."
                />
            )
            return
        }

        let code = generateRandomCode(TextObj)
        model.code = code;
        setLoading(true);
        if (props.id == 0) {
            let createQuestion = await questionService.createQuestion(model, props.poolId);
            console.log(createQuestion, 'createQuestion')
            if (createQuestion.status == 200 || createQuestion.status == 201) {
                let payload = {
                    poolId: props?.poolId,
                    questionId: createQuestion?.data?.questionId,
                    sequenceId: 0,
                    status: 0,
                    isLocked: false,
                    lockedBy: "",
                    lockedDate: null,
                    duration: null,
                    points: 0,
                    isMandatory: 0,
                    createdBy: user,
                    createdDate: new Date(),
                    updatedBy: user,
                    updatedDate: new Date(),
                }
                mapQuestion.mutate(payload);
                console.log(mapQuestion, 'mapQuestions')
                setShowAlert(
                    <CustomAlert
                        open={true}
                        openFlag={() => setFlag(flag ? false : true)}
                        severity="success"
                        alertMessage="Question created successfully."
                    />
                )
                setTimeout(() => {
                    props.afterFinish();
                }, 2000);
            } else if (createQuestion.status == 400) {
                setShowAlert(
                    <CustomAlert
                        open={true}
                        openFlag={() => setFlag(flag ? false : true)}
                        severity="warning"
                        alertMessage={createQuestion?.messege || "Error occured"}
                    />
                );
            }
            console.log(model, 'updated model')
        } else {
            await update.mutateAsync(model);
            if (update.isError) {
                setShowAlert(
                    <CustomAlert
                        open={true}
                        openFlag={() => setFlag(flag ? false : true)}
                        severity="error"
                        alertMessage="Question not updated"
                    />
                )
            } else {
                setShowAlert(
                    <CustomAlert
                        open={true}
                        openFlag={() => setFlag(flag ? false : true)}
                        severity="success"
                        alertMessage="Question updated successfully"
                    />
                )
                setTimeout(() => {
                    props.afterFinish()
                }, 2300)
            }
        }
        setLoading(false);

    }

    return (
        <div>
            {showAlert}
            <Card>
                <CardHeader color="rose" icon>
                    <CardIcon color="rose">
                        <Today />
                    </CardIcon>
                </CardHeader>
                <CardBody>
                    <GridContainer justifyContent="center">
                        <GridItem xs={12} sm={12} md={8}>
                            <CustomInput
                                labelText={<span>Text</span>}
                                id="text"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                inputProps={{
                                    name: "text",
                                    onChange: handleChangeLangauage,
                                    value: model.questionLanguages
                                        ? model.questionLanguages["$values"][0]["text"]
                                        : "",
                                    endAdornment: (
                                        <InputAdornment position="end" className={classes.inputAdornment}>
                                            <FormatAlignJustifyIcon
                                                className={classes.inputAdornmentIcon}
                                            />
                                        </InputAdornment>
                                    ),
                                    rows: 3,
                                    multiline: true,
                                }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={3}>
                            <ImageUpload
                                source={model.questionLanguages["$values"][0].image}
                                label={'Question'}
                                handleClear={handleClear}
                                setImageValue={(e) => {
                                    handleChangeLangauage({
                                        target: {
                                            name: "image",
                                            value: e == '/static/media/image_placeholder.61b9ec86.jpg' ? null : e,
                                        },
                                    });
                                }}
                            />
                        </GridItem>
                        {/* Question choices */}
                        {console.log(model.questionType, 'modelquestionType')}
                        {
                            (model.questionType == 1) ?
                                <>
                                    <GridItem xs={12} sm={12} md={5}>
                                        <div
                                            className={
                                                classes.checkboxAndRadio +
                                                " " +
                                                classes.checkboxAndRadioHorizontal
                                            }
                                        >
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        tabIndex={-1}
                                                        onClick={handleCheckbox}
                                                        checked={
                                                            (selected != null && selected.indexOf(model?.questionChoices["$values"][0]["choiceId01"]) !== -1
                                                                ? true
                                                                : false)
                                                        }
                                                        checkedIcon={
                                                            <Check className={classes.checkedIcon} />
                                                        }
                                                        icon={<Check className={classes.uncheckedIcon} />}
                                                        classes={{
                                                            checked: classes.checked,
                                                            root: classes.checkRoot,
                                                        }}
                                                        name={model.questionChoices["$values"][0]["choiceId01"]}
                                                    />
                                                }
                                                classes={{ label: classes.label }}
                                            />
                                            <CustomInput
                                                labelText={<span>Choice 1</span>}
                                                id="choiceId01"
                                                formControlProps={{
                                                    fullWidth: false,
                                                }}
                                                inputProps={{
                                                    name: 'choiceId01',
                                                    onChange: handleChangeChoices,
                                                    value:
                                                        model.questionChoices &&
                                                            model.questionChoices["$values"][0]["choiceId01"]
                                                            ? model.questionChoices["$values"][0]["choiceId01"]
                                                            : "",
                                                }}
                                            />
                                        </div>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={5}>
                                        <div
                                            className={
                                                classes.checkboxAndRadio +
                                                " " +
                                                classes.checkboxAndRadioHorizontal
                                            }
                                        >
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        tabIndex={-1}
                                                        onClick={handleCheckbox}
                                                        checked={
                                                            (selected != null && selected.indexOf(model?.questionChoices["$values"][0]["choiceId02"]) !== -1
                                                                ? true
                                                                : false)
                                                        }
                                                        checkedIcon={
                                                            <Check className={classes.checkedIcon} />
                                                        }
                                                        icon={<Check className={classes.uncheckedIcon} />}
                                                        classes={{
                                                            checked: classes.checked,
                                                            root: classes.checkRoot,
                                                        }}
                                                        name={model?.questionChoices["$values"][0]["choiceId02"]}
                                                    />
                                                }
                                                classes={{ label: classes.label }}
                                            />
                                            <CustomInput
                                                labelText={<span>Choice 2</span>}
                                                id="choiceId02"
                                                formControlProps={{
                                                    fullWidth: false,
                                                }}
                                                inputProps={{
                                                    name: 'choiceId02',
                                                    onChange: handleChangeChoices,
                                                    value:
                                                        model.questionChoices &&
                                                            model.questionChoices["$values"][0]["choiceId02"]
                                                            ? model.questionChoices["$values"][0]["choiceId02"]
                                                            : "",
                                                }}
                                            />
                                        </div>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={5}>
                                        <div
                                            className={
                                                classes.checkboxAndRadio +
                                                " " +
                                                classes.checkboxAndRadioHorizontal
                                            }
                                        >
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        tabIndex={-1}
                                                        onClick={handleCheckbox}
                                                        checked={
                                                            (selected != null && selected.indexOf(model?.questionChoices["$values"][0]["choiceId03"]) !== -1

                                                                ? true
                                                                : false)
                                                        }
                                                        checkedIcon={
                                                            <Check className={classes.checkedIcon} />
                                                        }
                                                        icon={<Check className={classes.uncheckedIcon} />}
                                                        classes={{
                                                            checked: classes.checked,
                                                            root: classes.checkRoot,
                                                        }}
                                                        name={model?.questionChoices["$values"][0]["choiceId03"]}
                                                    />
                                                }
                                                classes={{ label: classes.label }}
                                            />
                                            <CustomInput
                                                labelText={<span>Choice 3</span>}
                                                id="choiceId03"
                                                formControlProps={{
                                                    fullWidth: false,
                                                }}
                                                inputProps={{
                                                    name: 'choiceId03',
                                                    onChange: handleChangeChoices,
                                                    value:
                                                        model.questionChoices &&
                                                            model.questionChoices["$values"][0]["choiceId03"]
                                                            ? model.questionChoices["$values"][0]["choiceId03"]
                                                            : "",
                                                }}
                                            />
                                        </div>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={5}>
                                        <div
                                            className={
                                                classes.checkboxAndRadio +
                                                " " +
                                                classes.checkboxAndRadioHorizontal
                                            }
                                        >
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        tabIndex={-1}
                                                        onClick={handleCheckbox}
                                                        checked={
                                                            (selected != null && selected.indexOf(model?.questionChoices["$values"][0]["choiceId04"]) !== -1
                                                                ? true
                                                                : false)
                                                        }
                                                        checkedIcon={
                                                            <Check className={classes.checkedIcon} />
                                                        }
                                                        icon={<Check className={classes.uncheckedIcon} />}
                                                        classes={{
                                                            checked: classes.checked,
                                                            root: classes.checkRoot,
                                                        }}
                                                        name={model?.questionChoices["$values"][0]["choiceId04"]}
                                                    />
                                                }
                                                classes={{ label: classes.label }}
                                            />
                                            <CustomInput
                                                labelText={<span>Choice 4</span>}
                                                id="choiceId04"
                                                formControlProps={{
                                                    fullWidth: false,
                                                }}
                                                inputProps={{
                                                    name: 'choiceId04',
                                                    onChange: handleChangeChoices,
                                                    value:
                                                        model.questionChoices &&
                                                            model.questionChoices["$values"][0]["choiceId04"]
                                                            ? model.questionChoices["$values"][0]["choiceId04"]
                                                            : "",
                                                }}
                                            />
                                        </div>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={5}>
                                        <div
                                            className={
                                                classes.checkboxAndRadio +
                                                " " +
                                                classes.checkboxAndRadioHorizontal
                                            }
                                        >
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        tabIndex={-1}
                                                        onClick={handleCheckbox}
                                                        checked={
                                                            (selected != null && selected.indexOf(model?.questionChoices["$values"][0]["choiceId05"]) !== -1

                                                                ? true
                                                                : false)
                                                        }
                                                        checkedIcon={
                                                            <Check className={classes.checkedIcon} />
                                                        }
                                                        icon={<Check className={classes.uncheckedIcon} />}
                                                        classes={{
                                                            checked: classes.checked,
                                                            root: classes.checkRoot,
                                                        }}
                                                        name={model?.questionChoices["$values"][0]["choiceId05"]}
                                                    />
                                                }
                                                classes={{ label: classes.label }}
                                            />
                                            <CustomInput
                                                labelText={<span>Choice 5</span>}
                                                id="choiceId05"
                                                formControlProps={{
                                                    fullWidth: false,
                                                }}
                                                inputProps={{
                                                    name: 'choiceId05',
                                                    onChange: handleChangeChoices,
                                                    value:
                                                        model.questionChoices &&
                                                            model.questionChoices["$values"][0]["choiceId05"]
                                                            ? model.questionChoices["$values"][0]["choiceId05"]
                                                            : "",
                                                }}
                                            />
                                        </div>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={5}>
                                        <div
                                            className={
                                                classes.checkboxAndRadio +
                                                " " +
                                                classes.checkboxAndRadioHorizontal
                                            }
                                        >
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        tabIndex={-1}
                                                        onClick={handleCheckbox}
                                                        checked={
                                                            (selected != null && selected.indexOf(model?.questionChoices["$values"][0]["choiceId06"]) !== -1
                                                                ? true
                                                                : false)
                                                        }
                                                        checkedIcon={
                                                            <Check className={classes.checkedIcon} />
                                                        }
                                                        icon={<Check className={classes.uncheckedIcon} />}
                                                        classes={{
                                                            checked: classes.checked,
                                                            root: classes.checkRoot,
                                                        }}
                                                        name={model?.questionChoices["$values"][0]["choiceId06"]}
                                                    />
                                                }
                                                classes={{ label: classes.label }}
                                            />
                                            <CustomInput
                                                labelText={<span>Choice 6</span>}
                                                id="choiceId06"
                                                formControlProps={{
                                                    fullWidth: false,
                                                }}
                                                inputProps={{
                                                    name: 'choiceId06',
                                                    onChange: handleChangeChoices,
                                                    value:
                                                        model.questionChoices &&
                                                            model.questionChoices["$values"][0]["choiceId06"]
                                                            ? model.questionChoices["$values"][0]["choiceId06"]
                                                            : "",
                                                }}
                                            />
                                        </div>
                                    </GridItem>
                                </> :
                                (model.questionType == 2 || model.questionType == 3) ?
                                    <>
                                        <GridItem xs={12} sm={12} md={5}>
                                            <div
                                                style={{ color: 'black' }}
                                                className={
                                                    classes.checkboxAndRadio +
                                                    " " +
                                                    classes.checkboxAndRadioHorizontal
                                                }
                                            >
                                                <FormControlLabel
                                                    control={
                                                        <Radio
                                                            checked={model?.questionChoices["$values"][0]["choiceIdAnswers"] !== null && selectedEnabled !== null && selectedEnabled?.trim()?.toLowerCase()?.replace(/[(),]/g, '') === model?.questionChoices["$values"][0]["choiceId01"]?.trim()?.toLowerCase()?.replace(/[(),]/g, '')}
                                                            onChange={() => handleCheck(model?.questionChoices["$values"][0]["choiceId01"])}
                                                            value="a"
                                                            name="radio button enabled1"
                                                            aria-label="A"
                                                            icon={
                                                                <FiberManualRecord
                                                                    className={classes.radioUnchecked}
                                                                />
                                                            }
                                                            checkedIcon={
                                                                <FiberManualRecord
                                                                    className={classes.radioChecked}
                                                                />
                                                            }
                                                            classes={{
                                                                checked: classes.radio,
                                                                root: classes.radioRoot,
                                                            }}
                                                            style={{ width: '70%' }}
                                                        />
                                                    }
                                                    classes={{
                                                        label: classes.label,
                                                    }}
                                                />
                                                <CustomInput
                                                    labelText={<span>Choice 1</span>}
                                                    id="choiceId01"
                                                    formControlProps={{
                                                        fullWidth: false,
                                                    }}
                                                    inputProps={{
                                                        name: 'choiceId01',
                                                        onChange: handleChangeChoices,
                                                        value:
                                                            model.questionChoices &&
                                                                model.questionChoices["$values"][0]["choiceId01"]
                                                                ? model.questionChoices["$values"][0]["choiceId01"]
                                                                : "",
                                                    }}
                                                    disabled={model.questionType == 3 ? true : false}
                                                />
                                            </div>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={5}>
                                            <div
                                                style={{ color: 'black' }}
                                                className={
                                                    classes.checkboxAndRadio +
                                                    " " +
                                                    classes.checkboxAndRadioHorizontal
                                                }
                                            >
                                                <FormControlLabel
                                                    control={
                                                        <Radio
                                                            checked={model?.questionChoices["$values"][0]["choiceIdAnswers"] !== null && selectedEnabled !== null && selectedEnabled?.trim()?.toLowerCase()?.replace(/[(),]/g, '') === model?.questionChoices["$values"][0]["choiceId02"]?.trim()?.toLowerCase()?.replace(/[(),]/g, '')}
                                                            onChange={() => handleCheck(model?.questionChoices["$values"][0]["choiceId02"])}
                                                            value="b"
                                                            name="radio button enabled2"
                                                            aria-label="B"
                                                            icon={
                                                                <FiberManualRecord
                                                                    className={classes.radioUnchecked}
                                                                />
                                                            }
                                                            checkedIcon={
                                                                <FiberManualRecord
                                                                    className={classes.radioChecked}
                                                                />
                                                            }
                                                            classes={{
                                                                checked: classes.radio,
                                                                root: classes.radioRoot,
                                                            }}
                                                        />
                                                    }
                                                    classes={{
                                                        label: classes.label,
                                                    }}
                                                />
                                                <CustomInput
                                                    labelText={<span>Choice 2</span>}
                                                    id="choiceId02"
                                                    formControlProps={{
                                                        fullWidth: false,
                                                    }}
                                                    inputProps={{
                                                        name: 'choiceId02',
                                                        onChange: handleChangeChoices,
                                                        value:
                                                            model.questionChoices &&
                                                                model.questionChoices["$values"][0]["choiceId02"]
                                                                ? model.questionChoices["$values"][0]["choiceId02"]
                                                                : "",
                                                    }}
                                                    disabled={model.questionType == 3 ? true : false}
                                                />
                                            </div>
                                            {/* {props.questionType} QuickTestQuestionForm */}
                                        </GridItem>
                                        {
                                            model.questionType == 2 && (
                                                <>
                                                    <GridItem xs={12} sm={12} md={5}>
                                                        <div
                                                            style={{ color: 'black' }}
                                                            className={
                                                                classes.checkboxAndRadio +
                                                                " " +
                                                                classes.checkboxAndRadioHorizontal
                                                            }
                                                        >
                                                            <FormControlLabel
                                                                control={
                                                                    <Radio
                                                                        checked={model?.questionChoices["$values"][0]["choiceIdAnswers"] !== null && selectedEnabled !== null && selectedEnabled?.trim()?.toLowerCase()?.replace(/[(),]/g, '') === model?.questionChoices["$values"][0]["choiceId03"]?.trim()?.toLowerCase()?.replace(/[(),]/g, '')}
                                                                        onChange={() => handleCheck(model?.questionChoices["$values"][0]["choiceId03"])}
                                                                        value="c"
                                                                        name="radio button enabled3"
                                                                        aria-label="C"
                                                                        icon={
                                                                            <FiberManualRecord
                                                                                className={classes.radioUnchecked}
                                                                            />
                                                                        }
                                                                        checkedIcon={
                                                                            <FiberManualRecord
                                                                                className={classes.radioChecked}
                                                                            />
                                                                        }
                                                                        classes={{
                                                                            checked: classes.radio,
                                                                            root: classes.radioRoot,
                                                                        }}
                                                                    />
                                                                }
                                                                classes={{
                                                                    label: classes.label,
                                                                }}
                                                            />
                                                            <CustomInput
                                                                labelText={<span>Choice 3</span>}
                                                                id="choiceId03"
                                                                formControlProps={{
                                                                    fullWidth: false,
                                                                }}
                                                                inputProps={{
                                                                    name: 'choiceId03',
                                                                    onChange: handleChangeChoices,
                                                                    value:
                                                                        model.questionChoices &&
                                                                            model.questionChoices["$values"][0]["choiceId03"]
                                                                            ? model.questionChoices["$values"][0]["choiceId03"]
                                                                            : "",
                                                                }}
                                                            />
                                                        </div>
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={5}>
                                                        <div
                                                            style={{ color: 'black' }}
                                                            className={
                                                                classes.checkboxAndRadio +
                                                                " " +
                                                                classes.checkboxAndRadioHorizontal
                                                            }
                                                        >
                                                            <FormControlLabel
                                                                control={
                                                                    <Radio
                                                                        checked={model?.questionChoices["$values"][0]["choiceIdAnswers"] !== null && selectedEnabled !== null && selectedEnabled?.trim()?.toLowerCase()?.replace(/[(),]/g, '') === model?.questionChoices["$values"][0]["choiceId04"]?.trim()?.toLowerCase()?.replace(/[(),]/g, '')}
                                                                        onChange={() => handleCheck(model?.questionChoices["$values"][0]["choiceId04"])}
                                                                        value="d"
                                                                        name="radio button enabled4"
                                                                        aria-label="D"
                                                                        icon={
                                                                            <FiberManualRecord
                                                                                className={classes.radioUnchecked}
                                                                            />
                                                                        }
                                                                        checkedIcon={
                                                                            <FiberManualRecord
                                                                                className={classes.radioChecked}
                                                                            />
                                                                        }
                                                                        classes={{
                                                                            checked: classes.radio,
                                                                            root: classes.radioRoot,
                                                                        }}
                                                                    />
                                                                }
                                                                classes={{
                                                                    label: classes.label,
                                                                }}
                                                            />
                                                            <CustomInput
                                                                labelText={<span>Choice 4</span>}
                                                                id="choiceId04"
                                                                formControlProps={{
                                                                    fullWidth: false,
                                                                }}
                                                                inputProps={{
                                                                    name: 'choiceId04',
                                                                    onChange: handleChangeChoices,
                                                                    value:
                                                                        model.questionChoices &&
                                                                            model.questionChoices["$values"][0]["choiceId04"]
                                                                            ? model.questionChoices["$values"][0]["choiceId04"]
                                                                            : "",
                                                                }}
                                                            />
                                                        </div>
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={5}>
                                                        <div
                                                            style={{ color: 'black' }}
                                                            className={
                                                                classes.checkboxAndRadio +
                                                                " " +
                                                                classes.checkboxAndRadioHorizontal
                                                            }
                                                        >
                                                            <FormControlLabel
                                                                control={
                                                                    <Radio
                                                                        checked={model?.questionChoices["$values"][0]["choiceIdAnswers"] !== null && selectedEnabled !== null && selectedEnabled?.trim()?.toLowerCase()?.replace(/[(),]/g, '') === model?.questionChoices["$values"][0]["choiceId05"]?.trim()?.toLowerCase()?.replace(/[(),]/g, '')}
                                                                        onChange={() => handleCheck(model?.questionChoices["$values"][0]["choiceId05"])}
                                                                        value="c"
                                                                        name="radio button enabled3"
                                                                        aria-label="C"
                                                                        icon={
                                                                            <FiberManualRecord
                                                                                className={classes.radioUnchecked}
                                                                            />
                                                                        }
                                                                        checkedIcon={
                                                                            <FiberManualRecord
                                                                                className={classes.radioChecked}
                                                                            />
                                                                        }
                                                                        classes={{
                                                                            checked: classes.radio,
                                                                            root: classes.radioRoot,
                                                                        }}
                                                                    />
                                                                }
                                                                classes={{
                                                                    label: classes.label,
                                                                }}
                                                            />
                                                            <CustomInput
                                                                labelText={<span>Choice 5</span>}
                                                                id="choiceId05"
                                                                formControlProps={{
                                                                    fullWidth: false,
                                                                }}
                                                                inputProps={{
                                                                    name: 'choiceId05',
                                                                    onChange: handleChangeChoices,
                                                                    value:
                                                                        model.questionChoices &&
                                                                            model.questionChoices["$values"][0]["choiceId05"]
                                                                            ? model.questionChoices["$values"][0]["choiceId05"]
                                                                            : "",
                                                                }}
                                                            />
                                                        </div>
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={5}>
                                                        <div
                                                            style={{ color: 'black' }}
                                                            className={
                                                                classes.checkboxAndRadio +
                                                                " " +
                                                                classes.checkboxAndRadioHorizontal
                                                            }
                                                        >
                                                            <FormControlLabel
                                                                control={
                                                                    <Radio
                                                                        checked={model?.questionChoices["$values"][0]["choiceIdAnswers"] !== null && selectedEnabled !== null && selectedEnabled?.trim()?.toLowerCase()?.replace(/[(),]/g, '') === model?.questionChoices["$values"][0]["choiceId06"]?.trim()?.toLowerCase()?.replace(/[(),]/g, '')}
                                                                        onChange={() => handleCheck(model?.questionChoices["$values"][0]["choiceId06"])}
                                                                        value="d"
                                                                        name="radio button enabled4"
                                                                        aria-label="D"
                                                                        icon={
                                                                            <FiberManualRecord
                                                                                className={classes.radioUnchecked}
                                                                            />
                                                                        }
                                                                        checkedIcon={
                                                                            <FiberManualRecord
                                                                                className={classes.radioChecked}
                                                                            />
                                                                        }
                                                                        classes={{
                                                                            checked: classes.radio,
                                                                            root: classes.radioRoot,
                                                                        }}
                                                                    />
                                                                }
                                                                classes={{
                                                                    label: classes.label,
                                                                }}
                                                            />
                                                            <CustomInput
                                                                labelText={<span>Choice 6</span>}
                                                                id="choiceId06"
                                                                formControlProps={{
                                                                    fullWidth: false,
                                                                }}
                                                                inputProps={{
                                                                    name: 'choiceId06',
                                                                    onChange: handleChangeChoices,
                                                                    value:
                                                                        model.questionChoices &&
                                                                            model.questionChoices["$values"][0]["choiceId06"]
                                                                            ? model.questionChoices["$values"][0]["choiceId06"]
                                                                            : "",
                                                                }}
                                                            />
                                                        </div>
                                                    </GridItem>
                                                </>
                                            )
                                        }
                                    </> :
                                    (model.questionType == 4) ?
                                        <GridItem xs={12} sm={12} md={10}>
                                            <div
                                                className={
                                                    classes.checkboxAndRadio +
                                                    " " +
                                                    classes.checkboxAndRadioHorizontal
                                                }
                                            >
                                                <CustomInput
                                                    labelText={<span>Answer</span>}
                                                    id="choiceIdAnswers"
                                                    formControlProps={{
                                                        fullWidth: false,
                                                    }}
                                                    inputProps={{
                                                        name: 'choiceIdAnswers',
                                                        onChange: handleChangeChoices,
                                                        type: "number",
                                                        value:
                                                            model.questionChoices &&
                                                                model.questionChoices["$values"][0]["choiceIdAnswers"]
                                                                ? model.questionChoices["$values"][0]["choiceIdAnswers"]
                                                                : "",
                                                    }}
                                                />
                                            </div>
                                        </GridItem> : ''
                        }
                    </GridContainer>
                </CardBody>
            </Card>

            <Button color="primary" style={{ textTransform: 'none', float: 'right', marginInline: '7%' }}
                disabled={loading}
                onClick={onSaveQuestion}
            >
                {props.id == 0 ?
                    loading ? "Creating..." :
                        "Create" : loading ? "Updating" : "Update"
                }
            </Button>
            {/* {props.questionType} QuickTestQuestionForm */}
        </div>
    )
}
