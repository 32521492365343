import axios from "axios";
import { configUrl } from "config.js";

const getAllTests = async (page, pageSize, Status, difficultylevel, ShowHint, ShowBookmark, NavigationAllowed, Objective, TargetAudience, courseId, contentFilter, testType) => {
  let status = Status ?? 0;
  let difficultyLevel = difficultylevel ?? 0;
  let showHint = ShowHint ?? 0;
  let showBookmark = ShowBookmark ?? 0;
  let navigationAllowed = NavigationAllowed ?? 0;
  let objective = Objective ?? null;
  let targetAudience = TargetAudience ?? null;
  let CourseId = courseId ?? 0
  let ContentFilter = contentFilter ? parseInt(contentFilter) : 0;
  let TestType = testType ?? 0
  console.log(courseId, 'courseIdinHook')
  let testResponse = await axios.get(
    `${configUrl.AssessmentServer}/api/Tests?pageNumber=${page}&pageSize=${pageSize}&status=${status}&difficultyLevel=${difficultyLevel}&showHint=${showHint}&showBookmark=${showBookmark}&navigationAllowed=${navigationAllowed}&objective=${objective}&targetAudience=${targetAudience}&courseId=${CourseId}&contentFilter=${ContentFilter} &testType=${TestType}`

  );

  let testData = testResponse?.data["$values"]?.map((x, i) => ({
    ...x,
    id: x.testId,
  }));
  return testData;
};
export default {
  getAllTests: getAllTests,
}
