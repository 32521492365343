import React, { useEffect, useState } from "react";
// @material-ui/icons


// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Button from "components/CustomButtons/Button";
import CustomDataGrid from "components/CustomDataGrid.js/CustomDataGrid";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { LoginContext } from "contexts/LoginContext";
import useGetCategories from "hooks/categoryHooks/useGetCategories";
import useUpdateCourse from "hooks/courseHooks/useUpdateCourse";
import DropdownTreeSelect from "react-dropdown-tree-select";
import addTestsIntoCourses from "services/TestSeriesService/addTestsIntoCourses";
import getTestsByTopicIds from "services/TestSeriesService/getTestsByTopicIds";

const styles = {
    infoText: {
        fontWeight: "300",
        margin: "10px 0 30px",
        textAlign: "center",
    },
    inputAdornmentIcon: {
        color: "#555",
    },
    inputAdornmentDisabled: {
        color: "#b2b8be !important",
    },
    inputAdornment: {
        position: "relative",
    },
};

const useStyles = makeStyles(styles);

const TreeDropDown = React.memo(
    ({ data, onChange, className }) => {
        console.log(JSON.stringify(data), 'DropdownTreeData')
        return (
            <>
                <DropdownTreeSelect
                    data={data}
                    onChange={onChange}
                    className={className}
                />
            </>
        );
    },
    (prevProps, nextProps) => {
        return true;
    }
);
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const TestMappingGrid = (props) => {

    const { data: categories } = useGetCategories(true);
    let updateCourse = useUpdateCourse();
    const [courseDetails, setCourseDetails] = useState(props?.testObjs?.details);
    console.log(courseDetails?.selectedTopics, 'propsinTestMapping');
    const { licensePackageObject } = React.useContext(LoginContext);

    const [categoryTree, setCategoryTree] = React.useState(null);
    const [cols, setCols] = React.useState([]);
    const [testDetails, setTestDetails] = React.useState([]);
    const [selected, setSelection] = React.useState([]);
    console.log(selected, 'selected Test rows')
    const [selectedTopics, setSelectedTopics] = React.useState();   //props.st.topics?.split(',').map(Number)
    console.log(selectedTopics, 'kkkkk')
    const [showAlert, setShowAlert] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState(null);
    const [severity, setSeverity] = React.useState("success");
    const [loading, setLoading] = React.useState(false);
    const [loadingTests, setLoadingTests] = React.useState(false);


    const fetchData = async () => {
        if (selectedTopics == undefined) {
            return
        }
        setLoadingTests(true);
        let getTests = await getTestsByTopicIds.getTestsByTopicIds(props.courseId, selectedTopics, props.objective, props.targetAudience);
        setTestDetails(getTests)
        setLoadingTests(false);
        console.log(getTests, 'gettingTests')
    }
    useEffect(() => {
        fetchData();
        const newSelectedTopics = selectedTopics?.filter(element => element !== undefined);
        courseDetails.selectedTopics = newSelectedTopics;
        courseDetails.courseId = props.courseId;
        console.log(courseDetails, 'modifiedPropsTopicsValues')
    }, [selectedTopics, props.objective, props.targetAudience])

    const getTopicIds = (node, topics) => {
        console.log(categories, 'categoryis111')
        const children = categories.filter((x) => x.parent == node.id);
        // if (!children || children.length == 0) {
        topics.push(node.topicId);
        // }

        if (children && children.length > 0) {
            children.forEach((y) => {
                getTopicIds(y, topics);
            });
        }

    };
    const setTopicIds = (root, tops) => {

        const children = categories.filter((x) => x.parent == root.id);
        // if (!children || children.length == 0) {
        if (tops.includes(root.topicId)) {
            root.checked = true;
        }
        else {
            root.checked = false;
        }
        // }

        if (children && children.length > 0) {
            children.forEach((y) => {
                setTopicIds(y, tops);
            });
        }

    };

    const createHierarchy = (root) => {
        if (!root) return;

        root.label = root.name;
        root.value = root.id;
        if (selectedTopics == undefined) {
            root.checked = false;
        }
        // commented code for multiple category topic hierarchy added.
        // start
        // let children = [...categories].map(x => { return { ...x } }).filter(
        //   (x) => x.parent == root.id
        //   **// && x.isCategory == true**
        // );
        // end
        let children = [...categories].filter((x) => x.parent == root.id);

        // if (props.showCategoriesOnly == true) {
        //   children = children.filter((x) => x.isCategory == true);
        // }
        if (children && children.length > 0) {

            root.children = [];

            children.forEach((x) => {
                x.value = x.id;
                x.label = x.name;
                root.children.push(x);
                createHierarchy(x);
            });
        }
        return;
    };

    React.useEffect(() => {
        let columns = [
            {
                field: "testId",
                headerName: "Id",
                editable: false,
                width: 100,
                hide: true,
            },
            {
                field: "code",
                headerName: "Code",
                editable: false,
                width: 250,
            },
            {
                field: "name",
                headerName: "Test",
                editable: false,
                width: 350,
            },
            {
                field: "difficultyLevel",
                headerName: "Difficulty Level",
                editable: false,
                width: 180,
                renderCell: (cellValues) => {
                    if (!cellValues.row.difficultyLevel) {
                        return "Medium";
                    }
                    return cellValues.row.difficultyLevel == "1"
                        ? "Easy"
                        : cellValues.row.difficultyLevel == "2"
                            ? "Medium"
                            : "Difficult";
                },
            },
            {
                field: "providerName",
                headerName: "Provider Name",
                editable: false,
                width: 180,
            },
            {
                field: "objective",
                headerName: "Objective",
                editable: false,
                width: 200,
                // renderCell: (cellValues) => {
                //   console.log("courseTestCellVals",cellValues)
                //   return (
                //     <span>{cellValues.row.objective}</span>
                //   );
                // },
            },
            {
                field: "targetAudience",
                headerName: "Target Audience",
                editable: false,
                width: 200,
            }
        ];

        setCols(columns);
    }, []);

    React.useEffect(() => {
        if (categories) {
            console.log(categories, 'root1234')
            // let root = [...categories].map(x => { return { ...x } }).find((x) => x.parent == null);
            let root = [...categories].filter((x) => x.parent == null && x.isCategory == true);
            createHierarchy(root);

            console.log(root, 'root123')
            console.log(selectedTopics, ' ', 'selectedTopics123')
            if (selectedTopics) {
                // const newSelectedTopics = selectedTopics.filter(element => element !== undefined);
                const tops = selectedTopics?.map(Number); //.split(",")
                setTopicIds(root, tops);
            }
            setCategoryTree(root)
        }
    }, [categories]);

    const handleEdit = (p, e) => { };

    const onChange = (currentNode, selectedNodes) => {
        let topicIds = [];

        selectedNodes.forEach((x) => {
            // topicIds.push(x.topicId);
            getTopicIds(x, topicIds);
            console.log(topicIds, 'topicIds')
        });
        setSelectedTopics(topicIds);
    };
    const onCellEditCommit = (p) => {
        let row = questionData.questions.find((x) => x.id == p.id);
        if (row[p.field] == p.value) {
            return;
        }
    };
    const handleRowSelection = (e) => {
        setSelection(e);
    };
    const getRowClassName = (row) => {
        if (row.isLocked == true) {
            return "gray";
        }

        return null;
    };
    const onhandleAddTests = async () => {
        console.log(props?.testCount, selected, licensePackageObject, 'testDetailsinMapped12');
        if ((props?.testCount + selected?.length) > licensePackageObject?.TestSeriesTests) {
            showSnackBar(`Please upgrade to higher package to add more than ${licensePackageObject?.TestSeriesTests} tests.`, 'warning');
            return;
        }
        setLoading(true);
        let addTests = await addTestsIntoCourses.addTestsIntoCourses(props.courseId, selected)
        updateCourse.mutate(courseDetails);
        if ((updateCourse.isIdle == true || updateCourse.isSuccess == true) && addTests.status == 200) {
            showSnackBar("Test added successfully");
            setTimeout(() => {
                props.onFinish();
            }, 3000);
        } else {
            showSnackBar("Error occured", "error");
        }
        setLoading(false);
    }
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setAlertMessage(null);
        setOpen(false);
    };
    const showSnackBar = (message, sev) => {
        setSeverity(sev ?? "success");
        setAlertMessage(message);
        setOpen(true);
    };


    return (
        <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={11} lg={11}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={6} lg={6}>
                        {categoryTree && (
                            <TreeDropDown
                                data={categoryTree}
                                onChange={onChange}
                                className="mdl-demo"
                            />
                        )}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6} lg={6} style={{ textAlign: 'right' }}>
                        <Button disabled={loading} color="primary" onClick={onhandleAddTests}>
                            {loading ? 'Adding Tests...' : 'Add Test'}
                        </Button>
                    </GridItem>

                </GridContainer>
            </GridItem>
            <GridItem xs={12} sm={12} md={12} lg={12}>
                <div style={{ height: 500, width: "100%" }}>
                    <CustomDataGrid
                        loading={loadingTests}
                        columns={cols}
                        rows={testDetails}
                        autoPageSize={true}
                        onEditCellPropsChange={handleEdit}
                        onCellEditCommit={onCellEditCommit}
                        onSelectionModelChange={handleRowSelection}
                        checkboxSelection
                        getRowClassName={getRowClassName}
                        selectionModel={selected}
                    />
                </div>
            </GridItem>
            <Snackbar
                open={open}
                autoHideDuration={2000}
                onClose={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
                <Alert onClose={handleClose} severity={severity}>
                    {alertMessage}
                </Alert>
            </Snackbar>
        </GridContainer>
    );
};

export default TestMappingGrid;
