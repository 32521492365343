import React, { useContext } from "react";
// @material-ui/icons


// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Switch from "@material-ui/core/Switch";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import { FormControlLabel } from "@material-ui/core";
import CustomSelect from "components/CustomSelect/CustomSelect";
import { LoginContext } from "contexts/LoginContext";

const styles = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  inputAdornmentIcon: {
    color: "#555",
  },
  inputAdornment: {
    position: "relative",
  },
};

const useStyles = makeStyles(styles);

const TestConfiguration = React.forwardRef((props, ref) => {
  const classes = useStyles();

  const dateRef = React.useRef();

  console.log("con", props);

  const hide = true;
  const { userPrivileges } = useContext(LoginContext);
  const [feedbackFail, setFeedbackFail] = React.useState(props.st.feedbackFail ?? 'Failed');
  const [feedbackFailState, setFeedbackFailState] = React.useState("");

  const [feedbackPass, setFeedbackPass] = React.useState(props.st.feedbackPass ?? 'Pass');
  const [feedbackPassState, setFeedbackPassState] = React.useState("");

  const [feedbackMastery, setFeedbackfeedbackMastery] = React.useState(props.st.feedbackMastery ?? 'Excellent!');
  const [feedbackfeedbackMasteryState, setFeedbackfeedbackMasteryState] = React.useState("");


  const [expiryDate, setExpiryDate] = React.useState("2099-12-31T00:00:00");

  const [passingScore, setPassingScore] = React.useState(props.st.passingScore);
  const [passingScoreState, setPassingScoreState] = React.useState("success");
  const [pointsPerQuestionsState, setPointsPerQuestionState] = React.useState("");
  const [negativePointsPerQuestionsState, setNegativePointsPerQuestionState] = React.useState("");

  const [masteryScore, setMasteryScore] = React.useState(props.st.masteryScore);
  // const [masteryScoreState, setMasteryScoreState] = React.useState("success");

  const [difficultyLevel, setDifficultyLevel] = React.useState(
    props.st.difficultyLevel ?? 2
  );
  const [difficultyLevelState, setDifficultyLevelState] = React.useState("");

  console.log(props.st.showHint, 'showHint allowed')
  const [showHint, setShowHint] = React.useState(props.st.showHint == 2 ? true : false);
  const [showBookmark, setShowBookmark] = React.useState(props.st.showBookmark == 2 ? true : false);
  const [showTimer, setShowTimer] = React.useState(props.st.showTimer == 2 ? true : false);
  const [showResult, setShowResult] = React.useState(props.st.showResult == 2 ? true : false);
  const [showHintState, setShowHintState] = React.useState("");

  const [questionsPerPage, setQuestionsPerPage] = React.useState(
    1
  );
  const [questionsPerPageState, setQuestionsPerPageState] = React.useState("");
  const [masteryState, setMasteryState] = React.useState("success");
  const [attemptsAllowedState, setAttemptsAllowedState] = React.useState("success");
  const [expiryDateState, setExpiryDateState] = React.useState("");
  const [durationState, setDurationState] = React.useState("success");
  const [navigationAllowed, setNavigationAllowed] = React.useState(
    props.st.navigationAllowed == 2 ? true : false
  );

  const [navigationAllowedState, setNavigationAllowedState] = React.useState("");

  const [isLocked, setIsLocked] = React.useState(props.st.isLocked);
  const [status, setStatus] = React.useState(1);

  console.log("Status", status);
  console.log("Status", props.st.status);

  const [pointsPerQuestion, setPointsPerQuestion] = React.useState(
    props.st.pointsPerQuestion
  );

  const [negativePointsPerQuestion, setNegativePointsPerQuestion] = React.useState(
    props.st.negativePointsPerQuestion ?? 0
  );

  const [duration, setDuration] = React.useState(
    props.st.duration ?? 180
  );

  const [attemptsAllowed, setAttemptsAllowed] = React.useState(
    props.st.attemptsAllowed ?? 1
  );

  React.useEffect(() => {
    if (!verifyRange(questionsPerPage, 1, 20)) {
      setQuestionsPerPageState("error");
    } else {
      setQuestionsPerPageState("success");
    }
  }, [questionsPerPage]);

  React.useImperativeHandle(ref, () => ({
    isValidated: () => {
      return isValidated();
    },
    sendState: () => {
      return sendState();
    },
    state: {
      feedbackFail,
      feedbackPass,
      feedbackMastery,
      expiryDate,
      passingScore,
      masteryScore,
      difficultyLevel,
      showHint,
      showBookmark,
      questionsPerPage,
      navigationAllowed,
      type: "configuration",
      isLocked,
      pointsPerQuestion,
      pointsPerQuestionsState,
      masteryState,
      questionsPerPageState,
      attemptsAllowedState,
      negativePointsPerQuestion: negativePointsPerQuestion == "" ? 0 : negativePointsPerQuestion,
      duration,
      attemptsAllowed,
      status,
      showTimer,
      showResult,
    },
  }));
  const sendState = () => {
    return {
      feedbackFail,
      feedbackPass,
      feedbackMastery,
      expiryDate,
      passingScore,
      masteryScore,
      difficultyLevel,
      showHint,
      showBookmark,
      questionsPerPage,
      navigationAllowed,
      type: "configuration",
      isLocked,
      pointsPerQuestion,
      pointsPerQuestionsState,
      attemptsAllowedState,
      masteryState,
      questionsPerPageState,
      negativePointsPerQuestion: negativePointsPerQuestion == "" ? 0 : negativePointsPerQuestion,
      duration,
      attemptsAllowed,
      status,
      showTimer,
      showResult
    };
  };
  const isPrivilege = (x) => {
    if (userPrivileges?.Privileges?.Test.indexOf(x) !== -1) {
      return true;
    }
    else {
      return false;
    }
  }
  // function that returns true if value is email, false otherwise
  // function that verifies if a string has a given length or not
  const verifyLength = (value) => {
    console.log(value >= parseInt(passingScore), 'checking')
    if (value >= parseInt(passingScore)) {
      return true
    } else {
      return false
    }
    // if (value >= parseInt(length)) {
    //   return true;
    // }
    // return false;
  };

  const verifyRange = (value, min, max) => {
    if (!value) return false;

    if (value >= min && value <= max) {
      return true;
    }
    return false;
  };

  React.useEffect(() => {
    if (pointsPerQuestion && pointsPerQuestion > 0) {
      setPointsPerQuestionState('success');
    }

    if (negativePointsPerQuestion != null && negativePointsPerQuestion <= 0) {
      setNegativePointsPerQuestionState('success');
    }
  }, [])

  React.useEffect(() => {
    console.log(parseInt(passingScore) > parseInt(masteryScore), 'ispass', parseInt(passingScore), parseInt(masteryScore), masteryScore)
    if ((masteryScore != null && masteryScore != '') && passingScore == '') {
      setMasteryState('error');
    }
    if (parseInt(passingScore) > parseInt(masteryScore)) {
      setMasteryState('error');
    }
    if (parseInt(passingScore) <= parseInt(masteryScore) || masteryScore == '') {
      setMasteryState('success')
    }
  }, [passingScore, masteryScore])

  const isValidated = () => {
    console.log("pointsPerQuestionsState", pointsPerQuestionsState);
    console.log("negativePointsPerQuestionsState", negativePointsPerQuestionsState, attemptsAllowedState);

    if (questionsPerPageState == "success" && attemptsAllowedState == "success" && durationState == "success" && passingScoreState == "success" && masteryState == "success" && pointsPerQuestionsState == "success" && negativePointsPerQuestionsState == "success") {
      return true;
    } else {
      if (questionsPerPageState !== "success") {
        setQuestionsPerPageState("error");
      }
      if (attemptsAllowedState !== "success") {
        setAttemptsAllowedState("error");
      }
      if (durationState !== "success") {
        setDurationState("error");
      }
      if (passingScoreState !== 'success') {
        setPassingScoreState("error");
      }
      if (masteryState !== 'success') {
        setMasteryState("error")
      }
      if (pointsPerQuestionsState !== "success") {
        setPointsPerQuestionState("error");
      }
      if (negativePointsPerQuestionsState !== "success") {
        setNegativePointsPerQuestionState("error");
      }

    }
    return false;
  };

  React.useEffect(() => {
    console.log("dateRef", dateRef);
  }, [dateRef]);

  return (
    <GridContainer justify="center" style={{ marginInline: '10px' }}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={5}>
          <CustomInput
            success={true}
            labelText={<span>Feedback Pass</span>}
            id="feedbackPass"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              onChange: (event) => {
                setFeedbackPass(event.target.value);
              },
              value: feedbackPass,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={5}>
          <CustomInput
            success={true}
            labelText={<span>Feedback Fail</span>}
            id="feedbackFail"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              onChange: (event) => {
                setFeedbackFail(event.target.value);
              },
              value: feedbackFail,
            }}
          />
        </GridItem>
      </GridContainer>

      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={3}>
          <CustomInput
            success={true}
            labelText={<span>Feedback Mastery</span>}
            id="feedbackMastery"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              onChange: (event) => {
                setFeedbackfeedbackMastery(event.target.value);
              },
              value: feedbackMastery,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={2}>
          <CustomInput
            success={passingScoreState === 'success'}
            error={passingScoreState === 'error'}
            labelText={<span>Passing Score</span>}
            id="passingScore"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: "number",
              onChange: (event) => {
                if (event.target.value > 0) {
                  setPassingScore(event.target.value);
                  setPassingScoreState('success');
                } else if (event.target.value < 0) {
                  setPassingScoreState('error');
                  setPassingScore('');
                } else {
                  setPassingScore('');
                  setPassingScoreState('success');
                }
              },
              value: passingScore,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={3}>
          <CustomInput
            success={masteryState === "success"}
            error={masteryState === "error"}
            labelText={<span>Mastery Score<small> ( {'>'} Passing Score ) </small></span>}
            id="masteryScore"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: "number",
              onChange: (event) => {
                // if (!verifyLength(event.target.value)) {
                //   setMasteryState("error");
                // } else {
                //   setMasteryState("success");
                // }
                // if (event.target.value < 0) {
                //   setMasteryState("error");
                // }
                setMasteryScore(event.target.value);
              },
              value: masteryScore,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={2}>
          <FormControlLabel
            style={{ marginTop: '15%' }}
            control={
              <Switch
                value={showResult}
                onChange={(e) => {
                  setShowResult(e.target.checked);
                }}
                checked={showResult}
              />
            }
            label={<small>Show Result</small>}
          />
        </GridItem>
      </GridContainer>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={3}>
          <CustomInput
            success={durationState === "success"}
            error={durationState === "error"}
            labelText={<span>Duration <small> Range 1-360 minutes (required)</small></span>}
            id="duration"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: "number",
              onChange: (event) => {
                if (event.target.value > 0 && event.target.value <= 360) {
                  setDuration(event.target.value);
                  setDurationState('success');
                } else if (event.target.value <= 0 || event.target.value > 360) {
                  setDurationState('error');
                  setDuration('');
                } else {
                  setDuration('');
                  setDurationState('success');
                }
              },
              value: duration,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={2}>
          <FormControlLabel
            style={{ marginTop: '15%' }}
            control={
              <Switch
                value={showTimer}
                onChange={(e) => {
                  setShowTimer(e.target.checked);
                }}
                checked={showTimer}
              />
            }
            label={<small>Show Timer</small>}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={3}>
          <CustomSelect
            name="difficultyLevel"
            options={[
              {
                id: 1,
                name: "Easy",
              },
              {
                id: 2,
                name: "Medium",
              },
              {
                id: 3,
                name: "Difficult",
              },
            ]}
            setvalue={(v) => setDifficultyLevel(v)}
            value={difficultyLevel}
            label="Difficulty Level"
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={2}>
          <CustomSelect
            name="status"
            options={[
              {
                id: 1,
                name: "Draft",
              },
              {
                id: 2,
                name: "Ready",
              }
            ]}
            setvalue={(v) => setStatus(v)}
            value={status}
            label="Status"
          />
        </GridItem>

      </GridContainer>

      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={2}>
          {
            isPrivilege("AttemptsAllowed") == true ?
              <CustomInput
                success={attemptsAllowedState === "success"}
                error={attemptsAllowedState === "error"}
                labelText={<span>Attempts Allowed <small>(required)</small></span>}
                id="attemptsAllowed"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  type: "number",
                  onChange: (event) => {
                    if (event.target.value > 0) {
                      setAttemptsAllowed(event.target.value);
                      setAttemptsAllowedState('success')
                    }
                    else if (event.target.value == null || attemptsAllowed == null) {
                      setAttemptsAllowedState('error')
                      setAttemptsAllowed('')
                    } else {
                      setAttemptsAllowedState('error')
                      setAttemptsAllowed('')
                    }
                  },
                  value: attemptsAllowed,
                }}
              />
              : ""
          }
        </GridItem>

        <GridItem xs={12} sm={12} md={2}>
          {
            isPrivilege("QuestionsPerPage") == true ?
              <CustomInput
                success={questionsPerPageState === "success"}
                error={questionsPerPageState === "error"}
                labelText={
                  <span>
                    Questions per Page <small>( 1 to 20 )(required)</small>
                  </span>
                }
                id="questionsPerPage"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  type: "number",
                  onChange: (event) => {
                    if (!verifyRange(event.target.value, 1, 20)) {
                      setQuestionsPerPageState("error");
                    } else {
                      setQuestionsPerPageState("success");
                    }
                    setQuestionsPerPage(event.target.value);
                  },
                  value: questionsPerPage,
                }}
              />
              : ""
          }
        </GridItem>

        <GridItem xs={12} sm={12} md={3}>
          <CustomInput
            success={pointsPerQuestionsState === "success"}
            error={pointsPerQuestionsState === "error"}
            labelText={<span>Points per Question<small>(required)</small></span>}
            id="pointsPerQuestion"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: "number",
              onChange: (event) => {
                if (event.target.value > 0) {
                  setPointsPerQuestion(event.target.value);
                  setPointsPerQuestionState('success');
                } else {
                  setPointsPerQuestionState('error');
                  setPointsPerQuestion('');
                }
                // setPointsPerQuestion(event.target.value);
              },
              value: pointsPerQuestion,
            }}
          />
        </GridItem>

        <GridItem xs={12} sm={12} md={3}>
          {
            isPrivilege("NegativeMarks") == true ?
              <CustomInput
                success={negativePointsPerQuestionsState === "success"}
                error={negativePointsPerQuestionsState === "error"}
                labelText={<span>Negative Points per Question</span>}
                id="negativePointsPerQuestion"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  type: "number",
                  onChange: (event) => {

                    if (event.target.value <= 0) {
                      setNegativePointsPerQuestion(event.target.value);
                      setNegativePointsPerQuestionState('success');
                    } else {
                      setNegativePointsPerQuestionState('error');
                      setNegativePointsPerQuestion('');
                    }
                    //setNegativePointsPerQuestion(event.target.value);
                  },
                  value: negativePointsPerQuestion,
                }}
              />
              : ''
          }
        </GridItem>
      </GridContainer>
      <GridContainer justify="center">
        {/* <GridItem xs={12} sm={12} md={2}>
          <CustomInput
            success={expiryDateState === "success"}
            error ={expiryDateState === "error"}
            labelText={<span>Expiry Date</span>}
            id="expiryDate"
            formControlProps={{
              fullWidth: true,
            }}
            labelProps={{
              shrink: true,
            }}
            inputProps={{
              ref: dateRef,
              type: "datetime-local",
              onChange: (event) => {
              
                setExpiryDate(event.target.value);
              },
              value: expiryDate,
            }}
          />
        </GridItem> */}
        <GridItem xs={12} sm={12} md={2}>
          <FormControlLabel
            style={{ marginTop: '15%' }}
            control={
              <Switch
                value={navigationAllowed}
                onChange={(e) => {
                  setNavigationAllowed(e.target.checked);
                }}
                checked={navigationAllowed}
              />
            }
            label={<small>Navigation Allowed</small>}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={2}>
          {
            isPrivilege("ShowHint") == true ?
              <FormControlLabel
                style={{ marginTop: '15%' }}
                control={
                  <Switch
                    value={showHint}
                    onChange={(e) => {
                      setShowHint(e.target.checked);
                    }}
                    checked={showHint}
                  />
                }
                label={<small>Show Hint</small>}
              />
              : ""
          }
        </GridItem>
        <GridItem xs={12} sm={12} md={2}>
          {
            isPrivilege("Bookmark") == true ?
              <FormControlLabel
                style={{ marginTop: '15%' }}
                control={
                  <Switch
                    value={showBookmark}
                    onChange={(e) => {
                      setShowBookmark(e.target.checked);
                    }}
                    checked={showBookmark}
                  />
                }
                label={<small>Show Bookmark</small>}
              />
              : ""
          }
        </GridItem>


        <GridItem xs={12} sm={12} md={2}>
          {/* <FormControlLabel
            style={{ marginTop: '15%' }}
            control={
              <Switch
                value={isLocked}
                onChange={(e) => {
                  setIsLocked(e.target.checked);
                }}
                checked={isLocked}
              />
            }
            label={<small>Is Locked</small>}
          /> */}
        </GridItem>
        <GridItem xs={12} sm={12} md={2}>
        </GridItem>
      </GridContainer>
    </GridContainer>
  );
});

TestConfiguration.displayName = "Step1";

export default TestConfiguration;
